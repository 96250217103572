import type { Feature } from 'src/modules/FeatureToggler/types';

const STORAGE_KEY = 'adv-shopfront-features';

export const getLocalStorageFeatureState = (): Feature[] => {
  try {
    return JSON.parse(localStorage.getItem(STORAGE_KEY) || '')?.features;
  } catch {
    return [];
  }
};

export const setLocalStorageFeatureState = (features: Feature[]) => {
  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify({ features }));
  } catch {}
};
