import MainLayout from 'src/views/layouts/MainLayout/MainLayout';

import type { PageRouteProps } from './types';

export const UnauthenticatedPageRoute = ({
  activeTabId,
  element,
  renderBasicHeader,
  currentPage,
}: PageRouteProps) => (
  <MainLayout
    activeTabId={activeTabId}
    authenticationStatus="unauthenticated"
    renderBasicHeader={renderBasicHeader}
    analyticsCurrentPage={currentPage}
  >
    {element}
  </MainLayout>
);
