import * as countryCodes from 'country-codes-list';

import { filterUnsupportedCountries } from 'src/utils/filterUnsupportedCountries';

export interface CountryMap {
  name: string;
  countryCode: string;
  callingCode?: string;
}

const format = (arr: any) =>
  arr
    .map((country: any) => ({
      name: country.countryNameEn,
      countryCode: country.countryCode,
      callingCode: country.countryCallingCode,
    }))
    .sort(function (a: CountryMap, b: CountryMap) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

export const allCountryCodes: CountryMap[] = filterUnsupportedCountries(
  format(countryCodes.all()),
);

export const seekCountryCodes: CountryMap[] = format(
  countryCodes
    .all()
    .filter(
      (country) =>
        country.countryCode === 'AU' ||
        country.countryCode === 'HK' ||
        country.countryCode === 'ID' ||
        country.countryCode === 'MY' ||
        country.countryCode === 'NZ' ||
        country.countryCode === 'PH' ||
        country.countryCode === 'SG' ||
        country.countryCode === 'TH',
    ),
);
