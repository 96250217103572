import type { EmbedReferences } from '@graphcms/rich-text-types';
import { useTrackLink } from '@seek/cmsu-analytics';
import {
  type BannerShopfrontFragment,
  CParagraphMarginBottom,
  CTextAlign,
} from '@seek/cmsu-cms-connect';
import { Heading, Paragraph } from '@seek/cmsu-components';
import { RichText } from '@seek/cmsu-rich-text';
import { siteNames } from '@seek/melways-sites';
import {
  Box,
  Hidden,
  Stack,
  Text,
  Strong,
  TextLink,
  Alert,
  PageBlock,
  Bleed,
} from 'braid-design-system';

import { useConfig } from 'src/hooks/context';
import type { EmployerSiteName } from 'src/types';
import EmailCaptureFormContainer from 'src/views/shared/forms/EmailCaptureForm/EmailCaptureFormContainer';

import { bgImage, container, content, root } from './HeroBanner.css';

const renderDescription = (paragraph: any, site: EmployerSiteName) =>
  site === siteNames.employer.seek.au ? (
    <Paragraph
      content={paragraph.Paragraph_text?.raw}
      marginBottom={CParagraphMarginBottom.None}
      align={paragraph.align}
      tone={paragraph.tone}
      size={paragraph.size}
    />
  ) : (
    <Alert tone="promote">
      <Paragraph
        content={paragraph.Paragraph_text?.raw}
        marginBottom={CParagraphMarginBottom.None}
        align={CTextAlign.Left}
      />
    </Alert>
  );

type Props = Pick<
  BannerShopfrontFragment,
  'heading' | 'image' | 'paragraph'
> & {
  footnotes: BannerShopfrontFragment['BannerShopfront_footnotes'];
};

const BannerImage = ({
  image,
  position,
}: {
  image: BannerShopfrontFragment['image'];
  position: string;
}) => (
  <Box
    className={bgImage}
    style={{
      ...(image && { backgroundImage: `url(${image.url})` }),
      backgroundPosition: position,
    }}
  />
);

export const HeroBanner = ({ footnotes, heading, image, paragraph }: Props) => {
  const { site } = useConfig();

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      paddingTop={{ mobile: 'xlarge', tablet: 'none' }}
      className={root}
      data-testid="HeroBannerTestId"
    >
      <PageBlock>
        <Hidden below="tablet">
          <BannerImage image={image} position="center" />
        </Hidden>
        <Box className={container}>
          <Box
            position="relative"
            zIndex={1}
            flexGrow={{ mobile: 1 }}
            className={content}
            background="surface"
            borderRadius="xlarge"
            padding={{ tablet: 'xlarge' }}
          >
            <Stack space="large">
              <Box textAlign={{ mobile: 'center', tablet: 'left' }}>
                <Stack space={{ mobile: 'medium', tablet: 'large' }}>
                  {heading?.text ? <Heading {...heading} /> : null}
                  {renderDescription(paragraph, site)}
                </Stack>
              </Box>

              <Stack space="none" align="left">
                <EmailCaptureFormContainer />
              </Stack>
              {footnotes && (
                <Stack
                  space="small"
                  align={{ mobile: 'center', tablet: 'left' }}
                >
                  {footnotes.map((footnote, index) => (
                    <RichText
                      key={`rte-${index}`}
                      content={footnote.richTextEditorWrapper?.content?.raw}
                      references={
                        footnote.richTextEditorWrapper?.content
                          ?.references as EmbedReferences
                      }
                      renderers={{
                        embed: {
                          EmbedSingleLineText: ({ value, bold }) =>
                            bold ? (
                              <Strong>{value}</Strong>
                            ) : (
                              <span>{value}</span>
                            ),
                        },
                        link: {
                          EmbedSingleLineText: ({
                            value,
                            children,
                            nodeId,
                          }) => {
                            const onClickLink = useTrackLink(nodeId);
                            return (
                              <TextLink
                                onClick={() =>
                                  onClickLink ? onClickLink() : undefined
                                }
                                href={value}
                              >
                                {children}
                              </TextLink>
                            );
                          },
                        },
                        p: ({ children }) => (
                          <Text
                            size="small"
                            align={{ mobile: 'center', tablet: 'left' }}
                          >
                            {children}
                          </Text>
                        ),
                      }}
                    />
                  ))}
                </Stack>
              )}

              <Hidden above="mobile">
                <Bleed horizontal="gutter">
                  <Box
                    position="relative"
                    style={{ height: 240, overflow: 'hidden' }}
                  >
                    <BannerImage image={image} position="center right" />
                  </Box>
                </Bleed>
              </Hidden>
            </Stack>
          </Box>
        </Box>
      </PageBlock>
    </Box>
  );
};
