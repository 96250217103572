import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router';

import { ConfigProvider, type ConfigType } from 'src/hooks/context';
import type { PageContentItems } from 'src/types';
import { persistQueryParams } from 'src/utils/queryParams';

import App from './App';

export default ({
  config,
  pagesContent,
}: {
  config: ConfigType;
  pagesContent: PageContentItems;
}) => {
  persistQueryParams();

  const routerProps: any = {};

  hydrateRoot(
    document.getElementById('app') as Element,
    <ConfigProvider config={config}>
      <BrowserRouter {...routerProps}>
        <App pagesContent={pagesContent} />
      </BrowserRouter>
    </ConfigProvider>,
  );
};
