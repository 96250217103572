import 'braid-design-system/reset';
import { ApolloProvider } from '@apollo/client';
import { AnalyticsProvider, type AnalyticsZone } from '@seek/cmsu-analytics';
import { HirerAuthProvider } from '@seek/hirer-auth-react';
import type { Brand, Country } from '@seek/melways-sites';
import { adapter } from '@seek/tealium-adapter';
import { UivBanner } from '@seek/uiv-banner';
import { VocabProvider } from '@vocab/react';
import { BraidProvider, ToastProvider } from 'braid-design-system';
import seekJobs from 'braid-design-system/themes/seekJobs';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router';

import {
  ADDITIONAL_ADVERTISER_PAGE_CLICK_CAJA_LINK,
  ADDITIONAL_ADVERTISER_PAGE_VIEW,
  CONTENT_ELEMENT_PRESSED,
  REGISTER_ACCOUNT_CLICK_AWAY,
  REGISTER_ACCOUNT_ERROR,
  REGISTER_ACCOUNT_FIRST_FOCUS_FIELDS,
  REGISTER_ACCOUNT_FORM_VALIDATION_ERROR,
  REGISTER_ACCOUNT_NAV_CLICKED,
  REGISTER_ACCOUNT_PAGE_VIEW,
  REGISTER_ACCOUNT_SUBMIT,
  REGISTER_ACCOUNT_SUBMIT_SUCCEED,
  REGISTER_ACCOUNT_SWITCH_ACCOUNT,
  REGISTER_COMPLETE_PAGE_VIEW,
  RESEND_ACTIVATION_EMAIL_PRESSED,
  SELECT_ACCOUNT_PAGE_VIEW,
  SELECT_ACCOUNT_PENDING_ALERT_VIEW,
  USER_CONFIRM_PAGE_VIEW,
  TOOLTIP_PRESSED,
} from 'src/constants/pageViewUniqueKeys';
import * as route from 'src/constants/routes';
import { useConfig } from 'src/hooks/context';
import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';
import { FeatureToggleProvider } from 'src/modules/FeatureToggler';
import {
  reportPageEntry,
  reportPagePerformance,
} from 'src/modules/MetricsReporter';
import type { PageContentItems } from 'src/types';
import { initialiseLogger } from 'src/utils/logger/logger';
import AdditionalAdvertiserRegistrationCompletePage from 'src/views/pages/AdditionalAdvertiserRegistrationCompletePage/AdditionalAdvertiserRegistrationCompletePage';
import AgencyRegistrationCompletePage from 'src/views/pages/AgencyRegistrationCompletePage/AgencyRegistrationCompletePage';
import HomePage from 'src/views/pages/HomePage/HomePage';
import { shopfrontAnalyticsEvents } from 'src/views/pages/HomePage/analytics';
import RegistrationCompletePage from 'src/views/pages/RegistrationCompletePage/RegistrationCompletePage';
import RegistrationPage from 'src/views/pages/RegistrationPage/RegistrationPage';
import SecureLinkErrorPage from 'src/views/pages/SecureLinkPages/SecureLinkErrorPage/SecureLinkErrorPage';
import SecureLinkHandlerPage from 'src/views/pages/SecureLinkPages/SecureLinkHandlerPage/SecureLinkHandlerPage';
import SelectAccountPage from 'src/views/pages/SelectAccountPage/SelectAccountPage';
import UserVerificationCompletePage from 'src/views/pages/UserVerificationCompletePage/UserVerificationCompletePage';
import { AuthenticatedPageRoute } from 'src/views/shared/PageRoute/AuthenticatedPageRoute';
import { UnauthenticatedPageRoute } from 'src/views/shared/PageRoute/UnauthenticatedPageRoute';

interface AppProps {
  pagesContent: PageContentItems;
}

const tealiumAdapter = adapter();

const App = ({ pagesContent }: AppProps) => {
  const {
    locale,
    urlResolver,
    version,
    environment,
    zone,
    country,
    language,
    brand,
    branch,
  } = useConfig();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    reportPageEntry(pathname);
    reportPagePerformance(pathname);
  });

  initialiseLogger({
    buildVersion: version,
    environment,
    country,
  });

  const gqlClient = getClient();
  const commonAnalyticsData = {
    brand: brand as Brand,
    siteCountry: country as Country,
    siteLanguage: language,
    zone: zone as AnalyticsZone,
  };

  return (
    <ApolloProvider client={gqlClient}>
      <VocabProvider language={locale}>
        <BraidProvider theme={seekJobs}>
          <HirerAuthProvider>
            <ToastProvider>
              <UivBanner
                applicationName="adv-shopfront"
                branch={branch}
                version={version}
              />
              <FeatureToggleProvider>
                <Routes>
                  <Route
                    path={urlResolver({ path: route.ROUTE_ROOT })}
                    element={
                      <UnauthenticatedPageRoute
                        activeTabId="home"
                        currentPage="shopfront"
                        element={
                          <AnalyticsProvider
                            analytics={{
                              data: {
                                ...commonAnalyticsData,
                                isLoggedIn: false,
                                currentPage: 'shopfront',
                                siteSection: 'shopfront',
                              },
                              tealiumAdapter,
                              events: shopfrontAnalyticsEvents,
                            }}
                          >
                            <HomePage content={pagesContent.home} />
                          </AnalyticsProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path={urlResolver({ path: route.ROUTE_ACCOUNT_SELECT })}
                    element={
                      <AuthenticatedPageRoute
                        currentPage="account selection"
                        element={
                          <AnalyticsProvider
                            analytics={{
                              data: {
                                ...commonAnalyticsData,
                                isLoggedIn: true,
                                currentPage: 'account selection',
                                siteSection: 'home',
                              },
                              tealiumAdapter,
                              events: {
                                [SELECT_ACCOUNT_PAGE_VIEW]: {
                                  eventName: 'account_selection_displayed',
                                },
                                [SELECT_ACCOUNT_PENDING_ALERT_VIEW]: {
                                  eventName: SELECT_ACCOUNT_PENDING_ALERT_VIEW,
                                  linkName: SELECT_ACCOUNT_PENDING_ALERT_VIEW,
                                },
                                [CONTENT_ELEMENT_PRESSED]: {
                                  eventName: CONTENT_ELEMENT_PRESSED,
                                },
                                [RESEND_ACTIVATION_EMAIL_PRESSED]: {
                                  eventName: RESEND_ACTIVATION_EMAIL_PRESSED,
                                },
                                [TOOLTIP_PRESSED]: {
                                  eventName: TOOLTIP_PRESSED,
                                },
                              },
                            }}
                          >
                            <SelectAccountPage />
                          </AnalyticsProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path={urlResolver({
                      path: route.ROUTE_ACCOUNT_REGISTRATION,
                    })}
                    element={
                      <AuthenticatedPageRoute
                        currentPage="account registration"
                        element={
                          <AnalyticsProvider
                            analytics={{
                              data: {
                                ...commonAnalyticsData,
                                isLoggedIn: true,
                                currentPage: 'account registration',
                                siteSection: 'account registration',
                              },
                              tealiumAdapter,
                              events: {
                                [REGISTER_ACCOUNT_PAGE_VIEW]: {
                                  eventName: REGISTER_ACCOUNT_PAGE_VIEW,
                                },
                                [REGISTER_ACCOUNT_SUBMIT]: {
                                  eventName: REGISTER_ACCOUNT_SUBMIT,
                                  actionOrigin: 'account registration form',
                                },
                                [REGISTER_ACCOUNT_CLICK_AWAY]: {
                                  eventName: REGISTER_ACCOUNT_CLICK_AWAY,
                                },
                                [REGISTER_ACCOUNT_FIRST_FOCUS_FIELDS]: {
                                  eventName:
                                    REGISTER_ACCOUNT_FIRST_FOCUS_FIELDS,
                                },
                                [REGISTER_ACCOUNT_FORM_VALIDATION_ERROR]: {
                                  eventName:
                                    REGISTER_ACCOUNT_FORM_VALIDATION_ERROR,
                                },
                                [REGISTER_ACCOUNT_SUBMIT_SUCCEED]: {
                                  eventName: REGISTER_ACCOUNT_SUBMIT_SUCCEED,
                                },
                                [REGISTER_ACCOUNT_SWITCH_ACCOUNT]: {
                                  eventName: REGISTER_ACCOUNT_SWITCH_ACCOUNT,
                                },
                                [REGISTER_ACCOUNT_ERROR]: {
                                  eventName: REGISTER_ACCOUNT_ERROR,
                                },
                                [REGISTER_ACCOUNT_NAV_CLICKED]: {
                                  eventName: REGISTER_ACCOUNT_NAV_CLICKED,
                                },
                                [TOOLTIP_PRESSED]: {
                                  eventName: TOOLTIP_PRESSED,
                                },
                              },
                            }}
                          >
                            <RegistrationPage />
                          </AnalyticsProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path={urlResolver({
                      path: route.ROUTE_REGISTER_ADVERTISER_COMPLETE,
                    })}
                    element={
                      <UnauthenticatedPageRoute
                        renderBasicHeader
                        currentPage="account registration complete"
                        element={
                          <AnalyticsProvider
                            analytics={{
                              data: {
                                ...commonAnalyticsData,
                                isLoggedIn: false,
                                currentPage: 'account registration complete',
                                siteSection: 'account registration',
                              },
                              tealiumAdapter,
                              events: {
                                [REGISTER_COMPLETE_PAGE_VIEW]: {
                                  eventName:
                                    'account_registration_complete_displayed',
                                },
                              },
                            }}
                          >
                            <RegistrationCompletePage />
                          </AnalyticsProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path={urlResolver({
                      path: route.ROUTE_REGISTER_AGENCY_COMPLETE,
                    })}
                    element={
                      <AuthenticatedPageRoute
                        currentPage="agency registration complete"
                        element={<AgencyRegistrationCompletePage />}
                      />
                    }
                  />
                  <Route
                    path={urlResolver({
                      path: route.ROUTE_REGISTER_ADDITIONAL_ADVERTISER_COMPLETE,
                    })}
                    element={
                      <AuthenticatedPageRoute
                        currentPage="account registration additional confirmation"
                        element={
                          <AnalyticsProvider
                            analytics={{
                              data: {
                                brand: brand as Brand,
                                isLoggedIn: true,
                                siteCountry: country as Country,
                                siteLanguage: language,
                                zone: zone as AnalyticsZone,
                                currentPage:
                                  'account registration additional confirmation',
                                siteSection: 'account registration',
                              },
                              tealiumAdapter,
                              events: {
                                [ADDITIONAL_ADVERTISER_PAGE_VIEW]: {
                                  eventName: ADDITIONAL_ADVERTISER_PAGE_VIEW,
                                  registrationFlow: 'additional account',
                                },
                                [ADDITIONAL_ADVERTISER_PAGE_CLICK_CAJA_LINK]: {
                                  eventName:
                                    ADDITIONAL_ADVERTISER_PAGE_CLICK_CAJA_LINK,
                                  registrationFlow: 'additional account',
                                  entryPoint:
                                    'account registration additional confirmation',
                                },
                              },
                            }}
                          >
                            <AdditionalAdvertiserRegistrationCompletePage />
                          </AnalyticsProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path={urlResolver({
                      path: route.ROUTE_USER_VERIFICATION_COMPLETE,
                    })}
                    element={
                      <UnauthenticatedPageRoute
                        renderBasicHeader
                        currentPage="user registration confirmation"
                        element={
                          <AnalyticsProvider
                            analytics={{
                              data: {
                                ...commonAnalyticsData,
                                isLoggedIn: false,
                                currentPage: 'user registration confirmation',
                                siteSection: 'user registration',
                              },
                              tealiumAdapter,
                              events: {
                                [USER_CONFIRM_PAGE_VIEW]: {
                                  eventName:
                                    'user_registration_confirmation_displayed',
                                },
                              },
                            }}
                          >
                            <UserVerificationCompletePage />
                          </AnalyticsProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path={urlResolver({
                      path: route.ROUTE_COMPLETE_USER_REGISTRATION,
                    })}
                    element={
                      <UnauthenticatedPageRoute
                        renderBasicHeader
                        currentPage="complete user registration"
                        element={
                          <SecureLinkHandlerPage flow="completeUserRegistration" />
                        }
                      />
                    }
                  />
                  <Route
                    path={urlResolver({
                      path: route.ROUTE_COMPLETE_ADDITIONAL_ADVERTISER_REGISTRATION,
                    })}
                    element={
                      <UnauthenticatedPageRoute
                        renderBasicHeader
                        currentPage="complete additional advertiser registration"
                        element={
                          <SecureLinkHandlerPage flow="CompleteAdditionalAdvertiserRegistration" />
                        }
                      />
                    }
                  />
                  <Route
                    path={urlResolver({
                      path: route.ROUTE_COMPLETE_USER_REGISTRATION_ERROR,
                    })}
                    element={
                      <UnauthenticatedPageRoute
                        renderBasicHeader
                        currentPage="complete user registration error"
                        element={<SecureLinkErrorPage />}
                      />
                    }
                  />
                  <Route
                    path={urlResolver({
                      path: route.ROUTE_COMPLETE_ADDITIONAL_ADVERTISER_REGISTRATION_ERROR,
                    })}
                    element={
                      <UnauthenticatedPageRoute
                        renderBasicHeader
                        currentPage="complete additional advertiser registration error"
                        element={<SecureLinkErrorPage />}
                      />
                    }
                  />
                </Routes>
              </FeatureToggleProvider>
            </ToastProvider>
          </HirerAuthProvider>
        </BraidProvider>
      </VocabProvider>
    </ApolloProvider>
  );
};

export default App;
