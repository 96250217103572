import gql from 'graphql-tag';

import type {
  RegisterNewUserInput,
  RegisterPayload,
} from 'src/graphql/generated';
import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';

export const mutation = gql`
  mutation RegisterNewUser($input: RegisterNewUserInput!) {
    registerNewUser(input: $input) {
      seekId
      redirectUrl
    }
  }
`;

export type RegisterNewUser = (
  input: RegisterNewUserInput,
) => Promise<RegisterPayload>;

const registerNewUser: RegisterNewUser = async (input) => {
  const graphqlClient = getClient();
  const {
    data: {
      registerNewUser: { advertiserId, seekId, redirectUrl },
    },
  } = await graphqlClient.mutate({
    variables: { input },
    mutation,
  });

  return {
    advertiserId,
    seekId,
    redirectUrl,
  };
};

export default registerNewUser;
