import { createForm, type RenderParams } from '@seek/forms-ui';
import { useState } from 'react';

import { useConfig } from 'src/hooks/context';
import { registerUser } from 'src/modules/AuthHandler';

import EmailCaptureForm from './EmailCaptureForm';

const FORM_NAME = 'EmailCapture';

interface FormValues {
  email: string;
}

type FormProviderProps = RenderParams<FormValues>;

const { FormProvider, useField } = createForm<FormValues>(FORM_NAME);

const EmailCaptureFormContainer = () => {
  const [isProcessingForm, setIsProcessingForm] = useState(false);
  const { locale } = useConfig();

  const submitForm = async ({ email }: FormValues): Promise<any> => {
    setIsProcessingForm(true);
    registerUser({ email });
  };

  return (
    <FormProvider initialValues={{ email: '' }} language={locale}>
      {({ handleSubmit }: FormProviderProps) => (
        <EmailCaptureForm
          onSubmit={handleSubmit(submitForm)}
          isProcessingForm={isProcessingForm}
        />
      )}
    </FormProvider>
  );
};

export { useField };

export default EmailCaptureFormContainer;
