import { URL_SIGNIN } from '@seek/adv-constants';
import { useTrackView } from '@seek/cmsu-analytics';
import { useTranslations } from '@vocab/react';
import { Text } from 'braid-design-system';
import { useEffect } from 'react';

import { USER_CONFIRM_PAGE_VIEW } from 'src/constants/pageViewUniqueKeys';
import { EmptyPageLayout } from 'src/views/layouts/EmptyPageLayout/EmptyPageLayout';
import { IconEmailVerified } from 'src/views/shared/icons/IconEmailVerified';

import translations from './.vocab';

const UserVerificationCompletePage = () => {
  const { t } = useTranslations(translations);

  const trackFunction = useTrackView(USER_CONFIRM_PAGE_VIEW);

  useEffect(() => {
    if (trackFunction) {
      trackFunction();
    }
    setTimeout(() => {
      window.location.assign(URL_SIGNIN);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EmptyPageLayout
      heading={t('Email is confirmed title')}
      image={<IconEmailVerified />}
    >
      <Text>{t('Resume posting job ad message')}</Text>
    </EmptyPageLayout>
  );
};

export default UserVerificationCompletePage;
