import { URL_DASHBOARD, URL_ROOT } from '@seek/adv-constants';
import { ManagedContentBanner } from '@seek/cms-ui';
import { useTrackView } from '@seek/cmsu-analytics';
import { Stage } from '@seek/cmsu-cms-connect';
import { PageComponent } from '@seek/cmsu-components';
import { useHirerAuth } from '@seek/hirer-auth-react';
import type { Country } from '@seek/melways-sites';
import { Text, Stack, Alert, PageBlock } from 'braid-design-system';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';

import { CMS_PAGE_SLUG_HOME } from 'src/constants/cmsPageSlugs';
import { HOME_PAGE_VIEW } from 'src/constants/pageViewUniqueKeys';
import { useConfig } from 'src/hooks/context';
import { getCmsPageContent } from 'src/modules/ApiClient/cms/queries/getCmsPageContent';
import type { CmsPageContent } from 'src/types';
import { isLocalDev } from 'src/utils/environment';
import {
  blockItemRenderers,
  customBannerRenderers,
} from 'src/views/pages/pageRenderers';
import SeoMeta from 'src/views/shared/SeoMeta/SeoMeta';

import './HomePage.css';
import { PromotionalBanner } from './components/PromotionalBanner/PromotionalBanner';

interface HomePageProps {
  content?: CmsPageContent;
}

const HomePage = ({ content }: HomePageProps) => {
  const [cmsContent, setCmsContent] = useState(content);

  const {
    absoluteEmployerUrlResolver,
    country,
    environment,
    isAnz,
    language,
    locale,
    previewToken,
    site,
    urlResolver,
  } = useConfig();
  const { authenticated } = useHirerAuth();
  const [searchParams] = useSearchParams();
  const trackFunction = useTrackView(HOME_PAGE_VIEW);

  useEffect(() => {
    if (trackFunction) {
      trackFunction();
    }

    // Fetch the draft/preview content from Hygraph if
    // the 'hygraph-preview' query param exists
    const fetchContent = async () => {
      const data = await getCmsPageContent({
        environment,
        site,
        locale,
        pageSlug: CMS_PAGE_SLUG_HOME,
        previewToken,
        stage: Stage.Draft,
      });
      setCmsContent(data);
    };

    if (Boolean(searchParams.get('hygraph-preview'))) {
      fetchContent();
    }

    if (isLocalDev(environment)) {
      // eslint-disable-next-line no-console
      console.log('HomePage | cmsContent:', cmsContent);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authenticated) {
      window.location.assign(urlResolver({ path: URL_DASHBOARD }));
    }
  }, [authenticated, urlResolver]);

  // TEMP: Hide CMS testimonials for non ANZ sites
  // This can be removed once testimonials become available across all APAC sites in CMS
  useEffect(() => {
    const element = document.getElementById('testimonials');
    if (element) {
      if (!isAnz) {
        element.style.display = 'none';
      } else {
        element.style.display = '';
      }
    }
  }, [isAnz]);

  const fullURL = absoluteEmployerUrlResolver(URL_ROOT);

  return (
    <>
      <ManagedContentBanner
        platform="employer"
        context="hirer_shopfront"
        environment={environment}
        locale={locale}
      />

      {cmsContent ? (
        <>
          <SeoMeta
            title={cmsContent.seo?.title ?? 'SEEK Employer'}
            description={cmsContent.seo?.description ?? ''}
            url={fullURL}
          />
          <PromotionalBanner />
          <PageComponent
            language={language}
            melwaysLocale={locale}
            country={country.toLowerCase() as Country}
            fullURL={fullURL}
            page={cmsContent}
            wptSalesForceApi=""
            subscriptionFormPrivacyUrl=""
            subscriptionFormSourceName=""
            renderers={blockItemRenderers}
            bannerRenderers={customBannerRenderers}
          />
        </>
      ) : (
        // This _should_ never happen but in case it does, let the user know
        <Stack space="xlarge">
          <PageBlock>
            <Alert tone="critical">
              <Stack space="small">
                <Text weight="strong">Error loading page content</Text>
                <Text>
                  We were unable to fetch the content for this page. Please try
                  again or check back later.
                </Text>
              </Stack>
            </Alert>
          </PageBlock>
        </Stack>
      )}
    </>
  );
};

export default HomePage;
