import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends Record<string, unknown>,
  K extends keyof T,
> = Partial<Record<K, never>>;
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  LanguageCodeIso: { input: any; output: any };
  SafeInt: { input: any; output: any };
};

export type AccessCv = {
  __typename?: 'AccessCv';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type AccessCvState =
  | AccessCv
  | ConnectionPending
  | CvUnavailable
  | DownloadCv
  | InsufficientCredits;

export type AccessEmailReceiverInput = {
  /** User ID only used for logging/audit purposes */
  auditUserId: Scalars['String']['input'];
  /** Email of the user */
  email: Scalars['String']['input'];
  /** First name of the user */
  firstName: Scalars['String']['input'];
};

export type AccessProfile = {
  __typename?: 'AccessProfile';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type AccessProfileAndCv = {
  __typename?: 'AccessProfileAndCv';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type AccessProfileState =
  | AccessProfile
  | AccessProfileAndCv
  | ConnectionPending
  | DownloadProfile
  | InsufficientCredits
  | ProfileAndCvUnavailable;

export enum AccessRightFilter {
  ACTIVE = 'ACTIVE',
  ADMIN_ROLE = 'ADMIN_ROLE',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
  USER_ROLE = 'USER_ROLE',
}

export enum AccessRightStatus {
  /** User association with advertiser is active. */
  ACTIVE = 'ACTIVE',
  /** User association with advertiser has been deactivated (equivalent to deleted). */
  DEACTIVATED = 'DEACTIVATED',
  /** Awaiting email verification. */
  PENDING = 'PENDING',
}

export enum AccountActivationStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export type AccountBillingAddressInput = {
  /** Address line of where the Advertiser would like billings to go to */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Additional address line of where the advertiser would like their billing to go to. Can be left blank if it is not needed */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Address lines of where the Advertiser would like billings to go to */
  addressLines?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Country code of where the Advertiser would like billings to go to */
  countryCodeAlpha2: Scalars['String']['input'];
  /** Postcode in which the Advertiser would like billings to go to */
  postCode?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek billing account ID used by CRM
   * @deprecated Internally resolved via auth
   */
  seekBillingAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek ID
   * @deprecated Internally resolved via auth
   */
  seekId?: InputMaybe<Scalars['String']['input']>;
  /**
   * State in which the Advertiser would like billings to go to.
   * Can be NULL if their billing address is a country outside of Australia
   */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Suburb in which the Advertiser would like billings to go to */
  suburb: Scalars['String']['input'];
};

export type AccountBillingEmailAddressInput = {
  /** Email address to send invoices, outstanding balance notifications etc. */
  billingEmail: Scalars['String']['input'];
  /**
   * Seek billing account ID used by CRM
   * @deprecated Internally resolved via auth
   */
  seekBillingAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek ID used in Hirer Accounts API.
   * @deprecated Internally resolved via auth
   */
  seekId?: InputMaybe<Scalars['String']['input']>;
};

export type AccountBusinessIdentifiersInput = {
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  salesServiceTaxNumber?: InputMaybe<Scalars['String']['input']>;
  taxIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AccountDetailsUpdateResponse = {
  __typename?: 'AccountDetailsUpdateResponse';
  /** Response message we receive from calling the account/details API */
  message?: Maybe<Scalars['String']['output']>;
};

export type AccountHierarchyOption = {
  __typename?: 'AccountHierarchyOption';
  count: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type AccountPostalAddressInput = {
  /** Address line of where the Advertiser resides */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Additional address line of where the advertiser resides. Can be left blank if it is not needed */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Address lines of where the Advertiser resides */
  addressLines?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Country code of where the Advertiser resides */
  countryCodeAlpha2: Scalars['String']['input'];
  /** Postcode in which the Advertiser resides */
  postCode?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek billing account ID used by CRM
   * @deprecated Internally resolved via auth
   */
  seekBillingAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek ID
   * @deprecated Internally resolved via auth
   */
  seekId?: InputMaybe<Scalars['String']['input']>;
  /**
   * State in which the Advertiser resides
   * Can be NULL if their postal address is a country outside of Australia
   */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Suburb in which the Advertiser resides */
  suburb: Scalars['String']['input'];
  /** Boolean to signify if the user would the postal address to be the same as the billing address */
  useForBillingAddress: Scalars['Boolean']['input'];
};

export type AccountSelectionEdgeV3 = {
  __typename?: 'AccountSelectionEdgeV3';
  node: HirerAccountOption;
};

export type AccountSelectionQueryInput = {
  /** Return uri for successful account switching. */
  returnUri?: InputMaybe<Scalars['String']['input']>;
};

export type AccountSelectionResultsV3 = {
  __typename?: 'AccountSelectionResultsV3';
  defaultAccount?: Maybe<HirerAccountOption>;
  edges: AccountSelectionEdgeV3[];
  totalCount: Scalars['Int']['output'];
};

export type AccountSelectionV3Response =
  | AccountSelectionResultsV3
  | ResponseError;

export type ActionLink = {
  __typename?: 'ActionLink';
  viewProfile?: Maybe<Scalars['String']['output']>;
};

export type Actor = {
  __typename?: 'Actor';
  /** Email address of the actor. */
  email: Scalars['String']['output'];
  /** The Auth0 user id of the actor, eg 'samlp|seek-okta-global|tkilvington@seek.com.au'. */
  id: Scalars['String']['output'];
};

export type ActorContext = {
  __typename?: 'ActorContext';
  /** Url to toggle customer service mode. */
  csToggleHref: Scalars['String']['output'];
  /**
   * Text of CS toggle button.
   * @deprecated Text defined in client for translations purposes. Can use isCsView.
   */
  csToggleText?: Maybe<Scalars['String']['output']>;
  /** Whether the actor is in Customer Service View. */
  isCsView: Scalars['Boolean']['output'];
  /** Email address of the CS user. */
  operatorEmail: Scalars['String']['output'];
};

export type Ad = Product & {
  __typename?: 'Ad';
  adType: AdType;
  /** To show or hide the price */
  hidePrice: Scalars['Boolean']['output'];
  metadata: AdMetaData;
  name: Scalars['String']['output'];
  price?: Maybe<Price>;
  productType: ProductType;
};

export type AdBudget = Product & {
  __typename?: 'AdBudget';
  ads: AdBudgetType;
  id: Scalars['ID']['output'];
  metadata: AdBudgetMetadata;
  name: Scalars['String']['output'];
  productType: ProductType;
  value: Money;
};

export type AdBudgetAttributes = {
  /** Id of selected Ad Budget. */
  budgetId: Scalars['ID']['input'];
};

export type AdBudgetCartItem = CartItemProduct & {
  __typename?: 'AdBudgetCartItem';
  id: Scalars['String']['output'];
};

export type AdBudgetMetadata = {
  __typename?: 'AdBudgetMetadata';
  /** The ad posting frequency of budgets */
  adPostingFrequency: Scalars['String']['output'];
  /** Length of the contract in months */
  discountDurationMonths: Scalars['Int']['output'];
  /** Title used when displaying this product as a line item of a purchase */
  displayItemTitle: Scalars['String']['output'];
  /** Set to true when a discount is offered on standOut ads */
  hasStandOutDiscount: Scalars['Boolean']['output'];
  /** Approximate amount to convey the savings on ads as a percentage */
  percentageSavings: Scalars['Float']['output'];
};

export type AdBudgetProduct = CatalogueProduct & {
  __typename?: 'AdBudgetProduct';
  /** Display label for Ad posting frequency. Eg Occasional, Regular, Frequent. */
  adPostingFrequency: Scalars['String']['output'];
  /** Display label for Ad posting range. */
  adRange: Range;
  /** Information on how to display the product */
  display?: Maybe<ProductDisplay>;
  /** Features of the adbudget product */
  features: Array<Maybe<CatalogueProductFeature>>;
  /** The group that the budget is in */
  group?: Maybe<BudgetProductGroup>;
  /** Unique ID of the adbudget product */
  id: Scalars['ID']['output'];
  /** name of the adbudget product */
  name: Scalars['String']['output'];
  /** Price of the adbudget product, or price access level (priceVisibility) if hidePrice is enabled for the hirer */
  price: CatalogueProductPrice;
  /** The unique product code for this product. Human-readable alternative to ID */
  productCode?: Maybe<Scalars['String']['output']>;
  /** List of short labels describing products that can be purchased from the budget */
  purchasableProducts: PurchasableProducts;
  /** Whether the product is "purchasable" or was previously "purchased" */
  purchaseState: CatalogueProductPurchaseState;
};

export type AdBudgetProductList = {
  __typename?: 'AdBudgetProductList';
  /** A list of ad budget products */
  items: AdBudgetProduct[];
};

export type AdBudgetProductsForUpsellInput = {
  /**
   * Id of the advertiser.
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /** The locale to get product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** List of product ids */
  productIds: Array<Scalars['ID']['input']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID']['input'];
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
};

export type AdBudgetProductsForUpsellResponse =
  | AdBudgetProductList
  | ProductSetExpiredError
  | ProductSetNotFoundError;

export enum AdBudgetType {
  BRANDED_ONWARDS = 'BRANDED_ONWARDS',
  CLASSIC_ONWARDS = 'CLASSIC_ONWARDS',
  PREMIUM_ONLY = 'PREMIUM_ONLY',
}

export type AdBudgetsByAdvertiserInput = {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * ISO Alpha-2 country code based on the advertiser's billing address.
   * This is useful to ensure we surface the budgets matching the hirer's location
   * and we may not have their billing address in the system yet. Eg: when new NZ hirers
   * who register and create a Job at the same time on AU website. The NZ budgets will be
   * surfaced on AU site as upsell only if this param is populated by client.
   */
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
};

export type AdMetaData = {
  __typename?: 'AdMetaData';
  description?: Maybe<Scalars['String']['output']>;
  extendsAdType?: Maybe<AdType>;
  features?: Maybe<Array<Maybe<ProductFeature>>>;
  highlightText?: Maybe<Scalars['String']['output']>;
  subTitle?: Maybe<SubTitle>;
  theme?: Maybe<AdTypeTheme>;
  title: Scalars['String']['output'];
};

export type AdPerformanceReportResponse = {
  __typename?: 'AdPerformanceReportResponse';
  success: Scalars['Boolean']['output'];
};

export type AdPerformanceReportWidgetInput = {
  jobId: Scalars['String']['input'];
};

export type AdProduct = CatalogueProduct & {
  __typename?: 'AdProduct';
  /** Addons available for the product */
  addOns: AdProductAddOn[];
  /** Information on how to display the product */
  display?: Maybe<ProductDisplay>;
  /** Product information to display to customers */
  displayMetadata?: Maybe<AdProductDisplayMetadata>;
  /** Expectations for a draft */
  expectationForDraft?: Maybe<Expectation>;
  /** Expectations for a job */
  expectationForJob?: Maybe<Expectation>;
  features: Array<Maybe<CatalogueProductFeature>>;
  id: Scalars['ID']['output'];
  /** @deprecated Avoid use of this field and consider displayMetadata.name to display for customers, or use 'features' to make decisions instead */
  name: Scalars['String']['output'];
  price: CatalogueProductPrice;
  pricing?: Maybe<Pricing>;
  /** The unique product code for this product. Human-readable alternative to ID */
  productCode?: Maybe<Scalars['String']['output']>;
  purchaseState: CatalogueProductPurchaseState;
  /** Upgrade paths available for the product */
  upgradePaths: UpgradePath[];
};

export type AdProductexpectationForDraftArgs = {
  draftId?: InputMaybe<Scalars['String']['input']>;
};

export type AdProductexpectationForJobArgs = {
  jobId?: InputMaybe<Scalars['String']['input']>;
};

export type AdProductAddOn = CatalogueProduct & {
  __typename?: 'AdProductAddOn';
  /** Information on how to display the product */
  display?: Maybe<ProductDisplay>;
  /** Legacy display information for the product */
  displayMetadata?: Maybe<AdProductAddOnDisplayMetadata>;
  /** The Flexible Ad Architecture features of the AddOn product */
  features: Array<Maybe<CatalogueProductFeature>>;
  /** The unique identifier of the product */
  id: Scalars['ID']['output'];
  /** The name of the product as it appears in the catalogue */
  name: Scalars['String']['output'];
  /** The price of the product, or price access level (priceVisibility) if hidePrice is enabled for the hirer */
  price: CatalogueProductPrice;
  /** The unique product code for this product. Human-readable alternative to ID */
  productCode?: Maybe<Scalars['String']['output']>;
  purchaseState: CatalogueProductPurchaseState;
};

export type AdProductAddOnCartItem = CartItemProduct & {
  __typename?: 'AdProductAddOnCartItem';
  id: Scalars['String']['output'];
  job?: Maybe<JobReference>;
};

export type AdProductAddOnDisplayMetadata = {
  __typename?: 'AdProductAddOnDisplayMetadata';
  /** Product name that can be shown to customers (for example in an ad ladder) */
  name?: Maybe<Scalars['String']['output']>;
  /** Detailed name where more context or formality is required (e.g. Order summaries, invoicing, CRM) */
  qualifiedName?: Maybe<Scalars['String']['output']>;
  /** Tagline that can be shown to customers (for example in an ad ladder) */
  tagline?: Maybe<Scalars['String']['output']>;
};

export type AdProductCartItem = CartItemProduct & {
  __typename?: 'AdProductCartItem';
  id: Scalars['String']['output'];
  job?: Maybe<JobReference>;
};

export type AdProductDisplayMetadata = {
  __typename?: 'AdProductDisplayMetadata';
  /** Product name that can be shown to customers (for example in an ad ladder) */
  name?: Maybe<Scalars['String']['output']>;
  /** Description of upgrade product price */
  priceDescription?: Maybe<Scalars['String']['output']>;
  /** Detailed name where more context or formality is required (e.g. Order summaries, invoicing, CRM) */
  qualifiedName?: Maybe<Scalars['String']['output']>;
  /**
   * Url to redirect to the product booking form
   * @deprecated Use display.cta.url instead
   */
  redirectUrl?: Maybe<Scalars['String']['output']>;
  /** Selling points or features of a product */
  sellingPoints?: Maybe<SellingPoint[]>;
  /** Tagline that can be shown to customers (for example in an ad ladder) */
  tagline?: Maybe<Scalars['String']['output']>;
};

export type AdProductSetResponse =
  | DisplayMetadataError
  | ProductList
  | ProductSetExpiredError
  | ProductSetNotFoundError;

export type AdProductUpgrade = CatalogueProduct & {
  __typename?: 'AdProductUpgrade';
  /** Addons available for the product */
  addOns: AdProductAddOn[];
  /** Information on how to display the product */
  display?: Maybe<ProductDisplay>;
  displayMetadata?: Maybe<AdProductDisplayMetadata>;
  /** Expectations for a draft */
  expectationForDraft?: Maybe<Expectation>;
  /** Expectations for a job */
  expectationForJob?: Maybe<Expectation>;
  features: Array<Maybe<CatalogueProductFeature>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price: CatalogueProductPrice;
  /** The unique product code for this product. Human-readable alternative to ID */
  productCode?: Maybe<Scalars['String']['output']>;
  purchaseState: CatalogueProductPurchaseState;
  /** Upgrade paths available for the product */
  upgradePaths: UpgradePath[];
};

export type AdProductUpgradeexpectationForDraftArgs = {
  draftId?: InputMaybe<Scalars['String']['input']>;
};

export type AdProductUpgradeexpectationForJobArgs = {
  jobId?: InputMaybe<Scalars['String']['input']>;
};

export type AdProductUpgradeCartItem = CartItemProduct & {
  __typename?: 'AdProductUpgradeCartItem';
  id: Scalars['String']['output'];
  job?: Maybe<JobReference>;
};

export type AdProductsForAdEditingInput = {
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID']['input'];
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
};

export type AdProductsForAdPostingInput = {
  /**
   * Id of the advertiser.
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID']['input'];
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
};

export type AdRatingLabels = {
  __typename?: 'AdRatingLabels';
  groupLabel?: Maybe<Scalars['String']['output']>;
  locationLabel?: Maybe<Scalars['String']['output']>;
};

export type AdRatingResponse = {
  __typename?: 'AdRatingResponse';
  adRatingConfidence?: Maybe<AnalyticsAdRatingConfidence>;
  adRatingScore?: Maybe<AnalyticsAdRatingScore>;
  applications: Scalars['Int']['output'];
  applicationsBenchmarkHigh?: Maybe<Scalars['Int']['output']>;
  applicationsBenchmarkLow?: Maybe<Scalars['Int']['output']>;
  benchmarkLocationCountry?: Maybe<Scalars['String']['output']>;
  benchmarkLocationLabel?: Maybe<Scalars['String']['output']>;
  benchmarkScenario?: Maybe<AnalyticsBenchmarkScenario>;
  classificationName: Scalars['String']['output'];
  incompleteApplications: Scalars['Int']['output'];
  isLinkOutAd: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  lastUpdatedTimestampUTC: Scalars['DateTime']['output'];
  normalisedRoleTitle?: Maybe<Scalars['String']['output']>;
  subClassificationName: Scalars['String']['output'];
};

export enum AdRatingType {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  NOT_INCLUDED = 'NOT_INCLUDED',
  TOO_SOON_TO_RATE = 'TOO_SOON_TO_RATE',
  UNABLE_TO_RATE = 'UNABLE_TO_RATE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export type AdRatingTypeOption = {
  __typename?: 'AdRatingTypeOption';
  count: Scalars['Int']['output'];
  key: FiltersAdRatingType;
};

export type AdRatingWidgetInput = {
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
};

export enum AdRepost {
  NOT_REPOST = 'NOT_REPOST',
  REPOST = 'REPOST',
}

/** AdBudgetProduct feature to indicate ad spend allowance */
export type AdSpendFeature = CatalogueProductFeature & {
  __typename?: 'AdSpendFeature';
  /** Money allowance to purchase ad products */
  allowance: Money;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AdStatusOption = {
  __typename?: 'AdStatusOption';
  count: Scalars['Int']['output'];
  key: AdUsageAdStatus;
};

export type AdTipsOption = {
  __typename?: 'AdTipsOption';
  count: Scalars['Int']['output'];
  key: AdTipsType;
};

export enum AdTipsType {
  ADD_QUESTIONS = 'ADD_QUESTIONS',
  ADD_SPECIFIC_LOCATION = 'ADD_SPECIFIC_LOCATION',
  INCREASE_SALARY = 'INCREASE_SALARY',
  MAXIMISE_BRANDING = 'MAXIMISE_BRANDING',
  NO_TIPS = 'NO_TIPS',
  REVEAL_SALARY = 'REVEAL_SALARY',
  REVIEW_AD_DETAILS = 'REVIEW_AD_DETAILS',
  REVIEW_AD_SUMMARY = 'REVIEW_AD_SUMMARY',
}

export enum AdType {
  BRANDED = 'BRANDED',
  BRANDED_UPGRADE = 'BRANDED_UPGRADE',
  CLASSIC = 'CLASSIC',
  CONCIERGE = 'CONCIERGE',
  GUARANTEED = 'GUARANTEED',
  PREMIUM = 'PREMIUM',
}

/** Discount for Ad Product by legacy AdType */
export type AdTypeDiscount = {
  __typename?: 'AdTypeDiscount';
  /** Type of the ad. Eg CLASSIC, BRANDED, PREMIUM */
  adType: AdType;
  /** Discount in absolute money amount or percentage */
  discount: Discount;
};

/** AdBudgetProduct feature to determine discounts for Ad products. */
export type AdTypeDiscountsFeature = CatalogueProductFeature & {
  __typename?: 'AdTypeDiscountsFeature';
  /** Discounts applicable to the different Ad Products */
  discounts: AdTypeDiscount[];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum AdTypeTheme {
  BASIC = 'BASIC',
  PRIMARY = 'PRIMARY',
}

export type AdUpgradeProductSetResponse =
  | DisplayMetadataError
  | ProductSetExpiredError
  | ProductSetNotFoundError
  | ProductUpgradeList;

export type AdUpgradeProductsForUpgradePurchaseInput = {
  /** Job Id required to get current product(s) (optional) */
  jobId?: InputMaybe<Scalars['String']['input']>;
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID']['input'];
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
};

export enum AdUsageAdStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export type AdUsageAdTypeTrendInput = {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};

export type AdUsageAdTypeTrendResponse = {
  __typename?: 'AdUsageAdTypeTrendResponse';
  endDate: Scalars['String']['output'];
  productDisplayNameDaily: DailyTrendsProductDisplayName[];
  productDisplayNameMonthly: MonthlyTrendsProductDisplayName[];
  startDate: Scalars['String']['output'];
  totalProductDisplayNames?: Maybe<Array<Maybe<ProductDisplayNameOption>>>;
};

export type AdUsageConversionResponse = {
  __typename?: 'AdUsageConversionResponse';
  adClicks: ConversionRateData;
  adsWithoutRatingPercentage?: Maybe<Scalars['Float']['output']>;
  applicationsCompleted: ConversionRateData;
  applicationsStarted: ConversionRateData;
  jobSearchViews: ConversionRateData;
  linkOutAds: Scalars['Float']['output'];
};

export type AdUsageConversionWidgetInput = {
  filters: AdUsageQueryFilters;
};

export type AdUsageFiltersResponse = {
  __typename?: 'AdUsageFiltersResponse';
  accountHierarchies: Array<Maybe<AccountHierarchyOption>>;
  adIds: Array<Scalars['String']['output']>;
  adRatings: Array<Maybe<AdRatingTypeOption>>;
  adStatus: Array<Maybe<AdStatusOption>>;
  addOns: AddOnsOption[];
  budgets: Array<Maybe<BudgetOption>>;
  classifications: Array<Maybe<ClassificationOption>>;
  isChildAccount: Scalars['Boolean']['output'];
  jobTitles: Array<Maybe<JobTitleOption>>;
  locations: Array<Maybe<LocationOption>>;
  productDisplayNames: Array<Maybe<ProductDisplayNameOption>>;
  reposts: Array<Maybe<RepostOption>>;
  tips: Array<Maybe<AdTipsOption>>;
  users: Array<Maybe<UserOption>>;
};

export type AdUsageFiltersWidgetInput = {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
};

export type AdUsageQueryFilters = {
  accountIds: Array<InputMaybe<Scalars['Int']['input']>>;
  adIds: Array<InputMaybe<Scalars['Int']['input']>>;
  adRatings: Array<InputMaybe<FiltersAdRatingType>>;
  adStatuses: Array<InputMaybe<AdUsageAdStatus>>;
  adTips: Array<InputMaybe<AdTipsType>>;
  adTypes: Array<InputMaybe<Scalars['String']['input']>>;
  addOns: Array<InputMaybe<Scalars['String']['input']>>;
  budgetIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobTitles: Array<InputMaybe<Scalars['String']['input']>>;
  locationIds: Array<InputMaybe<Scalars['Int']['input']>>;
  postingDates: AdUsageQueryFiltersDates;
  repostTypes: Array<InputMaybe<AdRepost>>;
  subClassificationIds: Array<InputMaybe<Scalars['Int']['input']>>;
  userHashes: Array<InputMaybe<Scalars['String']['input']>>;
};

export type AdUsageQueryFiltersDates = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type AdUsageSpendResponse = {
  __typename?: 'AdUsageSpendResponse';
  averageApplications?: Maybe<Scalars['Int']['output']>;
  averageCostByProductDisplayNames?: Maybe<
    Array<Maybe<ProductDisplayNameAverageCost>>
  >;
  averageCostPerAd?: Maybe<Money>;
  averageCostPerApplication?: Maybe<Money>;
  hidePrice: Scalars['Boolean']['output'];
  otherCurrencyAdsCount?: Maybe<Scalars['Int']['output']>;
  repostedAdsCount?: Maybe<Scalars['Int']['output']>;
  repostedAdsSpend?: Maybe<Money>;
  totalAds: Scalars['Int']['output'];
  totalApplications?: Maybe<Scalars['Int']['output']>;
  totalPostByProductDisplayNames?: Maybe<
    Array<Maybe<ProductDisplayNamePostCount>>
  >;
  totalSpend?: Maybe<Money>;
};

export type AdUsageSpendWidgetInput = {
  filters: AdUsageQueryFilters;
  isAdBudgetFilterEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  siteCurrency?: InputMaybe<Scalars['String']['input']>;
};

export type AdUsageTrendClassification = {
  __typename?: 'AdUsageTrendClassification';
  classificationName: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
  totalAds: Scalars['Int']['output'];
};

export type AdUsageTrendClassificationsAndUsersInput = {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
};

export type AdUsageTrendClassificationsAndUsersResponse = {
  __typename?: 'AdUsageTrendClassificationsAndUsersResponse';
  classifications: AdUsageTrendClassification[];
  users: AdUsageTrendUser[];
};

export type AdUsageTrendUser = {
  __typename?: 'AdUsageTrendUser';
  percentage: Scalars['Float']['output'];
  totalAds: Scalars['Int']['output'];
  userFullname?: Maybe<Scalars['String']['output']>;
};

export type AddItemToCartErrorPayload = {
  __typename?: 'AddItemToCartErrorPayload';
  message: Scalars['String']['output'];
};

export type AddItemToCartInput = {
  cartId: Scalars['ID']['input'];
  item: CartItemInput;
};

export type AddItemToCartResultPayload =
  | AddItemToCartErrorPayload
  | AddItemToCartSuccessPayload;

export type AddItemToCartSuccessPayload = {
  __typename?: 'AddItemToCartSuccessPayload';
  cart: Cart;
};

export type AddOnsOption = {
  __typename?: 'AddOnsOption';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
};

export type AddQuestions = TipToImproveAds & {
  __typename?: 'AddQuestions';
  adCount: Scalars['Int']['output'];
  questions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type: TipsToImproveAdsType;
};

export type AddSpecificLocation = TipToImproveAds & {
  __typename?: 'AddSpecificLocation';
  adCount: Scalars['Int']['output'];
  nonSpecificLocation: Scalars['String']['output'];
  specificLocation: Scalars['String']['output'];
  type: TipsToImproveAdsType;
};

export type AddUserToAccountInput = {
  /** Email of the person we are wanting to add to the account */
  email: Scalars['String']['input'];
  /** First name of the person we are wanting to add to the account */
  firstName: Scalars['String']['input'];
  /** Last name of the person we are wanting to add to the account */
  lastName: Scalars['String']['input'];
  /** The audience zone of the site that this user originally registered on */
  originZone: Scalars['String']['input'];
};

export type AddUserToAccountResponse = {
  __typename?: 'AddUserToAccountResponse';
  /** The create date of the newly created user */
  createdDateUtc: Scalars['String']['output'];
  /** Email of the person we are wanting to add to the account */
  email: Scalars['String']['output'];
  /** First name of the person we are wanting to add to the account */
  firstName: Scalars['String']['output'];
  lastDailyCandidateMailDateUtc?: Maybe<Scalars['String']['output']>;
  /** Last name of the person we are wanting to add to the account */
  lastName: Scalars['String']['output'];
  lastWeeklyCandidateMailDateUtc?: Maybe<Scalars['String']['output']>;
  links: Link[];
  /** Current status ID of the newly created user */
  statusId: Scalars['String']['output'];
  /** User ID of the newly created user */
  userId: Scalars['String']['output'];
};

export type AdvertiserHeaderContext = {
  __typename?: 'AdvertiserHeaderContext';
  /** Billing ID */
  billingId: Scalars['String']['output'];
  /**
   * Can Manage Company Profile
   * @deprecated Use permissionOptions.canManageCompanyProfile instead.
   */
  canManageCompanyProfile?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the user has multiple accounts. Should really be part of the user payload :thinking-face: */
  hasMultipleAccounts: Scalars['Boolean']['output'];
  /** Indicates whether the user's activation is pending for this account. */
  isActivationPending: Scalars['Boolean']['output'];
  /** Indicates whether the advertiser is an agency account. */
  isAgency: Scalars['Boolean']['output'];
  /** Name of the advertiser. */
  name: Scalars['String']['output'];
  /** Key/value pairs of permissions for a user on behalf of the advertiser. */
  permissionOptions: PermissionOptions;
  /**
   * Array of permissions for a user on behalf of the advertiser.
   * @deprecated Use permissionOptions instead.
   */
  permissions?: Maybe<Permission[]>;
  /** Indicates whether the account is on credit hold. */
  showOnCreditHoldMessage: Scalars['Boolean']['output'];
  /** Url to switch accounts. Will be null if user has a single account. */
  switchAccountsHref?: Maybe<Scalars['String']['output']>;
};

export enum AdvertiserStatus {
  /** An active advertiser. */
  ACTIVE = 'ACTIVE',
  /** A deactivated advertiser. */
  DEACTIVATED = 'DEACTIVATED',
  /** A freshly migrated/created advertiser. */
  NEW = 'NEW',
  /** Awaiting primary contact registration. */
  PENDING = 'PENDING',
  /** Awaiting advertiser account security link activation. */
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
}

export type AllJobsSent = {
  __typename?: 'AllJobsSent';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type AnalyticsAdRating = {
  __typename?: 'AnalyticsAdRating';
  score?: Maybe<AnalyticsAdRatingScore>;
  status: AnalyticsAdRatingStatus;
};

export enum AnalyticsAdRatingConfidence {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum AnalyticsAdRatingScore {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NORMAL = 'NORMAL',
}

export enum AnalyticsAdRatingStatus {
  ARCHIVED = 'ARCHIVED',
  AVAILABLE_CANNOT_RATE = 'AVAILABLE_CANNOT_RATE',
  AVAILABLE_PENDING_RATING = 'AVAILABLE_PENDING_RATING',
  AVAILABLE_WITH_RATING = 'AVAILABLE_WITH_RATING',
  ERRORED = 'ERRORED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  NOT_INCLUDED = 'NOT_INCLUDED',
  PENDING = 'PENDING',
  UNAVAILABLE = 'UNAVAILABLE',
  WITHHELD = 'WITHHELD',
}

export enum AnalyticsBenchmarkScenario {
  COUNTRY = 'COUNTRY',
  EXACT = 'EXACT',
  EXACT_COUNTRY = 'EXACT_COUNTRY',
  EXACT_REGIONAL = 'EXACT_REGIONAL',
  IDEAL = 'IDEAL',
  IDEAL_COUNTRY = 'IDEAL_COUNTRY',
  IDEAL_REGIONAL = 'IDEAL_REGIONAL',
  SUBCLASS_COUNTRY = 'SUBCLASS_COUNTRY',
  SUBCLASS_LOCATION = 'SUBCLASS_LOCATION',
  SUBCLASS_REGIONAL = 'SUBCLASS_REGIONAL',
}

export type AnalyticsInsightsRoleAdInsightsTrendsDataPoint =
  | AnalyticsInsightsRoleAdInsightsTrendsInsufficientCompaniesItem
  | AnalyticsInsightsRoleAdInsightsTrendsSufficientDataItem;

export type AnalyticsInsightsRoleAdInsightsTrendsInsufficientCompaniesItem = {
  __typename?: 'AnalyticsInsightsRoleAdInsightsTrendsInsufficientCompaniesItem';
  adsPosted: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
};

export type AnalyticsInsightsRoleAdInsightsTrendsResponse = {
  __typename?: 'AnalyticsInsightsRoleAdInsightsTrendsResponse';
  code: AnalyticsInsightsRoleUniqueApplicantsCode;
  data: AnalyticsInsightsRoleAdInsightsTrendsDataPoint[];
};

export type AnalyticsInsightsRoleAdInsightsTrendsSufficientDataItem = {
  __typename?: 'AnalyticsInsightsRoleAdInsightsTrendsSufficientDataItem';
  adsPosted: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  uniqueApplicants: Scalars['Int']['output'];
};

export enum AnalyticsInsightsRoleAdPerformanceCode {
  INSUFFICIENT_COMPANIES = 'INSUFFICIENT_COMPANIES',
  INSUFFICIENT_EXPIRED_ADS = 'INSUFFICIENT_EXPIRED_ADS',
  SUFFICIENT_DATA = 'SUFFICIENT_DATA',
}

export type AnalyticsInsightsRoleAdPerformanceCodeResponse = {
  __typename?: 'AnalyticsInsightsRoleAdPerformanceCodeResponse';
  code: AnalyticsInsightsRoleAdPerformanceCode;
};

export type AnalyticsInsightsRoleAdPerformanceData = {
  __typename?: 'AnalyticsInsightsRoleAdPerformanceData';
  medianApplicants: Scalars['Int']['output'];
  recommendation?: Maybe<AnalyticsInsightsRoleAdPerformanceRecommendation>;
};

export type AnalyticsInsightsRoleAdPerformanceDataResponse = {
  __typename?: 'AnalyticsInsightsRoleAdPerformanceDataResponse';
  code: AnalyticsInsightsRoleAdPerformanceCode;
  data: AnalyticsInsightsRoleAdPerformanceData;
};

export type AnalyticsInsightsRoleAdPerformanceRecommendation =
  | AnalyticsInsightsRoleMostCompaniesPostedPremiumRecommendation
  | AnalyticsInsightsRolePremiumAttractedXPerAdRecommendation
  | AnalyticsInsightsRolePremiumAttractedXTimesRecommendation
  | AnalyticsInsightsRolePremiumDeliverMoreAtStartRecommendation;

export enum AnalyticsInsightsRoleAdPerformanceRecommendationType {
  MOST_COMPANIES_POSTED_PREMIUM = 'MOST_COMPANIES_POSTED_PREMIUM',
  PREMIUM_ATTRACTED_X_PER_AD = 'PREMIUM_ATTRACTED_X_PER_AD',
  PREMIUM_ATTRACTED_X_TIMES = 'PREMIUM_ATTRACTED_X_TIMES',
  PREMIUM_DELIVER_MORE_AT_START = 'PREMIUM_DELIVER_MORE_AT_START',
}

export type AnalyticsInsightsRoleAdPerformanceResponse =
  | AnalyticsInsightsRoleAdPerformanceCodeResponse
  | AnalyticsInsightsRoleAdPerformanceDataResponse;

export type AnalyticsInsightsRoleAdsPostedResponse = {
  __typename?: 'AnalyticsInsightsRoleAdsPostedResponse';
  adsPosted: Scalars['Int']['output'];
};

export type AnalyticsInsightsRoleCandidateProfiles = {
  __typename?: 'AnalyticsInsightsRoleCandidateProfiles';
  premiumTalentSearchProfilesStats?: Maybe<AnalyticsInsightsRolePremiumTalentSearchProfileStats>;
};

export type AnalyticsInsightsRoleCandidateProfilesInput = {
  countryCode: Scalars['String']['input'];
  locationIds: Array<Scalars['String']['input']>;
  normalisedRoleTitles: Array<Scalars['String']['input']>;
};

export type AnalyticsInsightsRoleCompaniesPostingResponse = {
  __typename?: 'AnalyticsInsightsRoleCompaniesPostingResponse';
  companiesPosting: Scalars['Int']['output'];
  topCompanies: AnalyticsInsightsRoleTopCompany[];
};

export enum AnalyticsInsightsRoleCompetitionForTalentCode {
  INSUFFICIENT_ADS_POSTED = 'INSUFFICIENT_ADS_POSTED',
  INSUFFICIENT_COMPANIES = 'INSUFFICIENT_COMPANIES',
  SUFFICIENT_DATA = 'SUFFICIENT_DATA',
}

export type AnalyticsInsightsRoleCompetitionForTalentCodeResponse = {
  __typename?: 'AnalyticsInsightsRoleCompetitionForTalentCodeResponse';
  code: AnalyticsInsightsRoleCompetitionForTalentCode;
};

export enum AnalyticsInsightsRoleCompetitionForTalentConfidence {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export type AnalyticsInsightsRoleCompetitionForTalentData = {
  __typename?: 'AnalyticsInsightsRoleCompetitionForTalentData';
  confidence: AnalyticsInsightsRoleCompetitionForTalentConfidence;
  score: Scalars['Float']['output'];
  threshold: AnalyticsInsightsRoleCompetitionForTalentThreshold;
};

export type AnalyticsInsightsRoleCompetitionForTalentDataResponse = {
  __typename?: 'AnalyticsInsightsRoleCompetitionForTalentDataResponse';
  code: AnalyticsInsightsRoleCompetitionForTalentCode;
  data: AnalyticsInsightsRoleCompetitionForTalentData;
};

export type AnalyticsInsightsRoleCompetitionForTalentResponse =
  | AnalyticsInsightsRoleCompetitionForTalentCodeResponse
  | AnalyticsInsightsRoleCompetitionForTalentDataResponse;

export type AnalyticsInsightsRoleCompetitionForTalentThreshold = {
  __typename?: 'AnalyticsInsightsRoleCompetitionForTalentThreshold';
  high: Scalars['Int']['output'];
  low: Scalars['Int']['output'];
};

export type AnalyticsInsightsRoleLastUpdatedResponse = {
  __typename?: 'AnalyticsInsightsRoleLastUpdatedResponse';
  lastUpdatedTimestampUTC: Scalars['DateTime']['output'];
};

export type AnalyticsInsightsRoleLocation = {
  __typename?: 'AnalyticsInsightsRoleLocation';
  children: AnalyticsInsightsRoleLocation[];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  leafNodeKeys: Array<Scalars['String']['output']>;
  level: Scalars['String']['output'];
};

export type AnalyticsInsightsRoleLocationFilterInput = {
  id: Scalars['String']['input'];
  level: Scalars['String']['input'];
};

export type AnalyticsInsightsRoleLocationsInput = {
  countryCode: Scalars['String']['input'];
};

export type AnalyticsInsightsRoleMostCompaniesPostedPremiumRecommendation = {
  __typename?: 'AnalyticsInsightsRoleMostCompaniesPostedPremiumRecommendation';
  type: AnalyticsInsightsRoleAdPerformanceRecommendationType;
};

export type AnalyticsInsightsRoleNormalisedRole = {
  __typename?: 'AnalyticsInsightsRoleNormalisedRole';
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AnalyticsInsightsRoleNormalisedRolesInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText: Scalars['String']['input'];
};

export type AnalyticsInsightsRoleNormalisedRolesResponse = {
  __typename?: 'AnalyticsInsightsRoleNormalisedRolesResponse';
  normalisedRoles: AnalyticsInsightsRoleNormalisedRole[];
};

export type AnalyticsInsightsRolePremiumAttractedXPerAdRecommendation = {
  __typename?: 'AnalyticsInsightsRolePremiumAttractedXPerAdRecommendation';
  medianApplicantsOnPremiumAds: Scalars['Int']['output'];
  type: AnalyticsInsightsRoleAdPerformanceRecommendationType;
};

export type AnalyticsInsightsRolePremiumAttractedXTimesRecommendation = {
  __typename?: 'AnalyticsInsightsRolePremiumAttractedXTimesRecommendation';
  medianApplicantsOnNonPremiumAds: Scalars['Int']['output'];
  medianApplicantsOnPremiumAds: Scalars['Int']['output'];
  type: AnalyticsInsightsRoleAdPerformanceRecommendationType;
};

export type AnalyticsInsightsRolePremiumDeliverMoreAtStartRecommendation = {
  __typename?: 'AnalyticsInsightsRolePremiumDeliverMoreAtStartRecommendation';
  type: AnalyticsInsightsRoleAdPerformanceRecommendationType;
};

export type AnalyticsInsightsRolePremiumTalentSearchProfileStats = {
  __typename?: 'AnalyticsInsightsRolePremiumTalentSearchProfileStats';
  approachableProfiles: Scalars['Int']['output'];
  totalProfiles: Scalars['Int']['output'];
};

export type AnalyticsInsightsRoleRelatedRolesInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  normalisedRoleId: Scalars['String']['input'];
};

export type AnalyticsInsightsRoleRelatedRolesResponse = {
  __typename?: 'AnalyticsInsightsRoleRelatedRolesResponse';
  relatedRoles: AnalyticsInsightsRoleNormalisedRole[];
};

export type AnalyticsInsightsRoleReportInput = {
  countryCode: Scalars['String']['input'];
  lastUpdatedTimestampUTC: Scalars['String']['input'];
  locations: AnalyticsInsightsRoleLocationFilterInput[];
  normalisedRoleIds: Array<Scalars['String']['input']>;
};

export type AnalyticsInsightsRoleSalaryDistributionItem = {
  __typename?: 'AnalyticsInsightsRoleSalaryDistributionItem';
  count: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
  salaryRange: AnalyticsInsightsRoleSalaryRange;
};

export type AnalyticsInsightsRoleSalaryDistributionResponse = {
  __typename?: 'AnalyticsInsightsRoleSalaryDistributionResponse';
  currency: Scalars['String']['output'];
  salaryDistribution: AnalyticsInsightsRoleSalaryDistributionItem[];
};

export enum AnalyticsInsightsRoleSalaryInsightsCode {
  SUFFICIENT_DATA = 'SUFFICIENT_DATA',
  SUPPRESS_INSIGHTS = 'SUPPRESS_INSIGHTS',
}

export type AnalyticsInsightsRoleSalaryInsightsCodeResponse = {
  __typename?: 'AnalyticsInsightsRoleSalaryInsightsCodeResponse';
  code: AnalyticsInsightsRoleSalaryInsightsCode;
};

export type AnalyticsInsightsRoleSalaryInsightsInput = {
  salaryType: AnalyticsInsightsRoleSalaryType;
};

export type AnalyticsInsightsRoleSalaryInsightsMajoritySalaryType = {
  __typename?: 'AnalyticsInsightsRoleSalaryInsightsMajoritySalaryType';
  comparisonInPercentage: Scalars['Float']['output'];
  salaryType: AnalyticsInsightsRoleSalaryType;
  type: AnalyticsInsightsRoleSalaryInsightsType;
};

export type AnalyticsInsightsRoleSalaryInsightsResponse =
  | AnalyticsInsightsRoleSalaryInsightsCodeResponse
  | AnalyticsInsightsRoleSalaryInsightsSufficientDataResponse;

export type AnalyticsInsightsRoleSalaryInsightsSufficientData = {
  __typename?: 'AnalyticsInsightsRoleSalaryInsightsSufficientData';
  insights: Array<Maybe<AnalyticsInsightsRoleSalaryInsightsTypes>>;
};

export type AnalyticsInsightsRoleSalaryInsightsSufficientDataResponse = {
  __typename?: 'AnalyticsInsightsRoleSalaryInsightsSufficientDataResponse';
  code: AnalyticsInsightsRoleSalaryInsightsCode;
  data: AnalyticsInsightsRoleSalaryInsightsSufficientData;
};

export type AnalyticsInsightsRoleSalaryInsightsTrendsByDays = {
  __typename?: 'AnalyticsInsightsRoleSalaryInsightsTrendsByDays';
  comparisonInPercentage: Scalars['Float']['output'];
  type: AnalyticsInsightsRoleSalaryInsightsType;
};

export type AnalyticsInsightsRoleSalaryInsightsTrendsByLocation = {
  __typename?: 'AnalyticsInsightsRoleSalaryInsightsTrendsByLocation';
  comparisonInPercentage: Scalars['Float']['output'];
  type: AnalyticsInsightsRoleSalaryInsightsType;
};

export enum AnalyticsInsightsRoleSalaryInsightsType {
  MAJORITY_SALARY_TYPE = 'MAJORITY_SALARY_TYPE',
  TRENDS_BY_DAYS = 'TRENDS_BY_DAYS',
  TRENDS_BY_LOCATION = 'TRENDS_BY_LOCATION',
}

export type AnalyticsInsightsRoleSalaryInsightsTypes =
  | AnalyticsInsightsRoleSalaryInsightsMajoritySalaryType
  | AnalyticsInsightsRoleSalaryInsightsTrendsByDays
  | AnalyticsInsightsRoleSalaryInsightsTrendsByLocation;

export type AnalyticsInsightsRoleSalaryPrivacyCheckResponse = {
  __typename?: 'AnalyticsInsightsRoleSalaryPrivacyCheckResponse';
  code: AnalyticsInsightsSalaryPrivacyCheckCode;
};

export type AnalyticsInsightsRoleSalaryRange = {
  __typename?: 'AnalyticsInsightsRoleSalaryRange';
  from: Scalars['Float']['output'];
  to: Scalars['Float']['output'];
};

export enum AnalyticsInsightsRoleSalaryRangeCode {
  SUFFICIENT_DATA = 'SUFFICIENT_DATA',
  SUPPRESS_BOUNDS = 'SUPPRESS_BOUNDS',
  SUPPRESS_RANGE = 'SUPPRESS_RANGE',
}

export type AnalyticsInsightsRoleSalaryRangeCodeResponse = {
  __typename?: 'AnalyticsInsightsRoleSalaryRangeCodeResponse';
  code: AnalyticsInsightsRoleSalaryRangeCode;
};

export type AnalyticsInsightsRoleSalaryRangeResponse =
  | AnalyticsInsightsRoleSalaryRangeCodeResponse
  | AnalyticsInsightsRoleSalaryRangeSufficientDataResponse
  | AnalyticsInsightsRoleSalaryRangeSuppressBoundsResponse;

export type AnalyticsInsightsRoleSalaryRangeSufficientData = {
  __typename?: 'AnalyticsInsightsRoleSalaryRangeSufficientData';
  isLowConfidence: Scalars['Boolean']['output'];
  numOfAds: Scalars['Int']['output'];
  salaryMedian: Scalars['Float']['output'];
  salaryRange: AnalyticsInsightsRoleSalaryRange;
};

export type AnalyticsInsightsRoleSalaryRangeSufficientDataResponse = {
  __typename?: 'AnalyticsInsightsRoleSalaryRangeSufficientDataResponse';
  code: AnalyticsInsightsRoleSalaryRangeCode;
  data: AnalyticsInsightsRoleSalaryRangeSufficientData;
};

export type AnalyticsInsightsRoleSalaryRangeSuppressBoundsData = {
  __typename?: 'AnalyticsInsightsRoleSalaryRangeSuppressBoundsData';
  numOfAds: Scalars['Int']['output'];
  salaryMedian: Scalars['Float']['output'];
};

export type AnalyticsInsightsRoleSalaryRangeSuppressBoundsResponse = {
  __typename?: 'AnalyticsInsightsRoleSalaryRangeSuppressBoundsResponse';
  code: AnalyticsInsightsRoleSalaryRangeCode;
  data: AnalyticsInsightsRoleSalaryRangeSuppressBoundsData;
};

export enum AnalyticsInsightsRoleSalaryType {
  ANNUAL = 'ANNUAL',
  COMBINED = 'COMBINED',
  HOURLY = 'HOURLY',
}

export type AnalyticsInsightsRoleTopCompany = {
  __typename?: 'AnalyticsInsightsRoleTopCompany';
  adsPosted: Scalars['Int']['output'];
  parentHirerName: Scalars['String']['output'];
};

export enum AnalyticsInsightsRoleUniqueApplicantsCode {
  INSUFFICIENT_COMPANIES = 'INSUFFICIENT_COMPANIES',
  SUFFICIENT_DATA = 'SUFFICIENT_DATA',
}

export type AnalyticsInsightsRoleUniqueApplicantsCodeResponse = {
  __typename?: 'AnalyticsInsightsRoleUniqueApplicantsCodeResponse';
  code: AnalyticsInsightsRoleUniqueApplicantsCode;
};

export type AnalyticsInsightsRoleUniqueApplicantsDataResponse = {
  __typename?: 'AnalyticsInsightsRoleUniqueApplicantsDataResponse';
  code: AnalyticsInsightsRoleUniqueApplicantsCode;
  uniqueApplicants: Scalars['Int']['output'];
};

export type AnalyticsInsightsRoleUniqueApplicantsResponse =
  | AnalyticsInsightsRoleUniqueApplicantsCodeResponse
  | AnalyticsInsightsRoleUniqueApplicantsDataResponse;

export enum AnalyticsInsightsSalaryPrivacyCheckCode {
  SUFFICIENT_DATA = 'SUFFICIENT_DATA',
  SUPPRESS_ALL = 'SUPPRESS_ALL',
}

export type AnalyticsLatestJobs = {
  __typename?: 'AnalyticsLatestJobs';
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  jobId: Scalars['String']['output'];
  jobLocationLabel?: Maybe<Scalars['String']['output']>;
  jobTitle: Scalars['String']['output'];
  userFullName?: Maybe<Scalars['String']['output']>;
};

export enum AnalyticsPostTypeCategory {
  DIRECT = 'DIRECT',
  INDIRECT = 'INDIRECT',
}

export enum AnalyticsSalaryType {
  ANNUAL_COMMISSION = 'ANNUAL_COMMISSION',
  ANNUAL_PACKAGE = 'ANNUAL_PACKAGE',
  COMMISSION_ONLY = 'COMMISSION_ONLY',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
}

export type AnalyticsTalentSearchUsageAccount = {
  __typename?: 'AnalyticsTalentSearchUsageAccount';
  count: Scalars['Int']['output'];
  data: AnalyticsTalentSearchUsageAccountData;
  key: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type AnalyticsTalentSearchUsageAccountData = {
  __typename?: 'AnalyticsTalentSearchUsageAccountData';
  displayId: Scalars['ID']['output'];
};

export type AnalyticsTalentSearchUsageAccountTrendsItem = {
  __typename?: 'AnalyticsTalentSearchUsageAccountTrendsItem';
  advertiserName: Scalars['String']['output'];
  percentage: Scalars['Int']['output'];
  totalCredits: Scalars['Int']['output'];
};

export type AnalyticsTalentSearchUsageConversionInput = {
  filters: AnalyticsTalentSearchUsageFilters;
};

export type AnalyticsTalentSearchUsageConversionResponse = {
  __typename?: 'AnalyticsTalentSearchUsageConversionResponse';
  cvPreviewCount: Scalars['Int']['output'];
  openJobCount: Scalars['Int']['output'];
  openJobPercentage: Scalars['Int']['output'];
  openMessageCount: Scalars['Int']['output'];
  openMessagePercentage: Scalars['Int']['output'];
  profileAccessCount: Scalars['Int']['output'];
  profileSearchCount: Scalars['Int']['output'];
  profileViewCount: Scalars['Int']['output'];
  sendJobCount: Scalars['Int']['output'];
  sendMessageCount: Scalars['Int']['output'];
};

export type AnalyticsTalentSearchUsageDateRangeInput = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type AnalyticsTalentSearchUsageFilters = {
  accountIds: Array<InputMaybe<Scalars['ID']['input']>>;
  usageDates: AnalyticsTalentSearchUsageDateRangeInput;
  userHashes: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type AnalyticsTalentSearchUsageFiltersInput = {
  filters: AnalyticsTalentSearchUsageFilters;
  locale: Scalars['String']['input'];
};

export type AnalyticsTalentSearchUsageFiltersResponse = {
  __typename?: 'AnalyticsTalentSearchUsageFiltersResponse';
  accounts: Array<Maybe<AnalyticsTalentSearchUsageAccount>>;
  users: Array<Maybe<AnalyticsTalentSearchUsageUser>>;
};

export type AnalyticsTalentSearchUsageLastUpdatedResponse = {
  __typename?: 'AnalyticsTalentSearchUsageLastUpdatedResponse';
  lastUpdated: Scalars['DateTime']['output'];
};

export type AnalyticsTalentSearchUsageOverviewInput = {
  filters: AnalyticsTalentSearchUsageFilters;
  siteCurrency: Scalars['String']['input'];
};

export type AnalyticsTalentSearchUsageOverviewResponse = {
  __typename?: 'AnalyticsTalentSearchUsageOverviewResponse';
  otherCurrencyCount: Scalars['Int']['output'];
  profileAccessCount: Scalars['Int']['output'];
  profileAccessCredits: Scalars['Int']['output'];
  profileAccessPercentage: Scalars['Int']['output'];
  profileAccessSpendPer: Money;
  sendJobCount: Scalars['Int']['output'];
  sendJobCredits: Scalars['Int']['output'];
  sendJobPercentage: Scalars['Int']['output'];
  sendJobSpendPer: Money;
  sendMessageCount: Scalars['Int']['output'];
  sendMessageCredits: Scalars['Int']['output'];
  sendMessagePercentage: Scalars['Int']['output'];
  sendMessageSpendPer: Money;
  totalCount: Scalars['Int']['output'];
  totalCredits: Scalars['Int']['output'];
  totalSpend: Money;
  userCount: Scalars['Int']['output'];
  userSpendAverage: Money;
};

export type AnalyticsTalentSearchUsageTimeTrendsItem = {
  __typename?: 'AnalyticsTalentSearchUsageTimeTrendsItem';
  profileAccessCredits: Scalars['Int']['output'];
  sendJobCredits: Scalars['Int']['output'];
  sendMessageCredits: Scalars['Int']['output'];
};

export type AnalyticsTalentSearchUsageTimeTrendsItemByInterval = {
  __typename?: 'AnalyticsTalentSearchUsageTimeTrendsItemByInterval';
  label: Scalars['String']['output'];
  profileAccessCredits: Scalars['Int']['output'];
  sendJobCredits: Scalars['Int']['output'];
  sendMessageCredits: Scalars['Int']['output'];
};

export type AnalyticsTalentSearchUsageTrendsByAccountInput = {
  filters: AnalyticsTalentSearchUsageFilters;
};

export type AnalyticsTalentSearchUsageTrendsByAccountResponse = {
  __typename?: 'AnalyticsTalentSearchUsageTrendsByAccountResponse';
  accounts: AnalyticsTalentSearchUsageAccountTrendsItem[];
};

export type AnalyticsTalentSearchUsageTrendsByTimeInput = {
  filters: AnalyticsTalentSearchUsageFilters;
  interval: AnalyticsTalentSearchUsageTrendsByTimeInterval;
  timezone: Scalars['String']['input'];
};

export enum AnalyticsTalentSearchUsageTrendsByTimeInterval {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
}

export type AnalyticsTalentSearchUsageTrendsByTimeResponse = {
  __typename?: 'AnalyticsTalentSearchUsageTrendsByTimeResponse';
  data: AnalyticsTalentSearchUsageTimeTrendsItemByInterval[];
  total: AnalyticsTalentSearchUsageTimeTrendsItem;
};

export type AnalyticsTalentSearchUsageUser = {
  __typename?: 'AnalyticsTalentSearchUsageUser';
  count: Scalars['Int']['output'];
  key: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type AnalyticsTalentSearchUsageUserUsage = {
  __typename?: 'AnalyticsTalentSearchUsageUserUsage';
  openJobPercentage: Scalars['Int']['output'];
  openMessagePercentage: Scalars['Int']['output'];
  profileAccessCount: Scalars['Int']['output'];
  profileSearchCount: Scalars['Int']['output'];
  profileViewCount: Scalars['Int']['output'];
  sendJobCount: Scalars['Int']['output'];
  sendMessageCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalCredits: Scalars['Int']['output'];
  userFullName: Scalars['String']['output'];
  userHash: Scalars['ID']['output'];
};

export type AnalyticsTalentSearchUsageUsersListInput = {
  filters: AnalyticsTalentSearchUsageFilters;
  pageNumber: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
};

export type AnalyticsTalentSearchUsageUsersListResponse = {
  __typename?: 'AnalyticsTalentSearchUsageUsersListResponse';
  list: AnalyticsTalentSearchUsageUserUsage[];
  userCount: Scalars['Int']['output'];
};

export enum AnalyticsWorkType {
  CASUAL = 'CASUAL',
  CONTRACT = 'CONTRACT',
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
}

export type Application = {
  __typename?: 'Application';
  adcentreCandidateId: Scalars['ID']['output'];
  adcentreProspectId: Scalars['ID']['output'];
  applicationId?: Maybe<Scalars['ID']['output']>;
  appliedDateUtc: Scalars['DateTime']['output'];
  attachmentsStats: ApplicationAttachmentsStatsResponse;
  attachmentsV2: ApplicationAttachmentsV2Response;
  billableStatus?: Maybe<BillableStatus>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateStats: ApplicationCandidateStatsResponse;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPriorityApply: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  job: ApplicationJobResponse;
  lastName: Scalars['String']['output'];
  matchedQualities: MatchedQuality[];
  metadata: ApplicationMetadataResponse;
  mostRecentCompanyName?: Maybe<Scalars['String']['output']>;
  mostRecentJobTitle?: Maybe<Scalars['String']['output']>;
  mostRecentRoleMonths: Scalars['Int']['output'];
  phone: Scalars['String']['output'];
  profile: ApplicationProfileResponse;
  questionnaireSubmission: ApplicationQuestionnaireSubmissionResponse;
  rating?: Maybe<ApplicationRating>;
  source: ApplicationSource;
  statusFolder: ApplicationStatusFolder;
  verifications: ApplicationVerificationsResponse;
};

export type ApplicationjobArgs = {
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export enum ApplicationAttachmentType {
  COVER_LETTER = 'COVER_LETTER',
  OTHER = 'OTHER',
  REFERENCE_CHECK = 'REFERENCE_CHECK',
  RESUME = 'RESUME',
  SELECTION_CRITERIA = 'SELECTION_CRITERIA',
}

export type ApplicationAttachmentV2 = {
  __typename?: 'ApplicationAttachmentV2';
  attachmentType: ApplicationAttachmentType;
  blobId: Scalars['ID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
  hasConvertedPdf: Scalars['Boolean']['output'];
  uploadedDate?: Maybe<Scalars['DateTime']['output']>;
  uploadedUser?: Maybe<Scalars['String']['output']>;
};

export type ApplicationAttachmentsStats = {
  __typename?: 'ApplicationAttachmentsStats';
  hirerUploadedAttachmentCount: Scalars['Int']['output'];
};

export type ApplicationAttachmentsStatsResponse =
  | ApplicationAttachmentsStatsResponseSuccess
  | ResponseError;

export type ApplicationAttachmentsStatsResponseSuccess = {
  __typename?: 'ApplicationAttachmentsStatsResponseSuccess';
  result: ApplicationAttachmentsStats;
};

export type ApplicationAttachmentsV2Input = {
  applicationCorrelationId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
};

export type ApplicationAttachmentsV2Response =
  | ApplicationAttachmentsV2ResponseSuccess
  | ResponseError;

export type ApplicationAttachmentsV2ResponseSuccess = {
  __typename?: 'ApplicationAttachmentsV2ResponseSuccess';
  result: ApplicationAttachmentV2[];
};

export enum ApplicationBucket {
  ACCEPT = 'ACCEPT',
  INBOX = 'INBOX',
  INTERVIEW = 'INTERVIEW',
  NOT_SUITABLE = 'NOT_SUITABLE',
  OFFER = 'OFFER',
  PRESCREEN = 'PRESCREEN',
  SHORTLIST = 'SHORTLIST',
}

export type ApplicationByAdcentreProspectIdInput = {
  adcentreProspectId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
};

export type ApplicationCandidateStats = {
  __typename?: 'ApplicationCandidateStats';
  totalApplicationCount: Scalars['Int']['output'];
};

export type ApplicationCandidateStatsResponse =
  | ApplicationCandidateStatsResponseSuccess
  | ResponseError;

export type ApplicationCandidateStatsResponseSuccess = {
  __typename?: 'ApplicationCandidateStatsResponseSuccess';
  result: ApplicationCandidateStats;
};

export type ApplicationData = {
  /** The position the prospect appeared in the candidate list starting from 1. Null if sent from CandidateDetail. */
  positionInList?: InputMaybe<Scalars['Int']['input']>;
  prospectId: Scalars['ID']['input'];
};

export type ApplicationEmail = {
  __typename?: 'ApplicationEmail';
  adcentreCandidateId: Scalars['ID']['output'];
  from: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  prospectId: Scalars['ID']['output'];
  sentDate: Scalars['DateTime']['output'];
  subject: Scalars['String']['output'];
  to: Scalars['String']['output'];
};

export type ApplicationEmailEdge = {
  __typename?: 'ApplicationEmailEdge';
  node: ApplicationEmail;
};

export type ApplicationEmails = {
  __typename?: 'ApplicationEmails';
  edges: ApplicationEmailEdge[];
};

export type ApplicationEmailsInput = {
  adcentreCandidateId: Scalars['ID']['input'];
  prospectId: Scalars['ID']['input'];
};

export type ApplicationFilters = {
  homeLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  nationalityRightToWorkIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionnaireFilters?: InputMaybe<QuestionnaireFilter[]>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statusFolders?: InputMaybe<ApplicationStatusFolder[]>;
};

export type ApplicationInput = {
  applicationCorrelationId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
};

export type ApplicationJobResponse =
  | ApplicationJobResponseSuccess
  | ResponseError;

export type ApplicationJobResponseSuccess = {
  __typename?: 'ApplicationJobResponseSuccess';
  result: Job;
};

export type ApplicationMetadata = {
  __typename?: 'ApplicationMetadata';
  emailCount: Scalars['Int']['output'];
  hasCoverLetter: Scalars['Boolean']['output'];
  hasResume: Scalars['Boolean']['output'];
  hasSelectionCriteria: Scalars['Boolean']['output'];
  noteCount: Scalars['Int']['output'];
};

export type ApplicationMetadataResponse =
  | ApplicationMetadataResponseSuccess
  | ResponseError;

export type ApplicationMetadataResponseSuccess = {
  __typename?: 'ApplicationMetadataResponseSuccess';
  result: ApplicationMetadata;
};

export type ApplicationNote = {
  __typename?: 'ApplicationNote';
  content?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  createdUser?: Maybe<Scalars['String']['output']>;
  createdUserId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
};

export type ApplicationNoteV2 = {
  __typename?: 'ApplicationNoteV2';
  content: Scalars['String']['output'];
  createdDate: Scalars['DateTime']['output'];
  createdUserFirstname: Scalars['String']['output'];
  createdUserId: Scalars['ID']['output'];
  createdUserLastname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ApplicationNotesInput = {
  applicationCorrelationId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
};

export type ApplicationNotesResponse =
  | ApplicationNotesResponseSuccess
  | ResponseError;

export type ApplicationNotesResponseSuccess = {
  __typename?: 'ApplicationNotesResponseSuccess';
  result: ApplicationNoteV2[];
};

export type ApplicationPerformanceResponse = {
  __typename?: 'ApplicationPerformanceResponse';
  adRatingConfidence: AnalyticsAdRatingConfidence;
  applications: Scalars['Int']['output'];
  hasAdRating: Scalars['Boolean']['output'];
  isLinkOutAd: Scalars['Boolean']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  mobileViewsInPercentage: Scalars['Float']['output'];
  similarAdsAverage?: Maybe<Scalars['Int']['output']>;
};

export type ApplicationPerformanceWidgetInput = {
  jobId: Scalars['String']['input'];
};

export type ApplicationProfile = {
  __typename?: 'ApplicationProfile';
  education: CandidateProfileEducation[];
  expectedSalaries: CandidateProfileExpectedSalary[];
  homeLocation?: Maybe<Location>;
  licences: CandidateProfileLicence[];
  nationalities: ApplicationProfileNationalitiesResponse;
  referenceChecksStats: ApplicationProfileReferenceChecksStatsResponse;
  rightsToWork: CandidateProfileRightToWork[];
  rightsToWorkV2: CandidateProfileRightToWorkV2[];
  skills: CandidateProfileSkill[];
  verifications: ApplicationProfileVerificationsResponse;
  verificationsStats: ApplicationProfileVerificationsStatsResponse;
  /** @deprecated Use verifiedIdentity from verifications instead. */
  verifiedIdentity?: Maybe<CandidateProfileVerifiedIdentity>;
  workHistory: CandidateProfileWorkHistory[];
};

export type ApplicationProfilenationalitiesArgs = {
  input: ApplicationProfileNationalitiesInput;
};

export type ApplicationProfileNationalitiesInput = {
  jobId: Scalars['ID']['input'];
};

export type ApplicationProfileNationalitiesResponse =
  | ApplicationProfileNationalitiesResponseSuccess
  | ResponseError;

export type ApplicationProfileNationalitiesResponseSuccess = {
  __typename?: 'ApplicationProfileNationalitiesResponseSuccess';
  result: CandidateProfileNationality[];
};

export type ApplicationProfileNotSpecifiedNationality = {
  __typename?: 'ApplicationProfileNotSpecifiedNationality';
  countryName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ApplicationProfileReferenceChecksStatsAvailable = {
  __typename?: 'ApplicationProfileReferenceChecksStatsAvailable';
  /** @deprecated Use finishedCount instead. */
  count: Scalars['Int']['output'];
  finishedCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ApplicationProfileReferenceChecksStatsRequested = {
  __typename?: 'ApplicationProfileReferenceChecksStatsRequested';
  _empty?: Maybe<Scalars['String']['output']>;
};

export type ApplicationProfileReferenceChecksStatsResponse =
  | ApplicationProfileReferenceChecksStatsResponseSuccess
  | ResponseError;

export type ApplicationProfileReferenceChecksStatsResponseSuccess = {
  __typename?: 'ApplicationProfileReferenceChecksStatsResponseSuccess';
  result?: Maybe<ApplicationProfileReferenceChecksStatsResult>;
};

export type ApplicationProfileReferenceChecksStatsResult =
  | ApplicationProfileReferenceChecksStatsAvailable
  | ApplicationProfileReferenceChecksStatsRequested;

export type ApplicationProfileResponse =
  | ApplicationProfileResponseSuccess
  | ResponseError;

export type ApplicationProfileResponseSuccess = {
  __typename?: 'ApplicationProfileResponseSuccess';
  result?: Maybe<ApplicationProfile>;
};

export type ApplicationProfileVerifications = {
  __typename?: 'ApplicationProfileVerifications';
  education: Verification[];
  licences: Verification[];
  rightToWork: Verification[];
  verifiedIdentity?: Maybe<Verification>;
  workHistory: Verification[];
};

export type ApplicationProfileVerificationsResponse =
  | ApplicationProfileVerificationsSuccess
  | ResponseError;

export type ApplicationProfileVerificationsStatsResponse =
  | ApplicationProfileVerificationsStatsSuccess
  | ResponseError;

export type ApplicationProfileVerificationsStatsSuccess = {
  __typename?: 'ApplicationProfileVerificationsStatsSuccess';
  totalCount: Scalars['Int']['output'];
  workHistoryCount: Scalars['Int']['output'];
};

export type ApplicationProfileVerificationsSuccess = {
  __typename?: 'ApplicationProfileVerificationsSuccess';
  result: ApplicationProfileVerifications;
};

export type ApplicationQuestionnaireAnswer = {
  __typename?: 'ApplicationQuestionnaireAnswer';
  id?: Maybe<Scalars['ID']['output']>;
  legacyId?: Maybe<Scalars['ID']['output']>;
  text: Scalars['String']['output'];
  verification?: Maybe<Verification>;
};

export type ApplicationQuestionnaireQuestion = {
  __typename?: 'ApplicationQuestionnaireQuestion';
  answerType?: Maybe<Scalars['String']['output']>;
  answers: ApplicationQuestionnaireAnswer[];
  id: Scalars['ID']['output'];
  legacyId?: Maybe<Scalars['ID']['output']>;
  source?: Maybe<ApplicationQuestionnaireQuestionSource>;
  status: ApplicationQuestionnaireQuestionStatus;
  text: Scalars['String']['output'];
};

export enum ApplicationQuestionnaireQuestionSource {
  Custom = 'Custom',
  Library = 'Library',
}

export enum ApplicationQuestionnaireQuestionStatus {
  CANNOT_MATCH = 'CANNOT_MATCH',
  MATCH = 'MATCH',
  NO_MATCH = 'NO_MATCH',
  PARTIAL_MATCH = 'PARTIAL_MATCH',
}

export type ApplicationQuestionnaireSubmission = {
  __typename?: 'ApplicationQuestionnaireSubmission';
  id: Scalars['ID']['output'];
  questions: ApplicationQuestionnaireQuestion[];
};

export type ApplicationQuestionnaireSubmissionResponse =
  | ApplicationQuestionnaireSubmissionResponseSuccess
  | ResponseError;

export type ApplicationQuestionnaireSubmissionResponseSuccess = {
  __typename?: 'ApplicationQuestionnaireSubmissionResponseSuccess';
  result?: Maybe<ApplicationQuestionnaireSubmission>;
};

export type ApplicationRating = {
  __typename?: 'ApplicationRating';
  rating: Scalars['Int']['output'];
};

export type ApplicationResponse = ApplicationResponseSuccess | ResponseError;

export type ApplicationResponseSuccess = {
  __typename?: 'ApplicationResponseSuccess';
  result: Application;
};

export enum ApplicationSort {
  APPLICATION_DATE = 'APPLICATION_DATE',
  NAME = 'NAME',
  RATING = 'RATING',
  RELEVANCE = 'RELEVANCE',
  SCORE = 'SCORE',
  SEARCH_RELEVANCE = 'SEARCH_RELEVANCE',
}

export enum ApplicationSource {
  NATIVE = 'NATIVE',
  UPLOADED = 'UPLOADED',
}

export enum ApplicationStatusFolder {
  ACCEPT = 'ACCEPT',
  INBOX = 'INBOX',
  INTERVIEW = 'INTERVIEW',
  NOT_SUITABLE = 'NOT_SUITABLE',
  OFFER = 'OFFER',
  PRESCREEN = 'PRESCREEN',
  SHORTLIST = 'SHORTLIST',
}

export type ApplicationVerificationsResponse =
  | ApplicationVerificationsResponseSuccess
  | ResponseError;

export type ApplicationVerificationsResponseSuccess = {
  __typename?: 'ApplicationVerificationsResponseSuccess';
  result: Verification[];
};

export type ApplicationsFacetsInput = {
  filters?: InputMaybe<ApplicationsFiltersInput>;
  jobId: Scalars['ID']['input'];
};

export type ApplicationsFacetsResponse =
  | ApplicationsFacetsResponseSuccess
  | ResponseError;

export type ApplicationsFacetsResponseSuccess = {
  __typename?: 'ApplicationsFacetsResponseSuccess';
  result: ApplicationsFacetsResult;
};

export type ApplicationsFacetsResult = {
  __typename?: 'ApplicationsFacetsResult';
  homeLocationFacets: ApplicationsFacetsResultHomeLocationFacet[];
  nationalityFacets: ApplicationsFacetsResultNationalityFacet[];
};

export type ApplicationsFacetsResultHomeLocationFacet = {
  __typename?: 'ApplicationsFacetsResultHomeLocationFacet';
  children: ApplicationsFacetsResultHomeLocationFacet[];
  count: Scalars['Int']['output'];
  homeLocation: Location;
};

export type ApplicationsFacetsResultNationalityFacet = {
  __typename?: 'ApplicationsFacetsResultNationalityFacet';
  count: Scalars['Int']['output'];
  nationality: ApplicationsFacetsResultNationalityFacetNationality;
};

export type ApplicationsFacetsResultNationalityFacetNationality =
  | ApplicationProfileNotSpecifiedNationality
  | CandidateProfileNationality;

export type ApplicationsFilterCounts = {
  __typename?: 'ApplicationsFilterCounts';
  questionnaireFilterCounts: RoleRequirementFilterCount[];
};

export type ApplicationsFiltersInput = {
  homeLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  nationalityRightToWorkIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionnaireFilters?: InputMaybe<QuestionnaireFilter[]>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statusFolders?: InputMaybe<ApplicationStatusFolder[]>;
};

export type ApplicationsInput = {
  filters?: InputMaybe<ApplicationFilters>;
  jobId: Scalars['ID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<ApplicationsSortInput>;
};

export type ApplicationsPageInfo = {
  __typename?: 'ApplicationsPageInfo';
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  preFilterTotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type ApplicationsResponse = ApplicationsResponseSuccess | ResponseError;

export type ApplicationsResponseSuccess = {
  __typename?: 'ApplicationsResponseSuccess';
  filterCounts: ApplicationsFilterCounts;
  pageInfo: ApplicationsPageInfo;
  result: Application[];
  statusFolderCounts: ApplicationsStatusFolderCounts;
};

export type ApplicationsSortInput = {
  orderBy?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<ApplicationSort>;
};

export type ApplicationsStatusFolderCounts = {
  __typename?: 'ApplicationsStatusFolderCounts';
  acceptCount: Scalars['Int']['output'];
  inboxCount: Scalars['Int']['output'];
  interviewCount: Scalars['Int']['output'];
  newCount: Scalars['Int']['output'];
  notSuitableCount: Scalars['Int']['output'];
  offerCount: Scalars['Int']['output'];
  prescreenCount: Scalars['Int']['output'];
  shortlistCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  viewedCount: Scalars['Int']['output'];
};

export type ApprovedIntegrationRelationship = {
  __typename?: 'ApprovedIntegrationRelationship';
  hirerAccountId: Scalars['ID']['output'];
  hirerOid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  relationship: IntegrationRelationshipType;
};

export type AssociatedAction = {
  __typename?: 'AssociatedAction';
  code: Scalars['String']['output'];
  date: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  job?: Maybe<InteractedJob>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type AssociatedParty = {
  __typename?: 'AssociatedParty';
  firstName?: Maybe<Scalars['String']['output']>;
  formattedName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
};

export type AuditSuccessfulLoginPayload = {
  __typename?: 'AuditSuccessfulLoginPayload';
  success: Scalars['Boolean']['output'];
};

/**
 * Country ID - ['bca', 'bni', 'bri', 'cimb', 'mandiri']
 * Country TH - ['kbank', 'scb', 'bbl', 'ktb', 'bay', 'tmb', 'uob']
 * Country PH - ['bpi', 'unionbank']
 */
export enum Bank2C2P {
  bay = 'bay',
  bbl = 'bbl',
  bca = 'bca',
  bni = 'bni',
  bpi = 'bpi',
  bri = 'bri',
  cimb = 'cimb',
  kbank = 'kbank',
  ktb = 'ktb',
  mandiri = 'mandiri',
  scb = 'scb',
  tmb = 'tmb',
  unionbank = 'unionbank',
  uob = 'uob',
}

export type BillableApplicantsCountResponse =
  | BillableApplicantsCountResponseSuccess
  | ResponseError;

export type BillableApplicantsCountResponseSuccess = {
  __typename?: 'BillableApplicantsCountResponseSuccess';
  result: Scalars['Int']['output'];
};

export type BillableApplicantsData = {
  __typename?: 'BillableApplicantsData';
  billableApplicantsCount: BillableApplicantsCountResponse;
  count: Scalars['Int']['output'];
  maxBillableApplicants: MaxBillableApplicantsResponse;
};

export type BillableApplicantsResponse =
  | BillableApplicantsResponseSuccess
  | ResponseError;

export type BillableApplicantsResponseSuccess = {
  __typename?: 'BillableApplicantsResponseSuccess';
  data?: Maybe<BillableApplicantsData>;
};

export enum BillableStatus {
  BILLABLE = 'BILLABLE',
  NOT_BILLABLE = 'NOT_BILLABLE',
}

export type BillingContact = {
  __typename?: 'BillingContact';
  /** Email address of the user */
  email?: Maybe<Scalars['String']['output']>;
};

export enum BillingStatus {
  OK = 'OK',
  ON_CREDIT_HOLD = 'ON_CREDIT_HOLD',
}

export type Brand = {
  __typename?: 'Brand';
  assets: BrandAssets;
  createdOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  isLiveOnAd?: Maybe<Scalars['Boolean']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  owner: BrandOwner;
  /** @deprecated Please use Brand.owner instead. */
  ownerId?: Maybe<Scalars['String']['output']>;
  state?: Maybe<BrandState>;
};

export type BrandAssetStrategies = CoverStrategies | LogoStrategies;

export type BrandAssetType = {
  __typename?: 'BrandAssetType';
  id: Scalars['String']['output'];
  strategies: Scalars['JSON']['output'];
  url: Scalars['String']['output'];
};

export enum BrandAssetTypeEnum {
  cover = 'cover',
  logo = 'logo',
}

export type BrandAssetTypeInput = {
  id: Scalars['String']['input'];
  strategies: Scalars['JSON']['input'];
  url: Scalars['String']['input'];
};

export type BrandAssets = {
  __typename?: 'BrandAssets';
  cover?: Maybe<BrandAssetType>;
  logo: BrandAssetType;
};

export type BrandAssetsInput = {
  cover?: InputMaybe<BrandAssetTypeInput>;
  logo: BrandAssetTypeInput;
};

export type BrandOwner = {
  __typename?: 'BrandOwner';
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<BrandOwnerType>;
};

export type BrandOwnerInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<BrandOwnerType>;
};

export enum BrandOwnerType {
  advertiserId = 'advertiserId',
  seekId = 'seekId',
}

export enum BrandState {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
}

export type BrandedAd = {
  __typename?: 'BrandedAd';
  bulletPointsUsed: Scalars['Boolean']['output'];
  coverImageUsed: Scalars['Boolean']['output'];
  logoUsed: Scalars['Boolean']['output'];
  type: RecommendationType;
};

/**
 * AdBudgetProduct feature to indicate budget is allowed to purchase ads of branded tier and onwards.
 * Also known as 'BrandedOnwards' in legacy terms.
 */
export type BrandedFeaturesFeature = CatalogueProductFeature & {
  __typename?: 'BrandedFeaturesFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type BrandingAsset = {
  __typename?: 'BrandingAsset';
  /** @deprecated Non translated string. Will return english only. */
  deleteWarning?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDeletable?: Maybe<Scalars['Boolean']['output']>;
  isLiveOnAd?: Maybe<Scalars['Boolean']['output']>;
  isOnDefaultBrand?: Maybe<Scalars['Boolean']['output']>;
  numberOfBrands?: Maybe<Scalars['Int']['output']>;
  numberOfLiveAds?: Maybe<Scalars['Int']['output']>;
  strategies: BrandAssetStrategies;
  url: Scalars['String']['output'];
};

/** AdProductUpgrade feature that indicates branding properties available for ad product */
export type BrandingFeature = CatalogueProductFeature & {
  __typename?: 'BrandingFeature';
  /** @deprecated borderForSearchListing is deprecated. Use StyleEnhancementFeature instead. */
  borderForSearchListing: Scalars['Boolean']['output'];
  /**
   * Number of bullet points available on job ad. Only positive numbers allowed.
   * @deprecated bulletPoints is deprecated. Use BulletPointsFeature instead.
   */
  bulletPoints: Scalars['Int']['output'];
  /** Indicates job ad can have cover image */
  hasCoverImage: Scalars['Boolean']['output'];
  /** Indicates job ad can have a logo */
  hasLogo: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  mask: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type BrandingInput = {
  brandId?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type BudgetOption = {
  __typename?: 'BudgetOption';
  count: Scalars['Int']['output'];
  data: BudgetOptionInfo;
  key: Scalars['String']['output'];
};

export type BudgetOptionInfo = {
  __typename?: 'BudgetOptionInfo';
  expiryTimestampUTC?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type BudgetProductGroup = {
  __typename?: 'BudgetProductGroup';
  displayName: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

/** Product feature that indicates bullet points for an existing ad. */
export type BulletPointsFeature = CatalogueProductFeature & {
  __typename?: 'BulletPointsFeature';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** Business numbers and identifiers such as ABN, BRN, TIN, etc. */
export type BusinessIdentifiers = {
  __typename?: 'BusinessIdentifiers';
  businessNumber?: Maybe<Scalars['String']['output']>;
  salesServiceTaxNumber?: Maybe<Scalars['String']['output']>;
  taxIdentificationNumber?: Maybe<Scalars['String']['output']>;
};

export enum BusinessType {
  BPO = 'BPO',
  Corporate = 'Corporate',
  Franchisee = 'Franchisee',
  Government = 'Government',
  NotForProfit = 'NotForProfit',
  Other = 'Other',
  Recruiter = 'Recruiter',
  SME = 'SME',
}

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type CandidateDemandResponse = {
  __typename?: 'CandidateDemandResponse';
  comparisonPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  currentPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  hasCandidateDemand: Scalars['Boolean']['output'];
};

export type CandidateDemandWidgetInput = {
  jobId: Scalars['String']['input'];
};

export enum CandidateLabel {
  APPLICATION_STARTS = 'APPLICATION_STARTS',
  CANDIDATES = 'CANDIDATES',
}

export type CandidateProfileEducation = {
  __typename?: 'CandidateProfileEducation';
  completed: Scalars['Boolean']['output'];
  completionDate?: Maybe<CandidateProfileEducationCompletionDate>;
  credential: CredentialInfo[];
  highlights?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  institute: Scalars['String']['output'];
  name: Scalars['String']['output'];
  verification?: Maybe<Verification>;
};

export type CandidateProfileEducationCompletionDate = {
  __typename?: 'CandidateProfileEducationCompletionDate';
  month?: Maybe<Scalars['Int']['output']>;
  year: Scalars['Int']['output'];
};

export type CandidateProfileExpectedSalary = {
  __typename?: 'CandidateProfileExpectedSalary';
  currency?: Maybe<Scalars['String']['output']>;
  frequency: SalaryFrequency;
  maximumSalaryValue?: Maybe<Scalars['Float']['output']>;
  minimumSalaryValue: Scalars['Float']['output'];
};

export type CandidateProfileLicence = {
  __typename?: 'CandidateProfileLicence';
  credential: CredentialInfo[];
  description?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<CandidateProfileMonthYear>;
  formattedExpiryDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  issueDate?: Maybe<CandidateProfileMonthYear>;
  issuingOrganisation?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  noExpiryDate: Scalars['Boolean']['output'];
  verification?: Maybe<Verification>;
};

export type CandidateProfileMonthYear = {
  __typename?: 'CandidateProfileMonthYear';
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type CandidateProfileNationality = {
  __typename?: 'CandidateProfileNationality';
  countryName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type CandidateProfileNationalitycountryNameArgs = {
  input: CandidateProfileNationalityCountryNameInput;
};

export type CandidateProfileNationalityCountryNameInput = {
  language: Scalars['String']['input'];
};

export type CandidateProfileRightToWork = {
  __typename?: 'CandidateProfileRightToWork';
  answer: Scalars['String']['output'];
  country: Scalars['String']['output'];
};

export type CandidateProfileRightToWorkDisplayLabelInput = {
  language: Scalars['String']['input'];
};

export type CandidateProfileRightToWorkV2 = {
  __typename?: 'CandidateProfileRightToWorkV2';
  displayLabel: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type CandidateProfileRightToWorkV2displayLabelArgs = {
  input: CandidateProfileRightToWorkDisplayLabelInput;
};

export type CandidateProfileSkill = {
  __typename?: 'CandidateProfileSkill';
  keyword: Scalars['String']['output'];
};

export type CandidateProfileVerifiedIdentity = {
  __typename?: 'CandidateProfileVerifiedIdentity';
  fullName: Scalars['String']['output'];
};

export type CandidateProfileWorkHistory = {
  __typename?: 'CandidateProfileWorkHistory';
  achievements?: Maybe<Scalars['String']['output']>;
  company: Scalars['String']['output'];
  endDate?: Maybe<CandidateProfileMonthYear>;
  id: Scalars['ID']['output'];
  startDate: CandidateProfileMonthYear;
  title: Scalars['String']['output'];
};

export type CandidateSupplyResponse = {
  __typename?: 'CandidateSupplyResponse';
  comparisonPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  currentPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  hasCandidateSupply: Scalars['Boolean']['output'];
};

export type CandidateSupplyWidgetInput = {
  jobId: Scalars['String']['input'];
};

export type CappedUnitPrice = {
  __typename?: 'CappedUnitPrice';
  cappedPrice: CatalogueProductPrice;
  maxBillableUnits: Scalars['Int']['output'];
  metadata: CappedUnitPriceMetadata;
  type: Scalars['String']['output'];
  unitPrice: CatalogueProductPrice;
};

export type CappedUnitPriceMetadata = {
  __typename?: 'CappedUnitPriceMetadata';
  unitType: Scalars['String']['output'];
};

export type Cart = {
  __typename?: 'Cart';
  customerId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isOrderReady: Scalars['Boolean']['output'];
  items: CartItem[];
  productSetId?: Maybe<Scalars['String']['output']>;
  promoCode?: Maybe<Scalars['String']['output']>;
};

export type CartItem = {
  __typename?: 'CartItem';
  parentReferenceId?: Maybe<Scalars['String']['output']>;
  product: CartItemProduct;
  referenceId: Scalars['String']['output'];
};

export type CartItemInput = {
  parentReferenceId?: InputMaybe<Scalars['String']['input']>;
  product: CartItemProductInput;
  referenceId: Scalars['String']['input'];
};

export type CartItemJobReferenceInput = {
  id: Scalars['String']['input'];
  revision: Scalars['ID']['input'];
};

export type CartItemProduct = {
  id: Scalars['String']['output'];
};

export type CartItemProductInput = {
  id: Scalars['ID']['input'];
  job?: InputMaybe<CartItemJobReferenceInput>;
  productType: CartItemProductTypeInput;
};

export enum CartItemProductTypeInput {
  AD_BUDGET = 'AD_BUDGET',
  AD_PRODUCT = 'AD_PRODUCT',
  AD_PRODUCT_ADD_ON = 'AD_PRODUCT_ADD_ON',
  AD_PRODUCT_UPGRADE = 'AD_PRODUCT_UPGRADE',
}

export type CartNotFoundError = {
  __typename?: 'CartNotFoundError';
  message: Scalars['String']['output'];
};

export type CatalogueProduct = {
  /** Information on how to display the product */
  display?: Maybe<ProductDisplay>;
  /** Features of the catalogue product */
  features: Array<Maybe<CatalogueProductFeature>>;
  /** Unique ID of the catalogue product */
  id: Scalars['ID']['output'];
  /** Name of the catalogue product */
  name: Scalars['String']['output'];
  /** Price of the catalogue product, or price access level (priceVisibility) if hidePrice is enabled for the hirer */
  price: CatalogueProductPrice;
  /** The unique product code for this product. Human-readable alternative to ID */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Whether the product is "purchasable" or was previously "purchased" */
  purchaseState: CatalogueProductPurchaseState;
};

export type CatalogueProductAttributes = {
  jobId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
  productSetId: Scalars['String']['input'];
  /** TODO: what if the set doesn't contain any JobAds? it is optional */
  revisionId?: InputMaybe<Scalars['String']['input']>;
};

export type CatalogueProductFeature = {
  /** Unique ID of the catalogue product feature */
  id: Scalars['ID']['output'];
  /** Name of the catalogue product feature, ie. Listing, Branding, StandardTalentSearch etc */
  name: Scalars['String']['output'];
  /** Type of the catalogue product feature, ie. Listing, Branding, StandardTalentSearch etc */
  type: Scalars['String']['output'];
};

export type CatalogueProductPrice = HiddenPrice | Price;

export enum CatalogueProductPurchaseState {
  PURCHASABLE = 'PURCHASABLE',
  PURCHASED = 'PURCHASED',
}

export type CategoriesInput = {
  categoryId: Scalars['String']['input'];
  subCategoryId: Scalars['String']['input'];
};

export type Category = {
  __typename?: 'Category';
  categoryId: Scalars['Int']['output'];
  categoryName: Scalars['String']['output'];
  subCategoryId: Scalars['Int']['output'];
  subCategoryName: Scalars['String']['output'];
};

export type CategorySuggestion = {
  __typename?: 'CategorySuggestion';
  category: Category;
  probability: Scalars['Float']['output'];
};

export enum ChannelCode {
  ALIPAYHK = 'ALIPAYHK',
  BILLEASE = 'BILLEASE',
  DANA = 'DANA',
  GCASH = 'GCASH',
  GRAB = 'GRAB',
  HKFPSQR = 'HKFPSQR',
  HOMECREDIT = 'HOMECREDIT',
  LINKAJA = 'LINKAJA',
  OVO = 'OVO',
  PAYMAYA = 'PAYMAYA',
  PAYPAL = 'PAYPAL',
  SHPQR = 'SHPQR',
}

export type ChildAccountBannerResponse = {
  __typename?: 'ChildAccountBannerResponse';
  isChild: Scalars['Boolean']['output'];
};

export type Claim = {
  __typename?: 'Claim';
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ClassificationOption = {
  __typename?: 'ClassificationOption';
  count: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  subClassifications?: Maybe<ClassificationOption[]>;
  value: Scalars['String']['output'];
};

export type ClicksOnYourAdResponse = {
  __typename?: 'ClicksOnYourAdResponse';
  adRatingConfidence?: Maybe<AnalyticsAdRatingConfidence>;
  appConversionInPercentage?: Maybe<Scalars['Float']['output']>;
  hasAdRating: Scalars['Boolean']['output'];
  isLinkOutAd: Scalars['Boolean']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  mobileClicksInPercentage: Scalars['Float']['output'];
  similarAdsAverage?: Maybe<Scalars['Int']['output']>;
  totalClicks: Scalars['Int']['output'];
};

export type ClicksOnYourAdWidgetInput = {
  jobId: Scalars['String']['input'];
};

export type CompanyAddress = {
  __typename?: 'CompanyAddress';
  country?: Maybe<Scalars['String']['output']>;
};

export type CompanyProfile = {
  __typename?: 'CompanyProfile';
  awards?: Maybe<CompanyProfileAward[]>;
  branding?: Maybe<CompanyProfileBranding>;
  cultureAndValues?: Maybe<CompanyProfileCultureAndValues>;
  customStatement?: Maybe<CompanyProfileCustomStatement>;
  featured?: Maybe<CompanyProfileFeatured>;
  gallery?: Maybe<CompanyProfilePhoto[]>;
  id: Scalars['ID']['output'];
  metadata?: Maybe<CompanyProfileMetadata>;
  missionStatement?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  overview?: Maybe<CompanyProfileOverview>;
  perksAndBenefits?: Maybe<CompanyProfilePerksAndBenefits[]>;
  profilePublic?: Maybe<Scalars['Boolean']['output']>;
  reviews: Reviews;
};

export type CompanyProfilereviewsArgs = {
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyProfileAction = {
  __typename?: 'CompanyProfileAction';
  actionType: CompanyProfileActionType;
  companyId: Scalars['ID']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
};

export enum CompanyProfileActionType {
  CLAIM = 'CLAIM',
  MANAGE = 'MANAGE',
}

export type CompanyProfileAdvertiserClaimInput = {
  businessType?: InputMaybe<BusinessType>;
  businessTypeOther?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  companySize?: InputMaybe<Scalars['String']['input']>;
  relationshipStatus: RelationshipStatus;
  zone: Scalars['String']['input'];
};

export type CompanyProfileAdvertiserClaimResponse = {
  __typename?: 'CompanyProfileAdvertiserClaimResponse';
  businessType?: Maybe<BusinessType>;
  businessTypeOther?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  companySize?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  hirerUserId?: Maybe<Scalars['String']['output']>;
  reasons?: Maybe<Array<Maybe<Reason>>>;
  relationshipStatus: RelationshipStatus;
  staffUserId?: Maybe<Scalars['String']['output']>;
};

export type CompanyProfileAward = {
  __typename?: 'CompanyProfileAward';
  description: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type CompanyProfileAwardInput = {
  imageUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type CompanyProfileBranding = {
  __typename?: 'CompanyProfileBranding';
  brandId?: Maybe<Scalars['ID']['output']>;
  cover?: Maybe<Scalars['String']['output']>;
  logo: Scalars['String']['output'];
};

export type CompanyProfileBrandingInput = {
  brandId?: InputMaybe<Scalars['String']['input']>;
  cover?: InputMaybe<Scalars['String']['input']>;
  logo: Scalars['String']['input'];
};

export type CompanyProfileCultureAndValues = {
  __typename?: 'CompanyProfileCultureAndValues';
  description: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  values?: Maybe<CompanyProfileCultureValue[]>;
};

export type CompanyProfileCultureAndValuesInput = {
  description: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  values?: InputMaybe<CompanyProfileCultureValueInput[]>;
};

export type CompanyProfileCultureValue = {
  __typename?: 'CompanyProfileCultureValue';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CompanyProfileCultureValueInput = {
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CompanyProfileCustomStatement = {
  __typename?: 'CompanyProfileCustomStatement';
  description: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type CompanyProfileCustomStatementInput = {
  description: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CompanyProfileFeatured = {
  __typename?: 'CompanyProfileFeatured';
  description?: Maybe<Array<Scalars['String']['output']>>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type CompanyProfileFeaturedInput = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyProfileIds = {
  __typename?: 'CompanyProfileIds';
  companyIds?: Maybe<Array<Scalars['ID']['output']>>;
};

export type CompanyProfileInput = {
  awards?: InputMaybe<CompanyProfileAwardInput[]>;
  branding?: InputMaybe<CompanyProfileBrandingInput>;
  cultureAndValues?: InputMaybe<CompanyProfileCultureAndValuesInput>;
  customStatement?: InputMaybe<CompanyProfileCustomStatementInput>;
  featured?: InputMaybe<CompanyProfileFeaturedInput>;
  gallery?: InputMaybe<CompanyProfilePhotoInput[]>;
  id: Scalars['ID']['input'];
  missionStatement?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  overview?: InputMaybe<CompanyProfileOverviewInput>;
  perksAndBenefits?: InputMaybe<CompanyProfilePerksAndBenefitsInput[]>;
};

export type CompanyProfileMetadata = {
  __typename?: 'CompanyProfileMetadata';
  profilePublic: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyProfileOverview = {
  __typename?: 'CompanyProfileOverview';
  description?: Maybe<Array<Scalars['String']['output']>>;
  industry?: Maybe<Scalars['String']['output']>;
  primaryLocation?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  specialities?: Maybe<Array<Scalars['String']['output']>>;
  website?: Maybe<CompanyProfileWebsite>;
};

export type CompanyProfileOverviewInput = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  industry?: InputMaybe<Scalars['String']['input']>;
  primaryLocation?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  specialities?: InputMaybe<Array<Scalars['String']['input']>>;
  website?: InputMaybe<CompanyProfileWebsiteInput>;
};

export type CompanyProfilePerksAndBenefits = {
  __typename?: 'CompanyProfilePerksAndBenefits';
  description?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type CompanyProfilePerksAndBenefitsInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CompanyProfilePhoto = {
  __typename?: 'CompanyProfilePhoto';
  date?: Maybe<Scalars['Int']['output']>;
  link: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type CompanyProfilePhotoInput = {
  date?: InputMaybe<Scalars['Int']['input']>;
  link: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyProfileWebsite = {
  __typename?: 'CompanyProfileWebsite';
  displayName?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type CompanyProfileWebsiteInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type ComparativeAttribute = {
  __typename?: 'ComparativeAttribute';
  description?: Maybe<Scalars['String']['output']>;
  helpTip?: Maybe<InclusionHelpTip>;
  key: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ComparativeSection = {
  __typename?: 'ComparativeSection';
  attributes?: Maybe<ComparativeAttribute[]>;
  title: Scalars['String']['output'];
};

export type ComparativeSections = {
  __typename?: 'ComparativeSections';
  sections?: Maybe<ComparativeSection[]>;
};

export type ConfirmInvoiceOrderInput = {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  billToAgent: Scalars['Boolean']['input'];
  idempotencyKey: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

export type ConfirmInvoiceOrderPayload =
  | InvoiceOrderNotFoundPayload
  | InvoiceOrderSuccessPayload
  | OrderExpiredError
  | OrderInvalidError
  | OrderNotFoundError
  | PromotionInvalidError;

export type ConnectedTalentSearchRedirectUrlInput = {
  /** Return URI to redirect to after selecting an account. */
  returnUri: Scalars['String']['input'];
};

export type ConnectionOptions = {
  __typename?: 'ConnectionOptions';
  accessCv?: Maybe<AccessCvState>;
  accessProfile?: Maybe<AccessProfileState>;
  exportProfile?: Maybe<ExportProfileState[]>;
  sendJob?: Maybe<SendJobState>;
  sendMessage?: Maybe<SendMessageState>;
};

export type ConnectionPending = {
  __typename?: 'ConnectionPending';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type ConnectionStatusPayload = {
  __typename?: 'ConnectionStatusPayload';
  connectionStatus: TalentSearchConnectionStatus;
  creditsRedeemedDateUtc?: Maybe<Scalars['String']['output']>;
  nextAvailableDateUtc?: Maybe<Scalars['String']['output']>;
};

export type Contract = {
  __typename?: 'Contract';
  /** Info on discounts that are available for Ad products, only available for SEEK_CONTRACT types */
  adDiscounts?: Maybe<Array<Maybe<ContractAdDiscount>>>;
  /** The date when contract was created eg. '2019-10-09T11:00:00.000Z' */
  createdDate: Scalars['String']['output'];
  current: Scalars['Boolean']['output'];
  expiry: Expiry;
  /** The date from when contract is consumable eg. '2019-11-01T11:00:00.000Z' */
  validFromDate: Scalars['String']['output'];
  value: ContractValue;
};

export type ContractAdDiscount = {
  __typename?: 'ContractAdDiscount';
  adType: AdType;
  /** Minimum possible discount for an AdType according to the contract and the current variable ad prices */
  minimumPercentageSavings?: Maybe<Scalars['Float']['output']>;
};

export type ContractBalance = {
  __typename?: 'ContractBalance';
  adType: AdType;
  adTypeDescription?: Maybe<Scalars['String']['output']>;
  contractType: ContractType;
  contracts?: Maybe<Contract[]>;
  dollarBased: Scalars['Boolean']['output'];
  /** To show or hide the balance */
  hideBalance: Scalars['Boolean']['output'];
  lowBalance: Scalars['Boolean']['output'];
  recommendUpSell: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  totalValue: ContractValue;
};

export type ContractBalanceGroup = {
  __typename?: 'ContractBalanceGroup';
  /** Collection of contracts grouped by the contract name */
  contracts: ContractDetails[];
  /** Name of the contract which can be shown to hirers, e.g. "Premium Ad Budget" */
  name: Scalars['String']['output'];
  /** Total balance of remaining and original amount of the contracts group */
  totalBalance: ContractBalanceSummary;
};

export type ContractBalanceSummary = {
  __typename?: 'ContractBalanceSummary';
  /** The currency the contract is based on */
  currency: Scalars['String']['output'];
  /** The original amount the hirer had purchased for their budget */
  originalAmount: Money;
  /** The amount remaining on the hirer budget that can be spent */
  remainingAmount: Money;
};

export type ContractDetails = {
  __typename?: 'ContractDetails';
  /** Denotes the 'amount' of the budget in minor units (E.g. cents in AUD) */
  balance: ContractBalanceSummary;
  /** The ISO timestamp indicating when the contract was created in CRM */
  createdAt: Scalars['String']['output'];
  /** The ISO timestamp indicating when the contract ends */
  endDate: Scalars['String']['output'];
  /** The ISO timestamp indicating when the contract is applicable for use by the hirer */
  startDate: Scalars['String']['output'];
};

export type ContractDollarValue = {
  __typename?: 'ContractDollarValue';
  original: Money;
  remaining: Money;
};

export enum ContractType {
  AD_PACK = 'AD_PACK',
  MASTER_AGREEMENT = 'MASTER_AGREEMENT',
  PRICING_GROUP = 'PRICING_GROUP',
  SEEK_CONTRACT = 'SEEK_CONTRACT',
  SUBSCRIPTION = 'SUBSCRIPTION',
  TALENT_SEARCH_CONTRACT = 'TALENT_SEARCH_CONTRACT',
}

export type ContractUnitValue = {
  __typename?: 'ContractUnitValue';
  original: Scalars['Int']['output'];
  remaining: Scalars['Int']['output'];
};

export type ContractValue = {
  __typename?: 'ContractValue';
  dollars: ContractDollarValue;
  units?: Maybe<ContractUnitValue>;
};

export type ConversionRateData = {
  __typename?: 'ConversionRateData';
  adConversionRate?: Maybe<Scalars['Float']['output']>;
  mobileRate: Scalars['Float']['output'];
  similarAdAverage: Scalars['Float']['output'];
  similarAdConversionRate?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
};

export type CorrectUserIncorrectHirerResponseSuccess = {
  __typename?: 'CorrectUserIncorrectHirerResponseSuccess';
  result: HirerJobAccess;
};

export type CountriesInput = {
  /** Used to generate the 'contextualName', this should usually represent the country the hire is located in. If it isn't provided it will fallback to the hirer's nation */
  contextual?: InputMaybe<Scalars['String']['input']>;
  /** Restricts the location suggestion search to only include these countries in the results. */
  only?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Controls the order of results so that you can specify for one countries results to appear above other(s). If it isn't provided it will use the order of 'only' as fallback. If 'only' isn't provided then it will fallback to 'contextual' as first preference. */
  preferenceOrder?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Country = {
  __typename?: 'Country';
  /** 2 digit code in ISO 3166-1 alpha-2 format eg GB */
  countryCode: Scalars['String']['output'];
  /** Full name of the country eg United Kingdom */
  name: Scalars['String']['output'];
};

export type CountrySelectionOptions = {
  __typename?: 'CountrySelectionOptions';
  /** List of countries to populate a drop-down list or autocomplete. */
  options: Country[];
};

export type CountryType = {
  __typename?: 'CountryType';
  /** Full name of the country */
  defaultName: Scalars['String']['output'];
  /** Type of the location, eg. "State" / "Country" */
  kind: Scalars['String']['output'];
};

export type CoverStrategies = {
  __typename?: 'CoverStrategies';
  jdpCover: Scalars['String']['output'];
  jdpCoverThumbnail: Scalars['String']['output'];
};

export type Create2C2PPaymentIntentForInvoiceInput = {
  /** The phone number of payer when OVO, BILLEASE or HOMECREDIT channelcode is used. */
  accountNo?: InputMaybe<Scalars['String']['input']>;
  /** bank used by 2c2p to identify the bank */
  bank?: InputMaybe<Bank2C2P>;
  /** optional, only for e-wallets. channelCode used by 2c2p to identify the channel */
  channelCode?: InputMaybe<ChannelCode>;
  /** The email of the advertiser. */
  email: Scalars['String']['input'];
  /** The return url for the frontend to redirect to after payment is completed. */
  frontendReturnUrl?: InputMaybe<Scalars['String']['input']>;
  /** Invoices to be paid. REMOVE this later when we are able to get the total amount from the invoice API. */
  invoices: InvoiceInput[];
  /** Type of payment method */
  paymentMethodType: PaymentMethodType2C2P;
  /** The reference of the invoice */
  prn: Scalars['String']['input'];
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * seekAdvertiserId of the advertiser.
   * This will be mapped to accountId on paymentService
   */
  seekAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * customer number for payment link experience.
   * seekBillingAccountId of the advertiser login experience
   * This will be mapped to customerId on paymentService
   */
  seekBillingAccountId: Scalars['String']['input'];
  /** The total amount to be paid for the payment intent. REMOVE this later when we are able to get the total amount from the invoice API. */
  totalForPaymentIntent: Scalars['SafeInt']['input'];
};

export type Create2C2PPaymentIntentInput = {
  /** The phone number of payer when OVO, BILLEASE or HOMECREDIT channelcode is used. */
  accountNo?: InputMaybe<Scalars['String']['input']>;
  /** bank used by 2c2p to identify the bank */
  bank?: InputMaybe<Bank2C2P>;
  /** optional, only for e-wallets. channelCode used by 2c2p to identify the channel */
  channelCode?: InputMaybe<ChannelCode>;
  /** The return url for the frontend to redirect to after payment is completed. */
  frontendReturnUrl?: InputMaybe<Scalars['String']['input']>;
  /** Id of the order. */
  orderId: Scalars['ID']['input'];
  /** Type of payment method */
  paymentMethodType: PaymentMethodType2C2P;
};

export type Create2c2pPaymentIntentPayload =
  | InvoiceError
  | OrderExpiredError
  | OrderInvalidError
  | OrderNotFoundError
  | PaymentIntent2C2PPayload
  | PaymentIntentChargeError
  | PaymentMethodError
  | PromotionInvalidError;

export type CreateApplicationNoteInput = {
  content: Scalars['String']['input'];
  prospectId: Scalars['ID']['input'];
};

export type CreateBrandInput = {
  assets: BrandAssetsInput;
  name?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Internally resolved via auth */
  owner?: InputMaybe<BrandOwnerInput>;
};

export type CreateBrandPayload =
  | CreateBrandSuccessPayload
  | InvalidInputPayload;

export type CreateBrandSuccessPayload = {
  __typename?: 'CreateBrandSuccessPayload';
  assets: BrandAssets;
  createdOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  owner: BrandOwner;
  state?: Maybe<BrandState>;
};

export type CreateCustomQuestionErrorResult = {
  __typename?: 'CreateCustomQuestionErrorResult';
  reason: Scalars['String']['output'];
};

export type CreateCustomQuestionInput = {
  /** Language code */
  languageId: Scalars['String']['input'];
  /** Custom question to be created */
  question: CustomQuestion;
};

export type CreateCustomQuestionOutputUnion =
  | CreateCustomQuestionErrorResult
  | CreateCustomQuestionResult;

export type CreateCustomQuestionResult = {
  __typename?: 'CreateCustomQuestionResult';
  question: OutputCustomQuestion;
  validationFailures: CustomQuestionValidationFailure[];
};

export type CreateDraftJobInput = {
  /**
   * Adcentre advertiserId
   * @deprecated No longer required. Will be retired once the front-end is updated.
   */
  adcentreAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: InputMaybe<Scalars['String']['input']>;
  /** Selected branding info */
  branding?: InputMaybe<DraftJobBrandingInput>;
  /** Selected category and sub-category */
  categories?: InputMaybe<DraftJobCategoryInput[]>;
  /** CreatedAt date will only update when used in migration */
  createdAt?: InputMaybe<Scalars['String']['input']>;
  /** The Job Description, aka "Write my own" */
  details?: InputMaybe<Scalars['String']['input']>;
  /**
   * The draft progress made in the job posting's create flow
   * Edit flow and refresh flow do not have draftProgress
   * @deprecated Will be removed once deprecated express api
   */
  draftProgress?: InputMaybe<DraftJobProgress>;
  /** Selected expire data */
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  /** The flow a draft is created from (e.g. create, edit) */
  flowType?: InputMaybe<DraftJobFlowType>;
  /** The pre-defined Guided Job description, aka "Help me write my ad" */
  guidedDetails?: InputMaybe<DraftJobGuidedDetailsInput[]>;
  /** The flag to tell whether the hirer is posting a private or a public Ad. */
  isConfidential?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reference number is displayed on invoices and used for linkout ads */
  jobAdReference?: InputMaybe<Scalars['String']['input']>;
  /** Standard or Guided Mode for writing job description */
  jobWritingMode?: InputMaybe<DraftJobWritingMode>;
  /** Selling points - used in the search description for standout/premium ads */
  keySellingPoints?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The job location info */
  locations?: InputMaybe<DraftJobLocationInput[]>;
  /**
   * The selected payment option (e.g. invoice, credit card etc.)
   * @deprecated Will be removed once deprecated express api
   */
  paymentOption?: InputMaybe<DraftJobPaymentOption>;
  /** Hirer's phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Selected products info */
  products?: InputMaybe<DraftJobProductsInput>;
  /** The id for a role requirements questionnaire */
  questionnaireId?: InputMaybe<Scalars['String']['input']>;
  /** Min/Max salaries info */
  salary?: InputMaybe<DraftJobSalaryDetailsInput>;
  /** The status of the draft */
  status?: InputMaybe<DraftStatus>;
  /** The job summary to appear in search */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** The job title(s) */
  titles?: InputMaybe<DraftJobRoleTitleInput[]>;
  /** The video info, e.g. video url */
  video?: InputMaybe<DraftJobVideoInput>;
  /** Work arrangements, ie Remote, Onsite, Hybrid */
  workArrangements?: InputMaybe<WorkArrangement[]>;
  /** The work type(s) (e.g. full time, part time) */
  workTypes?: InputMaybe<WorkType[]>;
};

export type CreateDraftJobPayload = {
  __typename?: 'CreateDraftJobPayload';
  /**
   * The successfully created draft job id. Deprecated "Int" version.
   * @deprecated Will be removed once no clients are calling it
   */
  draftId?: Maybe<Scalars['Int']['output']>;
  /** The successfully created draft job id */
  id?: Maybe<Scalars['String']['output']>;
};

export type CreateJobInput = {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  branding?: InputMaybe<BrandingInput>;
  draftId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
};

export type CreateJobResponse = {
  __typename?: 'CreateJobResponse';
  /** The key used to create the job */
  idempotencyKey: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  /** The id of the revision that was created for the job */
  revisionId: Scalars['String']['output'];
};

export type CreateJobRevisionInput = {
  idempotencyKey: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
  /** The workflow context that the Job Revision is being created in. e.g. upgrade-purchase */
  workflowContext?: InputMaybe<Scalars['String']['input']>;
};

export type CreateJobRevisionPayload = {
  __typename?: 'CreateJobRevisionPayload';
  revisionId: Scalars['String']['output'];
};

export type CreateOrderInput = {
  /**
   * Advertiser's Id.
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Advertiser's nation.
   * @deprecated not necessary
   */
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  /** Selected line items. Contains input attributes associated to selected product. */
  lineItems: OrderRequestInputItem[];
  /** Idempotency key for create order request */
  orderCreationId?: InputMaybe<Scalars['String']['input']>;
  /** Promotion code. */
  promoCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrderPayload = {
  __typename?: 'CreateOrderPayload';
  order?: Maybe<Order>;
};

export type CreateOrderSuccessPayload = {
  __typename?: 'CreateOrderSuccessPayload';
  order?: Maybe<Order>;
  orderCreationId?: Maybe<Scalars['String']['output']>;
  promotion?: Maybe<Promotion>;
};

export type CreateOrderWithPromotionPayload =
  | CreateOrderSuccessPayload
  | PromotionInvalidError;

export type CreateProductSetForAdEditingInput = {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /** The draft ID associated to the job posting flow */
  draftId: Scalars['String']['input'];
  /** The current job details as seen by the hirer during edit */
  jobAdDetails: JobAdDetails;
  jobId: Scalars['ID']['input'];
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
};

export type CreateProductSetForAdEditingPayload = {
  __typename?: 'CreateProductSetForAdEditingPayload';
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
};

export type CreateProductSetForAdPostingInput = {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  /** The draft ID associated to the job posting flow */
  draftId?: InputMaybe<Scalars['String']['input']>;
  jobAdDetails: JobAdDetails;
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
  /** The user intent */
  userIntent?: InputMaybe<UserIntentType>;
};

export type CreateProductSetForAdPostingPayload = {
  __typename?: 'CreateProductSetForAdPostingPayload';
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
};

export type CreateProductSetForAdRefreshInput = {
  /** The draft ID associated to the job refresh flow */
  draftId?: InputMaybe<Scalars['String']['input']>;
  /** The job ID associated to the job refresh flow */
  jobId: Scalars['ID']['input'];
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
  /** The user intent */
  userIntent?: InputMaybe<UserIntentType>;
};

export type CreateProductSetForAdRefreshPayload = {
  __typename?: 'CreateProductSetForAdRefreshPayload';
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
};

export type CreateProductSetForAdUpgradePurchaseInput = {
  /** The ID of the Job that is to be updated */
  jobId: Scalars['String']['input'];
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /**
   * The unique identifier linking tracking events associated with a user's interaction within a specific UI flow that can
   * result in a purchase. It enables the aggregation of data related to actions such as posting a job ad, paying for it,
   * upgrading the ad, and other relevant activities occurring within the same user session.
   */
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
  /** The user intent */
  userIntent?: InputMaybe<UserIntentType>;
};

export type CreateProductSetForAdUpgradePurchasePayload = {
  __typename?: 'CreateProductSetForAdUpgradePurchasePayload';
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The product IDs that are purchasable */
  productIds: Array<Scalars['ID']['output']>;
  /** The created product set */
  productSet: ProductSet;
  /** Products to display */
  products: AdProductUpgrade[];
};

export type CreateProductSetForBudgetsPurchaseForHirerInput = {
  /** The locale to get product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** The unique identifier linking tracking events associated with a user's interaction within a specific UI flow that can result in a purchase. */
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
};

export type CreateProductSetForBudgetsPurchaseForHirerPayload = {
  __typename?: 'CreateProductSetForBudgetsPurchaseForHirerPayload';
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
};

export type CreateProductSetForBudgetsPurchaseForVisitorInput = {
  /** The locale to get product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
  /** The randomly generated UUIDv4 of the logged out user */
  visitorId: Scalars['ID']['input'];
};

export type CreateProductSetForBudgetsPurchaseForVisitorPayload = {
  __typename?: 'CreateProductSetForBudgetsPurchaseForVisitorPayload';
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
};

export type CreateProductSetForMultiLocationInput = {
  /**
   * The ID of the advertiser
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /** The draft ID associated to the job posting flow */
  draftId?: InputMaybe<Scalars['String']['input']>;
  /** The current job details as seen by the hirer during edit */
  jobAdDetails: JobAdDetails;
  /** ID of the original job being posted to multiple locations */
  jobId: Scalars['String']['input'];
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
};

export type CreateProductSetForMultiLocationPayload = {
  __typename?: 'CreateProductSetForMultiLocationPayload';
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
};

export type CreateProductSetForPriceCheckerInput = {
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  jobAdDetails: JobAdDetails;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
};

export type CreateProductSetForPriceCheckerPreviewInput = {
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  jobAdDetails: JobAdDetails;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
};

export type CreateProductSetForPriceCheckerPreviewPayload = {
  __typename?: 'CreateProductSetForPriceCheckerPreviewPayload';
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
};

export type CreateQuestionnaireInput = {
  /** @deprecated Internally resolved via auth */
  adCentreAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** This is the country code for the website domain being used to post job and create questionnaire. e.g values au and nz as strings */
  domainCountryCode: Scalars['String']['input'];
  language: Scalars['String']['input'];
  sourceQuestionnaireId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateQuestionnaireResponse = {
  __typename?: 'CreateQuestionnaireResponse';
  questionnaireId?: Maybe<Scalars['String']['output']>;
  questionnaireUrl?: Maybe<Scalars['String']['output']>;
};

export type CreateSavedSearchInput = {
  createdDate: Scalars['String']['input'];
  criteria: TalentSearchCriteriaInput;
  name: Scalars['String']['input'];
};

export type CreateSavedSearchPayload = {
  __typename?: 'CreateSavedSearchPayload';
  savedSearch?: Maybe<TalentSearchSavedSearch>;
};

export type CreateStripePaymentIntentForInvoiceInput = {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand?: InputMaybe<CreditCardBrandInput>;
  /** The email of the advertiser. */
  email: Scalars['String']['input'];
  /** Number of days before the payment intent expires and is disabled for payment. Minimum of at least 1 day */
  expiryDays?: InputMaybe<Scalars['Int']['input']>;
  /** The idempotency key for the payment intent. If not provided, a random uuid will be generated. */
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  /** Invoices to be paid. REMOVE this later when we are able to get the total amount from the invoice API. */
  invoices: InvoiceInput[];
  /** Type of payment method */
  paymentMethod: StripePaymentMethod;
  /** The reference of the invoice */
  prn: Scalars['String']['input'];
  /** The payment intent will be set up to save the card (as a payment method) entered on the payment UI against advertiser Id. */
  saveCard?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * seekAdvertiserId of the advertiser.
   * This will be mapped to accountId on paymentService
   */
  seekAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * customer number for payment link experience.
   * seekBillingAccountId of the advertiser login experience
   * This will be mapped to customerId on paymentService
   */
  seekBillingAccountId: Scalars['String']['input'];
  /** The total amount to be paid for the payment intent. REMOVE this later when we are able to get the total amount from the invoice API. */
  totalForPaymentIntent: Scalars['SafeInt']['input'];
};

export type CreateStripePaymentIntentInput = {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand?: InputMaybe<CreditCardBrandInput>;
  /** The idempotency key for the payment intent. If not provided, a random uuid will be generated. */
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  /** Id of the order. */
  orderId: Scalars['ID']['input'];
  /** Type of payment method */
  paymentMethod: StripePaymentMethod;
  /** The payment intent will be set up to save the card (as a payment method) entered on the payment UI against advertiser Id. */
  saveCard?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateStripePaymentIntentPayload =
  | InvoiceError
  | OrderExpiredError
  | OrderInvalidError
  | OrderNotFoundError
  | PaymentMethodError
  | PromotionInvalidError
  | StripePaymentIntentPayload;

export type CredentialInfo = {
  __typename?: 'CredentialInfo';
  name: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type CreditCard = {
  __typename?: 'CreditCard';
  /** The brand of credit card processor such as 'Visa' or 'MasterCard' */
  brand: Scalars['String']['output'];
  /** The expiry month of the credit from 1 to 12, such as '02' for February or '12' for December. */
  expiryMonth: Scalars['String']['output'];
  /** A four digit number representing the expiry year, e.g. '2022' */
  expiryYear: Scalars['String']['output'];
  /** The last (typically 4) digits of a credit card, e.g. '4234' */
  lastDigits: Scalars['String']['output'];
  /** A token that uniquely represents the card, e.g. 'card_1HPZvMAtb48kSZAqvNZabcde' */
  token: Scalars['String']['output'];
};

export enum CreditCardBrandInput {
  AMEX = 'AMEX',
  MASTER_CARD = 'MASTER_CARD',
  VISA = 'VISA',
}

export enum Currency {
  AUD = 'AUD',
  HKD = 'HKD',
  IDR = 'IDR',
  MYR = 'MYR',
  NZD = 'NZD',
  PHP = 'PHP',
  SGD = 'SGD',
  THB = 'THB',
}

export type CurrentAdvertiserContext = {
  __typename?: 'CurrentAdvertiserContext';
  /** ID of the advertiser account. */
  advertiserId: Scalars['ID']['output'];
  /** Optional billing ID provided by CRM. */
  billingId?: Maybe<Scalars['String']['output']>;
  /**
   * Can Manage Company Profile
   * @deprecated Use permissionOptions.canManageCompanyProfile instead.
   */
  canManageCompanyProfile?: Maybe<Scalars['Boolean']['output']>;
  /** User claims relevant to the specified advertiser */
  claims?: Maybe<Array<Maybe<Claim>>>;
  /** Indicates whether the advertiser is an agency account. */
  isAgency: Scalars['Boolean']['output'];
  /** Name of the advertiser account. */
  name: Scalars['String']['output'];
  /** Key/value pairs of permissions for a user on behalf of the advertiser. */
  permissionOptions: PermissionOptions;
  /**
   * Array of permissions for a user on behalf of the advertiser.
   * @deprecated Use permissionOptions instead.
   */
  permissions?: Maybe<Permission[]>;
  /** Status of Profile Properties related to the account of the user currently signed in */
  profileProperties?: Maybe<ProfileProperties>;
  /** Role of user relevant to the specified advertiser. Observed to be null for migrated or impersonated customers. */
  role?: Maybe<Scalars['String']['output']>;
  /** SeekWeb Advertiser ID. */
  seekId: Scalars['ID']['output'];
  /** The advertiser's status. */
  status: AdvertiserStatus;
  /** Effective Talent Search access for this user against this advertiser */
  talentSearchAccess?: Maybe<TalentSearchAccess>;
};

export type CurrentAdvertiserResponse =
  | CurrentAdvertiserContext
  | MissingAdvertiserIdClaimError;

export type CurrentUserContext = {
  __typename?: 'CurrentUserContext';
  /** Email of the user currently signed in */
  email: Scalars['String']['output'];
  /** First name of the user. */
  firstName: Scalars['String']['output'];
  /** Adcentre user id. */
  id: Scalars['String']['output'];
  /** Last name of the user. */
  lastName: Scalars['String']['output'];
  /** First and last names of the user. */
  name: Scalars['String']['output'];
  /** Verification status of the user. */
  status: Scalars['String']['output'];
};

export type CurrentUserResponse = CurrentUserContext | MissingUserIdClaimError;

export type CustomContent = {
  __typename?: 'CustomContent';
  id: Scalars['String']['output'];
};

export type CustomQuestion = {
  /** Options */
  options: Option[];
  /** Hirer Question Type e.g SingleSelect, FreeText */
  questionType: HirerQuestionType;
  /** Question Text */
  text: Scalars['String']['input'];
};

export type CustomQuestionValidationFailure = {
  __typename?: 'CustomQuestionValidationFailure';
  /** Custom Question Validation Failure Message */
  message: Scalars['String']['output'];
};

export type CvUnavailable = {
  __typename?: 'CvUnavailable';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type DailyTrendsProductDisplayName = {
  __typename?: 'DailyTrendsProductDisplayName';
  day: Scalars['String']['output'];
  items?: Maybe<Array<Maybe<ProductDisplayNameOption>>>;
};

export type DashboardJobsCountsResponse =
  | DashboardJobsCountsResponseSuccess
  | ResponseError;

export type DashboardJobsCountsResponseSuccess = {
  __typename?: 'DashboardJobsCountsResponseSuccess';
  directExpired?: Maybe<Scalars['Int']['output']>;
  directPosted?: Maybe<Scalars['Int']['output']>;
  draftsCount?: Maybe<Scalars['Int']['output']>;
  indirectExpired?: Maybe<Scalars['Int']['output']>;
  indirectPosted?: Maybe<Scalars['Int']['output']>;
};

export type DashboardJobsInput = {
  /** The advertiser country */
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  /** The language SEEK site is using */
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  /** The maximum number of results per page. Default is 5. */
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type DashboardJobsResponse =
  | DashboardJobsResponseSuccess
  | ResponseError;

export type DashboardJobsResponseSuccess = {
  __typename?: 'DashboardJobsResponseSuccess';
  jobs: JobConnection;
};

export type DateAxisLabel = {
  __typename?: 'DateAxisLabel';
  date: Scalars['Date']['output'];
  label?: Maybe<Scalars['String']['output']>;
};

export type DeleteBrandingAssetInput = {
  assetId: Scalars['ID']['input'];
  assetType: BrandAssetTypeEnum;
  /** @deprecated Internally resolved via auth */
  owner?: InputMaybe<BrandOwnerInput>;
};

export type DeleteBrandingAssetPayload = {
  __typename?: 'DeleteBrandingAssetPayload';
  success?: Maybe<Scalars['Boolean']['output']>;
  successMessage?: Maybe<Scalars['String']['output']>;
};

export type DeleteDraftJobInput = {
  /** The id of the draft job to be deleted. */
  id: Scalars['String']['input'];
};

export type DeleteDraftJobPayload = {
  __typename?: 'DeleteDraftJobPayload';
  /** Indicates if deleting the draft job was successful or not. */
  successful: Scalars['Boolean']['output'];
};

export type DeleteSavedSearchPayload = {
  __typename?: 'DeleteSavedSearchPayload';
  deletedCriteriaHash?: Maybe<Scalars['String']['output']>;
};

export type DeleteStripeSavedCreditCardInput = {
  /** Nation of the advertiser. */
  advertiserNation: Scalars['String']['input'];
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String']['input'];
};

export type DeleteStripeSavedCreditCardPayload = {
  __typename?: 'DeleteStripeSavedCreditCardPayload';
  /** Status of the credit card deletion */
  successful?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteUploadedSupportDocInput = {
  applicationCorrelationId: Scalars['ID']['input'];
  blobId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
};

export type DeleteUploadedSupportDocResponse =
  | DeleteUploadedSupportDocResponseSuccess
  | ResponseError;

export type DeleteUploadedSupportDocResponseSuccess = {
  __typename?: 'DeleteUploadedSupportDocResponseSuccess';
  result: DeleteUploadedSupportDocResult;
};

export type DeleteUploadedSupportDocResult = {
  __typename?: 'DeleteUploadedSupportDocResult';
  blobId: Scalars['ID']['output'];
};

export type DigitalIdentity = {
  __typename?: 'DigitalIdentity';
  isVerified: Scalars['Boolean']['output'];
  verifiedFields: VerifiedField[];
};

export type DisableMessageType = {
  __typename?: 'DisableMessageType';
  en: Scalars['String']['output'];
};

export type Discount = {
  __typename?: 'Discount';
  discountAmount: Money;
  discountPercentage: Scalars['Float']['output'];
};

export type DisplayBanner = {
  __typename?: 'DisplayBanner';
  enabled: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  variant?: Maybe<Scalars['String']['output']>;
};

export type DisplayCallToAction = {
  __typename?: 'DisplayCallToAction';
  enabled: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<Scalars['String']['output']>;
};

export type DisplayComparative = {
  __typename?: 'DisplayComparative';
  content?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type DisplayComparatives = {
  __typename?: 'DisplayComparatives';
  content: DisplayComparative[];
  enabled: Scalars['Boolean']['output'];
};

export type DisplayCustom = {
  __typename?: 'DisplayCustom';
  content: CustomContent[];
  enabled: Scalars['Boolean']['output'];
};

export type DisplayExpectations = {
  __typename?: 'DisplayExpectations';
  enabled: Scalars['Boolean']['output'];
  variant?: Maybe<Scalars['String']['output']>;
};

export type DisplayHighlights = {
  __typename?: 'DisplayHighlights';
  content: Highlight[];
  enabled: Scalars['Boolean']['output'];
  variant?: Maybe<Scalars['String']['output']>;
};

export type DisplayImage = {
  __typename?: 'DisplayImage';
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type DisplayInclusions = {
  __typename?: 'DisplayInclusions';
  content: Inclusion[];
  enabled: Scalars['Boolean']['output'];
  title?: Maybe<DisplayInclusionsTitle>;
  variant?: Maybe<Scalars['String']['output']>;
};

export type DisplayInclusionsTitle = {
  __typename?: 'DisplayInclusionsTitle';
  content: Scalars['String']['output'];
};

export type DisplayMetadataError = Error & {
  __typename?: 'DisplayMetadataError';
  message: Scalars['String']['output'];
};

export type DisplayPanel = {
  __typename?: 'DisplayPanel';
  variant?: Maybe<Scalars['String']['output']>;
};

export type DisplayPrice = {
  __typename?: 'DisplayPrice';
  enabled: Scalars['Boolean']['output'];
  variant?: Maybe<Scalars['String']['output']>;
};

export type DisplayRating = {
  __typename?: 'DisplayRating';
  enabled: Scalars['Boolean']['output'];
  size?: Maybe<Scalars['String']['output']>;
  value: Scalars['Int']['output'];
  variant?: Maybe<Scalars['String']['output']>;
};

export enum DisplayRecommendation {
  exclusive = 'exclusive',
  inclusive = 'inclusive',
}

export type DisplayStyledContent = {
  __typename?: 'DisplayStyledContent';
  content: Scalars['String']['output'];
  variant?: Maybe<Scalars['String']['output']>;
};

/** DisplayTag feature for tags to be shown on candidate search results */
export type DisplayTagFeature = CatalogueProductFeature & {
  __typename?: 'DisplayTagFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tag: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type DisplayTagline = {
  __typename?: 'DisplayTagline';
  content: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  variant?: Maybe<Scalars['String']['output']>;
};

export type DisplayTitle = {
  __typename?: 'DisplayTitle';
  content: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  variant?: Maybe<Scalars['String']['output']>;
};

export type DownloadCv = {
  __typename?: 'DownloadCv';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type DownloadProfile = {
  __typename?: 'DownloadProfile';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type DownloadProfileResponse = {
  __typename?: 'DownloadProfileResponse';
  profileUrl: Scalars['String']['output'];
  resumeUrl?: Maybe<Scalars['String']['output']>;
};

export type DraftJob = {
  __typename?: 'DraftJob';
  /**
   * Adcentre adType that is old way of representing "products"
   * @deprecated Will be removed once deprecated express api
   */
  adType?: Maybe<Scalars['String']['output']>;
  /** A reference provided by the advertiser to facilitate searching in draft job lists. */
  advertiserJobReference?: Maybe<Scalars['String']['output']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: Maybe<Scalars['String']['output']>;
  /** Selected branding info */
  branding?: Maybe<DraftJobBranding>;
  /** Selected category and sub-category */
  categories?: Maybe<DraftJobCategory[]>;
  /** Date/time in UTC (ISO 8601 format) the draft job was created. */
  createdDate: Scalars['String']['output'];
  /** The Job Description, aka "Write my own" */
  details?: Maybe<Scalars['String']['output']>;
  /**
   * The draft progress made in the job posting's create flow
   * Edit flow and refresh flow do not have draftProgress
   * @deprecated Will be removed once deprecated express api
   */
  draftProgress?: Maybe<DraftJobProgress>;
  /** Selected expire data */
  expiresAt?: Maybe<Scalars['String']['output']>;
  /** The flow a draft is created from (e.g. create, edit) */
  flowType?: Maybe<DraftJobFlowType>;
  /** The pre-defined Guided Job description, aka "Help me write my ad" */
  guidedDetails?: Maybe<Array<Maybe<DraftJobGuidedDetails>>>;
  /** Flag to indicate if AI assistance has been used to generate job description and summary */
  hasAIAssistanceBeenUsed?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  /** The flag to tell whether the hirer is posting a private or a public Ad. */
  isConfidential?: Maybe<Scalars['Boolean']['output']>;
  /** Reference number is displayed on invoices and used for linkout ads */
  jobAdReference?: Maybe<Scalars['String']['output']>;
  /** Standard or Guided Mode for writing job description */
  jobWritingMode?: Maybe<DraftJobWritingMode>;
  /** Selling points - used in the search description for standout/premium ads */
  keySellingPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The job location info. Uses the Location type and will replace locations. */
  locationsV2?: Maybe<Location[]>;
  /** Details of the user who created the draft. */
  owner?: Maybe<HirerUserDetails>;
  /**
   * The selected payment option (e.g. invoice, credit card etc.)
   * @deprecated Will be removed once deprecated express api
   */
  paymentOption?: Maybe<DraftJobPaymentOption>;
  /** The phone number for the job */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Selected products info */
  products?: Maybe<DraftJobProducts>;
  /** The id for a role requirements questionnaire */
  questionnaireId?: Maybe<Scalars['String']['output']>;
  /** Min/Max salaries info */
  salary?: Maybe<DraftJobSalaryDetails>;
  status: DraftStatus;
  /** The job summary to appear in search */
  summary?: Maybe<Scalars['String']['output']>;
  /** The draft job title. */
  title?: Maybe<Scalars['String']['output']>;
  /**
   * The job title(s)
   * @deprecated Use title instead
   */
  titles?: Maybe<Array<Maybe<DraftJobRoleTitle>>>;
  /** The video info, e.g. video url */
  video?: Maybe<DraftJobVideo>;
  /** Work arrangements, ie Remote, Onsite, Hybrid */
  workArrangements?: Maybe<WorkArrangement[]>;
  /** The work type(s) (e.g. full time, part time) */
  workTypes?: Maybe<Array<Maybe<WorkType>>>;
};

export type DraftJobBranding = {
  __typename?: 'DraftJobBranding';
  id?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
};

export type DraftJobBrandingInput = {
  id: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type DraftJobCategory = {
  __typename?: 'DraftJobCategory';
  adcentreCategoryId?: Maybe<Scalars['String']['output']>;
  adcentreSubCategoryId?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  subCategoryId?: Maybe<Scalars['String']['output']>;
};

export type DraftJobCategoryInput = {
  adcentreCategoryId?: InputMaybe<Scalars['String']['input']>;
  adcentreSubCategoryId?: InputMaybe<Scalars['String']['input']>;
  categoryId: Scalars['String']['input'];
  subCategoryId: Scalars['String']['input'];
};

export enum DraftJobFlowType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  MULTILOCATION = 'MULTILOCATION',
}

export type DraftJobGuidedDetails = {
  __typename?: 'DraftJobGuidedDetails';
  type: GuidedDetailsType;
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DraftJobGuidedDetailsInput = {
  type: GuidedDetailsType;
  values: Array<Scalars['String']['input']>;
};

export type DraftJobLocation = {
  __typename?: 'DraftJobLocation';
  seekId: Scalars['String']['output'];
};

export type DraftJobLocationInput = {
  id: Scalars['String']['input'];
};

export type DraftJobNotFoundPayload = {
  __typename?: 'DraftJobNotFoundPayload';
  reason: Scalars['String']['output'];
};

export type DraftJobPayload = DraftJobNotFoundPayload | DraftJobSuccessPayload;

export enum DraftJobPaymentOption {
  CONTRACT = 'CONTRACT',
  CREDIT_CARD = 'CREDIT_CARD',
  INVOICE = 'INVOICE',
  SAVED_CREDIT_CARD = 'SAVED_CREDIT_CARD',
}

export type DraftJobProducts = {
  __typename?: 'DraftJobProducts';
  productIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  productSetId?: Maybe<Scalars['String']['output']>;
};

export type DraftJobProductsInput = {
  /** List of product id */
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Unique ID of the product set */
  productSetId?: InputMaybe<Scalars['String']['input']>;
};

export enum DraftJobProgress {
  CLASSIFY = 'CLASSIFY',
  MANAGE = 'MANAGE',
  PAY_AND_POST = 'PAY_AND_POST',
  SELECT_AD_TYPE = 'SELECT_AD_TYPE',
  WRITE = 'WRITE',
}

export type DraftJobRoleTitle = {
  __typename?: 'DraftJobRoleTitle';
  language: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type DraftJobRoleTitleInput = {
  language: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export enum DraftJobSalaryCurrency {
  AUD = 'AUD',
  BDT = 'BDT',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  IDR = 'IDR',
  INR = 'INR',
  JPY = 'JPY',
  MYR = 'MYR',
  NZD = 'NZD',
  PHP = 'PHP',
  SGD = 'SGD',
  THB = 'THB',
  USD = 'USD',
  VND = 'VND',
}

export type DraftJobSalaryDetails = {
  __typename?: 'DraftJobSalaryDetails';
  currency?: Maybe<DraftJobSalaryCurrency>;
  displayValue?: Maybe<Scalars['String']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  maximum?: Maybe<Scalars['SafeInt']['output']>;
  minimum?: Maybe<Scalars['SafeInt']['output']>;
  type?: Maybe<SalaryType>;
};

export type DraftJobSalaryDetailsInput = {
  /** The currency for the salary */
  currency?: InputMaybe<DraftJobSalaryCurrency>;
  /** The text to be displayed to show salary to candidates */
  displayValue?: InputMaybe<Scalars['String']['input']>;
  /** Should the salary text be displayed or not */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** The max salary value */
  maximum?: InputMaybe<Scalars['SafeInt']['input']>;
  /** The min salary value */
  minimum?: InputMaybe<Scalars['SafeInt']['input']>;
  /** The pay type (e.g. annual package, hourly rate) */
  type?: InputMaybe<SalaryType>;
};

export type DraftJobSuccessPayload = {
  __typename?: 'DraftJobSuccessPayload';
  draft: DraftJob;
  flowType?: Maybe<DraftJobFlowType>;
  /** @deprecated Use draft.status field instead */
  status: DraftStatus;
};

export type DraftJobVideo = {
  __typename?: 'DraftJobVideo';
  url: Scalars['String']['output'];
};

export type DraftJobVideoInput = {
  url: Scalars['String']['input'];
};

export enum DraftJobWritingMode {
  GUIDED = 'GUIDED',
  STANDARD = 'STANDARD',
}

export type DraftJobsInput = {
  /** The advertiser country */
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  /** The language SEEK site is using */
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  /** The maximum number of results per page. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The number of results to skip where the value must be a multiple of `limit`.
   * `null` defaults to an offset of `0`.
   * Example: With a `limit` of `5` results per page, an `offset` of `0` implies the first page of results, and an `offset` of `15` implies the fourth page of results.
   */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** The draft job search term. */
  term?: InputMaybe<Scalars['String']['input']>;
};

export enum DraftStatus {
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
}

export type DrawdownContractDetails = {
  __typename?: 'DrawdownContractDetails';
  /** This the ID of the contract being used for drawdown. */
  contractId: Scalars['String']['output'];
  /** A UI consumable name of the contract for consistent naming. */
  contractName: Scalars['String']['output'];
  /**
   * The current balance of the contract.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  currentValue?: Maybe<DrawdownContractValue>;
  /**
   * The contract is flexible and not unit based.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  dollarBased?: Maybe<Scalars['Boolean']['output']>;
  /**
   * The expiry date of the contract.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  expiryDate?: Maybe<Scalars['String']['output']>;
  /**
   * The remaining contract balance after paying for the current purchase.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  remainingValue?: Maybe<DrawdownContractValue>;
};

export type DrawdownContractValue = {
  __typename?: 'DrawdownContractValue';
  dollars?: Maybe<Money>;
};

export type DrawdownItem = {
  __typename?: 'DrawdownItem';
  amount: Scalars['SafeInt']['output'];
  drawdownContractDetails: DrawdownContractDetails;
};

export enum DrawdownType {
  BALANCE = 'BALANCE',
  PACK = 'PACK',
  PAYABLE = 'PAYABLE',
}

/** Feature to determine duration of a catalogue product is active. Eg AdBudgetProduct active for 12 months. */
export type DurationFeature = CatalogueProductFeature & {
  __typename?: 'DurationFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  /** Unit of the duration. Eg YEAR, MONTH, DAY */
  unit: DurationUnit;
  /** Size of duration. Eg 60, 12 */
  value: Scalars['Int']['output'];
};

export enum DurationUnit {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  SECOND = 'SECOND',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export type EditJobInput = {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  branding?: InputMaybe<BrandingInput>;
  draftId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
};

export type EditJobResponse = {
  __typename?: 'EditJobResponse';
  revisionId?: Maybe<Scalars['String']['output']>;
};

/** Base error interface. */
export type Error = {
  message: Scalars['String']['output'];
};

export type Expectation = {
  __typename?: 'Expectation';
  /** Expected completed applications for the AdProduct */
  applicationsCompleted?: Maybe<Scalars['Int']['output']>;
  /** Product expectations for the AdProduct */
  content?: Maybe<Array<Maybe<ProductExpectation>>>;
  /** Correlation ID for the expectations */
  correlationId?: Maybe<Scalars['String']['output']>;
};

export type ExpectedSalaryV2 = {
  __typename?: 'ExpectedSalaryV2';
  amount: SalaryAmountV2[];
  currency: Scalars['String']['output'];
};

export type ExpireJobInput = {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** When the expiryTime is set the job will be updated to expire at that time. This is only available during impersonation sessions */
  expiryTime?: InputMaybe<Scalars['DateTime']['input']>;
  jobId: Scalars['String']['input'];
  /** The reason for expiry - defaults to MANUAL */
  reason?: InputMaybe<ExpireReason>;
};

export type ExpireJobResponse = {
  __typename?: 'ExpireJobResponse';
  jobId: Scalars['String']['output'];
};

export enum ExpireReason {
  FRAUDULENT = 'FRAUDULENT',
  MANUAL = 'MANUAL',
  NATURAL = 'NATURAL',
  NONCOMPLIANT = 'NONCOMPLIANT',
}

export type Expiry = {
  __typename?: 'Expiry';
  daysRemaining: Scalars['Int']['output'];
  expiresSoon: Scalars['Boolean']['output'];
  expiryDate: Scalars['String']['output'];
};

export type ExportProfileState =
  | ConnectionPending
  | CvUnavailable
  | DownloadCv
  | ExportProfileWithAtsJob
  | ExportProfileWithoutAtsJob
  | InsufficientCredits
  | ViewProfile;

export type ExportProfileWithAtsJob = {
  __typename?: 'ExportProfileWithAtsJob';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type ExportProfileWithoutAtsJob = {
  __typename?: 'ExportProfileWithoutAtsJob';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type Feature = {
  __typename?: 'Feature';
  /** Unique identifier for the feature */
  key: Scalars['String']['output'];
  /** Reason for the Variant value being returned */
  reason: Scalars['String']['output'];
  /** Variant being returned for the specified Feature Flag, supported values are: Boolean, String, Number, JSON */
  variant: Scalars['JSON']['output'];
};

export enum FiltersAdRatingType {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  NO_RATING = 'NO_RATING',
}

export enum Frequency {
  DAILY = 'DAILY',
  EVERY_APPLICATION = 'EVERY_APPLICATION',
  NEVER = 'NEVER',
  WEEKLY = 'WEEKLY',
}

export type GenerateJobContentInput = {
  categoryName: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  draftId: Scalars['String']['input'];
  jobTitle: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  locationDescription: Scalars['String']['input'];
  priorJobDescription?: InputMaybe<Scalars['String']['input']>;
  subCategoryName: Scalars['String']['input'];
  workType: Scalars['String']['input'];
};

export type GenerateJobContentResponsePayload =
  JobContentGenerationResponseSuccessPayload;

export type GetBrandingAssetsInput = {
  assetType: BrandAssetTypeEnum;
  /** @deprecated Internally resolved via auth */
  owner?: InputMaybe<BrandOwnerInput>;
};

export type GetProductSetInput = {
  /** The locale to get product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID']['input'];
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
};

export type GetProductSetResponse =
  | ProductSet
  | ProductSetExpiredError
  | ProductSetNotFoundError;

export type GetSurchargeAmountInput = {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand?: InputMaybe<CreditCardBrandInput>;
  /** The currency of the payment. */
  paymentCurrency: Currency;
  /** The total amount to be paid for the payment intent. */
  totalForPaymentIntent: Scalars['SafeInt']['input'];
};

export type GetSurchargeAmountPayload = {
  __typename?: 'GetSurchargeAmountPayload';
  /** The surcharge amount for payment method */
  surchargeAmount: Scalars['SafeInt']['output'];
  /** Determine if the surcharge is applicable for payment method */
  surchargeApplicable: Scalars['Boolean']['output'];
};

export type GranularLocation = {
  __typename?: 'GranularLocation';
  nonSpecificLocation?: Maybe<Scalars['String']['output']>;
  specificLocation?: Maybe<Scalars['String']['output']>;
  type: RecommendationType;
};

export enum GuidedDetailsType {
  ABOUT_BUSINESS = 'ABOUT_BUSINESS',
  BENEFITS = 'BENEFITS',
  QUALIFICATIONS = 'QUALIFICATIONS',
  RESPONSIBILITIES = 'RESPONSIBILITIES',
}

export type HeaderFooterContext = {
  __typename?: 'HeaderFooterContext';
  /** Advertiser-specific information. This will be null until account selection is complete. */
  advertiser?: Maybe<AdvertiserHeaderContext>;
  /** Context supplied by Identity Provider (IdP). */
  identityContext?: Maybe<IdentityHeaderContext>;
  /** Profile information about the current user. */
  user?: Maybe<UserHeaderContext>;
};

export type HeaderFooterContextInput = {
  /** Location object. Optional. Supplied by client in order to support customer service view toggling. */
  location?: InputMaybe<WindowLocation>;
};

export type HelpTip = {
  __typename?: 'HelpTip';
  /** Content of a help tip */
  content?: Maybe<Scalars['String']['output']>;
  /** Image to help describe a product */
  image?: Maybe<HelpTipImage>;
};

export type HelpTipImage = {
  __typename?: 'HelpTipImage';
  /** Alternative text used in the UI */
  altText: Scalars['String']['output'];
  /** Image path where staging/prod url path is prepended */
  url: Scalars['String']['output'];
};

export type HiddenPrice = {
  __typename?: 'HiddenPrice';
  /** price access level (priceVisibility) if hidePrice is enabled for the hirer */
  priceVisibility: PriceVisibility;
};

export type Highlight = {
  __typename?: 'Highlight';
  content: Scalars['String']['output'];
};

export type HirerAccount = {
  __typename?: 'HirerAccount';
  /** Hirer account activation status */
  activationStatus: AccountActivationStatus;
  /** Seek billing account ID used by CRM */
  billingAccountId?: Maybe<Scalars['String']['output']>;
  /** The billing address of the account */
  billingAddress: PhysicalAddress;
  /** The billing contact of the account */
  billingContact: BillingContact;
  billingStatus?: Maybe<BillingStatus>;
  /** Country-specific identifier like ABN, NZBN */
  businessIdentifier?: Maybe<Scalars['String']['output']>;
  /** Business numbers and identifiers such as ABN, BRN, TIN, etc. */
  businessIdentifiers?: Maybe<BusinessIdentifiers>;
  /** Business name that gets collected on the registration form */
  businessName: Scalars['String']['output'];
  contractsSummary?: Maybe<HirerContractsSummary>;
  /** Indicates if the account is managed by an agency */
  hasAgent?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the LinkOut feature is enabled for the hirer. */
  hasLinkOutEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Show or hide all pricing from hirer */
  hidePrice?: Maybe<Scalars['Boolean']['output']>;
  hirerGroup?: Maybe<HirerGroup>;
  /** Indicates which type hirer belongs to */
  hirerType?: Maybe<HirerType>;
  id: Scalars['String']['output'];
  /** Partner integrations details */
  integrations: Integration[];
  /** Indicates whether the advertiser is yet to be approved in CRM. */
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the account is archived and in deactivated status. */
  isArchived: Scalars['Boolean']['output'];
  /** Indicates whether the Mirroring/Hyperlinks permission is enabled in CRM. */
  isEsmAccount?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the advertiser is managed by a Sales Consultant. */
  isManagedAccount?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the advertiser would prefer to hide their company name in their job ads. Null if the hirer has not set their preference yet. */
  isPrivateAdvertiser?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the account is approved for Talent Search access. New accounts have to wait 24 hours from when the account is approved before access to Talent Search access is granted. */
  isTalentSearchApproved?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the advertiser is marked as a test account in CRM. */
  isTestAccount?: Maybe<Scalars['Boolean']['output']>;
  /** The details of the agency that manages the account if there is one */
  managingAgency?: Maybe<ManagingAgency>;
  /** A country code that indicates which SEEK legal entity the business transacts with and the experience the user will be shown. */
  managingCountry?: Maybe<Scalars['String']['output']>;
  nation?: Maybe<Scalars['String']['output']>;
  /** Indicates the zone the account was originally created in. */
  originZone?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Parent>;
  /** The postal address of the account */
  postalAddress: PhysicalAddress;
  /** To show or hide the different price states */
  priceVisibility?: Maybe<PriceVisibility>;
  /** Indicates if a hirer has a pricing group contract available */
  pricingGroupContractExists?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates which pricing model hirer belongs to, either Fixed or Horizon */
  pricingModel?: Maybe<PricingModel>;
  /** The primary contact of the account */
  primaryContact: PrimaryContact;
  /** @deprecated This field is no longer neccessary due to FAA */
  rules?: Maybe<Array<Maybe<Rule>>>;
  /**
   * Hirer (Advertiser) account status
   * @deprecated Use activationStatus instead
   */
  status: HirerAccountStatus;
  /** The list of users associated with the account */
  users: User[];
};

export type HirerAccountDetails = {
  __typename?: 'HirerAccountDetails';
  /** Seek billing account ID used by CRM */
  billingAccountId?: Maybe<Scalars['String']['output']>;
  /** The billing address of the account */
  billingAddress: PhysicalAddress;
  /** The billing contact of the account */
  billingContact: BillingContact;
  /** Country-specific identifier like ABN, NZBN */
  businessIdentifier?: Maybe<Scalars['String']['output']>;
  /** Business name that gets collected on the registration form */
  businessName?: Maybe<Scalars['String']['output']>;
  /** The details of the agency that manages the account if there is one */
  managingAgency: ManagingAgency;
  /** The postal address of the account */
  postalAddress: PhysicalAddress;
  /** The primary contact of the account */
  primaryContact: PrimaryContact;
  /** Hirer (Advertiser) account status */
  status: HirerAccountStatus;
};

export type HirerAccountEdge = {
  __typename?: 'HirerAccountEdge';
  membershipStatus: Scalars['String']['output'];
  node: HirerAccount;
  role: Scalars['String']['output'];
};

/** A hirer account to display in the account selection UI. (AccountSelectionV3Response) */
export type HirerAccountOption = {
  __typename?: 'HirerAccountOption';
  /** Status of access right connecting the user to the advertiser. */
  accessRightStatus: AccessRightStatus;
  /** Optional billing ID provided by CRM. */
  billingId?: Maybe<Scalars['String']['output']>;
  /** Child accounts, applicable to agencies. Will be visually nested during account selection. */
  childAccounts: HirerAccountOption[];
  /** SeekWeb Advertiser ID. */
  hirerAccountId: Scalars['ID']['output'];
  /** The Hirer Account Status */
  hirerAccountStatus: AdvertiserStatus;
  /** Name of the advertiser account. */
  name: Scalars['String']['output'];
  /** The URL where the user will be redirected to after selecting the account. */
  redirectUrl: Scalars['String']['output'];
  /** The role of the user on the account - Either "USER" (standard) or "ADMINISTRATOR" */
  role: Role;
};

/** A hirer account to display in the account selection UI. (AccountSelectionV3Response) */
export type HirerAccountOptionredirectUrlArgs = {
  input?: InputMaybe<RedirectUrlInput>;
};

export enum HirerAccountStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
}

export type HirerAccountUserQueryInput = {
  /** Request to get user permissions to account. Can be "ACCESS_DETAILS" */
  include?: InputMaybe<UsersParamsInclude>;
  /** User ID of the user we want to find */
  userId: Scalars['String']['input'];
};

export type HirerAccountsResult = {
  __typename?: 'HirerAccountsResult';
  edges: HirerAccountEdge[];
  totalCount: Scalars['Int']['output'];
};

export type HirerActivitiesByJobInput = {
  /**
   * The SEEK ID of the advertiser
   * @deprecated Prefer advertiser ID claim from bearer token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the job */
  jobId: Scalars['String']['input'];
};

export type HirerActivity = {
  __typename?: 'HirerActivity';
  /** A list of extra data related the activity */
  activityData: HirerActivityData[];
  /** The time when the activity occurred */
  activityTime: Scalars['DateTime']['output'];
  /** The type of the activity */
  activityType: HirerActivityType;
  id: Scalars['ID']['output'];
  /** Partial list of the  job applications related to the activity, max 3 job applications. */
  partialJobApplicationList: HirerActivityJobApplication[];
  /** First name of the account user who triggers the activity. */
  userFirstName: Scalars['String']['output'];
  /** Last name of the account user who triggers the activity. */
  userLastName: Scalars['String']['output'];
};

export type HirerActivityConnection = {
  __typename?: 'HirerActivityConnection';
  edges: HirerActivityEdge[];
  pageInfo: PageInfo;
};

export type HirerActivityData = {
  __typename?: 'HirerActivityData';
  /** The name of data. eg: noteContent */
  name: Scalars['String']['output'];
  /** The value of data. eg: content of the note */
  value?: Maybe<Scalars['String']['output']>;
};

export type HirerActivityEdge = {
  __typename?: 'HirerActivityEdge';
  /** String based cursor for the edge node */
  cursor: Scalars['String']['output'];
  /** Node representing hirer activity */
  node: HirerActivity;
};

export type HirerActivityJobApplication = {
  __typename?: 'HirerActivityJobApplication';
  /** Prospect id of job application in Adcentre */
  adcentreProspectId: Scalars['Int']['output'];
  /** First name of the candidate. */
  candidateFirstName: Scalars['String']['output'];
  /** Last name of the candidate. */
  candidateLastName: Scalars['String']['output'];
};

export enum HirerActivityType {
  NOTE_ADDED = 'NOTE_ADDED',
  NOTE_DELETED = 'NOTE_DELETED',
}

export type HirerContractsSummary = {
  __typename?: 'HirerContractsSummary';
  /** Whether the hirer has active budget contracts (excludes pricing groups) */
  hasAnyActiveBudgetContracts: Scalars['Boolean']['output'];
  /** Whether the hirer has active pricing group contracts (excludes budgets) */
  hasAnyActivePricingGroupContracts: Scalars['Boolean']['output'];
};

export type HirerDashboardArticle = {
  __typename?: 'HirerDashboardArticle';
  /** Title of the image */
  imageTitle: Scalars['String']['output'];
  /** Image URL */
  imageUrl: Scalars['String']['output'];
  /** Minutes to read article */
  timeToRead: Scalars['Int']['output'];
  /** Title of the article */
  title: Scalars['String']['output'];
  /** Article URL */
  url: Scalars['String']['output'];
};

export type HirerDashboardBudgetUpsellInput = {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  dataSource?: InputMaybe<Scalars['String']['input']>;
};

export type HirerDashboardBudgetUpsellResponse = {
  __typename?: 'HirerDashboardBudgetUpsellResponse';
  /** Max contract discount rate used for display on the hirer dashboard */
  maxContractDiscountRate?: Maybe<Scalars['Float']['output']>;
  /** Set to true when a hirer is eligible for ad budget upsell on the hirer dashboard */
  upsellEligible: Scalars['Boolean']['output'];
};

export type HirerDashboardHiringAdviceList = {
  __typename?: 'HirerDashboardHiringAdviceList';
  /** Articles to display on the dashboard */
  articles?: Maybe<Array<Maybe<HirerDashboardArticle>>>;
  count: Scalars['Int']['output'];
};

export type HirerDashboardTalentSearch = {
  __typename?: 'HirerDashboardTalentSearch';
  premiumTalentSearchHref?: Maybe<Scalars['String']['output']>;
};

export type HirerExperience = {
  __typename?: 'HirerExperience';
  /**
   * The hirer control type of this question - one of 'Default', 'Range', 'RangeUpTo', 'RangeAtLeast', 'AdvancedAvailability',
   * this will be null when the question source is custom
   */
  controlType?: Maybe<Scalars['String']['output']>;
  /** Null if the question is not mandatory, defined if the question is mandataory */
  mandatory?: Maybe<MandatoryData>;
  /** Describes if simplified answers were selected - either "simplifiedAnswers" or "answers" */
  selectionType: Scalars['String']['output'];
  /** The simplified answers for the question - present only if selectionType is "simplifiedAnswers" */
  simplifiedAnswers?: Maybe<SimplifiedAnswers[]>;
};

export enum HirerGroup {
  ADVERTISER = 'ADVERTISER',
  AGENT = 'AGENT',
  RECRUITMENT_SOFTWARE_PROVIDER = 'RECRUITMENT_SOFTWARE_PROVIDER',
}

export type HirerJobAccess = {
  __typename?: 'HirerJobAccess';
  correctHirerAccountId: Scalars['ID']['output'];
};

export type HirerNotificationCategory = {
  __typename?: 'HirerNotificationCategory';
  description: Scalars['String']['output'];
  id: HirerNotificationCategoryType;
  label: Scalars['String']['output'];
};

export enum HirerNotificationCategoryType {
  HIRER_ADVICE_INSIGHTS = 'HIRER_ADVICE_INSIGHTS',
  HIRER_JOB_AD_POSTING = 'HIRER_JOB_AD_POSTING',
  HIRER_MOBILE = 'HIRER_MOBILE',
  HIRER_PARTNER_UPDATES = 'HIRER_PARTNER_UPDATES',
  HIRER_PRODUCT_SOLUTIONS = 'HIRER_PRODUCT_SOLUTIONS',
  HIRER_SURVEY_COMPETITIONS = 'HIRER_SURVEY_COMPETITIONS',
}

export type HirerNotificationChannel = {
  __typename?: 'HirerNotificationChannel';
  id: HirerNotificationChannelType;
  label: Scalars['String']['output'];
};

export enum HirerNotificationChannelType {
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
}

export type HirerNotificationPreference = {
  __typename?: 'HirerNotificationPreference';
  product: HirerNotificationProduct;
  subscriptions: HirerNotificationSubscription[];
};

export type HirerNotificationPreferenceCategory = {
  __typename?: 'HirerNotificationPreferenceCategory';
  category: HirerNotificationCategory;
  preferences: HirerNotificationPreference[];
};

export enum HirerNotificationPreferenceUpdateReason {
  PREFERENCE_UI = 'PREFERENCE_UI',
}

export type HirerNotificationProduct = {
  __typename?: 'HirerNotificationProduct';
  action?: Maybe<HirerNotificationProductAction>;
  description: Scalars['String']['output'];
  id: HirerNotificationProductType;
  label: Scalars['String']['output'];
};

export type HirerNotificationProductAction = {
  __typename?: 'HirerNotificationProductAction';
  label: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type HirerNotificationProductInput = {
  productId: HirerNotificationProductType;
  subscriptions: HirerNotificationSubscriptionInput[];
};

export enum HirerNotificationProductType {
  MARKETING_ADVICE_INSIGHTS = 'MARKETING_ADVICE_INSIGHTS',
  MARKETING_JOB_AD_POSTING = 'MARKETING_JOB_AD_POSTING',
  MARKETING_MOBILE = 'MARKETING_MOBILE',
  MARKETING_PARTNER_UPDATES = 'MARKETING_PARTNER_UPDATES',
  MARKETING_PRODUCT_UPDATES = 'MARKETING_PRODUCT_UPDATES',
  MARKETING_SURVEY_RESEARCH = 'MARKETING_SURVEY_RESEARCH',
}

export type HirerNotificationSubscription = {
  __typename?: 'HirerNotificationSubscription';
  channel: HirerNotificationChannel;
  id: Scalars['String']['output'];
  subscribed: Scalars['Boolean']['output'];
};

export type HirerNotificationSubscriptionInput = {
  channelId: HirerNotificationChannelType;
  subscribed: Scalars['Boolean']['input'];
};

export type HirerNotificationUpdatePreferenceInput = {
  locale: Scalars['String']['input'];
  reason: HirerNotificationPreferenceUpdateReason;
  updates: HirerNotificationProductInput[];
  zone: Scalars['String']['input'];
};

export enum HirerQuestionType {
  FreeText = 'FreeText',
  SingleSelect = 'SingleSelect',
}

export enum HirerType {
  BPO = 'BPO',
  CORPORATE = 'CORPORATE',
  GOVERNMENT = 'GOVERNMENT',
  JORA_LOCAL = 'JORA_LOCAL',
  RECRUITER = 'RECRUITER',
  SME = 'SME',
  SUBSCRIPTION_PARTNER = 'SUBSCRIPTION_PARTNER',
}

export type HirerUserDetails = {
  __typename?: 'HirerUserDetails';
  /** Email address of the hirer user */
  email: Scalars['String']['output'];
  /** Family name of the hirer user (formerly known as "Last Name" in ANZ systems) */
  familyName: Scalars['String']['output'];
  /** Full name of the hirer user, ie "givenName familyName". */
  fullName: Scalars['String']['output'];
  /** Given name of the hirer user (formerly known as "First Name" in ANZ Systems) */
  givenName: Scalars['String']['output'];
  /** The id of the hirer user (formerly known as "userId") */
  hirerUserId: Scalars['String']['output'];
  /** Permissions of the hirer user on the hirer account */
  permissions: UserPermissions;
  /** Hirer User status */
  status: HirerUserStatus;
};

export type HirerUserEmailFoundPayloadV2 = {
  __typename?: 'HirerUserEmailFoundPayloadV2';
  /** Email address of the hirer user */
  email: Scalars['String']['output'];
  /** ID used to uniquely identify the hirer user */
  hirerUserId: Scalars['String']['output'];
  /** Hirer User status */
  status: HirerUserStatus;
};

export type HirerUserEmailInputV2 = {
  /** Email address of the hirer user */
  email: Scalars['String']['input'];
};

export type HirerUserEmailNotFoundPayloadV2 = {
  __typename?: 'HirerUserEmailNotFoundPayloadV2';
  /**
   * message to return when we can't find the email in the Hirer Users system.
   * This is not a failure response as it is entirely possible to search for an
   * email that does not exist
   */
  message: Scalars['String']['output'];
};

export type HirerUserEmailPayloadV2 =
  | HirerUserEmailFoundPayloadV2
  | HirerUserEmailNotFoundPayloadV2
  | ResponseError;

export type HirerUserQueryInput = {
  /** ID used to uniquely identify the hirer user */
  hirerUserId: Scalars['String']['input'];
};

export enum HirerUserStatus {
  /** The User is verified and not archived (DEACTIVATED). */
  ACTIVE = 'ACTIVE',
  /** The User has been archived (DEACTIVATED). */
  DEACTIVATED = 'DEACTIVATED',
  /** The User is not verified and was created by a team member (add to account). */
  PENDING = 'PENDING',
  /** The User has requested their data to be removed. */
  REDACTED = 'REDACTED',
  /** The User is not verified and was created by first time account registration. */
  UNVERIFIED = 'UNVERIFIED',
}

export type Identity = {
  __typename?: 'Identity';
  /** Operator making requests on behalf of user. */
  actor?: Maybe<Actor>;
  /**
   * Indicates that the user is CS operator, regardless of whether they're actively impersonating.
   * Either true or undefined.
   */
  allowImpersonation?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates that the CS operator has enabled administrator mode. Either true or undefined. */
  showCustomerServiceView?: Maybe<Scalars['Boolean']['output']>;
  /** User requesting access to a resource. */
  subject: Subject;
};

export type IdentityHeaderContext = {
  __typename?: 'IdentityHeaderContext';
  /** Details of the cs user making requests on behalf of user. */
  act?: Maybe<ActorContext>;
  /** Email address of the user. */
  email: Scalars['String']['output'];
};

export type IdentityHeaderContextactArgs = {
  input?: InputMaybe<HeaderFooterContextInput>;
};

export type Image = {
  __typename?: 'Image';
  /** Description of an image */
  description: Scalars['String']['output'];
  /** Image path where staging/prod url path is prepended */
  url: Scalars['String']['output'];
};

export type Inclusion = {
  __typename?: 'Inclusion';
  content: Scalars['String']['output'];
  helpTip?: Maybe<InclusionHelpTip>;
  icon?: Maybe<InclusionIcon>;
  styledContents?: Maybe<DisplayStyledContent[]>;
  type?: Maybe<Scalars['String']['output']>;
};

export type InclusionHelpTip = {
  __typename?: 'InclusionHelpTip';
  content: Scalars['String']['output'];
  contents?: Maybe<InclusionHelpTipContent[]>;
  image?: Maybe<InclusionHelpTipImage>;
  title?: Maybe<Scalars['String']['output']>;
};

export type InclusionHelpTipContent = {
  __typename?: 'InclusionHelpTipContent';
  value: Scalars['String']['output'];
};

export type InclusionHelpTipImage = {
  __typename?: 'InclusionHelpTipImage';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type InclusionIcon = {
  __typename?: 'InclusionIcon';
  id?: Maybe<Scalars['String']['output']>;
  tone?: Maybe<Scalars['String']['output']>;
};

export type InitiateAccessCvV2Input = {
  advertiserEmail: Scalars['String']['input'];
  jobContext?: InputMaybe<JobContext>;
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
};

export type InitiateAccessProfileAndCvV2Input = {
  advertiserEmail: Scalars['String']['input'];
  jobContext?: InputMaybe<JobContext>;
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
};

export type InitiateAccessProfileV2Input = {
  advertiserEmail: Scalars['String']['input'];
  jobContext?: InputMaybe<JobContext>;
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
};

export type InitiateConnectionPayload = {
  __typename?: 'InitiateConnectionPayload';
  connectionId: Scalars['String']['output'];
};

export type InitiateConnectionResponse =
  | InitiateConnectionPayload
  | ResponseError
  | TalentSearchConnectionResponseError;

export type InitiateExportProfileV2Input = {
  jobContext?: InputMaybe<JobContext>;
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
};

export type InitiateExportProfileWithAtsJobV2Input = {
  externalReferenceId: Scalars['String']['input'];
  jobContext?: InputMaybe<JobContext>;
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
};

export type InitiateSendJobV2Input = {
  jobContext?: InputMaybe<JobContext>;
  jobId: Scalars['ID']['input'];
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
};

export type InitiateSendMessageV2Input = {
  advertiserEmail: Scalars['String']['input'];
  advertiserFirstName: Scalars['String']['input'];
  advertiserLastName: Scalars['String']['input'];
  advertiserPhone?: InputMaybe<Scalars['String']['input']>;
  advertiserTitle: Scalars['String']['input'];
  body: Scalars['String']['input'];
  /** @deprecated No longer required */
  isAtsHirer?: InputMaybe<Scalars['Boolean']['input']>;
  jobContext?: InputMaybe<JobContext>;
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
};

export type InsufficientCredits = {
  __typename?: 'InsufficientCredits';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type Integration = {
  __typename?: 'Integration';
  partner: IntegrationPartner;
  relationships: IntegrationRelationship[];
};

export type IntegrationConnection = {
  __typename?: 'IntegrationConnection';
  edges: IntegrationEdge[];
  totalCount: Scalars['Int']['output'];
};

export type IntegrationEdge = {
  __typename?: 'IntegrationEdge';
  node: Integration;
};

export type IntegrationPartner = {
  __typename?: 'IntegrationPartner';
  adPerformancePanel: Scalars['Boolean']['output'];
  configurableRelationships: IntegrationRelationshipType[];
  description?: Maybe<Scalars['String']['output']>;
  hasApacIntegration?: Maybe<Scalars['Boolean']['output']>;
  hasMiddlewareRequirement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  jobAdPreview: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  newPartner: Scalars['Boolean']['output'];
  partnerBlurbEN?: Maybe<Scalars['String']['output']>;
  partnerBlurbID?: Maybe<Scalars['String']['output']>;
  partnerBlurbTH?: Maybe<Scalars['String']['output']>;
  releaseNotes?: Maybe<Scalars['String']['output']>;
  releaseNotesImageUrl?: Maybe<Scalars['String']['output']>;
  releaseNotesStatus?: Maybe<Scalars['String']['output']>;
  screeningQuestions: Scalars['Boolean']['output'];
  siteUrl?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  tier: IntegrationPartnerTier;
  type: Scalars['String']['output'];
};

export enum IntegrationPartnerTier {
  CONNECTED_MIDDLEWARE = 'CONNECTED_MIDDLEWARE',
  CONNECTED_PARTNERS = 'CONNECTED_PARTNERS',
  GOLD_PARTNERS = 'GOLD_PARTNERS',
  KEY_MIDDLEWARE_PARTNERS = 'KEY_MIDDLEWARE_PARTNERS',
  PLATINUM_PARTNERS = 'PLATINUM_PARTNERS',
  SILVER_PARTNERS = 'SILVER_PARTNERS',
}

export type IntegrationRelationship = {
  __typename?: 'IntegrationRelationship';
  id: Scalars['ID']['output'];
  relationshipType: IntegrationRelationshipType;
};

export type IntegrationRelationshipType = {
  __typename?: 'IntegrationRelationshipType';
  name: Scalars['String']['output'];
  typeCode: IntegrationRelationshipTypeEnum;
};

export enum IntegrationRelationshipTypeEnum {
  APPLICATION_EXPORT = 'APPLICATION_EXPORT',
  APPLICATION_PREFILL = 'APPLICATION_PREFILL',
  JOB_POSTING = 'JOB_POSTING',
  PROACTIVE_SOURCING = 'PROACTIVE_SOURCING',
}

export type InteractedJob = {
  __typename?: 'InteractedJob';
  location?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type InteractedJoblocationArgs = {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type InvalidInputPayload = {
  __typename?: 'InvalidInputPayload';
  /** Reason of invalid used input. i.e. 'Validation error: logo is required to create a brand' */
  reason?: Maybe<Scalars['String']['output']>;
};

export type Invoice = {
  /** The ID of the invoice. */
  id: Scalars['ID']['input'];
  /** The status of the invoice. */
  invoiceStatus: InvoiceStatus;
};

export type InvoiceData = {
  __typename?: 'InvoiceData';
  invoices?: Maybe<Array<Maybe<InvoiceItem>>>;
  loadMoreCursor?: Maybe<Scalars['String']['output']>;
};

/** Error for invalid, expired, or not found invoices. */
export type InvoiceError = Error & {
  __typename?: 'InvoiceError';
  message: Scalars['String']['output'];
};

export type InvoiceFiltersInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  invoiceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvoiceStatus>;
};

export type InvoiceInput = {
  amount: Scalars['SafeInt']['input'];
  reference: Scalars['String']['input'];
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount: Scalars['Float']['output'];
  amountPayable?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
  documentDate: Scalars['String']['output'];
  documentStatus: InvoiceStatus;
  documentType: Scalars['String']['output'];
  dueDate: Scalars['String']['output'];
  invoiceId: Scalars['String']['output'];
  invoiceStatus: Scalars['String']['output'];
  migrated: Scalars['Boolean']['output'];
  netAmount: Scalars['Float']['output'];
  netPayable?: Maybe<Scalars['Float']['output']>;
  paidAmount?: Maybe<Scalars['Float']['output']>;
  payments?: Maybe<Array<Maybe<InvoicePayment>>>;
  taxAmount: Scalars['Float']['output'];
  taxPayable?: Maybe<Scalars['Float']['output']>;
  taxType: Scalars['String']['output'];
};

export type InvoiceOrderNotFoundPayload = {
  __typename?: 'InvoiceOrderNotFoundPayload';
  /** Requested OrderId could not be found. */
  reason?: Maybe<Scalars['String']['output']>;
};

export type InvoiceOrderSuccessPayload = {
  __typename?: 'InvoiceOrderSuccessPayload';
  responseStatus: Scalars['String']['output'];
};

export type InvoicePayment = {
  __typename?: 'InvoicePayment';
  ccLast4Digit: Scalars['String']['output'];
  chequeNo: Scalars['String']['output'];
  eftReference: Scalars['String']['output'];
  paymentAmount: Scalars['Float']['output'];
  paymentCardType: Scalars['String']['output'];
  paymentDate: Scalars['String']['output'];
  paymentDoc?: Maybe<Scalars['String']['output']>;
  paymentMethod: Scalars['String']['output'];
  paymentReference: Scalars['String']['output'];
};

export enum InvoiceStatus {
  /** The invoice is due. */
  due = 'due',
  /** The invoice is paid. */
  paid = 'paid',
  /** The invoice is pending. */
  pending = 'pending',
}

export type Job = {
  __typename?: 'Job';
  /** @deprecated Use `productDisplayName` instead and if empty fallback to `adType` */
  adType?: Maybe<JobAdType>;
  /** A reference provided by the advertiser to facilitate searching in job lists. */
  advertiserJobReference?: Maybe<Scalars['String']['output']>;
  applicationFormUrl?: Maybe<Scalars['String']['output']>;
  billableApplicants: BillableApplicantsResponse;
  branding?: Maybe<Brand>;
  canBeRefreshed: Scalars['Boolean']['output'];
  canBeUpgraded: Scalars['Boolean']['output'];
  canShowHomeLocationFilterInCandidateManagement: Scalars['Boolean']['output'];
  canShowNationalityFilterInCandidateManagement: Scalars['Boolean']['output'];
  candidatesCount?: Maybe<Scalars['Int']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  categoryName?: Maybe<Scalars['String']['output']>;
  /**
   * Date/time in UTC (ISO 8601 format) the job was created.
   * The job is only accessible to candidates once it has a `listingDate`.
   */
  createdDate: Scalars['String']['output'];
  /** Number of days remaining until the job gets expired, if the job end date is in the future. */
  daysRemaining?: Maybe<Scalars['Int']['output']>;
  /**
   * Expiry date/time in UTC (ISO 8601 format).
   * This may be null if the job is not live yet.
   */
  expiryDate?: Maybe<Scalars['String']['output']>;
  /**
   * The job ID.
   * This should contain the same values as `jobId` and will eventually replace the `jobId` field.
   */
  id: Scalars['String']['output'];
  /** Indicates whether this is a private ad */
  isConfidential?: Maybe<Scalars['Boolean']['output']>;
  jobDescription?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `id` instead */
  jobId: Scalars['String']['output'];
  jobSummary?: Maybe<Scalars['String']['output']>;
  keySellingPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * Date/time in UTC (ISO 8601 format) the job was searchable and accessible to candidates.
   * This may be later than `createdDate` as a hirer may create the job but complete the payment at a later date.
   */
  listingDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated listingId is deprecated and use `id` instead */
  listingId: ListingIdResponse;
  locationId?: Maybe<Scalars['String']['output']>;
  /**
   * Location details resolved from the `locationId`
   * While the schema allows multiple locations, only one will be returned for AU/NZ.
   */
  locations: Location[];
  maxSalary?: Maybe<Scalars['SafeInt']['output']>;
  minSalary?: Maybe<Scalars['SafeInt']['output']>;
  newCandidatesCount?: Maybe<Scalars['Int']['output']>;
  /** Details of the user who created the job. */
  owner?: Maybe<HirerUserDetails>;
  payPerPlacementEligibility: PayPerPlacementEligibilityResponse;
  payType?: Maybe<Scalars['String']['output']>;
  performanceRating?: Maybe<AnalyticsAdRating>;
  /** Hirer's phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  placement: JobPlacementResponse;
  placementV2: JobPlacementV2Response;
  /** @deprecated positionId is deprecated and use `id` instead */
  positionId: PositionIdResponse;
  /**
   * The channel the job was posted from. This does not refer to which underlying system used to post the job.
   * @deprecated Use `postType`
   */
  postChannel?: Maybe<PostChannel>;
  /** The postType from hirer jobs api, ie Direct or Indirect. */
  postType: PostType;
  productAddOnCodes?: Maybe<Array<Scalars['String']['output']>>;
  productCode?: Maybe<Scalars['String']['output']>;
  productDisplayName?: Maybe<Scalars['String']['output']>;
  /** Product features belonging to the job */
  productFeatures?: Maybe<CatalogueProductFeature[]>;
  questionnaireUrl?: Maybe<Scalars['String']['output']>;
  referenceCheckBalance: JobReferenceCheckBalanceResponse;
  salaryCurrency?: Maybe<JobSalaryCurrency>;
  salaryText?: Maybe<Scalars['String']['output']>;
  status: JobStatus;
  subCategoryId?: Maybe<Scalars['String']['output']>;
  subCategoryName?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
  workArrangements?: Maybe<WorkArrangement[]>;
  workType?: Maybe<WorkType>;
};

export type JobAd = {
  __typename?: 'JobAd';
  adRating?: Maybe<AdRatingType>;
  addOns: Array<Maybe<Scalars['String']['output']>>;
  applications: Scalars['Int']['output'];
  groupKeyApac: Scalars['String']['output'];
  hidePrice: Scalars['Boolean']['output'];
  isLatestGroupApac: Scalars['Boolean']['output'];
  isRefresh: Scalars['Boolean']['output'];
  isRepost: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  jobTitle: Scalars['String']['output'];
  locationLabel: Scalars['String']['output'];
  otherBudgetAmount: Money;
  productDisplayName: Scalars['String']['output'];
  status: AdUsageAdStatus;
  totalNetAmount: Money;
  userFullName?: Maybe<Scalars['String']['output']>;
};

export enum JobAdBookingCountryCode {
  AU = 'AU',
  HK = 'HK',
  ID = 'ID',
  MY = 'MY',
  NZ = 'NZ',
  PH = 'PH',
  SG = 'SG',
  TH = 'TH',
}

export type JobAdDetails = {
  locationId: Scalars['String']['input'];
  roleTitle: Scalars['String']['input'];
  subClassificationId: Scalars['String']['input'];
};

/** AdProductUpgrade feature that indicates the ability for editing the details of an existing ad. */
export type JobAdEditFeature = CatalogueProductFeature & {
  __typename?: 'JobAdEditFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type JobAdListResponse = {
  __typename?: 'JobAdListResponse';
  count: Scalars['Int']['output'];
  hasMultipleCurrencies: Scalars['Boolean']['output'];
  jobAds?: Maybe<Array<Maybe<JobAd>>>;
  lastUpdated: Scalars['DateTime']['output'];
};

export type JobAdListWidgetInput = {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
  pageNumber: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
};

export type JobAdRevisionAttributes = {
  /** Id of the existing Job Ad. */
  jobId: Scalars['String']['input'];
  /** Revision id of the existing Job Ad. */
  revisionId: Scalars['String']['input'];
};

export enum JobAdType {
  BRANDED = 'BRANDED',
  BRANDED_UPGRADE = 'BRANDED_UPGRADE',
  CLASSIC = 'CLASSIC',
  CONCIERGE = 'CONCIERGE',
  GUARANTEED = 'GUARANTEED',
  PREMIUM = 'PREMIUM',
}

export type JobConnection = {
  __typename?: 'JobConnection';
  /** The list of `HirerJob` edges for the current page. */
  edges: JobEdge[];
  /**
   * The total number of jobs across all pages.
   * The UI can use the `totalCount` and input `limit` to compute the total page count.
   */
  totalCount: Scalars['Int']['output'];
};

export type JobContentGenerationResponseSuccessPayload = {
  __typename?: 'JobContentGenerationResponseSuccessPayload';
  id: Scalars['String']['output'];
  jobDescription: Scalars['String']['output'];
  jobSummary: Scalars['String']['output'];
};

export type JobContext = {
  jobId: Scalars['ID']['input'];
};

/**
 * Providing `null` for a field will reset it.
 * This doesn't apply to fields that are required by Job Services such as `categories`, `locations`, and `title`. They will always maintain their original value unless a new value is provided.
 */
export type JobDetailsInput = {
  /** A reference provided by the advertiser to facilitate searching in draft job lists. */
  advertiserJobReference?: InputMaybe<Scalars['String']['input']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: InputMaybe<Scalars['String']['input']>;
  /** Updated branding info */
  branding?: InputMaybe<BrandingInput>;
  /** Updated category and subcategory */
  categories?: InputMaybe<CategoriesInput>;
  /** Indicates whether this is a private ad */
  isConfidential?: InputMaybe<Scalars['Boolean']['input']>;
  /** Updated job description */
  jobDescription?: InputMaybe<Scalars['String']['input']>;
  /** Updated key selling points */
  keySellingPoints?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Updated job location(s) */
  locations?: InputMaybe<LocationInput[]>;
  /** Updated salary information (incl. currency and range) */
  salary?: InputMaybe<SalaryInput>;
  /** Updated job summary */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** Updated job title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Updated video url */
  videoUrl?: InputMaybe<Scalars['String']['input']>;
  /** Updated work arrangements AKA workplace options */
  workArrangements?: InputMaybe<WorkArrangement[]>;
  /** Updated work types */
  workTypes?: InputMaybe<WorkType[]>;
};

export type JobEdge = {
  __typename?: 'JobEdge';
  /** A `JobNode` from the pagination query. */
  node: JobNode;
};

export type JobInput = {
  /** The advertiser country */
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  /** The job ID. */
  jobId: Scalars['String']['input'];
  /** The language SEEK site is using */
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  /**
   * The AdCentre position ID
   * @deprecated Use `jobId` instead
   */
  positionId?: InputMaybe<Scalars['ID']['input']>;
};

export type JobNode = DraftJob | Job;

export type JobPlacement = {
  __typename?: 'JobPlacement';
  shouldShowPlacementDialog: Scalars['Boolean']['output'];
};

export enum JobPlacementPostType {
  AdPostingApi = 'AdPostingApi',
  Direct = 'Direct',
}

export type JobPlacementResponse = JobPlacementResponseSuccess | ResponseError;

export type JobPlacementResponseSuccess = {
  __typename?: 'JobPlacementResponseSuccess';
  result: JobPlacement;
};

export enum JobPlacementStatus {
  FILLED = 'FILLED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_FILLED = 'NOT_FILLED',
}

export type JobPlacementV2 = {
  __typename?: 'JobPlacementV2';
  canShowPlacementPrompt: Scalars['Boolean']['output'];
  status?: Maybe<JobPlacementStatus>;
};

export type JobPlacementV2Response =
  | JobPlacementV2ResponseSuccess
  | ResponseError;

export type JobPlacementV2ResponseSuccess = {
  __typename?: 'JobPlacementV2ResponseSuccess';
  result: JobPlacementV2;
};

export type JobReference = {
  __typename?: 'JobReference';
  id: Scalars['String']['output'];
  revision: Scalars['ID']['output'];
};

export type JobReferenceCheckBalance = {
  __typename?: 'JobReferenceCheckBalance';
  availableBalance: Scalars['Int']['output'];
  totalBalance: Scalars['Int']['output'];
};

export type JobReferenceCheckBalanceResponse =
  | JobReferenceCheckBalanceResponseSuccess
  | ResponseError;

export type JobReferenceCheckBalanceResponseSuccess = {
  __typename?: 'JobReferenceCheckBalanceResponseSuccess';
  result?: Maybe<JobReferenceCheckResult>;
};

export type JobReferenceCheckExpired = {
  __typename?: 'JobReferenceCheckExpired';
  _empty?: Maybe<Scalars['String']['output']>;
};

export type JobReferenceCheckResult =
  | JobReferenceCheckBalance
  | JobReferenceCheckExpired;

export type JobResponse =
  | CorrectUserIncorrectHirerResponseSuccess
  | JobResponseSuccess
  | ResponseError;

export type JobResponseSuccess = {
  __typename?: 'JobResponseSuccess';
  result: Job;
};

export enum JobSalaryCurrency {
  AUD = 'AUD',
  BDT = 'BDT',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  IDR = 'IDR',
  INR = 'INR',
  JPY = 'JPY',
  MYR = 'MYR',
  NZD = 'NZD',
  PHP = 'PHP',
  SGD = 'SGD',
  THB = 'THB',
  USD = 'USD',
  VND = 'VND',
}

export type JobSent = {
  __typename?: 'JobSent';
  sentDate: Scalars['String']['output'];
};

export enum JobStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  PENDING_ACCOUNT_APPROVAL = 'PENDING_ACCOUNT_APPROVAL',
  SUSPENDED = 'SUSPENDED',
}

export enum JobStatusFilter {
  /** The job has been posted and is active. */
  ACTIVE = 'ACTIVE',
  /** The job has been blocked by customer service or an automated process. */
  BLOCKED = 'BLOCKED',
  /** The job has expired. */
  EXPIRED = 'EXPIRED',
  /** The account which posted the job has not yet been approved. */
  PENDING_ACCOUNT_APPROVAL = 'PENDING_ACCOUNT_APPROVAL',
  /** The job has been suspended. */
  SUSPENDED = 'SUSPENDED',
}

export type JobTitleOption = {
  __typename?: 'JobTitleOption';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
};

export type JobsInput = {
  /** The advertiser country */
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  /** The language SEEK site is using */
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  /** The page number to fetch. */
  page: Scalars['Int']['input'];
  /** The number of jobs to fetch in a single page. */
  pageSize: Scalars['Int']['input'];
  /**
   * Fetch only open or closed jobs.
   * When `null`, open and closed jobs are returned.
   */
  statuses?: InputMaybe<JobStatusFilter[]>;
  /** Text for which to search in fields such as job titles and reference. */
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type JobsResponse = JobsResponseSuccess | ResponseError;

export type JobsResponseSuccess = {
  __typename?: 'JobsResponseSuccess';
  result: JobConnection;
};

export type LastUpdatedResponse = {
  __typename?: 'LastUpdatedResponse';
  lastUpdated: Scalars['DateTime']['output'];
};

export type LayoutDisplay = {
  __typename?: 'LayoutDisplay';
  content?: Maybe<LayoutDisplayContent>;
  format: Scalars['String']['output'];
  productCap: ProductCap;
  sectionOrder: Array<Scalars['String']['output']>;
  sortProductIds: Array<Scalars['ID']['output']>;
};

export type LayoutDisplayContent = {
  __typename?: 'LayoutDisplayContent';
  comparatives?: Maybe<ComparativeSections>;
};

/** This specific type is used for order summary */
export type LineItemProduct = Product & {
  __typename?: 'LineItemProduct';
  /** Description of the line item */
  description: Scalars['String']['output'];
  /** Name used when displaying this product as a line item of a purchase */
  name: Scalars['String']['output'];
  /** Type of product purchased */
  productType: ProductType;
};

export type Link = {
  __typename?: 'Link';
  href: Scalars['String']['output'];
  rel: Scalars['String']['output'];
};

export type ListingIdResponse = ListingIdResponseSuccess | ResponseError;

export type ListingIdResponseSuccess = {
  __typename?: 'ListingIdResponseSuccess';
  result: Scalars['ID']['output'];
};

export type LocalisedContactInfo = {
  __typename?: 'LocalisedContactInfo';
  /** Business hours. Stored in hygraph. Falls back to `@seek/shared-constants` if not found. */
  businessHours?: Maybe<Scalars['String']['output']>;
  /** Contact number. Stored in hygraph. Falls back to `@seek/shared-constants` if not found. */
  contactNumber?: Maybe<Scalars['String']['output']>;
  /** Contact us link. Stored in hygraph. Falls back to `@seek/shared-constants` if not found. */
  contactUsLink?: Maybe<Scalars['String']['output']>;
  /** Help centre link. Stored in hygraph. Falls back to `@seek/shared-constants` if not found. */
  helpCentreLink?: Maybe<Scalars['String']['output']>;
};

export type LocalisedContactInfoInput = {
  locale: Scalars['String']['input'];
  site: Scalars['String']['input'];
};

export type Location = {
  __typename?: 'Location';
  country: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayDescription: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isSeekMarketCountry: Scalars['Boolean']['output'];
  parent?: Maybe<Location>;
  type: Scalars['String']['output'];
};

export type LocationdescriptionArgs = {
  input?: InputMaybe<LocationDescriptionInput>;
};

export type LocationdisplayDescriptionArgs = {
  input?: InputMaybe<LocationDisplayDescriptionInput>;
};

export enum LocationContext {
  /** Uses JoB Posting Filter logic. This will be the default if context is not specified. */
  JOB_POSTING = 'JOB_POSTING',
  /** Uses Talent Search Filter logic. */
  TALENT_SEARCH = 'TALENT_SEARCH',
}

export type LocationDescriptionInput = {
  displayCountry: Scalars['String']['input'];
  language: Scalars['String']['input'];
};

export type LocationDisplayDescriptionInput = {
  displayCountry?: InputMaybe<Scalars['String']['input']>;
  displayFormat?: InputMaybe<LocationDisplayFormat>;
  language: Scalars['String']['input'];
};

export enum LocationDisplayFormat {
  LONG = 'LONG',
  SHORT = 'SHORT',
}

export type LocationInput = {
  country: Scalars['String']['input'];
  description: Scalars['String']['input'];
  displayDescription: Scalars['String']['input'];
  id: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type LocationOption = {
  __typename?: 'LocationOption';
  count: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  level: Scalars['Int']['output'];
  parentId: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type LocationSearchResult = {
  __typename?: 'LocationSearchResult';
  country: Scalars['String']['output'];
  countryId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated unified location service doesn't return this field */
  matchedSearchString?: Maybe<MatchedSearchString[]>;
  parents: Array<Maybe<LocationSearchResult>>;
  type: Scalars['String']['output'];
};

export type LogoStrategies = {
  __typename?: 'LogoStrategies';
  jdpLogo: Scalars['String']['output'];
  serpLogo: Scalars['String']['output'];
};

export type LowApply = {
  __typename?: 'LowApply';
  averageConversionRate: Scalars['Float']['output'];
  conversionRate: Scalars['Float']['output'];
  type: RecommendationType;
};

export type LowClick = {
  __typename?: 'LowClick';
  averageConversionRate: Scalars['Float']['output'];
  conversionRate: Scalars['Float']['output'];
  type: RecommendationType;
};

export type LowSalary = {
  __typename?: 'LowSalary';
  benchmarkLocationLabel: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  normalisedRoleTitle: Scalars['String']['output'];
  salaryRangeMax: Scalars['Float']['output'];
  salaryRangeMin: Scalars['Float']['output'];
  salaryType: AnalyticsSalaryType;
  suggestedAverageRangeMax?: Maybe<Scalars['Int']['output']>;
  suggestedAverageRangeMin?: Maybe<Scalars['Int']['output']>;
  suggestedRangeMax?: Maybe<Scalars['Int']['output']>;
  suggestedRangeMin?: Maybe<Scalars['Int']['output']>;
  type: RecommendationType;
};

export type Maintenance = {
  __typename?: 'Maintenance';
  disableMessage?: Maybe<DisableMessageType>;
  disablePayment?: Maybe<Scalars['Boolean']['output']>;
  paymentMedium?: Maybe<Array<Maybe<PaymentMediumType>>>;
  paymentMethod: Scalars['String']['output'];
  showMaintenance?: Maybe<Scalars['Boolean']['output']>;
};

export type ManagingAgency = {
  __typename?: 'ManagingAgency';
  /** Id of the agency. */
  advertiserId?: Maybe<Scalars['String']['output']>;
};

export type MandatoryData = {
  __typename?: 'MandatoryData';
  /** Tooltip-friendly answer text to display for mandatory questions */
  mandatoryAnswerText: Array<Scalars['String']['output']>;
};

export type MarkApplicationsReadInput = {
  prospectIds: Array<Scalars['ID']['input']>;
};

export type MarkApplicationsReadPayload = {
  __typename?: 'MarkApplicationsReadPayload';
  success: Scalars['Boolean']['output'];
};

export type MarketInsightMonth = {
  __typename?: 'MarketInsightMonth';
  calendarMonth: Scalars['String']['output'];
  count: Scalars['Int']['output'];
};

export type MarketInsightsTitleResponse = {
  __typename?: 'MarketInsightsTitleResponse';
  benchmarkLocationCountry?: Maybe<Scalars['String']['output']>;
  benchmarkLocationLabel?: Maybe<Scalars['String']['output']>;
  benchmarkScenario?: Maybe<AnalyticsBenchmarkScenario>;
  classificationName: Scalars['String']['output'];
  normalisedRoleTitle?: Maybe<Scalars['String']['output']>;
  subClassificationName: Scalars['String']['output'];
};

export type MarketInsightsTitleWidgetInput = {
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
};

export type MarketPerformanceResponse = {
  __typename?: 'MarketPerformanceResponse';
  highAds: Scalars['Int']['output'];
  lowAds: Scalars['Int']['output'];
  noRatingAds: Scalars['Int']['output'];
  normalAds: Scalars['Int']['output'];
  totalAds: Scalars['Int']['output'];
};

export type MarketPerformanceWidgetInput = {
  filters: AdUsageQueryFilters;
};

export type MatchedQuality = {
  __typename?: 'MatchedQuality';
  displayLabel: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isTop: Scalars['Boolean']['output'];
  relevanceScore: Scalars['Float']['output'];
};

export type MatchedSearchString = {
  __typename?: 'MatchedSearchString';
  matches?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  text: Scalars['String']['output'];
};

export type MaxBillableApplicantsResponse =
  | MaxBillableApplicantsResponseSuccess
  | ResponseError;

export type MaxBillableApplicantsResponseSuccess = {
  __typename?: 'MaxBillableApplicantsResponseSuccess';
  result: Scalars['Int']['output'];
};

export type MaximiseBranding = TipToImproveAds & {
  __typename?: 'MaximiseBranding';
  adCount: Scalars['Int']['output'];
  bulletPointsUsed: Scalars['Boolean']['output'];
  coverImageUsed: Scalars['Boolean']['output'];
  logoUsed: Scalars['Boolean']['output'];
  type: TipsToImproveAdsType;
};

export type MissingAdvertiserIdClaimError = Error & {
  __typename?: 'MissingAdvertiserIdClaimError';
  message: Scalars['String']['output'];
};

export type MissingUserIdClaimError = Error & {
  __typename?: 'MissingUserIdClaimError';
  message: Scalars['String']['output'];
};

export type Money = {
  __typename?: 'Money';
  currency: Currency;
  value: Scalars['SafeInt']['output'];
};

export type MonthlyTrendsProductDisplayName = {
  __typename?: 'MonthlyTrendsProductDisplayName';
  items?: Maybe<Array<Maybe<ProductDisplayNameOption>>>;
  month: Scalars['String']['output'];
};

export type MostAdsByPerformanceCategory = {
  __typename?: 'MostAdsByPerformanceCategory';
  classification: MostAdsByPerformanceEntity;
  user: MostAdsByPerformanceEntity;
};

export type MostAdsByPerformanceEntity = {
  __typename?: 'MostAdsByPerformanceEntity';
  label?: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
};

export type MostAdsByPerformanceResponse = {
  __typename?: 'MostAdsByPerformanceResponse';
  high: MostAdsByPerformanceCategory;
  low: MostAdsByPerformanceCategory;
};

export type MostAdsByPerformanceWidgetInput = {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  addItemToCart?: Maybe<AddItemToCartResultPayload>;
  addOrRemoveProfileToTalentPools: Scalars['String']['output'];
  addUserToAccount?: Maybe<AddUserToAccountResponse>;
  applyCoverToAllBrands?: Maybe<Array<Maybe<Brand>>>;
  auditSuccessfulLogin: AuditSuccessfulLoginPayload;
  clearDefaultBrand?: Maybe<Brand>;
  completeAdditionalAdvertiserRegistration: SecureLinkResponse;
  completeUserRegistration: SecureLinkResponse;
  /** Request to confirm an invoice order. Returns request status. */
  confirmInvoiceOrder?: Maybe<ConfirmInvoiceOrderPayload>;
  /** Creates a Payment Intent and provides the paymentLink where user can complete the payment and paymentToken where client can check the payment status */
  create2C2PPaymentIntent: Create2c2pPaymentIntentPayload;
  /** Creates a Payment Intent for invoices and provides the paymentLink where user can complete the payment and paymentToken where client can check the payment status */
  create2C2PPaymentIntentForInvoices: Create2c2pPaymentIntentPayload;
  createApplicationNote: ApplicationNote;
  createBrand?: Maybe<CreateBrandPayload>;
  createCustomQuestion: CreateCustomQuestionOutputUnion;
  createDraftJob: CreateDraftJobPayload;
  createJob?: Maybe<CreateJobResponse>;
  createJobRevision: CreateJobRevisionPayload;
  /** Request to create a order from selected items. Returns Order. */
  createOrder?: Maybe<CreateOrderPayload>;
  /** Request to create an order with a promotion code applied. Returns Order. */
  createOrderWithPromotion?: Maybe<CreateOrderWithPromotionPayload>;
  createProductSetForAdEditing: CreateProductSetForAdEditingPayload;
  createProductSetForAdPosting: CreateProductSetForAdPostingPayload;
  createProductSetForAdRefresh: CreateProductSetForAdRefreshPayload;
  createProductSetForAdUpgradePurchase: CreateProductSetForAdUpgradePurchasePayload;
  createProductSetForBudgetsPurchaseForHirer: CreateProductSetForBudgetsPurchaseForHirerPayload;
  createProductSetForBudgetsPurchaseForVisitor: CreateProductSetForBudgetsPurchaseForVisitorPayload;
  /** createProductSet mutation used when posting a job to multiple locations */
  createProductSetForMultiLocation: CreateProductSetForMultiLocationPayload;
  createProductSetForPriceChecker: CreateProductSetForAdPostingPayload;
  createProductSetForPriceCheckerPreview: CreateProductSetForAdPostingPayload;
  createQuestionnaire?: Maybe<CreateQuestionnaireResponse>;
  createSavedSearch?: Maybe<CreateSavedSearchPayload>;
  /** Creates a Payment Intent and provides the clientSecret which is used on the client-side to charge the card with the Stripe confirmCardPayment API. */
  createStripePaymentIntent: CreateStripePaymentIntentPayload;
  /** Creates a Payment Intent for invoices and provides the clientSecret which is used on the client-side to charge the card with the Stripe confirmCardPayment API. */
  createStripePaymentIntentForInvoice: CreateStripePaymentIntentPayload;
  createTalentPool: TalentSearchPool;
  deleteBrand?: Maybe<Brand>;
  deleteBrandingAsset?: Maybe<DeleteBrandingAssetPayload>;
  deleteDraftJob?: Maybe<DeleteDraftJobPayload>;
  deleteSavedSearch?: Maybe<DeleteSavedSearchPayload>;
  /** To delete a saved credit card using provided payment method id. */
  deleteStripeSavedCreditCard?: Maybe<DeleteStripeSavedCreditCardPayload>;
  deleteTalentPool: Scalars['String']['output'];
  deleteUploadedSupportDoc: DeleteUploadedSupportDocResponse;
  editJob?: Maybe<EditJobResponse>;
  expireJob?: Maybe<ExpireJobResponse>;
  generateJobContent: GenerateJobContentResponsePayload;
  initiateAccessCvV2: InitiateConnectionResponse;
  initiateAccessProfileAndCvV2: InitiateConnectionResponse;
  initiateAccessProfileV2: InitiateConnectionResponse;
  initiateExportProfileV2: InitiateConnectionResponse;
  initiateExportProfileWithAtsJobV2: InitiateConnectionResponse;
  initiateSendJobV2: InitiateConnectionResponse;
  initiateSendMessageV2: InitiateConnectionResponse;
  markApplicationsRead: MarkApplicationsReadPayload;
  orderReferenceCheck: OrderReferenceCheckResponse;
  patchCompanyProfile?: Maybe<PatchCompanyProfileResponse>;
  /** To pay for a given order using provided payment method id associated to a saved credit card. */
  payByStripeSavedCreditCard?: Maybe<PayByStripeSavedCreditCardPayload>;
  /** To pay for a given order using provided payment method id associated to a saved credit card. */
  payByStripeSavedCreditCardForInvoice?: Maybe<PayByStripeSavedCreditCardPayload>;
  processUploadedSupportDoc: ProcessUploadedSupportDocResponse;
  publishCPQAEvent?: Maybe<PublishCPQAEventPayload>;
  publishJobAdBookingEvent?: Maybe<PublishJobAdBookingEventPayload>;
  publishJobPostedEvent?: Maybe<PublishJobPostedEventPayload>;
  refreshCartProductSet?: Maybe<RefreshCartProductSetResultPayload>;
  refreshJob?: Maybe<RefreshJobResponse>;
  registerAdditionalAdvertiser?: Maybe<RegisterPayload>;
  registerNewUser?: Maybe<RegisterPayload>;
  reinstate?: Maybe<ReinstateResponse>;
  removeApplicationNote: RemoveApplicationNotePayload;
  removeCartItem?: Maybe<RemoveCartItemResultPayload>;
  removeCartPromoCode?: Maybe<RemoveCartPromoCodeResultPayload>;
  removeUserFromAccount?: Maybe<AccountDetailsUpdateResponse>;
  /** Creates relationships between a hirer and partner */
  requestIntegrationRelationships: RequestIntegrationRelationshipsPayload;
  resendAccountActivationEmail: ResendAccountActivationEmailResponse;
  sendAccessEvent: SendAccessEventPayload;
  sendApplicationEmail: ApplicationEmail;
  sendBulkApplicationEmail: SendBulkApplicationEmailPayload;
  /** Send a verification email to a hirer. */
  sendEmailVerificationEmail?: Maybe<SendEmailVerificationEmailPayload>;
  /** Send a enrolment to MFA email to a hirer. */
  sendEnrolmentEmail?: Maybe<SendEnrolmentEmailPayload>;
  /** Send email to hirer account users notifying they have access to a specific job */
  sendHirerAccountUsersJobAccessEmail?: Maybe<SendHirerAccountUsersJobAccessEmailPayload>;
  /** Track the details of a successful login which cannot be reliably tracked server-side. */
  sendLoginCallbackEvent?: Maybe<SendLoginCallbackEventPayload>;
  sendPasswordResetEmail: SendEmailResponse;
  sendRegistrationEmail: SendEmailResponse;
  setDefaultBrand?: Maybe<Brand>;
  /** Set default notification preference for current logged in user for a specific job */
  setDefaultNotificationPreference: NotificationPreference;
  /** Set job access for hirer account users for a specific job */
  setHirerAccountUsersJobAccess?: Maybe<SetHirerAccountUsersJobAccessPayload>;
  /** Set notification preferences for account users for a specific job */
  setNotificationPreferences: SetNotificationPreferencesPayload;
  shareTalentPool: Scalars['String']['output'];
  submitCompanyProfileAdvertiserClaim?: Maybe<Scalars['Boolean']['output']>;
  submitCompanyProfileClaim?: Maybe<CompanyProfileAdvertiserClaimResponse>;
  /**
   * Track they query text entered or chosen location id after a user has been presented with location suggestions.
   * By tracking the outcome of a location suggestion request the accuracy of location data in future can be improved.
   *
   * The sessionId should also be sent to the "locations" query so that tracking between the suggestions provided and the chosen
   * result can be completed.
   */
  trackLocationSuggestion: TrackLocationSuggestionPayload;
  triggerActivation: TriggerActivationPayload;
  updateAccountBillingAddress?: Maybe<AccountDetailsUpdateResponse>;
  updateAccountBillingEmailAddress?: Maybe<AccountDetailsUpdateResponse>;
  updateAccountPostalAddress?: Maybe<AccountDetailsUpdateResponse>;
  updateAccountPrimaryContactV2?: Maybe<AccountDetailsUpdateResponse>;
  updateApplicationRating: ApplicationRating;
  updateApplicationsStatus: UpdateApplicationsStatusPayload;
  updateBrand?: Maybe<UpdateBrandPayload>;
  updateBusinessIdentifiers?: Maybe<AccountDetailsUpdateResponse>;
  updateCartProductSet?: Maybe<UpdateCartProductSetResultPayload>;
  updateCartPromoCode?: Maybe<UpdateCartPromoCodeResultPayload>;
  updateDraftJob: UpdateDraftPayload;
  updateHirerNotificationPreferences: HirerNotificationPreferenceCategory[];
  updateJobDetails: UpdateJobDetailsResponse;
  updateJobForCartItemByReference?: Maybe<UpdateJobForCartItemByReferenceResultPayload>;
  updateJobPlacement: UpdateJobPlacementResponse;
  updateTalentPool: Scalars['String']['output'];
  updateUserDetails: UpdateUserDetailsResponse;
  updateUserPermissions?: Maybe<UpdateUserPermissionsResponse>;
  /** upsert review reply method means: if the review reply exists, update it, otherwise create it */
  upsertReviewReply: UpsertReviewReplyResponse;
};

export type MutationaddItemToCartArgs = {
  input: AddItemToCartInput;
};

export type MutationaddOrRemoveProfileToTalentPoolsArgs = {
  input: TalentSearchAddOrRemoveProfileTalentPoolsInput;
};

export type MutationaddUserToAccountArgs = {
  input: AddUserToAccountInput;
};

export type MutationapplyCoverToAllBrandsArgs = {
  brandId: Scalars['String']['input'];
  owner?: InputMaybe<BrandOwnerInput>;
};

export type MutationclearDefaultBrandArgs = {
  owner?: InputMaybe<BrandOwnerInput>;
};

export type MutationcompleteAdditionalAdvertiserRegistrationArgs = {
  input: SecureLinkInput;
};

export type MutationcompleteUserRegistrationArgs = {
  input: SecureLinkInput;
};

export type MutationconfirmInvoiceOrderArgs = {
  input: ConfirmInvoiceOrderInput;
};

export type Mutationcreate2C2PPaymentIntentArgs = {
  input: Create2C2PPaymentIntentInput;
};

export type Mutationcreate2C2PPaymentIntentForInvoicesArgs = {
  input: Create2C2PPaymentIntentForInvoiceInput;
};

export type MutationcreateApplicationNoteArgs = {
  input: CreateApplicationNoteInput;
};

export type MutationcreateBrandArgs = {
  input: CreateBrandInput;
};

export type MutationcreateCustomQuestionArgs = {
  input: CreateCustomQuestionInput;
};

export type MutationcreateDraftJobArgs = {
  input: CreateDraftJobInput;
};

export type MutationcreateJobArgs = {
  input: CreateJobInput;
};

export type MutationcreateJobRevisionArgs = {
  input: CreateJobRevisionInput;
};

export type MutationcreateOrderArgs = {
  input: CreateOrderInput;
};

export type MutationcreateOrderWithPromotionArgs = {
  input: CreateOrderInput;
};

export type MutationcreateProductSetForAdEditingArgs = {
  input: CreateProductSetForAdEditingInput;
};

export type MutationcreateProductSetForAdPostingArgs = {
  input: CreateProductSetForAdPostingInput;
};

export type MutationcreateProductSetForAdRefreshArgs = {
  input: CreateProductSetForAdRefreshInput;
};

export type MutationcreateProductSetForAdUpgradePurchaseArgs = {
  input: CreateProductSetForAdUpgradePurchaseInput;
};

export type MutationcreateProductSetForBudgetsPurchaseForHirerArgs = {
  input?: InputMaybe<CreateProductSetForBudgetsPurchaseForHirerInput>;
};

export type MutationcreateProductSetForBudgetsPurchaseForVisitorArgs = {
  input: CreateProductSetForBudgetsPurchaseForVisitorInput;
};

export type MutationcreateProductSetForMultiLocationArgs = {
  input: CreateProductSetForMultiLocationInput;
};

export type MutationcreateProductSetForPriceCheckerArgs = {
  input: CreateProductSetForPriceCheckerInput;
};

export type MutationcreateProductSetForPriceCheckerPreviewArgs = {
  input: CreateProductSetForPriceCheckerPreviewInput;
};

export type MutationcreateQuestionnaireArgs = {
  input: CreateQuestionnaireInput;
};

export type MutationcreateSavedSearchArgs = {
  input: CreateSavedSearchInput;
};

export type MutationcreateStripePaymentIntentArgs = {
  input: CreateStripePaymentIntentInput;
};

export type MutationcreateStripePaymentIntentForInvoiceArgs = {
  input: CreateStripePaymentIntentForInvoiceInput;
};

export type MutationcreateTalentPoolArgs = {
  input: TalentSearchCreateTalentPoolInput;
};

export type MutationdeleteBrandArgs = {
  id: Scalars['ID']['input'];
  owner?: InputMaybe<BrandOwnerInput>;
};

export type MutationdeleteBrandingAssetArgs = {
  input: DeleteBrandingAssetInput;
};

export type MutationdeleteDraftJobArgs = {
  input: DeleteDraftJobInput;
};

export type MutationdeleteSavedSearchArgs = {
  criteriaHash: Scalars['String']['input'];
};

export type MutationdeleteStripeSavedCreditCardArgs = {
  input: DeleteStripeSavedCreditCardInput;
};

export type MutationdeleteTalentPoolArgs = {
  input: TalentSearchDeleteTalentPoolInput;
};

export type MutationdeleteUploadedSupportDocArgs = {
  input: DeleteUploadedSupportDocInput;
};

export type MutationeditJobArgs = {
  input: EditJobInput;
};

export type MutationexpireJobArgs = {
  input: ExpireJobInput;
};

export type MutationgenerateJobContentArgs = {
  input: GenerateJobContentInput;
};

export type MutationinitiateAccessCvV2Args = {
  input: InitiateAccessCvV2Input;
};

export type MutationinitiateAccessProfileAndCvV2Args = {
  input: InitiateAccessProfileAndCvV2Input;
};

export type MutationinitiateAccessProfileV2Args = {
  input: InitiateAccessProfileV2Input;
};

export type MutationinitiateExportProfileV2Args = {
  input: InitiateExportProfileV2Input;
};

export type MutationinitiateExportProfileWithAtsJobV2Args = {
  input: InitiateExportProfileWithAtsJobV2Input;
};

export type MutationinitiateSendJobV2Args = {
  input: InitiateSendJobV2Input;
};

export type MutationinitiateSendMessageV2Args = {
  input: InitiateSendMessageV2Input;
};

export type MutationmarkApplicationsReadArgs = {
  input: MarkApplicationsReadInput;
};

export type MutationorderReferenceCheckArgs = {
  input: OrderReferenceCheckInput;
};

export type MutationpatchCompanyProfileArgs = {
  input: CompanyProfileInput;
};

export type MutationpayByStripeSavedCreditCardArgs = {
  input: PayByStripeSavedCreditCardInput;
};

export type MutationpayByStripeSavedCreditCardForInvoiceArgs = {
  input: PayByStripeSavedCreditCardForInvoiceInput;
};

export type MutationprocessUploadedSupportDocArgs = {
  input: ProcessUploadedSupportDocInput;
};

export type MutationpublishCPQAEventArgs = {
  input: PublishCPQAEventInput;
};

export type MutationpublishJobAdBookingEventArgs = {
  input: PublishJobAdBookingEventInput;
};

export type MutationpublishJobPostedEventArgs = {
  input?: InputMaybe<PublishJobPostedEventInput>;
};

export type MutationrefreshCartProductSetArgs = {
  input: UpdateCartProductSetInput;
};

export type MutationrefreshJobArgs = {
  input: RefreshJobInput;
};

export type MutationregisterAdditionalAdvertiserArgs = {
  input: RegisterAdditionalAdvertiserInput;
};

export type MutationregisterNewUserArgs = {
  input: RegisterNewUserInput;
};

export type MutationreinstateArgs = {
  input: ReinstateInput;
};

export type MutationremoveApplicationNoteArgs = {
  input: RemoveApplicationNoteInput;
};

export type MutationremoveCartItemArgs = {
  input: RemoveCartItemInput;
};

export type MutationremoveCartPromoCodeArgs = {
  input: RemoveCartPromoCodeInput;
};

export type MutationremoveUserFromAccountArgs = {
  input: RemoveUserFromAccountInput;
};

export type MutationrequestIntegrationRelationshipsArgs = {
  input: RequestIntegrationRelationshipsInput;
};

export type MutationresendAccountActivationEmailArgs = {
  input: ResendAccountActivationEmailInput;
};

export type MutationsendApplicationEmailArgs = {
  input: SendApplicationEmailInput;
};

export type MutationsendBulkApplicationEmailArgs = {
  input: SendBulkApplicationEmailInput;
};

export type MutationsendHirerAccountUsersJobAccessEmailArgs = {
  input: SendHirerAccountUsersJobAccessEmailInput;
};

export type MutationsendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput;
};

export type MutationsendRegistrationEmailArgs = {
  input: SendRegistrationEmailInput;
};

export type MutationsetDefaultBrandArgs = {
  id: Scalars['ID']['input'];
  owner?: InputMaybe<BrandOwnerInput>;
};

export type MutationsetDefaultNotificationPreferenceArgs = {
  input: SetDefaultNotificationPreferenceInput;
};

export type MutationsetHirerAccountUsersJobAccessArgs = {
  input: SetHirerAccountUsersJobAccessInput;
};

export type MutationsetNotificationPreferencesArgs = {
  input: SetNotificationPreferencesInput;
};

export type MutationshareTalentPoolArgs = {
  input: TalentSearchShareTalentPoolInput;
};

export type MutationsubmitCompanyProfileAdvertiserClaimArgs = {
  input: CompanyProfileAdvertiserClaimInput;
};

export type MutationsubmitCompanyProfileClaimArgs = {
  input: CompanyProfileAdvertiserClaimInput;
};

export type MutationtrackLocationSuggestionArgs = {
  input: TrackLocationSuggestionInput;
};

export type MutationupdateAccountBillingAddressArgs = {
  input?: InputMaybe<AccountBillingAddressInput>;
};

export type MutationupdateAccountBillingEmailAddressArgs = {
  input?: InputMaybe<AccountBillingEmailAddressInput>;
};

export type MutationupdateAccountPostalAddressArgs = {
  input?: InputMaybe<AccountPostalAddressInput>;
};

export type MutationupdateAccountPrimaryContactV2Args = {
  input: UpdateAccountPrimaryContactV2Input;
};

export type MutationupdateApplicationRatingArgs = {
  input: UpdateApplicationRatingInput;
};

export type MutationupdateApplicationsStatusArgs = {
  input: UpdateApplicationsStatusInput;
};

export type MutationupdateBrandArgs = {
  input: UpdateBrandInput;
};

export type MutationupdateBusinessIdentifiersArgs = {
  input: AccountBusinessIdentifiersInput;
};

export type MutationupdateCartProductSetArgs = {
  input: UpdateCartProductSetInput;
};

export type MutationupdateCartPromoCodeArgs = {
  input: UpdateCartPromoCodeInput;
};

export type MutationupdateDraftJobArgs = {
  input: UpdateDraftJobInput;
};

export type MutationupdateHirerNotificationPreferencesArgs = {
  input: HirerNotificationUpdatePreferenceInput;
};

export type MutationupdateJobDetailsArgs = {
  input: UpdateJobDetailsInput;
};

export type MutationupdateJobForCartItemByReferenceArgs = {
  input: UpdateJobForCartItemByReferenceInput;
};

export type MutationupdateJobPlacementArgs = {
  input: UpdateJobPlacementInput;
};

export type MutationupdateTalentPoolArgs = {
  input: TalentSearchUpdateTalentPoolInput;
};

export type MutationupdateUserDetailsArgs = {
  input: UserDetailsInput;
};

export type MutationupdateUserPermissionsArgs = {
  input: UpdateUserPermissionsInput;
};

export type MutationupsertReviewReplyArgs = {
  input: UpsertReviewReplyInput;
};

export type MyJobInformationJobEdit = {
  __typename?: 'MyJobInformationJobEdit';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  timestampUTC: Scalars['DateTime']['output'];
};

export type MyJobInformationResponse = {
  __typename?: 'MyJobInformationResponse';
  addOns: Array<Maybe<Scalars['String']['output']>>;
  advertiserName: Scalars['String']['output'];
  analyticsProductDisplayName: Scalars['String']['output'];
  applyUrl?: Maybe<Scalars['String']['output']>;
  classificationName: Scalars['String']['output'];
  createdByEmail: Scalars['String']['output'];
  createdByName: Scalars['String']['output'];
  isLinkOut: Scalars['Boolean']['output'];
  isPrivateAdvertiser: Scalars['Boolean']['output'];
  isSalaryHidden: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  jobLocationLabel: Scalars['String']['output'];
  jobTitle: Scalars['String']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  latestEdit?: Maybe<MyJobInformationJobEdit>;
  mirrorClassificationName?: Maybe<Scalars['String']['output']>;
  mirrorSubClassificationName?: Maybe<Scalars['String']['output']>;
  postTypeCategory: AnalyticsPostTypeCategory;
  salary: MyJobInformationSalary;
  subClassificationName: Scalars['String']['output'];
  workType: AnalyticsWorkType;
};

export type MyJobInformationSalary = {
  __typename?: 'MyJobInformationSalary';
  additionalText?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  maximum: Scalars['Float']['output'];
  minimum: Scalars['Float']['output'];
  type?: Maybe<AnalyticsSalaryType>;
};

export type MyJobInformationWidgetInput = {
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
};

export type NewAdvertiser = {
  /** Business identification number. */
  businessIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Business name. */
  businessName: Scalars['String']['input'];
  /** Business phone number. */
  businessPhoneNumber: Scalars['String']['input'];
  /** Billing country. */
  country: Scalars['String']['input'];
  /** Indicates whether the employer is an agency account. */
  isAgency?: InputMaybe<Scalars['Boolean']['input']>;
  /** The zone where the user registered. */
  originZone?: InputMaybe<Scalars['String']['input']>;
  /** The company's tax number. Used for countries where their tax and business number differ. */
  taxIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type NewUser = {
  /** Given name */
  firstName: Scalars['String']['input'];
  /** Family name */
  lastName: Scalars['String']['input'];
  /** The audience zone of the site that this user originally registered on */
  originZone?: InputMaybe<Scalars['String']['input']>;
};

export type NoJobs = {
  __typename?: 'NoJobs';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  /** Email which notification will be sent to if account user chooses to receive notification for every new application. */
  applicationEmail?: Maybe<Scalars['String']['output']>;
  /** Frequency of receiving notifications. */
  frequency: Frequency;
  /** Id of the account user who receives the notification. Positive number only. */
  userId: Scalars['ID']['output'];
};

export type NotificationPreferenceInput = {
  /** Email which notification will be sent to if account user chooses to receive notification for every new application. */
  applicationEmail?: InputMaybe<Scalars['String']['input']>;
  /** Frequency of receiving notifications. */
  frequency: Frequency;
  /** Id of the account user who receives the notification. Positive number only. */
  userId: Scalars['ID']['input'];
};

export type Option = {
  /** Option Order */
  order: Scalars['Int']['input'];
  /** Option Text */
  text: Scalars['String']['input'];
};

export type OptionWithId = {
  __typename?: 'OptionWithId';
  /** Option Id */
  id: Scalars['ID']['output'];
  /** Option Order */
  order: Scalars['Int']['output'];
  /** Option Text */
  text: Scalars['String']['output'];
};

export type Order = {
  __typename?: 'Order';
  orderId: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** Error for order that has past expiry date. */
export type OrderExpiredError = Error & {
  __typename?: 'OrderExpiredError';
  message: Scalars['String']['output'];
};

/** Error for order without expiryTime or paymentBreakdown, any status not in pending, or an incomplete priced order. */
export type OrderInvalidError = Error & {
  __typename?: 'OrderInvalidError';
  message: Scalars['String']['output'];
};

export type OrderItem = {
  productReference?: InputMaybe<Scalars['String']['input']>;
  productType: ProductType;
};

export type OrderLineItem = {
  __typename?: 'OrderLineItem';
  /** The full name of the country (eg. Australia, Hong Kong) that the order item is relevant to. Localised if locale is provided. */
  country?: Maybe<Scalars['String']['output']>;
  /** Drawdown items of the order item. */
  drawdownItems: DrawdownItem[];
  /** Drawdown type of the order item. */
  drawdownType: DrawdownType;
  /** Price of the order item. */
  price: Price;
  pricing?: Maybe<Pricing>;
  /** Reference to product. Eg JobAd, AdPack, AdBudget. */
  product: Product;
};

/** Error for order not found. */
export type OrderNotFoundError = Error & {
  __typename?: 'OrderNotFoundError';
  message: Scalars['String']['output'];
};

export type OrderProductAttributesInput = {
  /** Required input attributes if product selected is an Ad Budget. */
  adBudget?: InputMaybe<AdBudgetAttributes>;
  catalogueProduct?: InputMaybe<CatalogueProductAttributes>;
  /** Required input attributes if product selected is a Job Ad. */
  jobAd?: InputMaybe<JobAdRevisionAttributes>;
  /** Required input attributes if product selected is a Job Ad Edit. */
  jobAdEdit?: InputMaybe<JobAdRevisionAttributes>;
  /** Required input attributes if product selected is a Job Ad Extend. */
  jobAdExtend?: InputMaybe<JobAdRevisionAttributes>;
};

export type OrderReferenceCheckInput = {
  applicationCorrelationId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
};

export type OrderReferenceCheckResponse =
  | OrderReferenceCheckResponseSuccess
  | ResponseError;

export type OrderReferenceCheckResponseSuccess = {
  __typename?: 'OrderReferenceCheckResponseSuccess';
  success: Scalars['Boolean']['output'];
};

export type OrderRequestInputItem = {
  /** Required attributes associated to the product. Key name to attributes must match product type. Eg. jobAd for JOB_AD, adBudget for AD_BUDGET. */
  productAttributes: OrderProductAttributesInput;
  /** Type of product. Eg. Jod ad, Job ad edit, Ad budget */
  productType: ProductTypeInput;
};

export type OrderSummary = {
  __typename?: 'OrderSummary';
  /** All the line items of the order summary. */
  lineItems: OrderLineItem[];
  /** The ID of the entity the order summary is built on. */
  orderId: Scalars['ID']['output'];
  /** This is the sum of the line item prices. The subTotal does not include taxes. */
  subTotal: Money;
  /** Optional surcharges that can be applied to the order. The order total does not include surcharges. */
  surcharges: Surcharge[];
  /** The tax of the order summary. */
  tax?: Maybe<Tax>;
  /** Total price of the order summary. Includes taxes. */
  total: Money;
};

export type OutputCustomQuestion = {
  __typename?: 'OutputCustomQuestion';
  /** Question Id */
  id: Scalars['ID']['output'];
  /** Options */
  options: OptionWithId[];
  /** Hirer Question Type e.g SingleSelect, FreeText */
  questionType: HirerQuestionType;
  /** Question Text */
  text: Scalars['String']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PageTitleActionsResponse = {
  __typename?: 'PageTitleActionsResponse';
  advertiserName: Scalars['String']['output'];
  classificationName: Scalars['String']['output'];
  displayCurrency?: Maybe<Scalars['String']['output']>;
  isBranded: Scalars['Boolean']['output'];
  isExpired: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isPrivateAdvertiser: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  jobExpiryTimestampUTC?: Maybe<Scalars['DateTime']['output']>;
  jobId: Scalars['String']['output'];
  jobLocationLabel: Scalars['String']['output'];
  jobSummary?: Maybe<Scalars['String']['output']>;
  jobTitle: Scalars['String']['output'];
  keySellingPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  lastUpdatedTimestampUTC?: Maybe<Scalars['String']['output']>;
  latestJobs: AnalyticsLatestJobs[];
  postTypeCategory: AnalyticsPostTypeCategory;
  salaryAdditionalText?: Maybe<Scalars['String']['output']>;
  serpLogoUrl?: Maybe<Scalars['String']['output']>;
  subClassificationName: Scalars['String']['output'];
};

export type PageTitleActionsWidgetInput = {
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
};

export type PaginationInput = {
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type Parent = {
  __typename?: 'Parent';
  advertiserId?: Maybe<Scalars['ID']['output']>;
  nation?: Maybe<Scalars['String']['output']>;
};

export type PatchCompanyProfileResponse = {
  __typename?: 'PatchCompanyProfileResponse';
  id: Scalars['String']['output'];
};

export type PayByStripeSavedCreditCardForInvoiceInput = {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand: CreditCardBrandInput;
  /** The email of the advertiser. */
  email: Scalars['String']['input'];
  /** Number of days before the payment intent expires and is disabled for payment. Minimum of at least 1 day */
  expiryDays?: InputMaybe<Scalars['Int']['input']>;
  /** The idempotency key for the payment intent. If not provided, a random uuid will be generated. */
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  /** Invoices to be paid. REMOVE this later when we are able to get the total amount from the invoice API. */
  invoices: InvoiceInput[];
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String']['input'];
  /** The reference of the invoice */
  prn: Scalars['String']['input'];
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * seekAdvertiserId of the advertiser.
   * This will be mapped to accountId on paymentService
   */
  seekAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * customer number for payment link experience.
   * seekBillingAccountId of the advertiser login experience
   * This will be mapped to customerId on paymentService
   */
  seekBillingAccountId: Scalars['String']['input'];
  /** The total amount to be paid for the payment intent. REMOVE this later when we are able to get the total amount from the invoice API. */
  totalForPaymentIntent: Scalars['SafeInt']['input'];
};

export type PayByStripeSavedCreditCardInput = {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand: CreditCardBrandInput;
  /** Id of the order. */
  orderId: Scalars['ID']['input'];
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String']['input'];
};

export type PayByStripeSavedCreditCardPayload =
  | InvoiceError
  | OrderExpiredError
  | OrderInvalidError
  | OrderNotFoundError
  | PromotionInvalidError
  | SavedCreditCardPaymentError
  | SavedCreditCardPaymentSuccessPayload;

export type PayPerPlacementEligibilityResponse =
  | PayPerPlacementEligibleResponse
  | PayPerPlacementNonEligibleResponse
  | ResponseError;

export type PayPerPlacementEligibleResponse = {
  __typename?: 'PayPerPlacementEligibleResponse';
  product: PayPerPlacementProduct;
  redirectUrl: Scalars['String']['output'];
};

export type PayPerPlacementNonEligibleResponse = {
  __typename?: 'PayPerPlacementNonEligibleResponse';
  reason: PayPerPlacementNonEligibleResponseReason;
};

export enum PayPerPlacementNonEligibleResponseReason {
  DISABLED_MARKET = 'DISABLED_MARKET',
  JOB_HIRER_COUNTRY_MISMATCH = 'JOB_HIRER_COUNTRY_MISMATCH',
  NON_ELIGIBLE_API_RESPONSE = 'NON_ELIGIBLE_API_RESPONSE',
  NON_ELIGIBLE_HIRER_ACCOUNT = 'NON_ELIGIBLE_HIRER_ACCOUNT',
  NON_ELIGIBLE_ORIGIN = 'NON_ELIGIBLE_ORIGIN',
  NOT_LOW_PERFORMANCE_AD = 'NOT_LOW_PERFORMANCE_AD',
  OVERRIDE_RESPONSE = 'OVERRIDE_RESPONSE',
}

export enum PayPerPlacementProduct {
  RECRUITER_NETWORK = 'RECRUITER_NETWORK',
  SMART_HIRE = 'SMART_HIRE',
}

export type Payment2c2pInquiry = {
  __typename?: 'Payment2c2pInquiry';
  amount?: Maybe<Scalars['SafeInt']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  /** Status of payment using 2c2p gateway */
  status?: Maybe<Payment2c2pInquiryStatus>;
};

/** Error for 2c2p payment inquiry */
export type Payment2c2pInquiryError = Error & {
  __typename?: 'Payment2c2pInquiryError';
  message: Scalars['String']['output'];
};

export type Payment2c2pInquiryPayload =
  | Payment2c2pInquiry
  | Payment2c2pInquiryError;

export enum Payment2c2pInquiryStatus {
  cancelled = 'cancelled',
  pending = 'pending',
  processing = 'processing',
  succeeded = 'succeeded',
}

export type PaymentConfigPayload = {
  __typename?: 'PaymentConfigPayload';
  features?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type PaymentIntent2C2PPayload = {
  __typename?: 'PaymentIntent2C2PPayload';
  expiryTimer?: Maybe<Scalars['String']['output']>;
  paymentLink?: Maybe<Scalars['String']['output']>;
  paymentToken?: Maybe<Scalars['String']['output']>;
};

/** Error for payment intent failed to generate */
export type PaymentIntentChargeError = Error & {
  __typename?: 'PaymentIntentChargeError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type PaymentLinkInvoicePaid = {
  __typename?: 'PaymentLinkInvoicePaid';
  /** Indicates whether the invoice has been paid. */
  paid: Scalars['Boolean']['output'];
};

export type PaymentLinkInvoicePaidInput = {
  reference: Scalars['String']['input'];
};

export type PaymentLinkInvoicePaidResponse =
  | PaymentLinkInvoicePaid
  | PaymentLinkInvoiceValidationError;

export type PaymentLinkInvoiceValidationError = Error & {
  __typename?: 'PaymentLinkInvoiceValidationError';
  message: Scalars['String']['output'];
};

export type PaymentMediumType = {
  __typename?: 'PaymentMediumType';
  disablePayment: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export enum PaymentMethod {
  CONTRACT = 'CONTRACT',
  CREDIT_CARD = 'CREDIT_CARD',
  INVOICE = 'INVOICE',
}

/** Error for payment method not supported for the currency */
export type PaymentMethodError = Error & {
  __typename?: 'PaymentMethodError';
  message: Scalars['String']['output'];
};

export type PaymentMethodPayload = {
  __typename?: 'PaymentMethodPayload';
  maintenance: Maintenance;
  paymentMethods?: Maybe<Array<Maybe<Maintenance>>>;
};

export enum PaymentMethodType2C2P {
  BNPL = 'BNPL',
  DPAY = 'DPAY',
  QR = 'QR',
  WEBPAY = 'WEBPAY',
}

export type PendingIntegrationRelationship = {
  __typename?: 'PendingIntegrationRelationship';
  hirerAccountId: Scalars['ID']['output'];
  hirerOid: Scalars['String']['output'];
  relationship: IntegrationRelationshipType;
};

/** PerApplicantBilling feature to indicate CPQA product */
export type PerApplicantBillingFeature = CatalogueProductFeature & {
  __typename?: 'PerApplicantBillingFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** PerUnitBilling feature to indicate CPQA product */
export type PerUnitBillingFeature = CatalogueProductFeature & {
  __typename?: 'PerUnitBillingFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  unit: Scalars['String']['output'];
};

export type PerformancePredictionChartEvent = {
  __typename?: 'PerformancePredictionChartEvent';
  comparisonValue: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
  day: Scalars['Int']['output'];
  editMade: Scalars['Boolean']['output'];
  value: Scalars['Int']['output'];
};

export type PerformancePredictionResponse = {
  __typename?: 'PerformancePredictionResponse';
  events?: Maybe<Array<Maybe<PerformancePredictionChartEvent>>>;
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  predictionDate?: Maybe<Scalars['Date']['output']>;
  predictionDayOptions: PredictionDayOptions[];
  valueLabel?: Maybe<CandidateLabel>;
  xAxisLabels?: Maybe<DateAxisLabel[]>;
  xRange?: Maybe<Array<Scalars['Date']['output']>>;
  yRange?: Maybe<Array<Scalars['Int']['output']>>;
};

export type PerformancePredictionWidgetInput = {
  jobId: Scalars['String']['input'];
};

export enum Permission {
  createJobs = 'createJobs',
  manageBrand = 'manageBrand',
  manageUsers = 'manageUsers',
  payInvoices = 'payInvoices',
  viewInvoiceHistory = 'viewInvoiceHistory',
  viewManagerReports = 'viewManagerReports',
}

export type PermissionOptions = {
  __typename?: 'PermissionOptions';
  canClaimCompanyProfile: Scalars['Boolean']['output'];
  canCreateJobs: Scalars['Boolean']['output'];
  canManageBrand: Scalars['Boolean']['output'];
  canManageCompanyProfile: Scalars['Boolean']['output'];
  canManageUsers: Scalars['Boolean']['output'];
  canPayInvoices: Scalars['Boolean']['output'];
  canViewInsightsRoleReport: Scalars['Boolean']['output'];
  canViewInvoiceHistory: Scalars['Boolean']['output'];
  canViewManagerReports: Scalars['Boolean']['output'];
};

export type PhysicalAddress = {
  __typename?: 'PhysicalAddress';
  /** Address line 1 */
  addressLine1?: Maybe<Scalars['String']['output']>;
  /** Address line 2 */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** All address lines */
  addressLines?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Name of the Country */
  country?: Maybe<Scalars['String']['output']>;
  /** Post code */
  postCode?: Maybe<Scalars['String']['output']>;
  /** State */
  state?: Maybe<Scalars['String']['output']>;
  /** Suburb */
  suburb?: Maybe<Scalars['String']['output']>;
};

export type PositionIdResponse = PositionIdResponseSuccess | ResponseError;

export type PositionIdResponseSuccess = {
  __typename?: 'PositionIdResponseSuccess';
  result: Scalars['ID']['output'];
};

export enum PostChannel {
  /** The job is posted through the adcentre CAJA flow. */
  DIRECT = 'DIRECT',
  /** The job is an aggregated job from an external website. */
  IMPORT = 'IMPORT',
  /** The job is posted through the indirect-managed SEEK API by a third party. */
  INDIRECT = 'INDIRECT',
}

export enum PostType {
  /** The job is posted through the adcentre CAJA flow. */
  DIRECT = 'DIRECT',
  /** The job is an aggregated job from an external website. */
  IMPORT = 'IMPORT',
  /** The job is posted through the indirect-managed SEEK API by a third party. */
  INDIRECT = 'INDIRECT',
  /** The job is a paid aggregation job, where the customer has requested and paid for their job to be imported to SEEK. */
  PAID_AGGREGATION = 'PAID_AGGREGATION',
}

export type PredictionDayOptions = {
  __typename?: 'PredictionDayOptions';
  prediction: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type PreviousApplicationsInput = {
  /** The selected application's GUID to be filtered out of the previous-apps results */
  applicationCorrelationId: Scalars['ID']['input'];
  /** The selected application's candidate email to search the previous applications */
  candidateEmail: Scalars['String']['input'];
  /** The selected application's job id */
  jobId: Scalars['ID']['input'];
};

export type PreviousApplicationsResponse =
  | PreviousApplicationsResponseSuccess
  | ResponseError;

export type PreviousApplicationsResponseSuccess = {
  __typename?: 'PreviousApplicationsResponseSuccess';
  applications: Application[];
};

export type Price = {
  __typename?: 'Price';
  /** Cost per applicant price for CPQA product. */
  costPerApplicant?: Maybe<Money>;
  discount?: Maybe<Discount>;
  isVariable?: Maybe<Scalars['Boolean']['output']>;
  netPrice: Money;
  retailPrice: Money;
  /** Tax data for the retail price. */
  retailTax?: Maybe<Tax>;
  /** Tax data for the net price. */
  tax?: Maybe<Tax>;
};

export type PriceAttributes = {
  locationId?: InputMaybe<Scalars['Float']['input']>;
  roleTitle?: InputMaybe<Scalars['String']['input']>;
  subclassificationId?: InputMaybe<Scalars['Float']['input']>;
};

export type PriceType = {
  __typename?: 'PriceType';
  type: Scalars['String']['output'];
};

export enum PriceVisibility {
  HIDE_PRICE = 'HIDE_PRICE',
  SHOW_DISCOUNTED_PRICE = 'SHOW_DISCOUNTED_PRICE',
  SHOW_RETAIL_PRICE = 'SHOW_RETAIL_PRICE',
}

export type Pricing = CappedUnitPrice | PriceType;

export enum PricingModel {
  FIXED = 'FIXED',
  HORIZON = 'HORIZON',
}

export type PrimaryContact = {
  __typename?: 'PrimaryContact';
  /** Email address of the user */
  email?: Maybe<Scalars['String']['output']>;
  /** First name of the user */
  firstName?: Maybe<Scalars['String']['output']>;
  /** ID of the user assigned as the primary contact */
  hirerUserId?: Maybe<Scalars['String']['output']>;
  /** Last name of the user */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Phone number of the advertiser collected on registration form */
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export enum PrivacyTypes {
  limited = 'limited',
  standard = 'standard',
}

export type ProcessUploadedSupportDocInput = {
  applicationCorrelationId: Scalars['ID']['input'];
  blobId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
};

export type ProcessUploadedSupportDocResponse =
  | ProcessUploadedSupportDocResponseSuccess
  | ResponseError;

export type ProcessUploadedSupportDocResponseSuccess = {
  __typename?: 'ProcessUploadedSupportDocResponseSuccess';
  result: ProcessUploadedSupportDocResult;
};

export type ProcessUploadedSupportDocResult = {
  __typename?: 'ProcessUploadedSupportDocResult';
  blobId: Scalars['ID']['output'];
};

export type Product = {
  name: Scalars['String']['output'];
  productType: ProductType;
};

export type ProductCap = {
  __typename?: 'ProductCap';
  addons?: Maybe<Scalars['Int']['output']>;
  budgets?: Maybe<Scalars['Int']['output']>;
  products?: Maybe<Scalars['Int']['output']>;
};

export type ProductDisplay = {
  __typename?: 'ProductDisplay';
  banner?: Maybe<DisplayBanner>;
  comparatives?: Maybe<DisplayComparatives>;
  cta?: Maybe<DisplayCallToAction>;
  custom?: Maybe<DisplayCustom>;
  expectations?: Maybe<DisplayExpectations>;
  highlights?: Maybe<DisplayHighlights>;
  image?: Maybe<DisplayImage>;
  inclusions?: Maybe<DisplayInclusions>;
  panel?: Maybe<DisplayPanel>;
  price?: Maybe<DisplayPrice>;
  rating?: Maybe<DisplayRating>;
  tagline?: Maybe<DisplayTagline>;
  title?: Maybe<DisplayTitle>;
};

export type ProductDisplayNameAverageCost = {
  __typename?: 'ProductDisplayNameAverageCost';
  cost: Money;
  type: Scalars['String']['output'];
};

export type ProductDisplayNameOption = {
  __typename?: 'ProductDisplayNameOption';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
};

export type ProductDisplayNamePostCount = {
  __typename?: 'ProductDisplayNamePostCount';
  adsCount: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type ProductExpectation = {
  __typename?: 'ProductExpectation';
  correlationId: Scalars['String']['output'];
  key: Scalars['String']['output'];
  unit: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type ProductFeature = {
  __typename?: 'ProductFeature';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ProductList = {
  __typename?: 'ProductList';
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** A list of all catalogue products in the product set */
  items: CatalogueProduct[];
  /** A list of catalogue products in the product set which can be presented to the customer (eg. in an ad ladder) */
  products: CatalogueProduct[];
};

export type ProductReference = {
  __typename?: 'ProductReference';
  /** Unique ID of the product reference that references to an addon/upgrade */
  id: Scalars['ID']['output'];
};

export type ProductSet = {
  __typename?: 'ProductSet';
  /** Unique ID of the product set */
  id: Scalars['ID']['output'];
  /** A list of all catalogue products in the product set */
  items: Array<Maybe<CatalogueProduct>>;
};

export type ProductSetExpiredError = Error & {
  __typename?: 'ProductSetExpiredError';
  message: Scalars['String']['output'];
};

export type ProductSetNotFoundError = Error & {
  __typename?: 'ProductSetNotFoundError';
  message: Scalars['String']['output'];
};

export enum ProductType {
  AD_BUDGET = 'AD_BUDGET',
  CATALOGUE_PRODUCT = 'CATALOGUE_PRODUCT',
  JOB_AD = 'JOB_AD',
  JOB_AD_EDIT = 'JOB_AD_EDIT',
  JOB_AD_EXTEND = 'JOB_AD_EXTEND',
  JOB_AD_UPGRADE = 'JOB_AD_UPGRADE',
}

export enum ProductTypeInput {
  AD_BUDGET = 'AD_BUDGET',
  CATALOGUE_PRODUCT = 'CATALOGUE_PRODUCT',
  JOB_AD = 'JOB_AD',
  JOB_AD_EDIT = 'JOB_AD_EDIT',
  JOB_AD_EXTEND = 'JOB_AD_EXTEND',
}

export type ProductUpgradeList = {
  __typename?: 'ProductUpgradeList';
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** A list of catalogue products in the product set which can be presented to the customer (eg. in an ad ladder) */
  products: CatalogueProduct[];
};

export type ProfileAndCvUnavailable = {
  __typename?: 'ProfileAndCvUnavailable';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type ProfileEducation = {
  __typename?: 'ProfileEducation';
  completionMonth: Scalars['Int']['output'];
  completionYear: Scalars['Int']['output'];
  institutionName: Scalars['String']['output'];
  isCompleted: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  qualificationName: Scalars['String']['output'];
  verifiedFields?: Maybe<VerifiedField[]>;
};

export type ProfileLicence = {
  __typename?: 'ProfileLicence';
  isVerified: Scalars['Boolean']['output'];
  issuingOrganisationName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  verifiedFields?: Maybe<VerifiedField[]>;
};

export type ProfileProperties = {
  __typename?: 'ProfileProperties';
  ExpressCajaOptOut?: Maybe<Scalars['String']['output']>;
  HasLoggedInBefore?: Maybe<Scalars['String']['output']>;
  HasOnlyUsedExpressCaja?: Maybe<Scalars['String']['output']>;
  HasSeenGuidedCallOut?: Maybe<Scalars['String']['output']>;
  HasSeenJobDetailsTour?: Maybe<Scalars['String']['output']>;
  UserStatusBeforeDeactivation?: Maybe<Scalars['String']['output']>;
};

export type ProfileResultHighlight = {
  __typename?: 'ProfileResultHighlight';
  resumeSnippet?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProfileSalaryV2 = {
  __typename?: 'ProfileSalaryV2';
  expected?: Maybe<ExpectedSalaryV2>;
};

export enum ProfileSource {
  combined = 'combined',
  seek = 'seek',
}

export enum ProfileTypes {
  combined = 'combined',
  partner = 'partner',
  seek = 'seek',
  shared = 'shared',
}

export type ProfilesByEmailInput = {
  /** @deprecated This is not required because we rely on advertiserId from the header */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

export type PromoCode = {
  __typename?: 'PromoCode';
  /** The campaign that the promo code belongs to */
  campaignId: Scalars['ID']['output'];
  /** The promo code value that a hirer can use on a product in order to get a discount */
  code: Scalars['String']['output'];
  /** The date and time when the promo code was created */
  createdAt: Scalars['DateTime']['output'];
  /** The discount amount or rate on a product after the promo code has been applied. Currently only applicable to discount rate */
  discount: PromoCodeDiscount;
  /** The date and time when the promo code was last updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The date and time when the promo code is valid from */
  validFrom: Scalars['DateTime']['output'];
  /** The products that the promo code can be applied to */
  validProducts: PromoCodeProduct[];
  /** The date and time when the promo code is valid to */
  validTo: Scalars['DateTime']['output'];
};

export type PromoCodeDiscount = PromoCodeDiscountRate;

export type PromoCodeDiscountRate = {
  __typename?: 'PromoCodeDiscountRate';
  /** The promo code discount rate in percentage up to two decimal places eg. 99.99 is equivalent to 99.99% */
  discountRate: Scalars['Float']['output'];
};

export type PromoCodeProduct = {
  __typename?: 'PromoCodeProduct';
  /** The product display name */
  displayName: Scalars['String']['output'];
  /** The product id */
  id: Scalars['ID']['output'];
};

export type Promotion = {
  __typename?: 'Promotion';
  code?: Maybe<Scalars['String']['output']>;
};

/** Error for invalid, expired, or not found promotion code. */
export type PromotionInvalidError = Error & {
  __typename?: 'PromotionInvalidError';
  message: Scalars['String']['output'];
};

export type PublishCPQAEventInput = {
  /** Advertiser name */
  accountName: Scalars['String']['input'];
  /** The job title */
  roleTitle: Scalars['String']['input'];
};

export type PublishCPQAEventPayload = {
  __typename?: 'PublishCPQAEventPayload';
  published?: Maybe<Scalars['Boolean']['output']>;
};

export type PublishJobAdBookingEventInput = {
  /**
   * The Seek advertiser/account id
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** The name of the company Hirer recruiting for */
  companyName: Scalars['String']['input'];
  /** The contact by time in UTC */
  contactBy: Scalars['String']['input'];
  /** The contact name for this particular request */
  contactName: Scalars['String']['input'];
  /** The Job Category ID */
  jobCategorySubClassificationId: Scalars['String']['input'];
  /** Two digit ISO country for location of job ad. */
  jobCountryCode: JobAdBookingCountryCode;
  /** The job location */
  jobLocation?: InputMaybe<Scalars['String']['input']>;
  /** The Job Location ID */
  jobLocationId: Scalars['String']['input'];
  /** The job title */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** The phone number where CS can contact Hirer on */
  phoneNumber: Scalars['String']['input'];
  /** The product ID */
  productId?: InputMaybe<Scalars['String']['input']>;
  /** The user email */
  userEmail: Scalars['String']['input'];
  /** Country code for the website where customer made the request. */
  websiteCountryCode: JobAdBookingCountryCode;
};

export type PublishJobAdBookingEventPayload = {
  __typename?: 'PublishJobAdBookingEventPayload';
  published?: Maybe<Scalars['Boolean']['output']>;
};

export enum PublishJobAdProductCode {
  /** The product we offer in AU, GUARANTEED_HIRE */
  AUSKGHRB = 'AUSKGHRB',
  /** The product we offer in NZ, CONCIERGE */
  CONCIERGE = 'CONCIERGE',
}

export type PublishJobPostedEventInput = {
  /**
   * The ID of the draft in Adcentre
   * @deprecated Use `draftId` instead
   */
  adcentreDraftId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the listing in Adcentre */
  adcentreListingId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the user in Adcentre */
  adcentreUserId: Scalars['Int']['input'];
  /**
   * The ID of the advertiser
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** The type of device used by the hirer to post their job */
  device: Scalars['String']['input'];
  /**
   * TODO: Make this below mandatory once frontend updated to post it
   * and remove the above adcentreDraftId
   */
  draftId?: InputMaybe<Scalars['String']['input']>;
  /** The experiment being run while this job was posted */
  experimentId?: InputMaybe<Scalars['String']['input']>;
  /** The experiment variant being run while this job was posted */
  experimentVariantId?: InputMaybe<Scalars['String']['input']>;
  /** Flow used to create/update the job, e.g. refresh, edit, create */
  flowType?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the job */
  jobId: Scalars['String']['input'];
  /** The experience the hirer used to post their job */
  jobPostingExperience: Scalars['String']['input'];
  /** The job writing flow that hirer has followed (guided or standard) */
  jobWritingFlow: Scalars['String']['input'];
};

export type PublishJobPostedEventPayload = {
  __typename?: 'PublishJobPostedEventPayload';
  jobId: Scalars['String']['output'];
};

export type PurchasableProducts =
  | PurchasableProductsList
  | PurchasableProductsString;

export type PurchasableProductsList = {
  __typename?: 'PurchasableProductsList';
  products?: Maybe<Array<Scalars['String']['output']>>;
};

export type PurchasableProductsString = {
  __typename?: 'PurchasableProductsString';
  product: Scalars['String']['output'];
};

export enum PurchaseContext {
  DIRECT_AD_PRODUCT_EDIT = 'DIRECT_AD_PRODUCT_EDIT',
  DIRECT_AD_PRODUCT_UPGRADE_EDIT = 'DIRECT_AD_PRODUCT_UPGRADE_EDIT',
}

export enum PurchaseContextUseCase {
  AddonsOnly = 'AddonsOnly',
  BrandingAddonsOnly = 'BrandingAddonsOnly',
  BudgetsOnly = 'BudgetsOnly',
}

export type Query = {
  __typename?: 'Query';
  /** Retrieves a list of credit cards for a particular user against an advertiser account either in 'AU' or 'NZ' country codes. */
  SavedStripeCreditCards?: Maybe<Array<Maybe<CreditCard>>>;
  _empty?: Maybe<Scalars['String']['output']>;
  _rateLimitingTestQuery?: Maybe<Scalars['String']['output']>;
  accountSelectionV3: AccountSelectionV3Response;
  /** Query to get ad budget products for upselling given list of product ids */
  adBudgetProductsForUpsell: AdBudgetProductsForUpsellResponse;
  /** Request to get adProducts for Ad Editing */
  adProductsForAdEditing: AdProductSetResponse;
  /** Request to get adProducts for Ad Posting */
  adProductsForAdPosting: AdProductSetResponse;
  /** Request to get upgrade products for Upgrade flow */
  adUpgradeProductsForAdUpgradePurchase: AdUpgradeProductSetResponse;
  /** Ad Performance Report query */
  analyticsAdPerformanceReport?: Maybe<AdPerformanceReportResponse>;
  /** Ad Rating query */
  analyticsAdRating?: Maybe<AdRatingResponse>;
  /** Ad Usage Trend ad type daily and monthly widget */
  analyticsAdTypeTrend?: Maybe<AdUsageAdTypeTrendResponse>;
  /** Ad Usage Conversion query */
  analyticsAdUsageConversion?: Maybe<AdUsageConversionResponse>;
  /** Ad Usage Filters query */
  analyticsAdUsageFilters?: Maybe<AdUsageFiltersResponse>;
  /** Ad Usage Last Updated query */
  analyticsAdUsageLastUpdated?: Maybe<LastUpdatedResponse>;
  /** Ad Usage Spend query */
  analyticsAdUsageSpend?: Maybe<AdUsageSpendResponse>;
  /** Ad Usage Tips widget */
  analyticsAdUsageTipsToImproveAds?: Maybe<TipsToImproveAdsResponse>;
  /** Application Performance query */
  analyticsApplicationPerformance?: Maybe<ApplicationPerformanceResponse>;
  /** Candidate Demand query */
  analyticsCandidateDemand?: Maybe<CandidateDemandResponse>;
  /** Candidate Supply query */
  analyticsCandidateSupply?: Maybe<CandidateSupplyResponse>;
  /** Ad Usage Child Account Banner query */
  analyticsChildAccountBanner?: Maybe<ChildAccountBannerResponse>;
  /** Ad Usage Trend classification and user widget */
  analyticsClassificationsAndUsersTrend?: Maybe<AdUsageTrendClassificationsAndUsersResponse>;
  /** Clicks on your ad query */
  analyticsClicksOnYourAd?: Maybe<ClicksOnYourAdResponse>;
  analyticsInsightsRoleAdInsightsTrends: AnalyticsInsightsRoleAdInsightsTrendsResponse;
  analyticsInsightsRoleAdPerformance: AnalyticsInsightsRoleAdPerformanceResponse;
  analyticsInsightsRoleAdsPosted: AnalyticsInsightsRoleAdsPostedResponse;
  analyticsInsightsRoleCandidateProfiles: AnalyticsInsightsRoleCandidateProfiles;
  analyticsInsightsRoleCompaniesPosting: AnalyticsInsightsRoleCompaniesPostingResponse;
  analyticsInsightsRoleCompetitionForTalent: AnalyticsInsightsRoleCompetitionForTalentResponse;
  analyticsInsightsRoleLastUpdated: AnalyticsInsightsRoleLastUpdatedResponse;
  analyticsInsightsRoleLocations: AnalyticsInsightsRoleLocation[];
  analyticsInsightsRoleNormalisedRoles: AnalyticsInsightsRoleNormalisedRolesResponse;
  analyticsInsightsRoleRelatedRoles: AnalyticsInsightsRoleRelatedRolesResponse;
  analyticsInsightsRoleSalaryDistribution: AnalyticsInsightsRoleSalaryDistributionResponse;
  analyticsInsightsRoleSalaryInsights: AnalyticsInsightsRoleSalaryInsightsResponse;
  analyticsInsightsRoleSalaryPrivacyCheck: AnalyticsInsightsRoleSalaryPrivacyCheckResponse;
  analyticsInsightsRoleSalaryRange: AnalyticsInsightsRoleSalaryRangeResponse;
  analyticsInsightsRoleUniqueApplicants: AnalyticsInsightsRoleUniqueApplicantsResponse;
  /** Job Ad List query */
  analyticsJobAdList?: Maybe<JobAdListResponse>;
  /** Market insights title query */
  analyticsMarketInsightsTitle?: Maybe<MarketInsightsTitleResponse>;
  /** Market Performance query */
  analyticsMarketPerformance?: Maybe<MarketPerformanceResponse>;
  /** Ad Usage Most Ads By Performance query */
  analyticsMostAdsByPerformance?: Maybe<MostAdsByPerformanceResponse>;
  /** Ad Details query */
  analyticsMyJobInformation?: Maybe<MyJobInformationResponse>;
  /** Page Title Actions query */
  analyticsPageTitleActions?: Maybe<PageTitleActionsResponse>;
  /** Performance Prediction query */
  analyticsPerformancePrediction?: Maybe<PerformancePredictionResponse>;
  /** Recommendations query */
  analyticsRecommendations?: Maybe<RecommendationsResponse>;
  /** Similar Ads query */
  analyticsSimilarAdsDetails?: Maybe<SimilarAdsResponse>;
  analyticsTalentSearchUsageConversion: AnalyticsTalentSearchUsageConversionResponse;
  analyticsTalentSearchUsageFilters?: Maybe<AnalyticsTalentSearchUsageFiltersResponse>;
  analyticsTalentSearchUsageLastUpdated?: Maybe<AnalyticsTalentSearchUsageLastUpdatedResponse>;
  analyticsTalentSearchUsageOverview: AnalyticsTalentSearchUsageOverviewResponse;
  analyticsTalentSearchUsageTrendsByAccount: AnalyticsTalentSearchUsageTrendsByAccountResponse;
  analyticsTalentSearchUsageTrendsByTime: AnalyticsTalentSearchUsageTrendsByTimeResponse;
  analyticsTalentSearchUsageUsersList?: Maybe<AnalyticsTalentSearchUsageUsersListResponse>;
  /** Views in Job Search query */
  analyticsViewsInJobSearch?: Maybe<ViewsInJobSearchResponse>;
  application: ApplicationResponse;
  applicationAttachmentsV2: ApplicationAttachmentsV2Response;
  applicationByAdcentreProspectId: ApplicationResponse;
  applicationEmails: ApplicationEmails;
  applicationNotesV2: ApplicationNotesResponse;
  applications: ApplicationsResponse;
  applicationsFacets: ApplicationsFacetsResponse;
  brand?: Maybe<Brand>;
  brandingAssets?: Maybe<Array<Maybe<BrandingAsset>>>;
  brands?: Maybe<Array<Maybe<Brand>>>;
  cart?: Maybe<Cart>;
  companyProfile?: Maybe<CompanyProfile>;
  companyProfileAction?: Maybe<CompanyProfileAction>;
  companyProfileIds?: Maybe<CompanyProfileIds>;
  contractBalanceGroups: ContractBalanceGroup[];
  contractBalances: ContractBalance[];
  countrySelectionOptions: CountrySelectionOptions;
  currentAdvertiser: CurrentAdvertiserResponse;
  currentUser: CurrentUserResponse;
  dashboardJobs: DashboardJobsResponse;
  dashboardJobsCounts: DashboardJobsCountsResponse;
  defaultBrand?: Maybe<Brand>;
  draftJob?: Maybe<DraftJobPayload>;
  draftJobs: JobsResponse;
  /** Email address of the user. */
  email: Scalars['String']['output'];
  /** Email verification status of the user. */
  emailVerified: Scalars['Boolean']['output'];
  /** Fetches all feature flags values for keys provided */
  featureFlags: Array<Maybe<Feature>>;
  /** @deprecated Use job query */
  getJobDetails?: Maybe<Job>;
  getPaymentConfig?: Maybe<PaymentConfigPayload>;
  getPaymentMethodForMaintenance?: Maybe<PaymentMethodPayload>;
  /** Request to get product set */
  getProductSet: GetProductSetResponse;
  /** Get the surcharge amount for amex payment method */
  getSurchargeAmount: GetSurchargeAmountPayload;
  headerFooterContext?: Maybe<HeaderFooterContext>;
  /** Summary of account details from hirer accounts API */
  hirerAccount?: Maybe<HirerAccount>;
  /**
   * Find the user associated with given User ID and AdCentre Advertiser ID
   * @deprecated Use hirerUser instead.
   */
  hirerAccountUser: UserDetails;
  /** List all active and pending users tied to the Hirer account with their access permission information to the job. */
  hirerAccountUsersWithJobPermissions: UserWithJobPermission[];
  /** List of hirer accounts associated with the user */
  hirerAccounts: HirerAccountsResult;
  /** Get hirer activities in pagination for a specific job order by most recent activity. Currently only add/delete notes on candidate */
  hirerActivitiesByJob: HirerActivityConnection;
  hirerDashboardBudgetUpsell: HirerDashboardBudgetUpsellResponse;
  hirerDashboardHiringAdviceList?: Maybe<HirerDashboardHiringAdviceList>;
  hirerDashboardTalentSearch: HirerDashboardTalentSearch;
  hirerNotificationPreferences: HirerNotificationPreferenceCategory[];
  hirerOriginZone?: Maybe<Scalars['String']['output']>;
  /** Finds the hirer user associated with the given id */
  hirerUser: HirerUserDetails;
  /** Finds the user associated with the given email */
  hirerUserEmailV2: HirerUserEmailPayloadV2;
  /**
   * Identity of the currently authenticated user.
   * @deprecated Use email and emailVerified queries
   */
  identity: Identity;
  /**
   * Fetches a list of invoices based on filters. Handles "load more" functionality.
   * Pagination is not currently supported.
   */
  invoices: InvoiceData;
  isImagePublished?: Maybe<Scalars['Boolean']['output']>;
  /** Is the user enroled for multi-factor authentication. */
  isMfaEnroled?: Maybe<Scalars['Boolean']['output']>;
  isPaymentLinkInvoicePaid: PaymentLinkInvoicePaidResponse;
  job: JobResponse;
  jobs: JobsResponse;
  localisedContactInfoV2: LocalisedContactInfo;
  location?: Maybe<Location>;
  locations: Array<Maybe<LocationSearchResult>>;
  locationsByIds: Array<Maybe<Location>>;
  /** Get notification preference for current logged in user for a specific job */
  notificationPreference: NotificationPreference;
  order?: Maybe<Order>;
  orderSummary?: Maybe<OrderSummary>;
  payment2c2pInquiry?: Maybe<Payment2c2pInquiryPayload>;
  payment2c2pInquiryForInvoices?: Maybe<Payment2c2pInquiryPayload>;
  previousApplications: PreviousApplicationsResponse;
  /**
   * Retrieves a list of promo codes. If a productSetId is provided, the valid product display names
   * in the promo codes will be updated based on the specified product set.
   *
   * - productSetId (optional): The ID of the product set to use for updating valid product display names.
   */
  promoCodes: PromoCode[];
  questionnaire: Questionnaire;
  registrationOptions: RegistrationOptions;
  registrationStatus: RegistrationStatusInfo;
  retrievePaymentIntent?: Maybe<RetrievePaymentIntentPayload>;
  roleReqsQuestionsByKeyword: RoleReqsQuestionsByKeywordResponse;
  /**
   * Searches for integration partners.
   *
   * The search can be filtered by a partners name, status, and release notes status.
   *
   * Valid values for status and releaseNotesStatus inputs are 'Published' and 'Draft'.
   */
  searchIntegrationPartners: IntegrationPartner[];
  /** Query to get state information based on country code */
  stateByCountry: State[];
  suggestedCategories: CategorySuggestion[];
  suggestedSalary?: Maybe<SuggestedSalary>;
  talentSearchATSJobs?: Maybe<TalentSearchATSJobsConnection>;
  talentSearchAccounts?: Maybe<TalentSearchAccounts>;
  talentSearchAssignedPools: TalentSearchAssignedPools[];
  /** Fetches available jobs that has not been sent to the candidate (Version 2). */
  talentSearchAvailableJobsV2: TalentSearchAvailableJobV2[];
  /** Fetches all jobs for an advertiser id. */
  talentSearchBudgetBalance: TalentSearchBudgetBalance;
  talentSearchConnectionOptions: ConnectionOptions;
  talentSearchConnectionStatus: ConnectionStatusPayload;
  talentSearchCvUrl: Scalars['String']['output'];
  talentSearchDownloadProfile: DownloadProfileResponse;
  talentSearchInteractions?: Maybe<TalentSearchInteractionsConnection>;
  /** Fetch a job for an advertiser. */
  talentSearchJob?: Maybe<TalentSearchJobV2>;
  /** Fetches all jobs for an advertiser id (Version 2). */
  talentSearchJobsV2?: Maybe<TalentSearchJobsV2>;
  talentSearchLatestInteractions: TalentSearchLatestInteraction[];
  talentSearchMessageDetails: TalentSearchMessageDetails;
  talentSearchMetadataCompanySuggest: Array<Scalars['String']['output']>;
  talentSearchMetadataIndustries: TalentSearchMetadataIndustry[];
  talentSearchMetadataMarkets: TalentSearchMarket[];
  talentSearchMetadataQueryValidations?: Maybe<TalentSearchMetadataQueryValidations>;
  /** Get all SEEK, Combined or Partner profiles added to a pool */
  talentSearchPoolCandidatesV2: TalentSearchProfileResultsV2;
  talentSearchPools: TalentSearchPool[];
  /** Get profile resume for SEEK, Combined or Partner profile */
  talentSearchProfileResume: TalentSearchProfileResumeResult;
  /** Get details for SEEK, Combined or Partner profile V2 */
  talentSearchProfileV2: TalentSearchProfileResultV2;
  /** Get details for SEEK profile by email */
  talentSearchProfilesByEmail: TalentSearchProfileResultsV2;
  /** Get recommended SEEK candidates powered by CQs recommendation algorithm for a job */
  talentSearchRecommendedCandidatesV2?: Maybe<TalentSearchProfileResultsV2>;
  talentSearchSavedSearches: TalentSearchSavedSearch[];
  talentSearchSendJobStatus: TalentSearchProfileSendJobStatus[];
  /** Get matching similar candidates for SEEK profile */
  talentSearchSimilarCandidatesByIdV2?: Maybe<TalentSearchSimilarCandidatesResultV2>;
  /** Get profiles count for uncoupled search query V2 */
  talentSearchUncoupledCountForQueryV2: Scalars['Int']['output'];
  /** Get V2 search results facets for uncoupled search query */
  talentSearchUncoupledCountsV2: TalentSearchCountsResults;
  /** Get search results for uncoupled search query with unified requirement */
  talentSearchUncoupledSearchV2: TalentSearchProfileResultsV2;
  /** Resolves user context data and relies on advertiserId in authorisation header. */
  talentSearchUserContext: TalentSearchUserContext;
  /** Fetches all feature flags for the current user */
  talentSearchUserFeatures: Array<Maybe<TalentSearchFeature>>;
  uploadPresignedUrlForCandidateSupportDoc: UploadPresignedUrlForCandidateSupportDocResponse;
  validatePromoCode: ValidatePromoCodeResponse;
  yourCandidates: YourCandidatesResponse;
};

export type QuerySavedStripeCreditCardsArgs = {
  countryCode: Scalars['String']['input'];
};

export type QueryadBudgetProductsForUpsellArgs = {
  input: AdBudgetProductsForUpsellInput;
};

export type QueryadProductsForAdEditingArgs = {
  input: AdProductsForAdEditingInput;
};

export type QueryadProductsForAdPostingArgs = {
  input: AdProductsForAdPostingInput;
};

export type QueryadUpgradeProductsForAdUpgradePurchaseArgs = {
  input: AdUpgradeProductsForUpgradePurchaseInput;
};

export type QueryanalyticsAdPerformanceReportArgs = {
  input: AdPerformanceReportWidgetInput;
};

export type QueryanalyticsAdRatingArgs = {
  input: AdRatingWidgetInput;
};

export type QueryanalyticsAdTypeTrendArgs = {
  input: AdUsageAdTypeTrendInput;
};

export type QueryanalyticsAdUsageConversionArgs = {
  input: AdUsageConversionWidgetInput;
};

export type QueryanalyticsAdUsageFiltersArgs = {
  input: AdUsageFiltersWidgetInput;
};

export type QueryanalyticsAdUsageSpendArgs = {
  input: AdUsageSpendWidgetInput;
};

export type QueryanalyticsAdUsageTipsToImproveAdsArgs = {
  input: TipsToImproveAdsWidgetInput;
};

export type QueryanalyticsApplicationPerformanceArgs = {
  input: ApplicationPerformanceWidgetInput;
};

export type QueryanalyticsCandidateDemandArgs = {
  input: CandidateDemandWidgetInput;
};

export type QueryanalyticsCandidateSupplyArgs = {
  input: CandidateSupplyWidgetInput;
};

export type QueryanalyticsClassificationsAndUsersTrendArgs = {
  input: AdUsageTrendClassificationsAndUsersInput;
};

export type QueryanalyticsClicksOnYourAdArgs = {
  input: ClicksOnYourAdWidgetInput;
};

export type QueryanalyticsInsightsRoleAdInsightsTrendsArgs = {
  input: AnalyticsInsightsRoleReportInput;
};

export type QueryanalyticsInsightsRoleAdPerformanceArgs = {
  input: AnalyticsInsightsRoleReportInput;
};

export type QueryanalyticsInsightsRoleAdsPostedArgs = {
  input: AnalyticsInsightsRoleReportInput;
};

export type QueryanalyticsInsightsRoleCandidateProfilesArgs = {
  input: AnalyticsInsightsRoleCandidateProfilesInput;
};

export type QueryanalyticsInsightsRoleCompaniesPostingArgs = {
  input: AnalyticsInsightsRoleReportInput;
};

export type QueryanalyticsInsightsRoleCompetitionForTalentArgs = {
  input: AnalyticsInsightsRoleReportInput;
};

export type QueryanalyticsInsightsRoleLocationsArgs = {
  input: AnalyticsInsightsRoleLocationsInput;
};

export type QueryanalyticsInsightsRoleNormalisedRolesArgs = {
  input: AnalyticsInsightsRoleNormalisedRolesInput;
};

export type QueryanalyticsInsightsRoleRelatedRolesArgs = {
  input: AnalyticsInsightsRoleRelatedRolesInput;
};

export type QueryanalyticsInsightsRoleSalaryDistributionArgs = {
  reportInput: AnalyticsInsightsRoleReportInput;
  salaryInsightsInput: AnalyticsInsightsRoleSalaryInsightsInput;
};

export type QueryanalyticsInsightsRoleSalaryInsightsArgs = {
  reportInput: AnalyticsInsightsRoleReportInput;
  salaryInsightsInput: AnalyticsInsightsRoleSalaryInsightsInput;
};

export type QueryanalyticsInsightsRoleSalaryPrivacyCheckArgs = {
  reportInput: AnalyticsInsightsRoleReportInput;
  salaryInsightsInput: AnalyticsInsightsRoleSalaryInsightsInput;
};

export type QueryanalyticsInsightsRoleSalaryRangeArgs = {
  reportInput: AnalyticsInsightsRoleReportInput;
  salaryInsightsInput: AnalyticsInsightsRoleSalaryInsightsInput;
};

export type QueryanalyticsInsightsRoleUniqueApplicantsArgs = {
  input: AnalyticsInsightsRoleReportInput;
};

export type QueryanalyticsJobAdListArgs = {
  input: JobAdListWidgetInput;
};

export type QueryanalyticsMarketInsightsTitleArgs = {
  input: MarketInsightsTitleWidgetInput;
};

export type QueryanalyticsMarketPerformanceArgs = {
  input: MarketPerformanceWidgetInput;
};

export type QueryanalyticsMostAdsByPerformanceArgs = {
  input: MostAdsByPerformanceWidgetInput;
};

export type QueryanalyticsMyJobInformationArgs = {
  input: MyJobInformationWidgetInput;
};

export type QueryanalyticsPageTitleActionsArgs = {
  input: PageTitleActionsWidgetInput;
};

export type QueryanalyticsPerformancePredictionArgs = {
  input: PerformancePredictionWidgetInput;
};

export type QueryanalyticsRecommendationsArgs = {
  input: RecommendationsWidgetInput;
};

export type QueryanalyticsSimilarAdsDetailsArgs = {
  input: SimilarAdsWidgetInput;
};

export type QueryanalyticsTalentSearchUsageConversionArgs = {
  input: AnalyticsTalentSearchUsageConversionInput;
};

export type QueryanalyticsTalentSearchUsageFiltersArgs = {
  input: AnalyticsTalentSearchUsageFiltersInput;
};

export type QueryanalyticsTalentSearchUsageOverviewArgs = {
  input: AnalyticsTalentSearchUsageOverviewInput;
};

export type QueryanalyticsTalentSearchUsageTrendsByAccountArgs = {
  input: AnalyticsTalentSearchUsageTrendsByAccountInput;
};

export type QueryanalyticsTalentSearchUsageTrendsByTimeArgs = {
  input: AnalyticsTalentSearchUsageTrendsByTimeInput;
};

export type QueryanalyticsTalentSearchUsageUsersListArgs = {
  input: AnalyticsTalentSearchUsageUsersListInput;
};

export type QueryanalyticsViewsInJobSearchArgs = {
  input: ViewsInJobSearchWidgetInput;
};

export type QueryapplicationArgs = {
  input: ApplicationInput;
};

export type QueryapplicationAttachmentsV2Args = {
  input: ApplicationAttachmentsV2Input;
};

export type QueryapplicationByAdcentreProspectIdArgs = {
  input: ApplicationByAdcentreProspectIdInput;
};

export type QueryapplicationEmailsArgs = {
  input: ApplicationEmailsInput;
};

export type QueryapplicationNotesV2Args = {
  input: ApplicationNotesInput;
};

export type QueryapplicationsArgs = {
  input: ApplicationsInput;
};

export type QueryapplicationsFacetsArgs = {
  input: ApplicationsFacetsInput;
};

export type QuerybrandArgs = {
  id: Scalars['ID']['input'];
  owner?: InputMaybe<BrandOwnerInput>;
};

export type QuerybrandingAssetsArgs = {
  input: GetBrandingAssetsInput;
};

export type QuerybrandsArgs = {
  owner?: InputMaybe<BrandOwnerInput>;
};

export type QuerycartArgs = {
  cartId: Scalars['ID']['input'];
};

export type QuerycompanyProfileArgs = {
  id: Scalars['ID']['input'];
};

export type QuerycontractBalancesArgs = {
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
};

export type QuerydashboardJobsArgs = {
  input?: InputMaybe<DashboardJobsInput>;
};

export type QuerydefaultBrandArgs = {
  owner?: InputMaybe<BrandOwnerInput>;
};

export type QuerydraftJobArgs = {
  id: Scalars['ID']['input'];
};

export type QuerydraftJobsArgs = {
  input: DraftJobsInput;
};

export type QueryfeatureFlagsArgs = {
  keys: Array<Scalars['String']['input']>;
};

export type QuerygetJobDetailsArgs = {
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  jobId: Scalars['String']['input'];
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type QuerygetPaymentConfigArgs = {
  country: Scalars['String']['input'];
  site: Scalars['String']['input'];
  useCase?: InputMaybe<UseCase>;
};

export type QuerygetPaymentMethodForMaintenanceArgs = {
  country: Scalars['String']['input'];
  site: Scalars['String']['input'];
};

export type QuerygetProductSetArgs = {
  input: GetProductSetInput;
};

export type QuerygetSurchargeAmountArgs = {
  input: GetSurchargeAmountInput;
};

export type QueryheaderFooterContextArgs = {
  input?: InputMaybe<HeaderFooterContextInput>;
};

export type QueryhirerAccountArgs = {
  advertiserId?: InputMaybe<Scalars['Int']['input']>;
  usersParams?: InputMaybe<UsersParamsInput>;
};

export type QueryhirerAccountUserArgs = {
  input: HirerAccountUserQueryInput;
};

export type QueryhirerAccountUsersWithJobPermissionsArgs = {
  input: UsersWithJobPermissionInput;
};

export type QueryhirerActivitiesByJobArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input: HirerActivitiesByJobInput;
};

export type QueryhirerDashboardBudgetUpsellArgs = {
  input: HirerDashboardBudgetUpsellInput;
};

export type QueryhirerNotificationPreferencesArgs = {
  locale: Scalars['String']['input'];
  zone: Scalars['String']['input'];
};

export type QueryhirerUserArgs = {
  input: HirerUserQueryInput;
};

export type QueryhirerUserEmailV2Args = {
  input: HirerUserEmailInputV2;
};

export type QueryinvoicesArgs = {
  filters?: InputMaybe<InvoiceFiltersInput>;
};

export type QueryisImagePublishedArgs = {
  id: Scalars['String']['input'];
};

export type QueryisPaymentLinkInvoicePaidArgs = {
  input: PaymentLinkInvoicePaidInput;
};

export type QueryjobArgs = {
  input: JobInput;
};

export type QueryjobsArgs = {
  input: JobsInput;
};

export type QuerylocalisedContactInfoV2Args = {
  input: LocalisedContactInfoInput;
};

export type QuerylocationArgs = {
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type QuerylocationsArgs = {
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  context?: InputMaybe<LocationContext>;
  countries?: InputMaybe<CountriesInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  excludeLocations?: InputMaybe<Array<Scalars['ID']['input']>>;
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  queryText?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerylocationsByIdsArgs = {
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type QuerynotificationPreferenceArgs = {
  advertiserId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
};

export type QueryorderArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryorderSummaryArgs = {
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
};

export type Querypayment2c2pInquiryArgs = {
  currency: Scalars['String']['input'];
  paymentToken?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type Querypayment2c2pInquiryForInvoicesArgs = {
  currency: Scalars['String']['input'];
  paymentToken?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type QuerypreviousApplicationsArgs = {
  input: PreviousApplicationsInput;
};

export type QuerypromoCodesArgs = {
  productSetId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryquestionnaireArgs = {
  adcentreAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type QueryregistrationStatusArgs = {
  input?: InputMaybe<RegistrationStatusInput>;
};

export type QueryretrievePaymentIntentArgs = {
  country: Scalars['String']['input'];
  id: Scalars['String']['input'];
  paymentMethodType?: InputMaybe<StripePaymentMethod>;
};

export type QueryroleReqsQuestionsByKeywordArgs = {
  input: RoleReqsQuestionsByKeywordInput;
};

export type QuerysearchIntegrationPartnersArgs = {
  input?: InputMaybe<SearchIntegrationPartnersInput>;
};

export type QuerystateByCountryArgs = {
  countryCode: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
};

export type QuerysuggestedCategoriesArgs = {
  country: Scalars['String']['input'];
  jobOrDraftId?: InputMaybe<Scalars['Int']['input']>;
  jobTitle: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  maxResults?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerysuggestedSalaryArgs = {
  countryCode: Scalars['String']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
  roleTitle: Scalars['String']['input'];
  salaryType?: InputMaybe<SalaryType>;
  workType?: InputMaybe<WorkType>;
};

export type QuerytalentSearchATSJobsArgs = {
  input: TalentSearchATSJobsInput;
};

export type QuerytalentSearchAccountsArgs = {
  input?: InputMaybe<TalentSearchAccountsInput>;
};

export type QuerytalentSearchAssignedPoolsArgs = {
  input?: InputMaybe<TalentSearchAssignedPoolsInput>;
};

export type QuerytalentSearchAvailableJobsV2Args = {
  input: TalentSearchAvailableJobsInput;
};

export type QuerytalentSearchConnectionOptionsArgs = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
  profileId: Scalars['Int']['input'];
};

export type QuerytalentSearchConnectionStatusArgs = {
  connectionId: Scalars['String']['input'];
};

export type QuerytalentSearchCvUrlArgs = {
  profileId: Scalars['Int']['input'];
};

export type QuerytalentSearchDownloadProfileArgs = {
  profileId: Scalars['Int']['input'];
};

export type QuerytalentSearchInteractionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  profileId: Scalars['Int']['input'];
};

export type QuerytalentSearchJobArgs = {
  input: TalentSearchJobInput;
};

export type QuerytalentSearchJobsV2Args = {
  input?: InputMaybe<TalentSearchJobsInput>;
};

export type QuerytalentSearchLatestInteractionsArgs = {
  profileIds: Array<Scalars['Int']['input']>;
};

export type QuerytalentSearchMessageDetailsArgs = {
  connectionId: Scalars['String']['input'];
};

export type QuerytalentSearchMetadataCompanySuggestArgs = {
  input?: InputMaybe<TalentSearchMetadataCompanySuggestInput>;
};

export type QuerytalentSearchMetadataIndustriesArgs = {
  input?: InputMaybe<TalentSearchMetadataIndustriesInput>;
};

export type QuerytalentSearchMetadataMarketsArgs = {
  input?: InputMaybe<TalentSearchMetadataMarketsInput>;
};

export type QuerytalentSearchMetadataQueryValidationsArgs = {
  input?: InputMaybe<TalentSearchMetadataQueryValidationsInput>;
};

export type QuerytalentSearchPoolCandidatesV2Args = {
  input: TalentSearchPoolCandidatesInputV2;
};

export type QuerytalentSearchProfileResumeArgs = {
  input: TalentSearchProfileResumeInput;
};

export type QuerytalentSearchProfileV2Args = {
  input: TalentSearchProfileInput;
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type QuerytalentSearchProfilesByEmailArgs = {
  input: ProfilesByEmailInput;
};

export type QuerytalentSearchRecommendedCandidatesV2Args = {
  input: TalentSearchRecommendedCandidatesInputV2;
};

export type QuerytalentSearchSendJobStatusArgs = {
  profileIds: Array<Scalars['Int']['input']>;
};

export type QuerytalentSearchSimilarCandidatesByIdV2Args = {
  input: TalentSearchSimilarCandidatesByIdInputV2;
};

export type QuerytalentSearchUncoupledCountForQueryV2Args = {
  input: TalentSearchUncoupledCountForQueryInputV2;
};

export type QuerytalentSearchUncoupledCountsV2Args = {
  input: TalentSearchUncoupledCountForQueryInputV2;
};

export type QuerytalentSearchUncoupledSearchV2Args = {
  input: TalentSearchUncoupledSearchInputV2;
};

export type QuerytalentSearchUserContextArgs = {
  input?: InputMaybe<TalentSearchUserContextInput>;
};

export type QueryuploadPresignedUrlForCandidateSupportDocArgs = {
  input: UploadPresignedUrlForCandidateSupportDocInput;
};

export type QueryvalidatePromoCodeArgs = {
  input: ValidatePromoCodeInput;
};

export type QueryyourCandidatesArgs = {
  input: YourCandidatesInput;
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  id: Scalars['String']['output'];
  published: Scalars['Boolean']['output'];
  questions?: Maybe<QuestionnaireQuestion[]>;
};

export type QuestionnairequestionsArgs = {
  input?: InputMaybe<QuestionnaireQuestionFilter>;
};

export type QuestionnaireAnswer = {
  __typename?: 'QuestionnaireAnswer';
  /** RR Answer Global ID */
  globalId?: Maybe<Scalars['String']['output']>;
  /**
   * RR Answer Local ID
   * @deprecated Use globalId instead. This id will be retired from FEB 13 2023. This value will only be returned for some legacy jobs
   */
  id?: Maybe<Scalars['String']['output']>;
  /** If true, represents that this answer is the inverse or 'negative' option to its siblings */
  isNegative: Scalars['Boolean']['output'];
  /** If true, represents that this answer is a preferred answer for the hirer */
  isPreferred: Scalars['Boolean']['output'];
  /** The display order of this answer in relation to that of its siblings */
  order: Scalars['Int']['output'];
  /** A statement that represents this answer */
  text: Scalars['String']['output'];
};

export type QuestionnaireFilter = {
  answerIds: Array<Scalars['ID']['input']>;
  operator: QuestionnaireFilterOperator;
  questionId: Scalars['ID']['input'];
};

export enum QuestionnaireFilterOperator {
  AND = 'AND',
  OR = 'OR',
}

export type QuestionnaireQuestion = {
  __typename?: 'QuestionnaireQuestion';
  /** The answer's type. e.g MultiSelect, SingleSelect, FreeText, UrlSingleSelect */
  answerType: Scalars['String']['output'];
  /** The answers to a question */
  answers?: Maybe<QuestionnaireAnswer[]>;
  /** If true represents that this question is a must-have requirement */
  essential?: Maybe<Scalars['Boolean']['output']>;
  /** RR Question Global ID */
  globalId?: Maybe<Scalars['String']['output']>;
  hirerExperience?: Maybe<HirerExperience>;
  /**
   * RR Question Local ID
   * @deprecated Use globalId instead. This id will be retired from FEB 13 2023. This value will only be returned for some legacy jobs
   */
  id?: Maybe<Scalars['String']['output']>;
  /** The display order of this question in relation to that of its siblings */
  order: Scalars['Int']['output'];
  /** Whether a question is library or custom */
  source: Scalars['String']['output'];
  /** A shorter, summarised version of the question being asked */
  statement?: Maybe<Scalars['String']['output']>;
  /** The question being asked */
  text: Scalars['String']['output'];
};

export enum QuestionnaireQuestionCategory {
  SALARY = 'SALARY',
}

export type QuestionnaireQuestionFilter = {
  category?: InputMaybe<QuestionnaireQuestionCategory>;
};

export type Range = {
  __typename?: 'Range';
  maximum: Scalars['Int']['output'];
  minimum: Scalars['Int']['output'];
};

export type RatingItem = {
  __typename?: 'RatingItem';
  description: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
};

export type Reason = {
  __typename?: 'Reason';
  description: Scalars['String']['output'];
  flag: Scalars['String']['output'];
};

export type Recommendation =
  | BrandedAd
  | GranularLocation
  | LowApply
  | LowClick
  | LowSalary
  | RevealSalary
  | RoleRequirements
  | TalentSearch;

export enum RecommendationType {
  BRANDED_AD = 'BRANDED_AD',
  GRANULAR_LOCATION = 'GRANULAR_LOCATION',
  LOW_APPLY = 'LOW_APPLY',
  LOW_CLICK = 'LOW_CLICK',
  LOW_SALARY = 'LOW_SALARY',
  REVEAL_SALARY = 'REVEAL_SALARY',
  ROLE_REQUIREMENTS = 'ROLE_REQUIREMENTS',
  TALENT_SEARCH = 'TALENT_SEARCH',
}

export type RecommendationsResponse = {
  __typename?: 'RecommendationsResponse';
  isExpired: Scalars['Boolean']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  postTypeCategory: AnalyticsPostTypeCategory;
  recommendations: Recommendation[];
};

export type RecommendationsWidgetInput = {
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
};

export type RedirectUrlInput = {
  /** Language param to apply to the URL. */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Return URI to redirect to after selecting an account. */
  returnUri?: InputMaybe<Scalars['String']['input']>;
};

export type RefreshCartProductSetErrorPayload = {
  __typename?: 'RefreshCartProductSetErrorPayload';
  message: Scalars['String']['output'];
};

export type RefreshCartProductSetResultPayload =
  | CartNotFoundError
  | ProductSetNotFoundError
  | RefreshCartProductSetSuccessPayload;

export type RefreshCartProductSetSuccessPayload = {
  __typename?: 'RefreshCartProductSetSuccessPayload';
  cart: Cart;
  hasCartItemsBeenRemoved: Scalars['Boolean']['output'];
  hasPriceChanged: Scalars['Boolean']['output'];
};

export type RefreshCartPromoCodeInput = {
  cartId: Scalars['ID']['input'];
  promoCode: Scalars['String']['input'];
};

export type RefreshJobInput = {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
};

export type RefreshJobResponse = {
  __typename?: 'RefreshJobResponse';
  /** The key used to refresh the job */
  idempotencyKey: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  /** The id of the revision that was created for the job */
  revisionId: Scalars['String']['output'];
};

export type RegisterAdditionalAdvertiserInput = {
  /** New advertiser record to persist */
  advertiser: NewAdvertiser;
  /** Redirection uri for after signing into new account. */
  returnUri?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterNewUserInput = {
  /** New advertiser record to persist */
  advertiser: NewAdvertiser;
  /** Language param to apply additional localisation to auth related pages. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Redirection uri for after signing into new account. */
  returnUri?: InputMaybe<Scalars['String']['input']>;
  /** New user record to persist */
  user: NewUser;
};

export type RegisterPayload = {
  __typename?: 'RegisterPayload';
  /** Adcentre advertiser ID of new advertiser */
  advertiserId: Scalars['ID']['output'];
  /** Unified URL to sign into the advertiser account. Undefined for additional advertiser registrations. */
  redirectUrl?: Maybe<Scalars['String']['output']>;
  /** SEEK ID of new advertiser */
  seekId: Scalars['ID']['output'];
};

export type RegistrationOptions = {
  __typename?: 'RegistrationOptions';
  /** Allows customer service to register new businesses on clients behalf */
  allowAgencyRegistration: Scalars['Boolean']['output'];
};

export type RegistrationStatusInfo = {
  __typename?: 'RegistrationStatusInfo';
  /** Indicates whether the user exists in Adcentre DB. */
  userExists: Scalars['Boolean']['output'];
};

export type RegistrationStatusInput = {
  /** Filter to restrict returned results on user status */
  filter?: InputMaybe<Scalars['String']['input']>;
};

export type ReinstateInput = {
  /** The ID of the user to be reinstated */
  userId: Scalars['Int']['input'];
};

export type ReinstateResponse = {
  __typename?: 'ReinstateResponse';
  /** Response message we receive from requesting to reinstate a user status */
  message: Scalars['String']['output'];
};

export enum RelationshipStatus {
  pending = 'pending',
  rejected = 'rejected',
}

export type RemoveApplicationNoteInput = {
  noteId: Scalars['ID']['input'];
  prospectId: Scalars['ID']['input'];
};

export type RemoveApplicationNotePayload = {
  __typename?: 'RemoveApplicationNotePayload';
  id: Scalars['ID']['output'];
};

export type RemoveCartItemInput = {
  cartId: Scalars['ID']['input'];
  referenceId: Scalars['String']['input'];
};

export type RemoveCartItemResultPayload =
  | CartNotFoundError
  | RemoveCartItemSuccessPayload;

export type RemoveCartItemSuccessPayload = {
  __typename?: 'RemoveCartItemSuccessPayload';
  cart: Cart;
};

export type RemoveCartPromoCodeInput = {
  cartId: Scalars['ID']['input'];
};

export type RemoveCartPromoCodeResultPayload =
  | CartNotFoundError
  | RemoveCartPromoCodeSuccessPayload;

export type RemoveCartPromoCodeSuccessPayload = {
  __typename?: 'RemoveCartPromoCodeSuccessPayload';
  cart: Cart;
};

export type RemoveUserFromAccountInput = {
  /** The ID of the user to be removed */
  userId: Scalars['Int']['input'];
};

export type RepostOption = {
  __typename?: 'RepostOption';
  count: Scalars['Int']['output'];
  key: AdRepost;
};

export type RequestIntegrationRelationshipsFailure = {
  __typename?: 'RequestIntegrationRelationshipsFailure';
  error: Scalars['String']['output'];
};

export type RequestIntegrationRelationshipsFollowUpQuestionInput = {
  answer: Scalars['String']['input'];
  followUpAnswer?: InputMaybe<Scalars['String']['input']>;
};

export type RequestIntegrationRelationshipsInput = {
  contactName: Scalars['String']['input'];
  futureApplications?: InputMaybe<RequestIntegrationRelationshipsFollowUpQuestionInput>;
  futurePosting?: InputMaybe<RequestIntegrationRelationshipsFollowUpQuestionInput>;
  goLiveDate: Scalars['String']['input'];
  hirerAccountIds: Array<Scalars['ID']['input']>;
  partnerId: Scalars['ID']['input'];
  relationshipTypeCodes: IntegrationRelationshipTypeEnum[];
};

export type RequestIntegrationRelationshipsPayload =
  | RequestIntegrationRelationshipsFailure
  | RequestIntegrationRelationshipsSuccess;

export type RequestIntegrationRelationshipsSuccess = {
  __typename?: 'RequestIntegrationRelationshipsSuccess';
  partnerId: Scalars['ID']['output'];
  relationships: RequestedIntegrationRelationship[];
};

export type RequestedIntegrationRelationship =
  | ApprovedIntegrationRelationship
  | PendingIntegrationRelationship;

export type ResendAccountActivationEmailInput = {
  /** Hirer Account to be serviced by the activation */
  hirerAccountId: Scalars['String']['input'];
};

export type ResendAccountActivationEmailResponse =
  | ResendAccountActivationEmailResponseSuccess
  | ResponseError;

export type ResendAccountActivationEmailResponseSuccess = {
  __typename?: 'ResendAccountActivationEmailResponseSuccess';
  hirerAccountId: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  resent: Scalars['Boolean']['output'];
};

export type ResponseError = {
  __typename?: 'ResponseError';
  error: ResponseErrorReason;
};

export enum ResponseErrorReason {
  BAD_INPUT = 'BAD_INPUT',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

export type ResumeEducation = {
  __typename?: 'ResumeEducation';
  displayDate?: Maybe<Scalars['String']['output']>;
  institutionName?: Maybe<Scalars['String']['output']>;
  qualificationName?: Maybe<Scalars['String']['output']>;
};

export type ResumeWorkHistory = {
  __typename?: 'ResumeWorkHistory';
  companyName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayDescription: ResumeWorkHistoryDisplayDescription[];
  duration?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  foundInCV: Scalars['Boolean']['output'];
  highlight?: Maybe<WorkHistoryHighlight>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type ResumeWorkHistoryDisplayDescription = {
  __typename?: 'ResumeWorkHistoryDisplayDescription';
  description: Scalars['String']['output'];
  isBullet: Scalars['Boolean']['output'];
};

export type RetrievePaymentIntentPayload = {
  __typename?: 'RetrievePaymentIntentPayload';
  amount?: Maybe<Scalars['SafeInt']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  virtualAccount?: Maybe<VirtualAccount>;
};

export type RevealAdSalary = TipToImproveAds & {
  __typename?: 'RevealAdSalary';
  adCount: Scalars['Int']['output'];
  type: TipsToImproveAdsType;
};

export type RevealSalary = {
  __typename?: 'RevealSalary';
  currency: Scalars['String']['output'];
  type: RecommendationType;
};

export type Review = {
  __typename?: 'Review';
  companyId: Scalars['String']['output'];
  cons: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  employmentStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  overallRating: Scalars['Float']['output'];
  pros: Scalars['String']['output'];
  ratings: RatingItem[];
  recommended: ReviewRecommended;
  reply?: Maybe<ReviewReply>;
  salarySummary: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  upvotes?: Maybe<Upvotes>;
  workLocation?: Maybe<Scalars['String']['output']>;
};

export type ReviewAdDetails = TipToImproveAds & {
  __typename?: 'ReviewAdDetails';
  adCount: Scalars['Int']['output'];
  benchmarkConversionRate?: Maybe<Scalars['Float']['output']>;
  conversionRate?: Maybe<Scalars['Float']['output']>;
  type: TipsToImproveAdsType;
};

export type ReviewAdSummary = TipToImproveAds & {
  __typename?: 'ReviewAdSummary';
  adCount: Scalars['Int']['output'];
  benchmarkConversionRate?: Maybe<Scalars['Float']['output']>;
  conversionRate?: Maybe<Scalars['Float']['output']>;
  type: TipsToImproveAdsType;
};

export type ReviewRecommended = {
  __typename?: 'ReviewRecommended';
  description: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export type ReviewReply = {
  __typename?: 'ReviewReply';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  user?: Maybe<ReviewReplyUser>;
};

export type ReviewReplyUser = {
  __typename?: 'ReviewReplyUser';
  userId?: Maybe<Scalars['String']['output']>;
};

export type Reviews = {
  __typename?: 'Reviews';
  data?: Maybe<Review[]>;
  paging?: Maybe<ReviewsPaging>;
};

export type ReviewsPaging = {
  __typename?: 'ReviewsPaging';
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type RightToWork = {
  __typename?: 'RightToWork';
  id?: Maybe<Scalars['String']['output']>;
  isVerified: Scalars['Boolean']['output'];
  label?: Maybe<Scalars['String']['output']>;
  verifiedFields?: Maybe<VerifiedField[]>;
};

export type RightToWorkInSavedSearch = {
  __typename?: 'RightToWorkInSavedSearch';
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
};

export type RightToWorkInSavedSearchlabelArgs = {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type RightToWorkInput = {
  ids: Array<Scalars['String']['input']>;
  includeUnspecified: Scalars['Boolean']['input'];
};

export enum Role {
  /** An administrator. */
  ADMINISTRATOR = 'ADMINISTRATOR',
  /** A standard user. */
  USER = 'USER',
}

export enum RoleReqsAnswerSelectionType {
  CHECKBOX = 'CHECKBOX',
  FREE_TEXT = 'FREE_TEXT',
  RADIO = 'RADIO',
  RANGE = 'RANGE',
}

export enum RoleReqsAnswerSelectionTypeView {
  ANSWERS = 'ANSWERS',
  SIMPLIFIED_ANSWERS = 'SIMPLIFIED_ANSWERS',
}

export enum RoleReqsAnswerType {
  FREE_TEXT = 'FREE_TEXT',
  MULTI_SELECT = 'MULTI_SELECT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  URL_SINGLE_SELECT = 'URL_SINGLE_SELECT',
}

export type RoleReqsAvailableAnswer = {
  __typename?: 'RoleReqsAvailableAnswer';
  /** The global ID of the answer */
  id: Scalars['String']['output'];
  /** Answer order */
  order: Scalars['Int']['output'];
  /** Flag if answer is preferred by hirer */
  preferred: Scalars['Boolean']['output'];
  /** Answer text */
  text: Scalars['String']['output'];
};

export type RoleReqsMinimumRequiredAnswer = {
  __typename?: 'RoleReqsMinimumRequiredAnswer';
  /** Acceptable answer global ID for mandatory question */
  id: Scalars['String']['output'];
  /** Acceptable answer text for mandatory question */
  text: Scalars['String']['output'];
};

export enum RoleReqsQuestionDescription {
  FREE_TEXT = 'FREE_TEXT',
  MULTI_SELECT = 'MULTI_SELECT',
  RANGE = 'RANGE',
  RANGE_AT_LEAST = 'RANGE_AT_LEAST',
  RANGE_UP_TO = 'RANGE_UP_TO',
  SINGLE_SELECT_ANY = 'SINGLE_SELECT_ANY',
  SINGLE_SELECT_ONLY = 'SINGLE_SELECT_ONLY',
}

export enum RoleReqsQuestionSource {
  CUSTOM = 'CUSTOM',
  LIBRARY = 'LIBRARY',
}

export type RoleReqsQuestionsByKeywordInput = {
  /** Country code */
  country: Scalars['String']['input'];
  /** Keywords to search for */
  keywords: Scalars['String']['input'];
  /** Language code */
  languageId: Scalars['String']['input'];
  /** Questionnaire Id */
  questionnaireId: Scalars['String']['input'];
  /** List of selected questions */
  selectedQuestionIds?: InputMaybe<RoleReqsSelectedQuestionId[]>;
};

export type RoleReqsQuestionsByKeywordResponse =
  | ResponseError
  | RoleReqsQuestionsByKeywordSuccessResponse;

export type RoleReqsQuestionsByKeywordSuccessResponse = {
  __typename?: 'RoleReqsQuestionsByKeywordSuccessResponse';
  /** Question hits */
  results: RoleReqsRecommendedQuestion[];
  /** Total number of question hits */
  totalCount: Scalars['Int']['output'];
};

export type RoleReqsRecommendedQuestion = {
  __typename?: 'RoleReqsRecommendedQuestion';
  /** How the question is to be answered */
  answerType: RoleReqsAnswerType;
  /** The complete collection of answers available for this question */
  availableAnswers?: Maybe<RoleReqsAvailableAnswer[]>;
  /** Flag to indicate if the question is certsy verifiable */
  certsyVerifiable: Scalars['Boolean']['output'];
  /** Description detailing how the hirer can select preferred answers */
  description: RoleReqsQuestionDescription;
  /** If true, enable selection type toggle if data allows */
  enableSelectionTypeToggle: Scalars['Boolean']['output'];
  /** Indicates if the question is required for the job */
  essential?: Maybe<Scalars['Boolean']['output']>;
  /** The unique ID of the question */
  id: Scalars['String']['output'];
  /** Indicates if the question is a bundling question */
  isBundlingQuestion?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the question is essential for filtering in Candidate Management */
  isEssentialFilters?: Maybe<Scalars['Boolean']['output']>;
  /** To show salary filter warning message if salary question is not selected */
  isSalaryFilters?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the question is a mandatory question */
  mandatory: Scalars['Boolean']['output'];
  /** Acceptable answers for mandatory question */
  minimumRequiredAnswers?: Maybe<RoleReqsMinimumRequiredAnswer[]>;
  /** The display order of this question in relation to that of its siblings */
  order: Scalars['Int']['output'];
  /** If true, this question has been selected */
  selected: Scalars['Boolean']['output'];
  /** The selection control that determines how the hirer will select preferred answers */
  selectionType: RoleReqsAnswerSelectionType;
  /** Answers or SimplifiedAnswers */
  selectionTypeView: RoleReqsAnswerSelectionTypeView;
  /** The complete collection of simplified answers available for this question */
  simplifiedAnswers?: Maybe<RoleReqsSimplifiedAnswer[]>;
  /** The question's origin (Library or Custom) */
  source: RoleReqsQuestionSource;
  /** The question statement */
  statement?: Maybe<Scalars['String']['output']>;
  /** The question text */
  text: Scalars['String']['output'];
};

export type RoleReqsSelectedQuestionId = {
  /** Question globalId */
  id: Scalars['String']['input'];
};

export type RoleReqsSimplifiedAnswer = {
  __typename?: 'RoleReqsSimplifiedAnswer';
  /** The global ID of the simplified answer */
  id: Scalars['String']['output'];
  /** Simplified answer order */
  order: Scalars['Int']['output'];
  /** Flag if simplified answer is preferred by hirer */
  preferred: Scalars['Boolean']['output'];
  /** Simplified answer text */
  text: Scalars['String']['output'];
};

export type RoleRequirementFilterCount = {
  __typename?: 'RoleRequirementFilterCount';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
};

export type RoleRequirements = {
  __typename?: 'RoleRequirements';
  roleReqsQuestions?: Maybe<Array<Scalars['String']['output']>>;
  type: RecommendationType;
};

/** RoleRequirementsMandatoryQuestions feature to indicate CPQA product */
export type RoleRequirementsMandatoryQuestionsFeature =
  CatalogueProductFeature & {
    __typename?: 'RoleRequirementsMandatoryQuestionsFeature';
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    questionAnswerSet: Scalars['String']['output'];
    type: Scalars['String']['output'];
  };

export type Rule = {
  __typename?: 'Rule';
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type Salary = {
  frequency: SalaryFrequency;
  includeUnspecified: Scalars['Boolean']['input'];
  range?: InputMaybe<SalaryRange>;
};

export type SalaryAmountV2 = {
  __typename?: 'SalaryAmountV2';
  frequency: SalaryFrequency;
  value: Scalars['SafeInt']['output'];
};

export enum SalaryFrequency {
  ANNUAL = 'ANNUAL',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
}

export type SalaryInput = {
  currency: JobSalaryCurrency;
  displayValue: Scalars['String']['input'];
  maximum: Scalars['SafeInt']['input'];
  minimum: Scalars['SafeInt']['input'];
  type: Scalars['String']['input'];
};

export type SalaryRange = {
  maximum?: InputMaybe<Scalars['SafeInt']['input']>;
  minimum?: InputMaybe<Scalars['SafeInt']['input']>;
};

export enum SalaryType {
  ANNUAL_COMMISSION = 'ANNUAL_COMMISSION',
  ANNUAL_PACKAGE = 'ANNUAL_PACKAGE',
  COMMISSION_ONLY = 'COMMISSION_ONLY',
  HOURLY_RATE = 'HOURLY_RATE',
  MONTHLY = 'MONTHLY',
}

export type SavedCreditCardPaymentError = Error & {
  __typename?: 'SavedCreditCardPaymentError';
  /** For card errors resulting from a card issuer decline, a short string indicating the card issuer’s reason for the decline if they provide one. */
  declineCode?: Maybe<Scalars['String']['output']>;
  /** Short string indicating the error code reported. */
  errorCode?: Maybe<Scalars['String']['output']>;
  /** A human-readable message providing more details about the error. */
  message: Scalars['String']['output'];
  /** If the error is parameter-specific, the parameter related to the error. For example, you can use this to display a message near the correct form field. */
  param?: Maybe<Scalars['String']['output']>;
  /** Id of the failed payment intent. */
  paymentIntentId: Scalars['String']['output'];
  /** The status of the payment intent. Examples: "requires_payment_method" "requires_confirmation" "requires_action" "processing" "requires_capture" "canceled". */
  paymentIntentStatus: Scalars['String']['output'];
};

export type SavedCreditCardPaymentSuccessPayload = {
  __typename?: 'SavedCreditCardPaymentSuccessPayload';
  /** The client secret of this PaymentIntent. Used for client-side retrieval using a publishable key. */
  clientSecret: Scalars['String']['output'];
  /** Id of the successful payment intent. */
  paymentIntentId: Scalars['String']['output'];
};

export type SearchIntegrationPartnersInput = {
  releaseNotesStatus?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type SecureLinkExpiredError = Error & {
  __typename?: 'SecureLinkExpiredError';
  message: Scalars['String']['output'];
};

export type SecureLinkInput = {
  /** Temporary link GUID of SecurityLink entities. */
  link: Scalars['String']['input'];
};

export type SecureLinkInvalidError = Error & {
  __typename?: 'SecureLinkInvalidError';
  message: Scalars['String']['output'];
};

export type SecureLinkResponse =
  | SecureLinkExpiredError
  | SecureLinkInvalidError
  | SecureLinkSuccessResponse
  | SecureLinkUsedError;

export type SecureLinkSuccessResponse = {
  __typename?: 'SecureLinkSuccessResponse';
  redirectUri: Scalars['String']['output'];
};

export type SecureLinkUsedError = Error & {
  __typename?: 'SecureLinkUsedError';
  message: Scalars['String']['output'];
};

export type SellingPoint = {
  __typename?: 'SellingPoint';
  /** Additional information about a product */
  helpTip?: Maybe<HelpTip>;
  /** To determine whether to highlight the displayed selling point or not */
  isHighlighted: Scalars['Boolean']['output'];
  /** Description of a selling point */
  name: Scalars['String']['output'];
};

export type SendAccessEventPayload = {
  __typename?: 'SendAccessEventPayload';
  success: Scalars['Boolean']['output'];
};

export type SendApplicationEmailInput = {
  enableReplies: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  prospectId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
};

export type SendBulkApplicationEmailInput = {
  enableReplies: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  prospectIds: Array<Scalars['ID']['input']>;
  subject: Scalars['String']['input'];
};

export type SendBulkApplicationEmailPayload = {
  __typename?: 'SendBulkApplicationEmailPayload';
  success: Scalars['Boolean']['output'];
};

export type SendEmailResponse = {
  __typename?: 'SendEmailResponse';
  /** Response message we receive from requesting the password and registration emails to be sent. */
  message?: Maybe<Scalars['String']['output']>;
};

export type SendEmailVerificationEmailPayload = {
  __typename?: 'SendEmailVerificationEmailPayload';
  /** Indicates whether a user has already verified their email address. */
  emailVerified: Scalars['Boolean']['output'];
  /** Message of resource api response. */
  msg: Scalars['String']['output'];
  /** Success status of sending email. */
  success: Scalars['Boolean']['output'];
};

export type SendEnrolmentEmailPayload = {
  __typename?: 'SendEnrolmentEmailPayload';
  /** success of sending Enrolment Email */
  isEnrolmentEmailSent: Scalars['Boolean']['output'];
};

export type SendHirerAccountUsersJobAccessEmailInput = {
  /** The job advertiser id */
  advertiserId: Scalars['ID']['input'];
  /** Custom email message written by the sender */
  emailMessage?: InputMaybe<Scalars['String']['input']>;
  /** If set to true, sender will be included in the email cc list */
  includeSender?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the job that users are to be notified that they have access to */
  jobId: Scalars['ID']['input'];
  /** A list of team members who are invited to access the job */
  receivers: AccessEmailReceiverInput[];
};

export type SendHirerAccountUsersJobAccessEmailPayload = {
  __typename?: 'SendHirerAccountUsersJobAccessEmailPayload';
  /** Success status of sending email. Returns true if send email operation is accepted. */
  success: Scalars['Boolean']['output'];
};

export type SendJob = {
  __typename?: 'SendJob';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type SendJobState =
  | AllJobsSent
  | ConnectionPending
  | InsufficientCredits
  | JobSent
  | NoJobs
  | SendJob;

export type SendLoginCallbackEventPayload = {
  __typename?: 'SendLoginCallbackEventPayload';
  /** Success status of processing callback event. */
  success: Scalars['Boolean']['output'];
};

export type SendMessage = {
  __typename?: 'SendMessage';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type SendMessageState =
  | ConnectionPending
  | InsufficientCredits
  | SendMessage
  | ViewSentMessage;

export type SendPasswordResetEmailInput = {
  /** Email address to send password reset email to. */
  email: Scalars['String']['input'];
};

export type SendRegistrationEmailInput = {
  /** Is the user in Customer Service view. */
  isInCSView: Scalars['Boolean']['input'];
  /** ID of user to send registration email to. */
  userId: Scalars['ID']['input'];
};

export type SetDefaultNotificationPreferenceInput = {
  /** Id of the job to set notification preferences. */
  jobId: Scalars['ID']['input'];
};

export type SetHirerAccountUsersJobAccessInput = {
  /** List of user job access settings */
  accessList: UserJobAccessInput[];
  /** The advertiser id that users tied to the account. */
  advertiserId: Scalars['ID']['input'];
  /**
   * If set true, delay updating to wait position is synchronized in adCentre.
   * If set false, update job access immediately
   */
  delayUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  /** The job id to set users access to */
  jobId: Scalars['ID']['input'];
};

export type SetHirerAccountUsersJobAccessPayload = {
  __typename?: 'SetHirerAccountUsersJobAccessPayload';
  /** Success status of set user job access operation. Returns true if set job access operation is accepted. */
  success: Scalars['Boolean']['output'];
};

export type SetNotificationPreferencesInput = {
  /** The job advertiser id */
  advertiserId: Scalars['ID']['input'];
  /**
   * If set true, delay updating to wait position is synchronized in adCentre.
   * If set false, update notification preferences immediately
   */
  delayUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the job to set notification preferences. */
  jobId: Scalars['ID']['input'];
  /** Notification preferences to be set for account users. */
  preferences: NotificationPreferenceInput[];
};

export type SetNotificationPreferencesPayload = {
  __typename?: 'SetNotificationPreferencesPayload';
  /** Success status of setting notification preferences operation. Returns true if set notification preferences operation is accepted. */
  success: Scalars['Boolean']['output'];
};

export type SimilarAdsDetails = {
  __typename?: 'SimilarAdsDetails';
  advertiserName: Scalars['String']['output'];
  analyticsProductDisplayName: Scalars['String']['output'];
  hasKeySellingPoints: Scalars['Boolean']['output'];
  isPrivateAdvertiser: Scalars['Boolean']['output'];
  isSalaryHidden: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  jobId: Scalars['String']['output'];
  jobLocationLabel: Scalars['String']['output'];
  jobTitle: Scalars['String']['output'];
  serpLogoUrl?: Maybe<Scalars['String']['output']>;
  showProductBadge: Scalars['Boolean']['output'];
};

export type SimilarAdsResponse = {
  __typename?: 'SimilarAdsResponse';
  ads: SimilarAdsDetails[];
  benchmarkLocationCountry?: Maybe<Scalars['String']['output']>;
  benchmarkLocationLabel?: Maybe<Scalars['String']['output']>;
  benchmarkScenario?: Maybe<AnalyticsBenchmarkScenario>;
  classificationName: Scalars['String']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  normalisedRoleTitle?: Maybe<Scalars['String']['output']>;
  subClassificationName: Scalars['String']['output'];
};

export type SimilarAdsWidgetInput = {
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
};

export type SimplifiedAnswers = {
  __typename?: 'SimplifiedAnswers';
  /** The global ids of the granular answers that the given simplified answer maps to */
  answerGlobalIds?: Maybe<Array<Scalars['String']['output']>>;
  /** The local ids of the granular answers that the given simplified answer maps to */
  answerIds?: Maybe<Array<Scalars['String']['output']>>;
  /** The simplified answer global id */
  globalId?: Maybe<Scalars['String']['output']>;
  /**
   * The simplified answer local id
   * @deprecated Use globalId instead. This id will be retired from FEB 13 2023. This value will only be returned for some legacy jobs.
   */
  id?: Maybe<Scalars['String']['output']>;
  /** True if the hirer selected the answer during CAJA */
  isPreferred: Scalars['Boolean']['output'];
  /** The display order of the answer, low to high */
  order: Scalars['Int']['output'];
  /** The simplified answer text */
  text: Scalars['String']['output'];
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

/** Product feature that indicates Sponsored Slot feature properties available for ad product */
export type SponsoredSlotFeature = CatalogueProductFeature & {
  __typename?: 'SponsoredSlotFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type State = {
  __typename?: 'State';
  /** Country of the state */
  country?: Maybe<CountryType>;
  /** Full name of the state */
  defaultName: Scalars['String']['output'];
  /** Whether the state information is deprecated or not */
  deprecated: Scalars['Boolean']['output'];
  /** Type of the location, eg. "State" / "Country" */
  kind: Scalars['String']['output'];
};

export type StripePaymentIntentPayload = {
  __typename?: 'StripePaymentIntentPayload';
  /** The client secret of this PaymentIntent. Used for client-side retrieval using a publishable key. */
  clientSecret: Scalars['String']['output'];
  /** Unique identifier for payment intent object */
  id: Scalars['String']['output'];
  /** A url that the customer can use to complete payment. If a url has not been set up for that market, this field will be omitted. */
  paymentLink?: Maybe<Scalars['String']['output']>;
  /** The base64 image data for the QR code */
  qrCodeUrl?: Maybe<Scalars['String']['output']>;
  /** The payment reference for the payment intent. To be used only on invoices. */
  reference?: Maybe<Scalars['String']['output']>;
};

export enum StripePaymentMethod {
  card = 'card',
  fpx = 'fpx',
  id_bank_transfer = 'id_bank_transfer',
  paynow = 'paynow',
  promptpay = 'promptpay',
}

/** Product feature that indicates border available for ad product */
export type StyleEnhancementFeature = CatalogueProductFeature & {
  __typename?: 'StyleEnhancementFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  style: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SubTitle = {
  __typename?: 'SubTitle';
  style: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type Subject = {
  __typename?: 'Subject';
  /**
   * Email address of the subject.
   * @deprecated Use email query
   */
  email: Scalars['String']['output'];
  /**
   * Indicates whether a subject has verified their email address.
   * @deprecated Use emailVerified query
   */
  emailVerified: Scalars['Boolean']['output'];
  /** The Auth0 user id of the subject, eg 'auth0|12eb841a02c489d1'. */
  id: Scalars['String']['output'];
  /**
   * The associated adcentre user id. This may be null if a corresponding adcentre user has not yet been
   * created, or accounts are not associated correctly.
   */
  userId?: Maybe<Scalars['String']['output']>;
};

export type SuggestedSalary = {
  __typename?: 'SuggestedSalary';
  averageRange?: Maybe<SuggestedSalaryAverageRange>;
  countryCode?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['Int']['output']>;
  range?: Maybe<SuggestedSalaryRange>;
  roleTitle?: Maybe<Scalars['String']['output']>;
  salaryType?: Maybe<SalaryType>;
  workType?: Maybe<WorkType>;
};

export type SuggestedSalaryAverageRange = {
  __typename?: 'SuggestedSalaryAverageRange';
  max?: Maybe<Scalars['SafeInt']['output']>;
  median?: Maybe<Scalars['SafeInt']['output']>;
  min?: Maybe<Scalars['SafeInt']['output']>;
};

export type SuggestedSalaryRange = {
  __typename?: 'SuggestedSalaryRange';
  max?: Maybe<Scalars['SafeInt']['output']>;
  min?: Maybe<Scalars['SafeInt']['output']>;
};

export type Surcharge = {
  __typename?: 'Surcharge';
  /** The surcharge amount. */
  amount: Money;
  /** The type of surcharge. Ex, AmexSurcharge */
  surchargeType: Scalars['String']['output'];
};

export type TalentSearch = {
  __typename?: 'TalentSearch';
  hirerId: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  type: RecommendationType;
};

export type TalentSearchATSJob = {
  __typename?: 'TalentSearchATSJob';
  externalReferenceId: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  jobLocations?: Maybe<Array<Scalars['String']['output']>>;
  jobOwners?: Maybe<TalentSearchJobOwner[]>;
  jobTitle: Scalars['String']['output'];
  positionProfileName?: Maybe<Scalars['String']['output']>;
  seekHirerJobReference?: Maybe<Scalars['String']['output']>;
};

export type TalentSearchATSJobEdge = {
  __typename?: 'TalentSearchATSJobEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: TalentSearchATSJob;
};

export type TalentSearchATSJobs = {
  __typename?: 'TalentSearchATSJobs';
  count: Scalars['Int']['output'];
  items?: Maybe<TalentSearchATSJob[]>;
};

export type TalentSearchATSJobsConnection = {
  __typename?: 'TalentSearchATSJobsConnection';
  edges: TalentSearchATSJobEdge[];
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TalentSearchATSJobsInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  jobOwnerName?: InputMaybe<Scalars['String']['input']>;
  jobTitle: Scalars['String']['input'];
};

export enum TalentSearchAccess {
  DISABLED = 'DISABLED',
  PREMIUM = 'PREMIUM',
  STANDARD = 'STANDARD',
  SYSTEM_DOWN = 'SYSTEM_DOWN',
}

export type TalentSearchAccount = {
  __typename?: 'TalentSearchAccount';
  advertiserId: Scalars['Int']['output'];
  advertiserName: Scalars['String']['output'];
  companyAddress?: Maybe<CompanyAddress>;
  hasConnectedTalentSearchAccess: Scalars['Boolean']['output'];
  hasPremiumTalentSearchAccess: Scalars['Boolean']['output'];
  parentAdvertiserId?: Maybe<Scalars['Int']['output']>;
  /** The URL where the user will be redirected to after selecting the account. */
  redirectUrl: Scalars['String']['output'];
  seekBillingAccountId: Scalars['String']['output'];
};

export type TalentSearchAccountredirectUrlArgs = {
  input: ConnectedTalentSearchRedirectUrlInput;
};

export type TalentSearchAccounts = {
  __typename?: 'TalentSearchAccounts';
  count: Scalars['Int']['output'];
  items: TalentSearchAccount[];
};

export type TalentSearchAccountsInput = {
  orderBy?: InputMaybe<TalentSearchAccountsOrderBy>;
  query?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type TalentSearchAccountsOrderBy = {
  direction: TalentSearchAccountsOrderByDirection;
  field: TalentSearchAccountsOrderByField;
};

export enum TalentSearchAccountsOrderByDirection {
  asc = 'asc',
  desc = 'desc',
}

export enum TalentSearchAccountsOrderByField {
  advertiserId = 'advertiserId',
  advertiserName = 'advertiserName',
}

export type TalentSearchAddOrRemoveProfileTalentPoolsInput = {
  profileId: Scalars['String']['input'];
  values: Array<
    InputMaybe<TalentSearchAddOrRemoveProfileTalentPoolsValuesInput>
  >;
};

export type TalentSearchAddOrRemoveProfileTalentPoolsValuesInput = {
  addToPool: Scalars['Boolean']['input'];
  poolId: Scalars['String']['input'];
  searchId?: InputMaybe<Scalars['String']['input']>;
};

export type TalentSearchAdvertiser = {
  __typename?: 'TalentSearchAdvertiser';
  accountNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isApproved: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parentAdvertiserId?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<TalentSearchHirerAccountTags>;
};

export type TalentSearchAssignedPool = {
  __typename?: 'TalentSearchAssignedPool';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TalentSearchAssignedPools = {
  __typename?: 'TalentSearchAssignedPools';
  assignedPools: TalentSearchAssignedPool[];
  profileId: Scalars['ID']['output'];
};

export type TalentSearchAssignedPoolsInput = {
  profileIds: Array<Scalars['Int']['input']>;
};

export type TalentSearchAtsHirerRelationship = {
  __typename?: 'TalentSearchAtsHirerRelationship';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type TalentSearchAvailableJobV2 = {
  __typename?: 'TalentSearchAvailableJobV2';
  expiry: Scalars['String']['output'];
  id: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type TalentSearchAvailableJobsInput = {
  /** Limits the number of available jobs in the response. Default is set to 10. */
  maxJobs?: InputMaybe<Scalars['Int']['input']>;
  /** The profile id to retrieve sent jobs with. */
  profileId: Scalars['Int']['input'];
  /** Filters jobs based on the title and reference fields. */
  query?: InputMaybe<Scalars['String']['input']>;
};

export type TalentSearchBudgetBalance = {
  __typename?: 'TalentSearchBudgetBalance';
  /** Current budget balance for an advertiser or job. */
  currentBalance: Scalars['Int']['output'];
  /** Initial budget balance for an advertiser or job. */
  initialBalance: Scalars['Int']['output'];
};

export type TalentSearchCandidateJobInvitationsBalance = {
  __typename?: 'TalentSearchCandidateJobInvitationsBalance';
  /** Current balance of candidate invitations for a job. */
  currentBalance: Scalars['Int']['output'];
  /** Initial balance of candidate invitations for a job. */
  initialBalance: Scalars['Int']['output'];
};

export enum TalentSearchClaim {
  CONNECTED_TALENT_SEARCH = 'CONNECTED_TALENT_SEARCH',
  PREMIUM_TALENT_SEARCH = 'PREMIUM_TALENT_SEARCH',
  STANDARD_TALENT_SEARCH = 'STANDARD_TALENT_SEARCH',
}

export type TalentSearchConnectionAvailability = {
  __typename?: 'TalentSearchConnectionAvailability';
  connectionId?: Maybe<Scalars['String']['output']>;
  connectionType: Scalars['Int']['output'];
  connectionTypeName: Scalars['String']['output'];
  createdDateUtc?: Maybe<Scalars['String']['output']>;
  isUsed: Scalars['Boolean']['output'];
  nextAvailableDateUtc?: Maybe<Scalars['String']['output']>;
};

export enum TalentSearchConnectionErrorReason {
  BAD_REQUEST_CONNECTION_ALREADY_ADDED = 'BAD_REQUEST_CONNECTION_ALREADY_ADDED',
  BAD_REQUEST_EXCEEDED_THROTTLING_LIMIT = 'BAD_REQUEST_EXCEEDED_THROTTLING_LIMIT',
  BAD_REQUEST_INSUFFICIENT_CREDITS = 'BAD_REQUEST_INSUFFICIENT_CREDITS',
  BAD_REQUEST_INVALID_PROFILE_PRIVACY = 'BAD_REQUEST_INVALID_PROFILE_PRIVACY',
  BAD_REQUEST_NO_CONNECTION = 'BAD_REQUEST_NO_CONNECTION',
  BAD_REQUEST_NO_CREDITS = 'BAD_REQUEST_NO_CREDITS',
  BAD_REQUEST_NO_PHONE_NUMBER = 'BAD_REQUEST_NO_PHONE_NUMBER',
  BAD_REQUEST_NO_PROFILE = 'BAD_REQUEST_NO_PROFILE',
  BAD_REQUEST_NO_RESUME = 'BAD_REQUEST_NO_RESUME',
  BAD_REQUEST_RESUME_FOUND = 'BAD_REQUEST_RESUME_FOUND',
}

export enum TalentSearchConnectionOrigin {
  CANDIMAN_COUPLED_SEARCH = 'CANDIMAN_COUPLED_SEARCH',
  SIMILAR_CANDIDATES = 'SIMILAR_CANDIDATES',
  SINGLE_PROFILE = 'SINGLE_PROFILE',
  TALENTSEARCH_COUPLED_SEARCH = 'TALENTSEARCH_COUPLED_SEARCH',
  TALENT_POOL = 'TALENT_POOL',
  UNCOUPLED_SEARCH = 'UNCOUPLED_SEARCH',
  UNKNOWN = 'UNKNOWN',
}

export type TalentSearchConnectionResponseError = {
  __typename?: 'TalentSearchConnectionResponseError';
  reason: TalentSearchConnectionErrorReason;
};

export enum TalentSearchConnectionStatus {
  pending = 'pending',
  redeemed = 'redeemed',
  rejected = 'rejected',
}

export type TalentSearchContract = {
  __typename?: 'TalentSearchContract';
  endDate: Scalars['String']['output'];
  productType: TalentSearchContractType;
  startDate: Scalars['String']['output'];
};

export type TalentSearchContractBalance = {
  __typename?: 'TalentSearchContractBalance';
  /** Current balance for a contract. */
  currentBalance: Scalars['Int']['output'];
  /** Initial balance for a contract. */
  initialBalance: Scalars['Int']['output'];
};

export type TalentSearchContractBalanceResponse =
  | ResponseError
  | TalentSearchContractBalance
  | TalentSearchUnavailableContractBalance;

export enum TalentSearchContractType {
  CONNECTED_TALENT_SEARCH = 'CONNECTED_TALENT_SEARCH',
  PREMIUM_TALENT_SEARCH = 'PREMIUM_TALENT_SEARCH',
  PREMIUM_TALENT_SEARCH_TRIAL = 'PREMIUM_TALENT_SEARCH_TRIAL',
}

export type TalentSearchCountResult = {
  __typename?: 'TalentSearchCountResult';
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
};

export type TalentSearchCountResultWithLabel = {
  __typename?: 'TalentSearchCountResultWithLabel';
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
};

export type TalentSearchCountsResults = {
  __typename?: 'TalentSearchCountsResults';
  approachabilitysignal?: Maybe<TalentSearchCountResult[]>;
  company?: Maybe<TalentSearchCountResult[]>;
  currentLocation?: Maybe<TalentSearchCountResult[]>;
  industry?: Maybe<TalentSearchCountResult[]>;
  rightToWork?: Maybe<TalentSearchCountResultWithLabel[]>;
  salary?: Maybe<TalentSearchCountResult[]>;
};

export type TalentSearchCountsResultsrightToWorkArgs = {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type TalentSearchCreateTalentPoolInput = {
  name: Scalars['String']['input'];
};

export type TalentSearchCriteria = {
  __typename?: 'TalentSearchCriteria';
  approachable?: Maybe<Scalars['Boolean']['output']>;
  company?: Maybe<Array<Scalars['String']['output']>>;
  countryCode?: Maybe<Scalars['String']['output']>;
  cv?: Maybe<Scalars['Boolean']['output']>;
  industry?: Maybe<Array<Scalars['String']['output']>>;
  keywords?: Maybe<Scalars['String']['output']>;
  lastUpdated?: Maybe<Scalars['String']['output']>;
  locationDisplayTexts: Array<Scalars['String']['output']>;
  locationList: Array<Scalars['Int']['output']>;
  maxSalary?: Maybe<Scalars['Int']['output']>;
  minSalary?: Maybe<Scalars['Int']['output']>;
  nation?: Maybe<Scalars['Int']['output']>;
  profileTypes?: Maybe<ProfileSource[]>;
  rightToWorkList?: Maybe<RightToWorkInSavedSearch[]>;
  rightToWorkUnspecified?: Maybe<Scalars['Boolean']['output']>;
  salaryNation?: Maybe<Scalars['Int']['output']>;
  salaryType?: Maybe<Scalars['String']['output']>;
  salaryUnspecified?: Maybe<Scalars['Boolean']['output']>;
  uncoupledFreeText: Scalars['String']['output'];
  /** @deprecated Will be removed once granular right to work is implemented */
  visa?: Maybe<Array<Scalars['String']['output']>>;
  willingToRelocate?: Maybe<Scalars['Boolean']['output']>;
  workType?: Maybe<Array<Scalars['String']['output']>>;
};

export type TalentSearchCriterialocationDisplayTextsArgs = {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type TalentSearchCriteriaInput = {
  approachable?: InputMaybe<Scalars['Boolean']['input']>;
  company?: InputMaybe<Array<Scalars['String']['input']>>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  cv?: InputMaybe<Scalars['Boolean']['input']>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  lastUpdated?: InputMaybe<Scalars['String']['input']>;
  locationList: Array<Scalars['Int']['input']>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  nation?: InputMaybe<Scalars['Int']['input']>;
  profileTypes?: InputMaybe<ProfileSource[]>;
  rightToWorkIds?: InputMaybe<Array<Scalars['String']['input']>>;
  rightToWorkUnspecified?: InputMaybe<Scalars['Boolean']['input']>;
  salaryNation?: InputMaybe<Scalars['Int']['input']>;
  salaryType?: InputMaybe<Scalars['String']['input']>;
  salaryUnspecified?: InputMaybe<Scalars['Boolean']['input']>;
  uncoupledFreeText: Scalars['String']['input'];
  visa?: InputMaybe<Array<Scalars['String']['input']>>;
  willingToRelocate?: InputMaybe<Scalars['Boolean']['input']>;
  workType?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TalentSearchDeleteTalentPoolInput = {
  poolId: Scalars['String']['input'];
};

export type TalentSearchFeature = {
  __typename?: 'TalentSearchFeature';
  /** Whether or not the feature flag is active */
  active: Scalars['Boolean']['output'];
  /** Unique identifier of feature flag */
  key: Scalars['String']['output'];
  /** Name of feature flag */
  name: Scalars['String']['output'];
};

export type TalentSearchHirerAccountTags = {
  __typename?: 'TalentSearchHirerAccountTags';
  recordExpiry?: Maybe<Scalars['String']['output']>;
  testBehaviours?: Maybe<Scalars['String']['output']>;
  testRecord: Scalars['String']['output'];
  testScope?: Maybe<Scalars['String']['output']>;
};

export type TalentSearchHirerContactDetails = {
  __typename?: 'TalentSearchHirerContactDetails';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  position: Scalars['String']['output'];
};

export type TalentSearchInteraction = {
  __typename?: 'TalentSearchInteraction';
  associatedAction: AssociatedAction;
  associatedParties?: Maybe<Array<Maybe<AssociatedParty>>>;
  id: Scalars['String']['output'];
  interactionType: Scalars['String']['output'];
  positionProfileId?: Maybe<Scalars['String']['output']>;
  seekSourceName?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
};

export type TalentSearchInteractionEdge = {
  __typename?: 'TalentSearchInteractionEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: TalentSearchInteraction;
};

export type TalentSearchInteractionsConnection = {
  __typename?: 'TalentSearchInteractionsConnection';
  edges: TalentSearchInteractionEdge[];
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TalentSearchJobBalance =
  | ResponseError
  | TalentSearchCandidateJobInvitationsBalance
  | TalentSearchJobCreditsBalance
  | TalentSearchUnavailableJobBalance;

export enum TalentSearchJobBudgetUnavailableReason {
  /** An error was encountered fetching the budget. */
  BUDGET_ERROR = 'BUDGET_ERROR',
  /** Job budget does not apply, likely it's a pre-tilt Ad for non-STS hirer. */
  BUDGET_NOT_APPLICABLE = 'BUDGET_NOT_APPLICABLE',
  /** The job has no budget, likely it's a lite ad. */
  BUDGET_NOT_FOUND = 'BUDGET_NOT_FOUND',
}

export type TalentSearchJobCreditsBalance = {
  __typename?: 'TalentSearchJobCreditsBalance';
  /** Current balance of credits for a job. */
  currentBalance: Scalars['Int']['output'];
  /** Initial balance of credits for a job. */
  initialBalance: Scalars['Int']['output'];
};

export type TalentSearchJobInput = {
  jobId: Scalars['ID']['input'];
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type TalentSearchJobLocationV2 = {
  __typename?: 'TalentSearchJobLocationV2';
  countryCode?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type TalentSearchJobOwner = {
  __typename?: 'TalentSearchJobOwner';
  firstName?: Maybe<Scalars['String']['output']>;
  formattedName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type TalentSearchJobSalaryV2 = {
  __typename?: 'TalentSearchJobSalaryV2';
  frequency: SalaryFrequency;
};

export type TalentSearchJobV2 = {
  __typename?: 'TalentSearchJobV2';
  /** The associated advertiser id for the job. */
  advertiserId: Scalars['String']['output'];
  /** Current balance for this job in connections or credits. */
  balanceV2: TalentSearchJobBalance;
  /** Date in ISO format when job was originally posted */
  createdDate: Scalars['String']['output'];
  /** Date in ISO format when job will expire */
  expiryDate: Scalars['String']['output'];
  /** The projected job id. */
  id: Scalars['String']['output'];
  /** Whether job is upgradable */
  isUpgradable?: Maybe<Scalars['Boolean']['output']>;
  /** Location of the job */
  location: TalentSearchJobLocationV2;
  /** An optional reference provided for the job. */
  reference?: Maybe<Scalars['String']['output']>;
  /** Salary details of the job */
  salary: TalentSearchJobSalaryV2;
  /** Whether the job is Active or Inactive. */
  status: Scalars['String']['output'];
  /** The title of the job. */
  title: Scalars['String']['output'];
};

export type TalentSearchJobV2locationArgs = {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type TalentSearchJobsInput = {
  /** Returns jobs with both "Active" and "Expired" status. */
  includeExpired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specify langugage for translated location */
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  /** Limits the number of jobs in the response. */
  maxJobs?: InputMaybe<Scalars['Int']['input']>;
  /** Order jobs by field and direction (asc, desc) */
  orderBy?: InputMaybe<TalentSearchJobsOrderBy>;
  /** Filters jobs based on the title and reference fields. */
  query?: InputMaybe<Scalars['String']['input']>;
};

export type TalentSearchJobsOrderBy = {
  direction: TalentSearchJobsOrderByDirection;
  field: TalentSearchJobsOrderByField;
};

export enum TalentSearchJobsOrderByDirection {
  asc = 'asc',
  desc = 'desc',
}

export enum TalentSearchJobsOrderByField {
  listed = 'listed',
}

export type TalentSearchJobsV2 = {
  __typename?: 'TalentSearchJobsV2';
  jobs: TalentSearchJobV2[];
  totalJobs: Scalars['Int']['output'];
};

export type TalentSearchLatestInteraction = {
  __typename?: 'TalentSearchLatestInteraction';
  count: Scalars['Int']['output'];
  interaction?: Maybe<TalentSearchInteraction>;
  profileId: Scalars['Int']['output'];
};

export type TalentSearchMarket = {
  __typename?: 'TalentSearchMarket';
  countryCode: Scalars['String']['output'];
  countryName: Scalars['String']['output'];
};

export type TalentSearchMessageDetails = {
  __typename?: 'TalentSearchMessageDetails';
  body: Scalars['String']['output'];
  hirer: TalentSearchHirerContactDetails;
  nextAvailableDate: Scalars['String']['output'];
  sentDate: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export type TalentSearchMetadataCompanySuggestInput = {
  query: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
};

export enum TalentSearchMetadataCulture {
  au = 'au',
  nz = 'nz',
}

export type TalentSearchMetadataIndustriesInput = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type TalentSearchMetadataIndustry = {
  __typename?: 'TalentSearchMetadataIndustry';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  subIndustries?: Maybe<TalentSearchMetadataSubIndustry[]>;
};

export type TalentSearchMetadataMarketsInput = {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type TalentSearchMetadataQueryValidations = {
  __typename?: 'TalentSearchMetadataQueryValidations';
  stopwords: Array<Scalars['String']['output']>;
  whitelistRegexPattern: Scalars['String']['output'];
};

export type TalentSearchMetadataQueryValidationsInput = {
  region?: InputMaybe<Scalars['String']['input']>;
};

export type TalentSearchMetadataSubIndustry = {
  __typename?: 'TalentSearchMetadataSubIndustry';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TalentSearchNationality = {
  __typename?: 'TalentSearchNationality';
  displayLabel: Scalars['String']['output'];
};

export type TalentSearchNationalitydisplayLabelArgs = {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type TalentSearchPool = {
  __typename?: 'TalentSearchPool';
  id: Scalars['String']['output'];
  lastUpdated: Scalars['String']['output'];
  name: Scalars['String']['output'];
  policy?: Maybe<Scalars['String']['output']>;
  profileCount: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type TalentSearchPoolCandidatesInputV2 = {
  countryCode: Scalars['String']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  poolId: Scalars['String']['input'];
};

export type TalentSearchProfileInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['Int']['input']>;
};

export type TalentSearchProfileResultV2 = {
  __typename?: 'TalentSearchProfileResultV2';
  actionLinks?: Maybe<ActionLink>;
  approachabilitySignal?: Maybe<Scalars['Int']['output']>;
  atsLastUpdatedDateUtc?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  currentIndustry?: Maybe<Scalars['String']['output']>;
  currentIndustryId?: Maybe<Scalars['String']['output']>;
  currentInferredIndustry?: Maybe<Scalars['String']['output']>;
  currentJobTitle?: Maybe<Scalars['String']['output']>;
  currentLocation?: Maybe<Scalars['String']['output']>;
  currentSubindustry?: Maybe<Scalars['String']['output']>;
  currentSubindustryId?: Maybe<Scalars['String']['output']>;
  digitalIdentity?: Maybe<DigitalIdentity>;
  firstName: Scalars['String']['output'];
  hasResume: Scalars['Boolean']['output'];
  hasVerifiedCredentials: Scalars['Boolean']['output'];
  highlight?: Maybe<ProfileResultHighlight>;
  homeLocationId?: Maybe<Scalars['String']['output']>;
  jobSeekerId: Scalars['Int']['output'];
  languages?: Maybe<Array<Scalars['String']['output']>>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  licences?: Maybe<ProfileLicence[]>;
  nationalities: TalentSearchNationality[];
  noticePeriodDays?: Maybe<Scalars['Int']['output']>;
  partnerDoNotContact: Scalars['Boolean']['output'];
  pastJobTitles?: Maybe<Array<Scalars['String']['output']>>;
  profileEducation?: Maybe<ProfileEducation[]>;
  profileId: Scalars['Int']['output'];
  profilePrivacy: PrivacyTypes;
  profileType: ProfileTypes;
  resumeCoreDataAvailable?: Maybe<Scalars['Boolean']['output']>;
  resumeSnippet?: Maybe<Scalars['String']['output']>;
  rightToWork?: Maybe<RightToWork>;
  salary?: Maybe<ProfileSalaryV2>;
  sensitiveSearchTermsInResumeSnippet: Scalars['Boolean']['output'];
  sensitiveWordsFoundInResumeSnippet: Scalars['Boolean']['output'];
  skills?: Maybe<Array<Scalars['String']['output']>>;
  state?: Maybe<Scalars['String']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
  workHistories: WorkHistory[];
  workTypes: Array<Scalars['String']['output']>;
};

export type TalentSearchProfileResultV2currentLocationArgs = {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type TalentSearchProfileResultV2rightToWorkArgs = {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type TalentSearchProfileResultsV2 = {
  __typename?: 'TalentSearchProfileResultsV2';
  count: Scalars['Int']['output'];
  items: TalentSearchProfileResultV2[];
  searchIdentifier?: Maybe<Scalars['String']['output']>;
};

export type TalentSearchProfileResumeInput = {
  keywords?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['Int']['input']>;
};

export type TalentSearchProfileResumeResult = {
  __typename?: 'TalentSearchProfileResumeResult';
  resumeEducation: ResumeEducation[];
  resumeSkills: Array<Scalars['String']['output']>;
  resumeWorkHistories: ResumeWorkHistory[];
};

export type TalentSearchProfileSendJobStatus = {
  __typename?: 'TalentSearchProfileSendJobStatus';
  profileId: Scalars['Int']['output'];
  status: TalentSearchSendJobStatus;
};

export enum TalentSearchQuerySalaryType {
  ANNUAL = 'ANNUAL',
  HOURLY_RATE = 'HOURLY_RATE',
  MONTHLY = 'MONTHLY',
}

export enum TalentSearchQuerySortBy {
  CREATED_DATE = 'CREATED_DATE',
  FRESHNESS = 'FRESHNESS',
  MODIFIED_DATE = 'MODIFIED_DATE',
  RELEVANCE = 'RELEVANCE',
}

export type TalentSearchRecommendedCandidatesInputV2 = {
  countryCode: Scalars['String']['input'];
  jobId: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  searchExperiment?: InputMaybe<Scalars['String']['input']>;
  searchId?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  userSessionId?: InputMaybe<Scalars['String']['input']>;
};

export type TalentSearchSavedSearch = {
  __typename?: 'TalentSearchSavedSearch';
  createdDate: Scalars['String']['output'];
  criteria: TalentSearchCriteria;
  criteriaHash: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum TalentSearchSendJobStatus {
  ALL_JOBS_SENT = 'ALL_JOBS_SENT',
  HAS_AVAILABLE_JOBS = 'HAS_AVAILABLE_JOBS',
  NO_ACTIVE_JOBS = 'NO_ACTIVE_JOBS',
}

export type TalentSearchShareTalentPoolInput = {
  policy: Scalars['String']['input'];
  poolId: Scalars['String']['input'];
};

export type TalentSearchSimilarCandidatesByIdInputV2 = {
  countryCode: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isSeedProfileLocationOnly?: InputMaybe<Scalars['Boolean']['input']>;
  profileId?: InputMaybe<Scalars['Int']['input']>;
  profileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  searchExperiment?: InputMaybe<Scalars['String']['input']>;
  searchId?: InputMaybe<Scalars['String']['input']>;
};

export type TalentSearchSimilarCandidatesResultV2 = {
  __typename?: 'TalentSearchSimilarCandidatesResultV2';
  profiles: TalentSearchProfileResultsV2;
  seedProfile?: Maybe<TalentSearchProfileResultV2>;
};

export type TalentSearchUnavailableContractBalance = {
  __typename?: 'TalentSearchUnavailableContractBalance';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type TalentSearchUnavailableJobBalance = {
  __typename?: 'TalentSearchUnavailableJobBalance';
  /** Reason that budget is unavailable. */
  reason: TalentSearchJobBudgetUnavailableReason;
};

export type TalentSearchUncoupledCountForQueryInputV2 = {
  approachable?: InputMaybe<Scalars['Boolean']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  cv?: InputMaybe<Scalars['Boolean']['input']>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  lastUpdated?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  locationList?: InputMaybe<Array<Scalars['String']['input']>>;
  locations?: InputMaybe<Array<Scalars['String']['input']>>;
  profileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  rightToWork?: InputMaybe<RightToWorkInput>;
  salary?: InputMaybe<Salary>;
  sortBy?: InputMaybe<TalentSearchQuerySortBy>;
  uncoupledFreeText: Scalars['String']['input'];
  /** @deprecated use rightToWork instead */
  visa?: InputMaybe<Array<Scalars['String']['input']>>;
  willingToRelocate?: InputMaybe<Scalars['Boolean']['input']>;
  workType?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TalentSearchUncoupledSearchInputV2 = {
  approachable?: InputMaybe<Scalars['Boolean']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  cv?: InputMaybe<Scalars['Boolean']['input']>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  lastUpdated?: InputMaybe<Scalars['Int']['input']>;
  locationList?: InputMaybe<Array<Scalars['String']['input']>>;
  locations?: InputMaybe<Array<Scalars['String']['input']>>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  profileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  rightToWork?: InputMaybe<RightToWorkInput>;
  salary?: InputMaybe<Salary>;
  searchExperiment?: InputMaybe<Scalars['String']['input']>;
  searchId?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<TalentSearchQuerySortBy>;
  uncoupledFreeText: Scalars['String']['input'];
  userSessionId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated use rightToWork instead */
  visa?: InputMaybe<Array<Scalars['String']['input']>>;
  willingToRelocate?: InputMaybe<Scalars['Boolean']['input']>;
  workType?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TalentSearchUpdateTalentPoolInput = {
  name: Scalars['String']['input'];
  poolId: Scalars['String']['input'];
};

export type TalentSearchUser = {
  __typename?: 'TalentSearchUser';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type TalentSearchUserContext = {
  __typename?: 'TalentSearchUserContext';
  advertiser: TalentSearchAdvertiser;
  ats?: Maybe<TalentSearchAtsHirerRelationship>;
  claims: TalentSearchClaim[];
  /** The contract balance for a PTS/TSC advertiser. Will be unavailable for STS hirers. */
  contractBalance: TalentSearchContractBalanceResponse;
  contracts: TalentSearchContract[];
  /** The user ID of the user making the request. Helps with caching. */
  id: Scalars['ID']['output'];
  productType?: Maybe<TalentSearchClaim>;
  user: TalentSearchUser;
};

export type TalentSearchUserContextInput = {
  jobId?: InputMaybe<Scalars['ID']['input']>;
};

export type Tax = {
  __typename?: 'Tax';
  amount?: Maybe<Money>;
  code: TaxCode;
  description?: Maybe<Scalars['String']['output']>;
  displayRecommendation?: Maybe<DisplayRecommendation>;
  rate?: Maybe<Scalars['Int']['output']>;
};

export enum TaxCode {
  GST = 'GST',
  PPN = 'PPN',
  SST = 'SST',
  VAT = 'VAT',
}

export type TipToImproveAds = {
  adCount: Scalars['Int']['output'];
  type: TipsToImproveAdsType;
};

export type TipsToImproveAdsResponse = {
  __typename?: 'TipsToImproveAdsResponse';
  numOfAds: Scalars['Int']['output'];
  tips?: Maybe<Array<Maybe<TipToImproveAds>>>;
};

export enum TipsToImproveAdsType {
  ADD_QUESTIONS = 'ADD_QUESTIONS',
  ADD_SPECIFIC_LOCATION = 'ADD_SPECIFIC_LOCATION',
  MAXIMISE_BRANDING = 'MAXIMISE_BRANDING',
  REVEAL_SALARY = 'REVEAL_SALARY',
  REVIEW_AD_DETAILS = 'REVIEW_AD_DETAILS',
  REVIEW_AD_SUMMARY = 'REVIEW_AD_SUMMARY',
}

export type TipsToImproveAdsWidgetInput = {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
  nonSpecificLocationShortId: Scalars['Int']['input'];
  specificLocationShortId: Scalars['Int']['input'];
};

export type TrackLocationSuggestionInput = {
  /** Location ID (ie, "18579") */
  id?: InputMaybe<Scalars['ID']['input']>;
  queryText?: InputMaybe<Scalars['String']['input']>;
  /** Same Session ID as the one used during 'locations()' Query */
  sessionId: Scalars['ID']['input'];
};

export type TrackLocationSuggestionInvalidInputPayload = {
  __typename?: 'TrackLocationSuggestionInvalidInputPayload';
  reason: Scalars['String']['output'];
};

export type TrackLocationSuggestionPayload =
  | TrackLocationSuggestionInvalidInputPayload
  | TrackLocationSuggestionSuccessPayload;

export type TrackLocationSuggestionSuccessPayload = {
  __typename?: 'TrackLocationSuggestionSuccessPayload';
  responseStatus: Scalars['String']['output'];
};

export type TriggerActivationPayload = {
  __typename?: 'TriggerActivationPayload';
  success: Scalars['Boolean']['output'];
};

export type UpdateAccountPrimaryContactV2Input = {
  /** The ID of the hirer user to be set as primary account */
  hirerUserId: Scalars['String']['input'];
  /** The phone number for the primary contact */
  phoneNumber: Scalars['String']['input'];
};

export type UpdateApplicationRatingInput = {
  prospectId: Scalars['ID']['input'];
  rating: Scalars['Int']['input'];
};

export type UpdateApplicationsStatusInput = {
  bucket: ApplicationBucket;
  prospectData: ApplicationData[];
  /** The location the status was updated. Should be either CandidateList or CandidateDetail or null */
  statusChangeLocation?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateApplicationsStatusPayload = {
  __typename?: 'UpdateApplicationsStatusPayload';
  success: Scalars['Boolean']['output'];
};

export type UpdateBrandInput = {
  assets?: InputMaybe<BrandAssetsInput>;
  id: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Internally resolved via auth */
  owner?: InputMaybe<BrandOwnerInput>;
};

export type UpdateBrandPayload =
  | InvalidInputPayload
  | UpdateBrandSuccessPayload;

export type UpdateBrandSuccessPayload = {
  __typename?: 'UpdateBrandSuccessPayload';
  assets: BrandAssets;
  createdOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  owner: BrandOwner;
  state?: Maybe<BrandState>;
};

export type UpdateCartProductSetErrorPayload = {
  __typename?: 'UpdateCartProductSetErrorPayload';
  message: Scalars['String']['output'];
};

export type UpdateCartProductSetInput = {
  cartId: Scalars['ID']['input'];
  productSetId: Scalars['ID']['input'];
};

export type UpdateCartProductSetResultPayload =
  | UpdateCartProductSetErrorPayload
  | UpdateCartProductSetSuccessPayload;

export type UpdateCartProductSetSuccessPayload = {
  __typename?: 'UpdateCartProductSetSuccessPayload';
  cart: Cart;
};

export type UpdateCartPromoCodeErrorPayload = {
  __typename?: 'UpdateCartPromoCodeErrorPayload';
  message: Scalars['String']['output'];
};

export type UpdateCartPromoCodeInput = {
  cartId: Scalars['ID']['input'];
  promoCode: Scalars['String']['input'];
};

export type UpdateCartPromoCodeResultPayload =
  | UpdateCartPromoCodeErrorPayload
  | UpdateCartPromoCodeSuccessPayload;

export type UpdateCartPromoCodeSuccessPayload = {
  __typename?: 'UpdateCartPromoCodeSuccessPayload';
  cart: Cart;
};

export type UpdateDraftJobInput = {
  /**
   * Adcentre advertiserId
   * @deprecated No longer required. Will be retired once the front-end is updated.
   */
  adcentreAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: InputMaybe<Scalars['String']['input']>;
  /** Selected branding info */
  branding?: InputMaybe<DraftJobBrandingInput>;
  /** Selected category and sub-category */
  categories?: InputMaybe<DraftJobCategoryInput[]>;
  /** The Job Description, aka "Write my own" */
  details?: InputMaybe<Scalars['String']['input']>;
  /**
   * The draft progress made in the job posting's create flow
   * Edit flow and refresh flow do not have draftProgress
   */
  draftProgress?: InputMaybe<DraftJobProgress>;
  /** Selected expire data */
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  /** The flow a draft is created from (e.g. create, edit) */
  flowType: DraftJobFlowType;
  /** The pre-defined Guided Job description, aka "Help me write my ad" */
  guidedDetails?: InputMaybe<DraftJobGuidedDetailsInput[]>;
  /** Flag to indicate if AI assistance has been used to generate job description and summary */
  hasAIAssistanceBeenUsed?: InputMaybe<Scalars['Boolean']['input']>;
  /** the id of the draft to be update */
  id: Scalars['String']['input'];
  /** The flag to tell whether the hirer is posting a private or a public Ad. */
  isConfidential?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reference number is displayed on invoices and used for linkout ads */
  jobAdReference?: InputMaybe<Scalars['String']['input']>;
  /** Standard or Guided Mode for writing job description */
  jobWritingMode?: InputMaybe<DraftJobWritingMode>;
  /** Selling points - used in the search description for standout/premium ads */
  keySellingPoints?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The job location info */
  locations?: InputMaybe<DraftJobLocationInput[]>;
  /**
   * The selected payment option (e.g. invoice, credit card etc.)
   * @deprecated Will be removed once deprecated express api
   */
  paymentOption?: InputMaybe<DraftJobPaymentOption>;
  /** Hirer's phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Selected products info */
  products?: InputMaybe<DraftJobProductsInput>;
  /** The id for a role requirements questionnaire */
  questionnaireId?: InputMaybe<Scalars['String']['input']>;
  /** Min/Max salaries info */
  salary?: InputMaybe<DraftJobSalaryDetailsInput>;
  /** The status of the draft */
  status: DraftStatus;
  /** The job summary to appear in search */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** The job title(s) */
  titles?: InputMaybe<DraftJobRoleTitleInput[]>;
  /** The video info, e.g. video url */
  video?: InputMaybe<DraftJobVideoInput>;
  /** Work arrangements, ie Remote, Onsite, Hybrid */
  workArrangements?: InputMaybe<WorkArrangement[]>;
  /** The work type(s) (e.g. full time, part time) */
  workTypes?: InputMaybe<WorkType[]>;
};

export type UpdateDraftJobPayload = {
  __typename?: 'UpdateDraftJobPayload';
  /** The updated draft job id from the new drafts api */
  draftId: Scalars['String']['output'];
};

export type UpdateDraftPayload = UpdateDraftJobPayload;

export type UpdateJobDetailsInput = {
  /** Key used to update the job */
  idempotencyKey: Scalars['String']['input'];
  /** An object containing values to update the job with */
  jobDetails: JobDetailsInput;
  /** The id of the job to update the details of */
  jobId: Scalars['String']['input'];
  /** An id used to identify the lifecycle of the job update */
  opportunityId: Scalars['String']['input'];
  /** The context in which the job is being updated (eg. updating or editing the job)) */
  purchaseContext: PurchaseContext;
};

export type UpdateJobDetailsResponse = UpdateJobDetailsSuccessResponse;

export type UpdateJobDetailsSuccessResponse = {
  __typename?: 'UpdateJobDetailsSuccessResponse';
  revisionId: Scalars['String']['output'];
};

export type UpdateJobForCartItemByReferenceInput = {
  cartId: Scalars['ID']['input'];
  job: CartItemJobReferenceInput;
  referenceId: Scalars['String']['input'];
};

export type UpdateJobForCartItemByReferenceResultPayload =
  | CartNotFoundError
  | UpdateJobForCartItemByReferenceSuccessPayload;

export type UpdateJobForCartItemByReferenceSuccessPayload = {
  __typename?: 'UpdateJobForCartItemByReferenceSuccessPayload';
  cart: Cart;
};

export type UpdateJobPlacementInput = {
  advertiserId: Scalars['Int']['input'];
  isPlaced?: InputMaybe<Scalars['Boolean']['input']>;
  jobId: Scalars['Int']['input'];
  postType: JobPlacementPostType;
  submissionDateUtc: Scalars['String']['input'];
  submitted: Scalars['Boolean']['input'];
  userId: Scalars['Int']['input'];
};

export type UpdateJobPlacementResponse =
  | ResponseError
  | UpdateJobPlacementResponseSuccess;

export type UpdateJobPlacementResponseSuccess = {
  __typename?: 'UpdateJobPlacementResponseSuccess';
  result: Scalars['Boolean']['output'];
};

export type UpdateUserDetailsResponse = {
  __typename?: 'UpdateUserDetailsResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateUserPermissionsInput = {
  /** The info of the user in AdCentre */
  userInfo: UserInfoInput;
};

export type UpdateUserPermissionsResponse = {
  __typename?: 'UpdateUserPermissionsResponse';
  permissions: UserPermissions;
  userId: Scalars['String']['output'];
};

export type UpgradePath = {
  __typename?: 'UpgradePath';
  /** Target product selected when upgrade product is selected */
  targetProduct2: CatalogueProduct;
  /** Type of upgrade path */
  type: UpgradePathType;
  /** Upgrade product available for AdProduct to which upgrade path is associated */
  upgradeProduct: AdProductUpgrade;
};

export enum UpgradePathType {
  UpgradeOnly = 'UpgradeOnly',
}

export type UploadPresignedUrl = {
  __typename?: 'UploadPresignedUrl';
  link: Scalars['String']['output'];
};

export type UploadPresignedUrlForCandidateSupportDocInput = {
  applicationCorrelationId: Scalars['ID']['input'];
  /** The uploading attachment blobId - generated by cm-ui */
  blobId: Scalars['ID']['input'];
  /** The uploading attachment content length */
  contentLength: Scalars['Int']['input'];
  /** The uploading attachment content type */
  contentType: Scalars['String']['input'];
  /** The uploading attachment file name */
  fileName: Scalars['String']['input'];
  jobId: Scalars['ID']['input'];
};

export type UploadPresignedUrlForCandidateSupportDocResponse =
  | ResponseError
  | UploadPresignedUrlForCandidateSupportDocResponseSuccess;

export type UploadPresignedUrlForCandidateSupportDocResponseSuccess = {
  __typename?: 'UploadPresignedUrlForCandidateSupportDocResponseSuccess';
  result: UploadPresignedUrl;
};

export type UpsertReviewReplyInput = {
  companyId: Scalars['String']['input'];
  from: Scalars['String']['input'];
  reviewId: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type UpsertReviewReplyResponse = {
  __typename?: 'UpsertReviewReplyResponse';
  success: Scalars['Boolean']['output'];
};

export type Upvotes = {
  __typename?: 'Upvotes';
  count: Scalars['Int']['output'];
  description: Scalars['String']['output'];
};

export enum UseCase {
  ADBUDGET = 'ADBUDGET',
  CAJA = 'CAJA',
  GENERIC = 'GENERIC',
  INVOICE = 'INVOICE',
  ORDER = 'ORDER',
  UPGRADE = 'UPGRADE',
}

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  permissions: UserPermissions;
  status: Scalars['String']['output'];
};

export type UserDetails = {
  __typename?: 'UserDetails';
  /** Email address of the user */
  email: Scalars['String']['output'];
  /** First name of the user */
  firstName: Scalars['String']['output'];
  /** User ID of the user */
  id: Scalars['String']['output'];
  /** Last name of the user */
  lastName: Scalars['String']['output'];
  /** Permissions of the user on the advertiser account */
  permissions?: Maybe<UserPermissions>;
  /** Status of the user */
  status: UserStatus;
};

export type UserDetailsInput = {
  /**
   * Advertiser ID which the user is currently signed in as
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** First name of the user */
  firstName: Scalars['String']['input'];
  /** Last name of the user */
  lastName: Scalars['String']['input'];
  /** AdCentre user ID of the user that is currently signed in */
  userId: Scalars['ID']['input'];
};

export type UserHeaderContext = {
  __typename?: 'UserHeaderContext';
  /** First name of user. Used for header dropdown text when advertiser context unavailable. */
  firstName: Scalars['String']['output'];
  /** Adcentre User ID */
  id: Scalars['ID']['output'];
  /** Full name of user. */
  name: Scalars['String']['output'];
};

export type UserInfoInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  permissions: UserPermissionsInput;
};

export enum UserIntentType {
  PREVIEW = 'PREVIEW',
  PURCHASE = 'PURCHASE',
}

export type UserJobAccessInput = {
  /** User access to job */
  jobAccess: Scalars['Boolean']['input'];
  /** Id of the user */
  userId: Scalars['ID']['input'];
};

export type UserListResponse = {
  __typename?: 'UserListResponse';
  users: User[];
};

export type UserOption = {
  __typename?: 'UserOption';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type UserPermissions = {
  __typename?: 'UserPermissions';
  canCreatePosition: Scalars['Boolean']['output'];
  hasAllPositionAccess: Scalars['Boolean']['output'];
  hasAnalytics: Scalars['Boolean']['output'];
  hasPremiumTalentSearch: Scalars['Boolean']['output'];
  role: Scalars['String']['output'];
};

export type UserPermissionsInput = {
  canCreatePosition: Scalars['Boolean']['input'];
  hasAllPositionAccess: Scalars['Boolean']['input'];
  hasAnalytics: Scalars['Boolean']['input'];
  hasPremiumTalentSearch: Scalars['Boolean']['input'];
  role: Role;
};

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  FRAUDULENT = 'FRAUDULENT',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED',
  UNVERIFIED = 'UNVERIFIED',
}

export type UserWithJobPermission = {
  __typename?: 'UserWithJobPermission';
  /** The users email address. */
  email: Scalars['String']['output'];
  /** The users first name. */
  firstName: Scalars['String']['output'];
  /** A flag denoting whether the user has access to all jobs created within the advertiser account. */
  hasAccessToAllJobs: Scalars['Boolean']['output'];
  /** A flag denoting whether the user has access to querying job. */
  hasAccessToCurrentJob: Scalars['Boolean']['output'];
  /** A flag denoting whether the user is an Administrator. */
  isAdmin: Scalars['Boolean']['output'];
  /** A flag denoting whether the user is job creator. */
  isJobCreator: Scalars['Boolean']['output'];
  /** A flag denoting whether the user is still required to activate their user account. */
  isPending: Scalars['Boolean']['output'];
  /** The users last name. */
  lastName: Scalars['String']['output'];
  /** Id of the user on the advertiser account. */
  userId: Scalars['ID']['output'];
};

export enum UsersParamsFilter {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
  UNVERIFIED = 'UNVERIFIED',
}

export enum UsersParamsInclude {
  ACCESS_DETAILS = 'ACCESS_DETAILS',
}

export type UsersParamsInput = {
  /** Filter array to narrow down the returned list of users based on access right. Can be include [ACTIVE | PENDING | DEACTIVATED | ADMIN_ROLE | USER_ROLE] */
  accessRightFilter?: InputMaybe<Array<InputMaybe<AccessRightFilter>>>;
  /** Add on properties to returned users. Can be "ACCESS_DETAILS" */
  include?: InputMaybe<UsersParamsInclude>;
  /** Filter array to narrow down the returned list of users based on user status. Can be include [ACTIVE | PENDING | DEACTIVATED] */
  usersFilter?: InputMaybe<Array<InputMaybe<UsersParamsFilter>>>;
};

export type UsersWithJobPermissionInput = {
  /** The advertiser id to fetch users tied to the account. */
  advertiserId: Scalars['ID']['input'];
  /** The job id to check users access permission */
  jobId: Scalars['ID']['input'];
};

export type ValidatePromoCodeInput = {
  /** The locale of the user. The locale is used to determine the currency and language of the user */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** The product id to verify the campaign is applicable to. ProductId must be a valid product id from a product set */
  productId: Scalars['String']['input'];
  /** The promo code to be verified */
  promoCode: Scalars['String']['input'];
};

export type ValidatePromoCodeResponse = {
  __typename?: 'ValidatePromoCodeResponse';
  /** Promo code is valid or is not valid */
  isValid: Scalars['Boolean']['output'];
  /** The message with detail as to why the promo code is not valid e.g. The promo code has expired */
  message: Scalars['String']['output'];
};

export type Verification = {
  __typename?: 'Verification';
  title: Scalars['String']['output'];
  viewFields: VerificationViewField[];
};

export type VerificationViewField = {
  __typename?: 'VerificationViewField';
  label: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

export type VerifiedField = {
  __typename?: 'VerifiedField';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ViewProfile = {
  __typename?: 'ViewProfile';
  atsProfileUrl: Scalars['String']['output'];
};

export type ViewSentMessage = {
  __typename?: 'ViewSentMessage';
  connectionId: Scalars['String']['output'];
};

export type ViewsInJobSearchResponse = {
  __typename?: 'ViewsInJobSearchResponse';
  adRatingConfidence?: Maybe<AnalyticsAdRatingConfidence>;
  clickConversionInPercentage?: Maybe<Scalars['Float']['output']>;
  hasAdRating: Scalars['Boolean']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  mobileViewsInPercentage: Scalars['Float']['output'];
  similarAdsAverage?: Maybe<Scalars['Int']['output']>;
  views: Scalars['Int']['output'];
};

export type ViewsInJobSearchWidgetInput = {
  jobId: Scalars['String']['input'];
};

export type VirtualAccount = {
  __typename?: 'VirtualAccount';
  accountNo?: Maybe<Scalars['String']['output']>;
  bank?: Maybe<Scalars['String']['output']>;
  bankCode?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['Int']['output']>;
};

export type WindowLocation = {
  /** The current URLs path */
  pathname: Scalars['String']['input'];
  /** The current URLs query (includes leading “?” if non-empty) */
  search: Scalars['String']['input'];
};

export enum WorkArrangement {
  HYBRID = 'HYBRID',
  ONSITE = 'ONSITE',
  REMOTE = 'REMOTE',
}

export type WorkHistory = {
  __typename?: 'WorkHistory';
  companyName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  duration?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  foundInCV: Scalars['Boolean']['output'];
  highlight?: Maybe<WorkHistoryHighlight>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type WorkHistoryHighlight = {
  __typename?: 'WorkHistoryHighlight';
  companyName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
};

export enum WorkType {
  CASUAL = 'CASUAL',
  CONTRACT_TEMP = 'CONTRACT_TEMP',
  FULLTIME = 'FULLTIME',
  PARTTIME = 'PARTTIME',
}

export type YourCandidate = {
  __typename?: 'YourCandidate';
  adcentreCandidateId: Scalars['Int']['output'];
  applicationId?: Maybe<Scalars['ID']['output']>;
  applicationTime: Scalars['String']['output'];
  candidateId: Scalars['Int']['output'];
  channel: YourCandidateChannel;
  emailAddress: Scalars['String']['output'];
  hasCoverLetter: Scalars['Boolean']['output'];
  hasResume: Scalars['Boolean']['output'];
  hasSelectionCriteria: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  jobId: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  lastRoleCompanyName?: Maybe<Scalars['String']['output']>;
  lastRoleJobTitle?: Maybe<Scalars['String']['output']>;
  lastUpdated: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  prospectId: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
  status: YourCandidateStatus;
  statusText: Scalars['String']['output'];
  totalApplications: Scalars['Int']['output'];
};

export enum YourCandidateChannel {
  NATIVE = 'NATIVE',
  UPLOADED = 'UPLOADED',
}

export type YourCandidateEdge = {
  __typename?: 'YourCandidateEdge';
  node: YourCandidate;
};

export enum YourCandidateStatus {
  ACCEPT = 'ACCEPT',
  INTERVIEW = 'INTERVIEW',
  NEW = 'NEW',
  OFFER = 'OFFER',
  PRESCREEN = 'PRESCREEN',
  REJECTED = 'REJECTED',
  SHORTLIST = 'SHORTLIST',
  VIEWED = 'VIEWED',
}

export type YourCandidatesInput = {
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type YourCandidatesPayloadError = {
  __typename?: 'YourCandidatesPayloadError';
  error: YourCandidatesPayloadErrorType;
};

export enum YourCandidatesPayloadErrorType {
  FORBIDDEN_ERROR = 'FORBIDDEN_ERROR',
  INPUT_VALIDATION_ERROR = 'INPUT_VALIDATION_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

export type YourCandidatesResponse =
  | YourCandidatesPayloadError
  | YourCandidatesResults;

export type YourCandidatesResults = {
  __typename?: 'YourCandidatesResults';
  edges: YourCandidateEdge[];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type CompleteAdditionalAdvertiserRegistrationMutationVariables = Exact<{
  input: SecureLinkInput;
}>;

export type CompleteAdditionalAdvertiserRegistrationMutation = {
  __typename?: 'Mutation';
  completeAdditionalAdvertiserRegistration:
    | { __typename: 'SecureLinkExpiredError'; message: string }
    | { __typename: 'SecureLinkInvalidError'; message: string }
    | { __typename: 'SecureLinkSuccessResponse'; redirectUri: string }
    | { __typename: 'SecureLinkUsedError'; message: string };
};

export type CompleteUserRegistrationMutationVariables = Exact<{
  input: SecureLinkInput;
}>;

export type CompleteUserRegistrationMutation = {
  __typename?: 'Mutation';
  completeUserRegistration:
    | { __typename: 'SecureLinkExpiredError'; message: string }
    | { __typename: 'SecureLinkInvalidError'; message: string }
    | { __typename: 'SecureLinkSuccessResponse'; redirectUri: string }
    | { __typename: 'SecureLinkUsedError'; message: string };
};

export type RegisterAdditionalAdvertiserMutationVariables = Exact<{
  input: RegisterAdditionalAdvertiserInput;
}>;

export type RegisterAdditionalAdvertiserMutation = {
  __typename?: 'Mutation';
  registerAdditionalAdvertiser?: {
    __typename?: 'RegisterPayload';
    seekId: string;
  } | null;
};

export type RegisterNewUserMutationVariables = Exact<{
  input: RegisterNewUserInput;
}>;

export type RegisterNewUserMutation = {
  __typename?: 'Mutation';
  registerNewUser?: {
    __typename?: 'RegisterPayload';
    seekId: string;
    redirectUrl?: string | null;
  } | null;
};

export type ResendActivationEmailMutationVariables = Exact<{
  input: ResendAccountActivationEmailInput;
}>;

export type ResendActivationEmailMutation = {
  __typename?: 'Mutation';
  resendAccountActivationEmail:
    | {
        __typename: 'ResendAccountActivationEmailResponseSuccess';
        message?: string | null;
        resent: boolean;
        hirerAccountId: string;
      }
    | { __typename: 'ResponseError'; error: ResponseErrorReason };
};

export type CheckRegistrationStatusQueryVariables = Exact<
  Record<string, never>
>;

export type CheckRegistrationStatusQuery = {
  __typename?: 'Query';
  registrationStatus: {
    __typename?: 'RegistrationStatusInfo';
    userExists: boolean;
  };
};

export type accountPartsFragment = {
  __typename?: 'HirerAccountOption';
  name: string;
  hirerAccountId: string;
  billingId?: string | null;
  accessRightStatus: AccessRightStatus;
  hirerAccountStatus: AdvertiserStatus;
  redirectUrl: string;
  role: Role;
};

export type GetAccountSelectionQueryVariables = Exact<{
  input?: InputMaybe<RedirectUrlInput>;
}>;

export type GetAccountSelectionQuery = {
  __typename?: 'Query';
  accountSelectionV3:
    | {
        __typename: 'AccountSelectionResultsV3';
        totalCount: number;
        defaultAccount?: {
          __typename?: 'HirerAccountOption';
          name: string;
          hirerAccountId: string;
          billingId?: string | null;
          accessRightStatus: AccessRightStatus;
          hirerAccountStatus: AdvertiserStatus;
          redirectUrl: string;
          role: Role;
        } | null;
        edges: Array<{
          __typename?: 'AccountSelectionEdgeV3';
          node: {
            __typename?: 'HirerAccountOption';
            name: string;
            hirerAccountId: string;
            billingId?: string | null;
            accessRightStatus: AccessRightStatus;
            hirerAccountStatus: AdvertiserStatus;
            redirectUrl: string;
            role: Role;
            childAccounts: Array<{
              __typename?: 'HirerAccountOption';
              name: string;
              hirerAccountId: string;
              billingId?: string | null;
              accessRightStatus: AccessRightStatus;
              hirerAccountStatus: AdvertiserStatus;
              redirectUrl: string;
              role: Role;
            }>;
          };
        }>;
      }
    | { __typename: 'ResponseError'; error: ResponseErrorReason };
};

export type GetCurrentUserQueryVariables = Exact<Record<string, never>>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  currentUser:
    | { __typename: 'CurrentUserContext'; id: string }
    | { __typename: 'MissingUserIdClaimError'; message: string };
  currentAdvertiser:
    | {
        __typename: 'CurrentAdvertiserContext';
        seekId: string;
        advertiserId: string;
      }
    | { __typename: 'MissingAdvertiserIdClaimError'; message: string };
};

export type GetCountriesQueryVariables = Exact<Record<string, never>>;

export type GetCountriesQuery = {
  __typename?: 'Query';
  countrySelectionOptions: {
    __typename?: 'CountrySelectionOptions';
    options: Array<{
      __typename?: 'Country';
      name: string;
      countryCode: string;
    }>;
  };
};

export type GetHeaderFooterContextQueryVariables = Exact<{
  input: HeaderFooterContextInput;
}>;

export type GetHeaderFooterContextQuery = {
  __typename?: 'Query';
  headerFooterContext?: {
    __typename?: 'HeaderFooterContext';
    identityContext?: {
      __typename?: 'IdentityHeaderContext';
      email: string;
      act?: {
        __typename?: 'ActorContext';
        operatorEmail: string;
        isCsView: boolean;
      } | null;
    } | null;
    advertiser?: {
      __typename?: 'AdvertiserHeaderContext';
      billingId: string;
      hasMultipleAccounts: boolean;
      isActivationPending: boolean;
      isAgency: boolean;
      name: string;
      showOnCreditHoldMessage: boolean;
      switchAccountsHref?: string | null;
      permissionOptions: {
        __typename?: 'PermissionOptions';
        canCreateJobs: boolean;
        canManageBrand: boolean;
        canManageUsers: boolean;
        canPayInvoices: boolean;
        canViewInvoiceHistory: boolean;
        canViewManagerReports: boolean;
        canManageCompanyProfile: boolean;
        canViewInsightsRoleReport: boolean;
      };
    } | null;
    user?: {
      __typename?: 'UserHeaderContext';
      name: string;
      firstName: string;
      id: string;
    } | null;
  } | null;
};

export type GetIdentityQueryVariables = Exact<Record<string, never>>;

export type GetIdentityQuery = {
  __typename?: 'Query';
  identity: {
    __typename?: 'Identity';
    subject: { __typename?: 'Subject'; email: string };
  };
};

export type GetRegistrationOptionsQueryVariables = Exact<Record<string, never>>;

export type GetRegistrationOptionsQuery = {
  __typename?: 'Query';
  registrationOptions: {
    __typename?: 'RegistrationOptions';
    allowAgencyRegistration: boolean;
  };
};

export const accountPartsFragmentDoc = gql`
  fragment accountParts on HirerAccountOption {
    name
    hirerAccountId
    billingId
    accessRightStatus
    hirerAccountStatus
    redirectUrl(input: $input)
    role
  }
`;
export const CompleteAdditionalAdvertiserRegistrationDocument = gql`
  mutation CompleteAdditionalAdvertiserRegistration($input: SecureLinkInput!) {
    completeAdditionalAdvertiserRegistration(input: $input) {
      ... on SecureLinkSuccessResponse {
        __typename
        redirectUri
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;
export type CompleteAdditionalAdvertiserRegistrationMutationFn =
  Apollo.MutationFunction<
    CompleteAdditionalAdvertiserRegistrationMutation,
    CompleteAdditionalAdvertiserRegistrationMutationVariables
  >;

/**
 * __useCompleteAdditionalAdvertiserRegistrationMutation__
 *
 * To run a mutation, you first call `useCompleteAdditionalAdvertiserRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAdditionalAdvertiserRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAdditionalAdvertiserRegistrationMutation, { data, loading, error }] = useCompleteAdditionalAdvertiserRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteAdditionalAdvertiserRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteAdditionalAdvertiserRegistrationMutation,
    CompleteAdditionalAdvertiserRegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteAdditionalAdvertiserRegistrationMutation,
    CompleteAdditionalAdvertiserRegistrationMutationVariables
  >(CompleteAdditionalAdvertiserRegistrationDocument, options);
}
export type CompleteAdditionalAdvertiserRegistrationMutationHookResult =
  ReturnType<typeof useCompleteAdditionalAdvertiserRegistrationMutation>;
export type CompleteAdditionalAdvertiserRegistrationMutationResult =
  Apollo.MutationResult<CompleteAdditionalAdvertiserRegistrationMutation>;
export type CompleteAdditionalAdvertiserRegistrationMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteAdditionalAdvertiserRegistrationMutation,
    CompleteAdditionalAdvertiserRegistrationMutationVariables
  >;
export const CompleteUserRegistrationDocument = gql`
  mutation CompleteUserRegistration($input: SecureLinkInput!) {
    completeUserRegistration(input: $input) {
      ... on SecureLinkSuccessResponse {
        __typename
        redirectUri
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;
export type CompleteUserRegistrationMutationFn = Apollo.MutationFunction<
  CompleteUserRegistrationMutation,
  CompleteUserRegistrationMutationVariables
>;

/**
 * __useCompleteUserRegistrationMutation__
 *
 * To run a mutation, you first call `useCompleteUserRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteUserRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeUserRegistrationMutation, { data, loading, error }] = useCompleteUserRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteUserRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteUserRegistrationMutation,
    CompleteUserRegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteUserRegistrationMutation,
    CompleteUserRegistrationMutationVariables
  >(CompleteUserRegistrationDocument, options);
}
export type CompleteUserRegistrationMutationHookResult = ReturnType<
  typeof useCompleteUserRegistrationMutation
>;
export type CompleteUserRegistrationMutationResult =
  Apollo.MutationResult<CompleteUserRegistrationMutation>;
export type CompleteUserRegistrationMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteUserRegistrationMutation,
    CompleteUserRegistrationMutationVariables
  >;
export const RegisterAdditionalAdvertiserDocument = gql`
  mutation RegisterAdditionalAdvertiser(
    $input: RegisterAdditionalAdvertiserInput!
  ) {
    registerAdditionalAdvertiser(input: $input) {
      seekId
    }
  }
`;
export type RegisterAdditionalAdvertiserMutationFn = Apollo.MutationFunction<
  RegisterAdditionalAdvertiserMutation,
  RegisterAdditionalAdvertiserMutationVariables
>;

/**
 * __useRegisterAdditionalAdvertiserMutation__
 *
 * To run a mutation, you first call `useRegisterAdditionalAdvertiserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAdditionalAdvertiserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAdditionalAdvertiserMutation, { data, loading, error }] = useRegisterAdditionalAdvertiserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterAdditionalAdvertiserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterAdditionalAdvertiserMutation,
    RegisterAdditionalAdvertiserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterAdditionalAdvertiserMutation,
    RegisterAdditionalAdvertiserMutationVariables
  >(RegisterAdditionalAdvertiserDocument, options);
}
export type RegisterAdditionalAdvertiserMutationHookResult = ReturnType<
  typeof useRegisterAdditionalAdvertiserMutation
>;
export type RegisterAdditionalAdvertiserMutationResult =
  Apollo.MutationResult<RegisterAdditionalAdvertiserMutation>;
export type RegisterAdditionalAdvertiserMutationOptions =
  Apollo.BaseMutationOptions<
    RegisterAdditionalAdvertiserMutation,
    RegisterAdditionalAdvertiserMutationVariables
  >;
export const RegisterNewUserDocument = gql`
  mutation RegisterNewUser($input: RegisterNewUserInput!) {
    registerNewUser(input: $input) {
      seekId
      redirectUrl
    }
  }
`;
export type RegisterNewUserMutationFn = Apollo.MutationFunction<
  RegisterNewUserMutation,
  RegisterNewUserMutationVariables
>;

/**
 * __useRegisterNewUserMutation__
 *
 * To run a mutation, you first call `useRegisterNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerNewUserMutation, { data, loading, error }] = useRegisterNewUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterNewUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterNewUserMutation,
    RegisterNewUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterNewUserMutation,
    RegisterNewUserMutationVariables
  >(RegisterNewUserDocument, options);
}
export type RegisterNewUserMutationHookResult = ReturnType<
  typeof useRegisterNewUserMutation
>;
export type RegisterNewUserMutationResult =
  Apollo.MutationResult<RegisterNewUserMutation>;
export type RegisterNewUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterNewUserMutation,
  RegisterNewUserMutationVariables
>;
export const ResendActivationEmailDocument = gql`
  mutation ResendActivationEmail($input: ResendAccountActivationEmailInput!) {
    resendAccountActivationEmail(input: $input) {
      ... on ResendAccountActivationEmailResponseSuccess {
        __typename
        message
        resent
        hirerAccountId
      }
      ... on ResponseError {
        __typename
        error
      }
    }
  }
`;
export type ResendActivationEmailMutationFn = Apollo.MutationFunction<
  ResendActivationEmailMutation,
  ResendActivationEmailMutationVariables
>;

/**
 * __useResendActivationEmailMutation__
 *
 * To run a mutation, you first call `useResendActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationEmailMutation, { data, loading, error }] = useResendActivationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendActivationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >(ResendActivationEmailDocument, options);
}
export type ResendActivationEmailMutationHookResult = ReturnType<
  typeof useResendActivationEmailMutation
>;
export type ResendActivationEmailMutationResult =
  Apollo.MutationResult<ResendActivationEmailMutation>;
export type ResendActivationEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendActivationEmailMutation,
  ResendActivationEmailMutationVariables
>;
export const CheckRegistrationStatusDocument = gql`
  query CheckRegistrationStatus {
    registrationStatus(input: { filter: "includeDeactivated" }) {
      userExists
    }
  }
`;

/**
 * __useCheckRegistrationStatusQuery__
 *
 * To run a query within a React component, call `useCheckRegistrationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckRegistrationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckRegistrationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckRegistrationStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CheckRegistrationStatusQuery,
    CheckRegistrationStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckRegistrationStatusQuery,
    CheckRegistrationStatusQueryVariables
  >(CheckRegistrationStatusDocument, options);
}
export function useCheckRegistrationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckRegistrationStatusQuery,
    CheckRegistrationStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckRegistrationStatusQuery,
    CheckRegistrationStatusQueryVariables
  >(CheckRegistrationStatusDocument, options);
}
export function useCheckRegistrationStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CheckRegistrationStatusQuery,
        CheckRegistrationStatusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CheckRegistrationStatusQuery,
    CheckRegistrationStatusQueryVariables
  >(CheckRegistrationStatusDocument, options);
}
export type CheckRegistrationStatusQueryHookResult = ReturnType<
  typeof useCheckRegistrationStatusQuery
>;
export type CheckRegistrationStatusLazyQueryHookResult = ReturnType<
  typeof useCheckRegistrationStatusLazyQuery
>;
export type CheckRegistrationStatusSuspenseQueryHookResult = ReturnType<
  typeof useCheckRegistrationStatusSuspenseQuery
>;
export type CheckRegistrationStatusQueryResult = Apollo.QueryResult<
  CheckRegistrationStatusQuery,
  CheckRegistrationStatusQueryVariables
>;
export const GetAccountSelectionDocument = gql`
  query GetAccountSelection($input: RedirectUrlInput) {
    accountSelectionV3 {
      ... on AccountSelectionResultsV3 {
        __typename
        totalCount
        defaultAccount {
          ...accountParts
        }
        edges {
          node {
            ...accountParts
            childAccounts {
              ...accountParts
            }
          }
        }
      }
      ... on ResponseError {
        __typename
        error
      }
    }
  }
  ${accountPartsFragmentDoc}
`;

/**
 * __useGetAccountSelectionQuery__
 *
 * To run a query within a React component, call `useGetAccountSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountSelectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAccountSelectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountSelectionQuery,
    GetAccountSelectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountSelectionQuery,
    GetAccountSelectionQueryVariables
  >(GetAccountSelectionDocument, options);
}
export function useGetAccountSelectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountSelectionQuery,
    GetAccountSelectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountSelectionQuery,
    GetAccountSelectionQueryVariables
  >(GetAccountSelectionDocument, options);
}
export function useGetAccountSelectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAccountSelectionQuery,
        GetAccountSelectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAccountSelectionQuery,
    GetAccountSelectionQueryVariables
  >(GetAccountSelectionDocument, options);
}
export type GetAccountSelectionQueryHookResult = ReturnType<
  typeof useGetAccountSelectionQuery
>;
export type GetAccountSelectionLazyQueryHookResult = ReturnType<
  typeof useGetAccountSelectionLazyQuery
>;
export type GetAccountSelectionSuspenseQueryHookResult = ReturnType<
  typeof useGetAccountSelectionSuspenseQuery
>;
export type GetAccountSelectionQueryResult = Apollo.QueryResult<
  GetAccountSelectionQuery,
  GetAccountSelectionQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    currentUser {
      __typename
      ... on CurrentUserContext {
        id
      }
      ... on Error {
        message
      }
    }
    currentAdvertiser {
      ... on CurrentAdvertiserContext {
        __typename
        seekId
        advertiserId
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentUserSuspenseQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetCountriesDocument = gql`
  query GetCountries {
    countrySelectionOptions {
      options {
        name
        countryCode
      }
    }
  }
`;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export function useGetCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export function useGetCountriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCountriesQuery,
        GetCountriesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export type GetCountriesQueryHookResult = ReturnType<
  typeof useGetCountriesQuery
>;
export type GetCountriesLazyQueryHookResult = ReturnType<
  typeof useGetCountriesLazyQuery
>;
export type GetCountriesSuspenseQueryHookResult = ReturnType<
  typeof useGetCountriesSuspenseQuery
>;
export type GetCountriesQueryResult = Apollo.QueryResult<
  GetCountriesQuery,
  GetCountriesQueryVariables
>;
export const GetHeaderFooterContextDocument = gql`
  query GetHeaderFooterContext($input: HeaderFooterContextInput!) {
    headerFooterContext {
      identityContext {
        email
        act(input: $input) {
          operatorEmail
          isCsView
        }
      }
      advertiser {
        billingId
        hasMultipleAccounts
        isActivationPending
        isAgency
        name
        permissionOptions {
          canCreateJobs
          canManageBrand
          canManageUsers
          canPayInvoices
          canViewInvoiceHistory
          canViewManagerReports
          canManageCompanyProfile
          canViewInsightsRoleReport
        }
        showOnCreditHoldMessage
        switchAccountsHref
      }
      user {
        name
        firstName
        id
      }
    }
  }
`;

/**
 * __useGetHeaderFooterContextQuery__
 *
 * To run a query within a React component, call `useGetHeaderFooterContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeaderFooterContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeaderFooterContextQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHeaderFooterContextQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHeaderFooterContextQuery,
    GetHeaderFooterContextQueryVariables
  > &
    (
      | { variables: GetHeaderFooterContextQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetHeaderFooterContextQuery,
    GetHeaderFooterContextQueryVariables
  >(GetHeaderFooterContextDocument, options);
}
export function useGetHeaderFooterContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHeaderFooterContextQuery,
    GetHeaderFooterContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHeaderFooterContextQuery,
    GetHeaderFooterContextQueryVariables
  >(GetHeaderFooterContextDocument, options);
}
export function useGetHeaderFooterContextSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetHeaderFooterContextQuery,
        GetHeaderFooterContextQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetHeaderFooterContextQuery,
    GetHeaderFooterContextQueryVariables
  >(GetHeaderFooterContextDocument, options);
}
export type GetHeaderFooterContextQueryHookResult = ReturnType<
  typeof useGetHeaderFooterContextQuery
>;
export type GetHeaderFooterContextLazyQueryHookResult = ReturnType<
  typeof useGetHeaderFooterContextLazyQuery
>;
export type GetHeaderFooterContextSuspenseQueryHookResult = ReturnType<
  typeof useGetHeaderFooterContextSuspenseQuery
>;
export type GetHeaderFooterContextQueryResult = Apollo.QueryResult<
  GetHeaderFooterContextQuery,
  GetHeaderFooterContextQueryVariables
>;
export const GetIdentityDocument = gql`
  query GetIdentity {
    identity {
      subject {
        email
      }
    }
  }
`;

/**
 * __useGetIdentityQuery__
 *
 * To run a query within a React component, call `useGetIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIdentityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIdentityQuery,
    GetIdentityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIdentityQuery, GetIdentityQueryVariables>(
    GetIdentityDocument,
    options,
  );
}
export function useGetIdentityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIdentityQuery,
    GetIdentityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIdentityQuery, GetIdentityQueryVariables>(
    GetIdentityDocument,
    options,
  );
}
export function useGetIdentitySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetIdentityQuery,
        GetIdentityQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetIdentityQuery, GetIdentityQueryVariables>(
    GetIdentityDocument,
    options,
  );
}
export type GetIdentityQueryHookResult = ReturnType<typeof useGetIdentityQuery>;
export type GetIdentityLazyQueryHookResult = ReturnType<
  typeof useGetIdentityLazyQuery
>;
export type GetIdentitySuspenseQueryHookResult = ReturnType<
  typeof useGetIdentitySuspenseQuery
>;
export type GetIdentityQueryResult = Apollo.QueryResult<
  GetIdentityQuery,
  GetIdentityQueryVariables
>;
export const GetRegistrationOptionsDocument = gql`
  query GetRegistrationOptions {
    registrationOptions {
      allowAgencyRegistration
    }
  }
`;

/**
 * __useGetRegistrationOptionsQuery__
 *
 * To run a query within a React component, call `useGetRegistrationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistrationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistrationOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegistrationOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRegistrationOptionsQuery,
    GetRegistrationOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRegistrationOptionsQuery,
    GetRegistrationOptionsQueryVariables
  >(GetRegistrationOptionsDocument, options);
}
export function useGetRegistrationOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegistrationOptionsQuery,
    GetRegistrationOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRegistrationOptionsQuery,
    GetRegistrationOptionsQueryVariables
  >(GetRegistrationOptionsDocument, options);
}
export function useGetRegistrationOptionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetRegistrationOptionsQuery,
        GetRegistrationOptionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRegistrationOptionsQuery,
    GetRegistrationOptionsQueryVariables
  >(GetRegistrationOptionsDocument, options);
}
export type GetRegistrationOptionsQueryHookResult = ReturnType<
  typeof useGetRegistrationOptionsQuery
>;
export type GetRegistrationOptionsLazyQueryHookResult = ReturnType<
  typeof useGetRegistrationOptionsLazyQuery
>;
export type GetRegistrationOptionsSuspenseQueryHookResult = ReturnType<
  typeof useGetRegistrationOptionsSuspenseQuery
>;
export type GetRegistrationOptionsQueryResult = Apollo.QueryResult<
  GetRegistrationOptionsQuery,
  GetRegistrationOptionsQueryVariables
>;
