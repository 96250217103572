import { useAuthenticator, useHirerAuth } from '@seek/hirer-auth-react';

import MainLayout from 'src/views/layouts/MainLayout/MainLayout';
import PageLoader from 'src/views/shared/PageLoader/PageLoader';

import type { PageRouteProps } from './types';

export const AuthenticatedPageRoute = ({
  activeTabId,
  element,
  currentPage,
}: PageRouteProps) => {
  useAuthenticator({ level: 'User' });
  const { loading } = useHirerAuth();

  return (
    <MainLayout
      activeTabId={activeTabId}
      authenticationStatus="pending"
      renderBasicHeader
      analyticsCurrentPage={currentPage}
    >
      {loading ? <PageLoader /> : element}
    </MainLayout>
  );
};
